import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationSquare,
    faSquareList,
    faClub,
    faMessageLines,
    faNote,
} from "@fortawesome/pro-light-svg-icons";
import { faSquareList as faSquareListBlack } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { RootDataGuest } from "../../types/guest";
import { TranslationsKeys } from "../../types/translations";
import { useHistory } from "react-router-dom";

const PlayerTopBoxes = ({ playerData }: { playerData: RootDataGuest }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const actionHandler = (action: string) => {
        const params = new URLSearchParams();
        params.set("action", action);
        history.push({
            pathname: history.location.pathname,
            search: `?${params.toString()}`,
        });
    };

    const topBoxDetails = [
        {
            label: t("member"),
            icon: faClub,
            value: "",
            key: playerData.player.is_member,
            bgColor: "bg-[#1DC690]",
            action: () => history.push(`${playerData.player.id}/edit`),
        },
        {
            label: t("on_white_list_until"),
            icon: faSquareList,
            value:
                playerData?.whitelist?.end_date
                    ?.replace(/\//g, "-")
                    .split("T")[0] || " " + t("indefinitely"),
            key: playerData.player.is_white_listed,
            action: () => actionHandler("playpause"),
        },
        {
            label: t("on_black_list_until"),
            icon: faSquareListBlack,
            value:
                playerData?.blacklist?.end_date
                    ?.replace(/\//g, "-")
                    .split("T")[0] || " " + t("indefinitely"),
            key: playerData.player.is_black_listed,
            action: () => actionHandler("blacklist"),
        },
        {
            label: t("visits_per", {
                entity: t(
                    playerData?.player?.active_visit_limit
                        ?.period as TranslationsKeys
                ) as string,
            }),
            icon: faExclamationSquare,
            value:
                " " +
                playerData.player.active_visit_limit?.guest_period_visits +
                "/" +
                playerData.player.active_visit_limit?.limit,

            key: playerData.player.active_visit_limit,
            action: () => actionHandler("visit_limit"),
        },
        {
            label:
                playerData.check_in_message_count === 1
                    ? t("has_message", {
                          entity: playerData.check_in_message_count,
                      })
                    : t("has_messages", {
                          entity: playerData.check_in_message_count,
                      }),
            icon: faMessageLines,
            value: "",
            key: playerData.check_in_message_count > 0,
            action: () => actionHandler("guest_messages"),
        },
        {
            label: t(playerData.notes_count === 1 ? "has_note" : "has_notes", {
                entity: playerData.notes_count,
            }),

            icon: faNote,
            value: "",
            key: playerData.notes_count > 0,
            action: () => actionHandler("guest_notes"),
        },
    ];

    return (
        <>
            {topBoxDetails.map(
                ({
                    label,
                    value,
                    bgColor = "bg-[#FBFBFB]",
                    key,
                    icon,
                    action,
                }) =>
                    key &&
                    bgColor && (
                        <div
                            key={label}
                            className={`row-span-1 mb-5 flex h-14 max-w-fit cursor-pointer items-center justify-evenly rounded-md ${bgColor} px-5 shadow-md `}
                            onClick={action}
                        >
                            <FontAwesomeIcon
                                icon={icon as IconProp}
                                size="lg"
                                className="mr-3"
                            />
                            <div className="flex space-x-1 text-lg">
                                <div>{label}</div>
                                <div>{value}</div>
                            </div>
                        </div>
                    )
            )}
        </>
    );
};

export default PlayerTopBoxes;
