import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as S from "./ExportDateFields.styled";

function ExportDateFields({ register, errors }) {
    const { t } = useTranslation();

    return (
        <>
            <S.FormControl error={errors.from_date} required={true}>
                <label>{t("from_date")}:</label>
                <input
                    type="date"
                    min={dayjs(new Date("2020/06/01")).format("YYYY-MM-DD")}
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("from_date", { required: true })}
                />{" "}
                <input
                    type="time"
                    {...register("from_time", { required: false })}
                />
            </S.FormControl>

            <S.FormControl error={errors.until_date} required={true}>
                <label>{t("until_date")}:</label>
                <input
                    type="date"
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("until_date", { required: true })}
                />
                <input
                    type="time"
                    {...register("until_time", { required: false })}
                />
            </S.FormControl>
        </>
    );
}

export default ExportDateFields;
