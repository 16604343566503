import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";
import Popup from "./Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useSignature from "../../hooks/useSignature";
import { PenPoint } from "../../types/signature";
import useCustomToast from "../../hooks/useCustomToast";
import { TranslationsKeys } from "../../types/translations";
import SignatureLanguageMenu from "../SignatureLanguageMenu";

const sleep = (ms: number): Promise<void> =>
    new Promise(resolve => setTimeout(resolve, ms));

function AddSignaturePopup({
    setGeneratedImage,
    signatureTextKey,
    signatureLanguage,
}: {
    setGeneratedImage: (val: string) => void;
    signatureTextKey: TranslationsKeys;
    signatureLanguage?: "en" | "nl";
}) {
    const { t } = useTranslation();
    const { startSignature, closeDevice } = useSignature();
    const { close: closePopup } = usePopup();
    const [currentLang, setCurrentLang] = useState("nl");
    const [isDeviceProcessing, setIsDeviceProcessing] = useState(false);

    const [hasError, setHasError] = useState(false);

    const { customToast } = useCustomToast();

    const okCallback = useCallback(
        (imgUrl: string, penPoints: PenPoint[]) => {
            const hasRegisteredPoint =
                penPoints.find(element => element.y < 4500) !== undefined;

            if (hasRegisteredPoint) {
                setGeneratedImage(imgUrl);
                closeDevice();
                closePopup();
            } else {
                customToast("warning", {
                    title: t("signature_is_empty"),
                });
            }
        },
        [closeDevice, closePopup, customToast, setGeneratedImage, t]
    );

    const okRef = useRef(okCallback);
    const signatureActiveRef = useRef(false);

    const cancelCallback = useCallback(() => {
        closePopup();
    }, [closePopup]);

    const errorCallback = useCallback(
        (errorType: string, errorMessage: string) => {
            setHasError(true);
        },
        []
    );

    useEffect(() => {
        // Prevent React 18 double render in dev mode
        if (signatureActiveRef.current) return;
        signatureActiveRef.current = true;

        const lang = signatureLanguage || currentLang;
        setCurrentLang(lang);

        const callbacks = {
            ok: okRef.current,
            cancel: cancelCallback,
            error: errorCallback,
        };

        const buttonLabels = {
            ok: t("ok", { lng: lang }),
            cancel: t("cancel", { lng: lang }),
            clear: t("clear", { lng: lang }),
        };

        startSignature(
            callbacks,
            t(signatureTextKey, { lng: lang }) as string,
            buttonLabels
        );
    }, [
        cancelCallback,
        errorCallback,
        startSignature,
        signatureTextKey,
        t,
        closeDevice,
        currentLang,
        signatureLanguage,
    ]);

    const onLangChange = useCallback(
        async (lang: string) => {
            // Prevent button spamming
            setIsDeviceProcessing(true);

            const callbacks = {
                ok: okRef.current,
                cancel: cancelCallback,
                error: errorCallback,
            };

            setCurrentLang(lang);
            closeDevice();

            const buttonLabels = {
                ok: t("ok", { lng: lang }),
                cancel: t("cancel", { lng: lang }),
                clear: t("clear", { lng: lang }),
            };

            // Give device time to close
            await sleep(500);

            startSignature(
                callbacks,
                t(signatureTextKey, { lng: lang }) as string,
                buttonLabels
            );

            setIsDeviceProcessing(false);
        },
        [
            cancelCallback,
            closeDevice,
            errorCallback,
            signatureTextKey,
            startSignature,
            t,
        ]
    );

    return (
        <Popup>
            <div className="w-[800px]">
                <div className="flex justify-between border-b pb-5 text-2xl">
                    <span>{t("add_signature")}</span>
                    <SignatureLanguageMenu
                        currentLang={currentLang}
                        onLangChange={onLangChange}
                        isDeviceProcessing={isDeviceProcessing}
                    />
                </div>
                {hasError ? (
                    <div className="p-5">
                        <div className="text-center text-6xl">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle as IconProp}
                            />
                        </div>
                        <div className="mx-auto mt-6 w-80 text-center text-xl">
                            {t("cannot_connect_to_signature_pad")}
                        </div>
                    </div>
                ) : (
                    <canvas
                        id="signatureCanvas"
                        width="800"
                        height="480"
                        className="border border-gray-200"
                    ></canvas>
                )}
            </div>
        </Popup>
    );
}

export default AddSignaturePopup;
