import i18n from "i18next";
import { getTimeRangeHours } from "./timeRange";

export const tableMap = {
    total_count: {
        title: i18n.t("totals"),
    },
    men_count: {
        title: i18n.t("men"),
    },
    women_count: {
        title: i18n.t("women"),
    },
};

type TableColumn = {
    Header: string;
    accessor: string;
    Cell?: (props: any) => JSX.Element;
};

export const tableBaseColumns: TableColumn[] = [
    {
        Header: i18n.t("days"),
        accessor: "days",
    },
];

export const tableHourColumns: TableColumn[] = getTimeRangeHours().map(hour => {
    return {
        Header: hour,
        accessor: hour,
    };
});

export const getTableColumns = () => {
    return [...tableBaseColumns, ...tableHourColumns];
};

type GenderCount = {
    men_count: number;
    women_count: number;
    total_count: number;
};

type FloorCount = {
    [key: string]: {
        [key: string]: GenderCount;
    };
};

type Row = { [key: string]: string };

export const getTableRows = (data: FloorCount, countKey: string) => {
    let tableRows: Row[] = [];

    const getTableRow = (dateString: string) => {
        const tableRow: Row = {};
        const hourList = getTimeRangeHours();

        hourList.forEach(hour => {
            tableRow[hour] = "-";
            Object.entries(data[dateString]).forEach(([key, val]) => {
                tableRow.days = dateString;

                if (hour === key) {
                    tableRow[hour] = String(val[countKey as keyof GenderCount]);
                }
            });
        });

        return tableRow;
    };

    if (data) {
        Object.entries(data).forEach(([key, val]) => {
            tableRows.push(getTableRow(key));
        });
    }

    return tableRows;
};
