import React from "react";
import { usePopupStore } from "../../store/popupStore";
import WelcomeBackPopup from "./WelcomeBackPopup";
import ExportEntryLogPopup from "./ExportEntryLogPopup/ExportEntryLogPopup";
import DeletePopup from "./DeletePopup";
import ExportMessagesPopup from "./ExportMessagesPopup/ExportMessagesPopup";
import ExportPreventionPlanOverviewPopup from "./ExportPreventionPlanOverviewPopup/ExportPreventionPlanOverviewPopup";
import ExportFloorNumbersOverviewPopup from "./ExportFloorNumbersOverviewPopup";
import ScanLoyaltyCardPopup from "./ScanLoyaltyCardPopup/ScanLoyaltyCardPopup";
import AddDevicePopup from "./AddDevicePopup/AddDevicePopup";
import AddSignaturePopup from "./AddSignaturePopup";
import ExportBlackListPopup from "./ExportBlackListPopup/ExportBlackListPopup";
import ExportPlayPausePopup from "./ExportPlayPausePopup/ExportPlayPausePopup";
const PopupContainer = () => {
    const { popupType, data } = usePopupStore();

    return (
        <>
            {(() => {
                switch (popupType) {
                    case "ADD_SIGNATURE":
                        return (
                            <AddSignaturePopup
                                setGeneratedImage={data.setGeneratedImage}
                                signatureTextKey={data.signatureTextKey}
                                signatureLanguage={data.signatureLanguage}
                            />
                        );
                    case "WHITELIST_EXPIRE_TALK":
                        return (
                            <WelcomeBackPopup
                                recordId={data.recordId}
                                playerId={data.playerId}
                                list={"wl"}
                            />
                        );
                    case "BLACKLIST_EXPIRE_TALK":
                        return (
                            <WelcomeBackPopup
                                recordId={data.recordId}
                                playerId={data.playerId}
                                list={"bl"}
                            />
                        );
                    case "EXPORT_ENTRY_LOG":
                        return <ExportEntryLogPopup />;
                    case "EXPORT_BLACKLIST":
                        return <ExportBlackListPopup />;
                    case "EXPORT_PLAYPAUSE":
                        return <ExportPlayPausePopup />;
                    case "EXPORT_MESSAGES":
                        return <ExportMessagesPopup />;
                    case "EXPORT_PREVENTION_PLAN_OVERVIEW":
                        return <ExportPreventionPlanOverviewPopup />;
                    case "EXPORT_FLOOR_NUMBERS_OVERVIEW":
                        return <ExportFloorNumbersOverviewPopup />;
                    case "SCAN_LOYALTY_CARD_POPUP":
                        return (
                            <ScanLoyaltyCardPopup
                                updateLoyaltyCard={data.updateLoyaltyCard}
                            />
                        );
                    case "ADD_DEVICE_POPUP":
                        return (
                            <AddDevicePopup type={data?.type} set={data?.set} />
                        );
                    case "DELETE_ENTITY":
                        return (
                            <DeletePopup
                                deleteHandler={data.deleteHandler}
                                entityName={data.entityName}
                            />
                        );
                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default PopupContainer;
