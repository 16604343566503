import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import backgroundLoginImage from "../../assets/backgroundLogin.jpg";
import Logo from "../../assets/logo.png";
import client from "../../client";
import CustomSelect from "../../components/CustomSelect";
import { useUserStore } from "../../store/userStore";
import * as S from "./Welcome.styled";
import Button from "../../components/Button";

const Welcome = () => {
    const { user } = useUserStore();
    const { t } = useTranslation();
    const history = useHistory();
    const [isSelected, setIsSelected] = useState(user?.current_location_id);

    const changeLocation = locationId => {
        client.post(`/api/user/set-location/${locationId}`);
    };

    const confirm = useCallback(() => {
        history.push("/check-in");
    }, [history]);

    return (
        <S.Mask>
            <S.Wrapper backgroundLogin={backgroundLoginImage}>
                <S.Container>
                    <S.Logo src={Logo} alt="logo" />

                    <div>
                        <S.UserAvatar src={user.profile_photo_url} />
                    </div>

                    <S.Text>
                        {t("hello")} {user.first_name}
                    </S.Text>

                    <S.Text>{t("signing_in_to_location")}:</S.Text>

                    <S.SelectContainer>
                        <CustomSelect
                            options={user.locations.map(location => ({
                                value: location.id,
                                text: location.name,
                            }))}
                            onChangeCallback={changeLocation}
                            isSelected={isSelected}
                            setIsSelected={setIsSelected}
                        />
                        <Button className="btn-primary" onClick={confirm}>
                            {t("confirm")}
                        </Button>
                    </S.SelectContainer>
                </S.Container>
            </S.Wrapper>
        </S.Mask>
    );
};

export default Welcome;
