import styled from "styled-components";

export const MenuItem = styled.button`
    font-size: 20px;
    display: flex;
    align-items: center;
    ${props =>
        props.active &&
        `
        color: ${props.theme.main.secondaryColor};
        `}
    line-height: 1;
    width: 100%;
    margin-bottom: 24px;
    text-align: left;
    transition: 0.2s ease-in-out;

    &:hover {
        color: ${props => props.theme.main.secondaryColor};
    }

    span {
        text-decoration: underline;
    }
`;

export const MenuIcon = styled.div`
    line-height: 1;
    min-width: 40px;
    > svg {
        margin-right: 14px;
        font-size: 24px;
    }
    > img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
`;
