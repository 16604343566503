import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { dateOptions } from "../../utils/filterOptions";
import DataTable from "../DataTable/DataTable";
import Filter from "../Filter/Filter";
import { columns } from "../../utils/tableColumns";
import { TranslationsKeys } from "../../types/translations";
import { RootDataGuest } from "../../types/guest";

const Activity = ({ playerData }: { playerData: RootDataGuest }) => {
    const { t } = useTranslation();
    const params = useParams();
    const { activity } = columns;
    const {
        onScroll,
        containerRef,
        isLoading,
        filteredData,
        updateFilterParams,
    } = useInfiniteScroll(`/api/guests/${params.id}/activity`);

    const activityTypes = playerData.activity_types.map(label => {
        return {
            label: t(label as TranslationsKeys),
            params: {
                types: [label],
            },
        };
    });

    const filters = [
        {
            type: "date",
            label: t("date"),
            options: dateOptions,
        },
        {
            type: "multi",
            key: "type",
            label: t("type"),
            options: activityTypes,
        },
    ];

    return (
        <div>
            <Filter
                filters={filters}
                updateFilterParams={updateFilterParams}
                updatedAppliedFilters={undefined}
                filterId={undefined}
            />
            <DataTable
                columns={activity}
                data={filteredData}
                onScroll={onScroll}
                containerRef={containerRef}
                tableType="guest"
                pagination={false}
                isLoading={isLoading}
                onClick={undefined}
                name={undefined}
                contextMenuItems={undefined}
                updateSortParams={undefined}
                verticalAlign="top"
            />
        </div>
    );
};

export default Activity;
