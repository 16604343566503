import i18n from "i18next";

export const columnsOptions = [
    { value: "Name", label: i18n.t("name"), key: "columns" },
    { value: "Reason", label: i18n.t("reason"), key: "columns" },
    { value: "From", label: i18n.t("from"), key: "columns" },
    { value: "Until", label: i18n.t("until"), key: "columns" },
    { value: "Notes", label: i18n.t("notes"), key: "columns" },
    { value: "Added by", label: i18n.t("added_by"), key: "columns" },
    { value: "Added at", label: i18n.t("added_at"), key: "columns" },
];
