import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import Filter from "../../components/Filter";

import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import InfoBox from "../../components/InfoBox";
import client from "../../client";
import PreventionPlanReportPopup from "../../components/Popups/PreventionPlanReportPopup";
import { dateOptions } from "../../utils/filterOptions";
import { oldReportTypeOptions } from "../../utils/preventionTalkOptions";
import { useHistory } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import usePopup from "../../hooks/usePopup";
import { useQueryClient } from "react-query";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useCustomToast from "../../hooks/useCustomToast";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";

const PreventionPlanOverview = () => {
    const { t } = useTranslation();

    const [reportPopup, setReportPopup] = useState({
        state: null,
        visible: false,
    });
    const queryClient = useQueryClient();
    const [updatedAppliedFilters, setUpdatedAppliedFilters] = useState([]);
    const { popup } = usePopup();
    const { customToast } = useCustomToast();

    const history = useHistory();

    const {
        onScroll,
        filteredData,
        updateFilterParams,
        updateSortParams,
        containerRef,
        isLoading,
    } = useInfiniteScroll("/api/prevention-plan-records");

    const { hasPermissions } = usePermissions();
    const hasExportPermissions = hasPermissions([
        "export_prevention_plan_overview",
    ]);

    const tableColumns = columns.preventionPlanOverview;

    const filters = [
        {
            type: "multi",
            key: "type",
            label: t("filter_by_type"),
            options: oldReportTypeOptions.map(item => ({
                label: item.text,
                params: {
                    types: [item.value],
                },
            })),
        },
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    const buttons = () => {
        const exportButton = {
            text: t("export"),
            clickHandler: () =>
                popup({
                    type: "EXPORT_PREVENTION_PLAN_OVERVIEW",
                }),
            type: "pageHeader",
            faIcon: faFileExport,
        };

        const buttons = [];

        if (hasExportPermissions) {
            buttons.push(exportButton);
        }

        return buttons;
    };

    const handleTableClick = data => {
        setReportPopup({ state: data, visible: !reportPopup.visible });
    };

    const deleteItem = id => {
        client
            .delete(`api/prevention-plan-records/${id}`)
            .then(res => {
                customToast("success", {
                    title: t(`entity_successfully_deleted`, {
                        entity: t("prevention_plan"),
                    }),
                });
                queryClient.invalidateQueries("prevention-plan-records");
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    const contextMenuItems = [
        {
            title: t("export_talk_details"),
            action: data =>
                setReportPopup({ state: data, visible: !reportPopup.visible }),
        },
        {
            title: t("follow_up_on_next_visit"),
            action: data => {},
        },
        {
            title: t("view_guest"),
            action: data => {
                history.push(`/guest/${data.guest.id}`);
            },
        },
        {
            title: t("delete_talk"),
            action: data => {
                popup({
                    type: "DELETE_ENTITY",
                    data: {
                        deleteHandler: () => deleteItem(data.id),
                        entityName: t("record"),
                    },
                });
            },
        },
    ];

    const updateFilter = type => {
        const matchedOption = oldReportTypeOptions.find(
            option => option.value === type
        );

        matchedOption &&
            setUpdatedAppliedFilters([
                {
                    key: "multi",
                    selectedValue: {
                        label: matchedOption.text,
                        params: {
                            types: [matchedOption.value],
                        },
                    },
                },
            ]);
    };

    return (
        <>
            <>
                {reportPopup.visible && (
                    <PreventionPlanReportPopup
                        setReportPopup={setReportPopup}
                        reportPopup={reportPopup}
                    />
                )}
                <PageHeader
                    title={t("prevention_plan_overview")}
                    placeholder={t("search_player")}
                    buttons={buttons()}
                />
                <InfoBox updateFilter={updateFilter} />
                <>
                    <Filter
                        filters={filters}
                        updateFilterParams={updateFilterParams}
                        updatedAppliedFilters={updatedAppliedFilters}
                    />
                </>
                <DataTable
                    columns={tableColumns}
                    data={filteredData}
                    onScroll={onScroll}
                    containerRef={containerRef}
                    pagination={false}
                    onClick={handleTableClick}
                    contextMenuItems={contextMenuItems}
                    updateSortParams={updateSortParams}
                    isLoading={isLoading}
                />
            </>
        </>
    );
};

export default PreventionPlanOverview;
