import styled from "styled-components";
import { FieldError } from "react-hook-form";

type Error = FieldError | undefined;
type Control = {
    required?: boolean;
    type?: string;
    error?: any;
};

export const Form = styled.form`
    width: 100%;
`;

export const FormGroup = styled.div<{ type: string; error: Error }>`
    span {
        font-weight: bold;
        text-decoration: underline;
    }

    ${({ type, error }) =>
        type === "radio" &&
        `
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
                display: inline-block;
                margin-left: 6px;
                line-height: initial;
                font-size: 16px;
                 ${error && `color: #e65f5f;`}
            }            
        }

    `};
    ${({ type, error }) =>
        type === "checkbox" &&
        `
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
                display: inline-block;
                margin-left: 6px;
                line-height: initial;
                font-weight: 500;
                font-size: 16px;
                 ${error && `color: red;`}
            }            
        }

    `}
    ${({ type, error }) =>
        type === "checkbox" &&
        `
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
                display: inline-block;
                margin-left: 6px;
                line-height: initial;
                font-size: 16px;
                 ${error && `color: #e65f5f;`}
                 
            }   

        }

    `}
`;

export const FormControl = styled.div<Control>`
    font-size: 16px;
    width: 100%;
    display: flex;
    margin: 0 0 40px 0;

    @media screen and (max-width: ${({ theme }) => theme.layout.bpM}) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    @media screen and (max-width: ${({ theme }) => theme.layout.bpM}) {
        &:last-child {
            margin: 0 0 10px 0;
        }
    }

    > button {
        margin-left: -20px;
        > svg {
            margin-left: -13px;
        }
    }
    > label {
        font-size: 20px;
        flex: 0 0 200px;
        @media screen and (max-width: ${({ theme }) => theme.layout.bpM}) {
            flex: 0 0 0px;
        }

        ${({ theme, required }) =>
            required &&
            `
            &:after {
                content: "*";
                margin-left: 2px;
                color: ${theme.main.secondaryColor};
            }        
        `}
    }

    ${({ error, type }) =>
        type === "checkbox" &&
        `
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        input {
            order: 1;
        }
        label {
            display: inline-block;
            margin-left: 8px;
            line-height: initial;
            order: 2;
            ${error && `color: #e65f5f;`}
        }
    `}

    ${({ error, type }) =>
        type === "radio" &&
        `
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        label {
            display: inline-block;
            margin-left: 6px;
            line-height: initial;
             ${error && `color: #e65f5f;`}
        }
    `}
    >input:disabled {
        background: none !important;
        opacity: 0.8;
    }
    > input:read-only {
        color: #828282;
    }
    > input[type="text"],
    > input[type="password"],
    > input[type="email"],
    > input[type="date"],
    > input[type="number"] {
        border: none;
        border-bottom: 1px solid #828282;
        padding: 6px 4px 0 4px;
        width: 100%;
        ${({ error }) => error && `border-bottom: 2px solid #e65f5f;`}
        ${({ error }) => error && `color:#e65f5f;`}
        height: 32px;
    }

    > textarea {
        flex-grow: 1;
        border: 1px solid #ccc;
        padding: 10px;
        /* min-height: 250px; */
        width: 100%;
        border-radius: 4px;
        &::placeholder {
            color: #bdbdbd;
        }
    }

    > textarea,
    .css-yk16xz-control {
        ${({ error }) =>
            error && `border-bottom: 2px solid #e65f5f !important;`}
    }
    input {
        background: transparent;
        /* margin-right: 5px; */
        &::placeholder {
            color: #bdbdbd;
        }
        &:disabled {
            background: #f0f0f0;
        }
    }
`;

export const Select = styled.div`
    flex-grow: 1;

    .css-1pahdxg-control {
        border: 1px solid black;
        box-shadow: none;
    }

    .css-yk16xz-control {
        border-style: none;
        border-bottom: 1px solid #828282;
        border-radius: 0px;
    }

    .css-1wa3eu0-placeholder {
        color: #333333;
    }

    .css-g1d714-ValueContainer {
        padding: 2px 4px;
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-tlfecz-indicatorContainer {
        color: black;
    }
`;

export const MultiSelect = styled(Select)<{ position?: string }>`
    ${({ position, theme }) =>
        position === "form_column" &&
        `
        padding-left: 200px;
        margin-bottom: 30px;
        margin-top: -20px;
        @media screen and (max-width: ${theme.layout.bpM}) {
            padding-left: 0;
        }    
    `}
`;

export const AddItem = styled.div`
    align-items: center;
    display: flex;
    svg {
        margin-right: 10px;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const RemoveItem = styled(AddItem)``;
