import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Loading";
import GuestInfoCard from "../../components/GuestInfoCard/GuestInfoCard";
import PlayerCardActions from "../../components/PlayerCardActions/PlayerCardActions";
import PlayerActionContainer from "../../components/PlayerActionContainer/PlayerActionContainer";
import PlayerTopBoxes from "../../components/PlayerTopBoxes/PlayerTopBoxes";
import { RootDataGuest } from "../../types/guest";
import { useState } from "react";
import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button } from "../../types/button";
import useCheckinHelpers from "../../hooks/useCheckinHelpers";

const GuestDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { entryRequest } = useCheckinHelpers();

    const { isLoading, data, isError } = useFetch<RootDataGuest>(
        `player/${params.id}`
    );

    const [checkActive, setCheckActive] = useState(false);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <Redirect to="/check-in" />;
    }

    if (!data) return <Redirect to="/check-in" />;

    const handleCheck = () => {
        setCheckActive(true);
        entryRequest({
            guest_id: data.player.id,
        }).finally(() => {
            setCheckActive(false);
        });
    };

    const checkVisitLimit =
        data.player.active_visit_limit &&
        data.player.active_visit_limit.guest_period_visits >=
            data.player.active_visit_limit.limit;

    const pageHeaderButtons: Button[] = [
        {
            text: t("check_in"),
            link: "/check-in",
            type: "pageHeader",
            faIcon: faAddressCard as IconProp,
            clickHandler: handleCheck,
            loading: checkActive,
            disabled: checkActive || checkVisitLimit,
        },
    ];

    return (
        <>
            <PageHeader
                title={t("guest_information")}
                back={true}
                showRegisterButton={false}
                placeholder={t("search_guest")}
                buttons={pageHeaderButtons}
            />
            <div className="grid grid-cols-1 sm:px-6 lg:max-w-[1700px] lg:grid-flow-col-dense lg:grid-cols-4 lg:gap-6">
                <div className="col-start-0 col-span-1 col-end-2 space-y-4 ">
                    <GuestInfoCard player={data.player} />
                    <PlayerCardActions playerData={data} />
                </div>
                <div className="col-span-3 grid h-full w-full grid-rows-5  ">
                    <div className="row-start-0 row-end-1 flex flex-wrap gap-x-6 ">
                        <PlayerTopBoxes playerData={data} />
                    </div>
                    <PlayerActionContainer playerData={data} />
                </div>
            </div>
        </>
    );
};

export default GuestDetails;
