import React, {
    useState,
    useRef,
    useEffect,
    SetStateAction,
    Dispatch,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";
import { useUserStore } from "../../store/userStore";
import client from "../../client";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import CustomSelectSettingsMenu from "../CustomSelect/CustomSelectSettingsMenu";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import Search from "../Search/Search";
import SystemStatus from "../SystemStatus/SystemStatus";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import useLogo from "../../hooks/useLogo";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import UserSwitch from "../UserSwitch";

export default function Header({
    setShowMobileMenu,
}: {
    setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
}) {
    const { user, setUser } = useUserStore();
    const history = useHistory();
    const [showSettings, setShowSettings] = useState(false);
    const [isSelected, setIsSelected] = useState<number | null>(null);
    const [click, setClick] = useState(false);

    const { t } = useTranslation();
    const { getClientLogo } = useLogo();

    const { data: count } = useFetch<{ entriesToday: number }>("system/counts");

    const signOut = () => {
        client.post("/logout").then(res => {
            history.push("/");
            setUser(null);
        });
    };

    const settingsRef = useRef(null);

    useOnClickOutside(settingsRef, () => setShowSettings(false));

    const locations =
        user?.locations.map(location => {
            return { value: location.id, text: location.name };
        }) ?? [];

    const selectLocation = (option: number) => {
        click &&
            client
                .post("/api/user/set-location/" + option)
                .then(res => {
                    setUser(res.data);
                    setClick(false);
                    setIsSelected(res.data.current_location_id);
                })
                .catch(err => {
                    setClick(false);
                });
    };

    useEffect(() => {
        user?.current_location_id && setIsSelected(user.current_location_id);
    }, [user?.current_location_id]);

    return (
        <>
            <div className="fixed left-0 top-0 z-50 flex h-16 w-full items-center bg-[#fffbfb] pl-4 drop-shadow-xl 2xl:justify-between">
                <div className="flex w-full items-center">
                    <button
                        className="mx-2 text-2xl 3xl:hidden"
                        onClick={() =>
                            setShowMobileMenu(prevState => !prevState)
                        }
                    >
                        <FontAwesomeIcon icon={faBars as IconProp} />
                    </button>
                    <div className="w-36 shrink-0">
                        <Link
                            className="flex place-content-center items-center"
                            to="/check-in"
                        >
                            <img
                                className="contain h-12 w-auto"
                                src={getClientLogo()}
                                alt="logo"
                            />
                        </Link>
                    </div>

                    <div className="ml-px hidden h-16 w-[350px] drop-shadow-[3px_3px_3px_rgba(0,0,0,0.10)] lg:flex  2xl:w-96 3xl:ml-10">
                        <svg
                            preserveAspectRatio="none"
                            viewBox="0 0 40 100"
                            className="w-8 fill-alternative"
                        >
                            <polygon points="0,100,40,0,100,100"></polygon>
                        </svg>
                        <div className="flex items-center gap-3 bg-alternative pl-5 pr-3">
                            <FontAwesomeIcon
                                icon={faMapMarkerAlt as IconProp}
                                className="-mt-2 mr-2 text-2xl 2xl:text-3xl"
                            />
                            <div className="flex flex-col items-start ">
                                <div className="text-lg font-medium leading-3">
                                    {user?.current_location_name}
                                </div>
                                <div className="leading-1 text-base font-normal	">
                                    {count?.entriesToday === 1 ? (
                                        <span className="font-base">
                                            {t("number_guest_today", {
                                                number: 1,
                                            })}
                                        </span>
                                    ) : (
                                        <span className="font-base">
                                            {t("number_guests_today", {
                                                number: count?.entriesToday,
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <svg
                            preserveAspectRatio="none"
                            viewBox="0 0 60 100"
                            className="fill-alternative"
                        >
                            <polygon points="0,100,-40,0,100,-100"></polygon>
                        </svg>
                    </div>
                </div>

                <div className="ml-auto flex items-center">
                    <div className="hidden xl:flex">
                        <Search placeholder={t("search_guests")} />
                    </div>

                    <SystemStatus />
                </div>
                <div className="flex h-full shrink-0">
                    <svg
                        preserveAspectRatio="none"
                        viewBox="0 0 50 100"
                        className="w-6 fill-alternative"
                    >
                        <polygon points="0,100,50,0,100,100"></polygon>
                    </svg>
                    <div className="flex shrink-0 items-center justify-end space-x-4 bg-[#F2F1E8] pl-4 pr-4">
                        <UserSwitch />
                        <div className="flex items-center" ref={settingsRef}>
                            <button
                                className="h-10 w-10 flex-shrink-0"
                                onClick={() =>
                                    setShowSettings(prevState => !prevState)
                                }
                            >
                                <img
                                    src={user?.profile_photo_url as string}
                                    alt="avatar"
                                    className=" relative h-full w-full rounded-full object-cover"
                                />
                            </button>
                            <div
                                className={`${
                                    showSettings ? "block" : "hidden"
                                } absolute right-8 top-16 mt-1 w-72 rounded-md bg-[#fffbfb] py-5 shadow-2xl`}
                            >
                                <div className="flex px-5 align-top">
                                    <img
                                        src={user?.profile_photo_url as string}
                                        alt="avatar"
                                        className=" relative h-12 w-12  rounded-full object-cover"
                                    />
                                    <div className="mx-auto my-0">
                                        <div className="font-normal lg:pb-1">
                                            {user?.full_name}
                                        </div>
                                        <div className="text-sm font-semibold text-gray-500 underline lg:hidden">
                                            {user?.current_location_name}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                            Emp. ID {user?.id}
                                        </div>
                                    </div>
                                </div>
                                <div className="-mb-4 flex w-full justify-end pt-2 lg:hidden">
                                    <SystemStatus />
                                </div>

                                <div className="mt-5">
                                    {locations.length > 1 && (
                                        <CustomSelectSettingsMenu
                                            options={locations}
                                            onChangeCallback={selectLocation}
                                            isSelected={isSelected}
                                            setIsSelected={setIsSelected}
                                            setClick={setClick}
                                        />
                                    )}
                                    <button
                                        className="h-12 w-full border-b px-5 text-left hover:bg-zinc-100 hover:text-secondary"
                                        onClick={() => {
                                            history.push("/my-account");
                                        }}
                                    >
                                        {t("my_account")}
                                    </button>

                                    <LanguageSelector />
                                    <button
                                        className="h-12 w-full border-b px-5 text-left hover:bg-zinc-100 hover:text-secondary"
                                        onClick={signOut}
                                    >
                                        {t("sign_out")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
