import styled from "styled-components";

export const SelectionOptions = styled.div`
    margin-bottom: 36px;
    display: flex;
    flex-wrap: wrap;
    > span {
        display: inline-flex;
        margin-right: 20px;
        height: 50px;
        align-items: center;
    }
`;

export const SelectionButton = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 48px;
    font-size: 20px;
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    color: ${props => props.theme.main.primaryColor};
    border: 3px solid ${props => props.theme.main.primaryColor};
    transition: 0.2s ease-in-out;
    margin-bottom: 20px;

    ${props =>
        props.isActive &&
        `
            background: ${props.theme.main.primaryColor};
            color: #fff;
            border-color: transparent;
        `}
    &:last-child {
        margin: 0;
    }
`;

export const DeleteButton = styled.button`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    padding: 0;
    margin: 0;
    top: -10px;
    border: 2px solid #1d1e45;
    right: 0px;
    background: #ffffff;
`;

export const EditButton = styled.button`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    padding: 0;
    margin: 0;
    top: -10px;
    left: 0px;
    border: 2px solid #1d1e45;
    background: #ffffff;
`;

export const SelectionButtonContainer = styled.div`
    position: relative;
    margin-right: 20px;
    &:hover {
        ${DeleteButton}, ${EditButton} {
            visibility: visible;
            opacity: 1;
        }
        ${SelectionButton} {
            background: ${props => props.theme.main.primaryColor};
            color: #fff;
            border-color: transparent;
        }
    }
`;

export const SelectionButtonEdit = styled.input`
    padding: 0 40px;
    height: 48px;
    font-size: 20px;
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    border: 3px solid ${props => props.theme.main.primaryColor};
    background: ${props => props.theme.main.primaryColor};
    color: #fff;
    border-color: transparent;
    transition: 0.2s ease-in-out;
    margin-bottom: 20px;
    text-align: center;
    width: 200px;
`;
