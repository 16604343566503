import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ButtonProps } from "../types/button";

const Button = ({ children, className, isLoading, ...props }: ButtonProps) => {
    return (
        <button
            disabled={props.disabled || isLoading}
            className={className}
            {...props}
        >
            {isLoading ? (
                <FontAwesomeIcon
                    icon={faSpinner as IconProp}
                    className="fa-spin"
                />
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
