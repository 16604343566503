import * as S from "./Filter.styled";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

export default function AppliedFilters({
    appliedFilters,
    setAppliedFilters,
    setDateRange,
}) {
    const { t } = useTranslation();

    const removeFilter = filterIndex => {
        setAppliedFilters(prev => {
            let copy = [...prev];

            if (copy[filterIndex].key === "date") {
                setDateRange({});
            }

            copy.splice(filterIndex, 1);

            return copy;
        });
    };

    return (
        <S.AppliedFiltersContainer>
            <span>{t("applied_filters")}</span>:
            {appliedFilters.map((filter, filterIndex) => (
                <S.SelectedValue key={filterIndex}>
                    {filter.selectedValue.label}
                    <button onClick={() => removeFilter(filterIndex)}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                                "--fa-primary-color": "#000",
                                "--fa-secondary-color": "#000",
                                "--fa-primary-opacity": "1.0",
                                "--fa-secondary-opacity": "1.0",
                            }}
                            size="sm"
                        />
                    </button>
                </S.SelectedValue>
            ))}
        </S.AppliedFiltersContainer>
    );
}
