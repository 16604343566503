import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Disclosure, Transition } from "@headlessui/react";
import { MenuStatus } from "../SideMenu/SideMenu";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MenuItem } from "../../utils/menuItems";

type Props = {
    title: String;
    items: MenuItem[];
    menuId: string;
    menuStatus: MenuStatus;
    setMenuStatus: React.Dispatch<React.SetStateAction<MenuStatus>>;
    setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

function MenuSection({
    title,
    items,
    menuId,
    menuStatus,
    setMenuStatus,
    setShowMobileMenu,
}: Props) {
    const history = useHistory();
    const location = useLocation();

    const toggleSection = () => {
        setMenuStatus(prevState => ({
            ...prevState,
            [menuId]: !prevState[menuId],
        }));
    };

    const renderItem = (item: MenuItem): JSX.Element => {
        const CountComponent = item.CountComponent;

        return (
            <button
                onClick={() => {
                    setShowMobileMenu(false);
                    history.push(item.path);
                }}
                className={`mb-[10px] flex items-center pt-[4px] text-base text-white hover:text-blue-light hover:transition-all ${
                    item.path === location.pathname && "text-blue-light"
                }`}
            >
                <div className="text-md relative mr-[10px]">
                    {item.icon}
                    {CountComponent && <CountComponent />}
                </div>
                <span>{item.title}</span>
            </button>
        );
    };

    return (
        <>
            <Disclosure defaultOpen={true}>
                <Disclosure.Button
                    className={
                        "mb-3 flex w-full justify-between text-base text-gray-300"
                    }
                    onClick={() => toggleSection()}
                >
                    <div>{title}</div>
                    <div
                        key={menuId}
                        className={`${menuStatus[menuId] && "rotate-[180deg]"}`}
                    >
                        <FontAwesomeIcon icon={faChevronDown as IconProp} />
                    </div>
                </Disclosure.Button>

                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className="mb-6"
                >
                    {items.map(item => (
                        <Disclosure.Panel key={item.title}>
                            {renderItem(item)}
                        </Disclosure.Panel>
                    ))}
                </Transition>
            </Disclosure>
        </>
    );
}

export default MenuSection;
