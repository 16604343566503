import React, { useState, useRef } from "react";
import * as S from "./ContextMenu.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";

function ContextMenu({ menuItems }) {
    const [isActive, setIsActive] = useState(false);

    const contextMenuRef = useRef(null);

    useOnClickOutside(contextMenuRef, () => setIsActive(false));

    const showActiveMenu = e => {
        e.stopPropagation();
        setIsActive(prev => !prev);
    };

    const handleMenuItemClick = (e, menuItem) => {
        e.stopPropagation();
        menuItem.action();
        setIsActive(false);
    };

    return (
        <S.ContextMenu ref={contextMenuRef}>
            <S.ContextMenuButton isActive={isActive} onClick={showActiveMenu}>
                <FontAwesomeIcon icon={faEllipsisV} />
            </S.ContextMenuButton>
            <S.ContextMenuOptions isActive={isActive}>
                {menuItems.map((menuItem, index) => (
                    <li key={index}>
                        <button onClick={e => handleMenuItemClick(e, menuItem)}>
                            {menuItem.title}
                        </button>
                    </li>
                ))}
            </S.ContextMenuOptions>
        </S.ContextMenu>
    );
}

export default ContextMenu;
