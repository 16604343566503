import styled from "styled-components";
import { Link } from "react-router-dom";

export const TableStyles = styled.div`
    flex-grow: 1;
    height: 575px;
    overflow-y: scroll;
    position: relative;

    ${props => props.tableType === "floor_numbers_overview" && `height: auto;`}

    table {
        color: black;
        width: 100%;
        border-spacing: 0;
        padding-bottom: 20px;

        thead {
            position: sticky;
            top: 0;
            background: #fbfbff;
            z-index: 5;
            box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
                0 2px 4px -2px rgb(0 0 0 / 0.1);

            tr {
                &:hover {
                    background: none;
                }
            }
        }

        th {
            text-align: left;
            border-bottom: 1px solid #242424;
            font-size: 18px;
            font-weight: 400;
            min-width: 100px;
            height: 50px;
            padding-left: 16px;

            > svg {
                width: 15px;
                height: 15px;
                vertical-align: middle;
                margin-left: 20px;
                cursor: pointer;
            }

            ${props =>
                props.tableType === "blacklist"
                    ? {
                          background: "#242424",
                          color: "white",
                          border: "none",
                          height: "50px",
                      }
                    : null}

            ${props => props.tableType === "guest" && `background: #FFFBFB;`}
        }

        td {
            border-bottom: 1px solid #e5e5e5;
            height: 100px;
            max-height: 190px;
            padding: 20px 16px;
            font-size: 16px;
            vertical-align: ${props => props.verticalAlign};
            max-width: 400px;

            word-break: break-word;
            ${props =>
                props.tableType === "floor_numbers_overview" && `height: 40px;`}

            ${props =>
                props.tableType === "guestHistory" && {
                    "&:nth-child(2)": {
                        background: "rgba(241, 234, 234, 0.6)",
                    },
                }}
            ${props =>
                props.tableType === "reports" && {
                    "&:nth-child(3)": {
                        color: "#a94430",
                        fontSize: "18px",
                    },
                }}

            ${props =>
                props.tableType === "reports" && {
                    "&:last-child": {
                        color: "#F2994A",
                    },
                }}


            width: auto;
            :last-child {
                border-right: 0;
            }
        }
    }
`;

export const Tr = styled.tr`
    cursor: pointer;
    background: ${props => (props.selectedColor ? "#1d1e4552" : "")};

    &:hover {
        background: #f2f2f2;
        transition: 0.2s;
    }

    &:nth-last-child(-n + 3):nth-child(n + 5) td ul {
        top: ${props =>
            props.contextMenuItems?.length > 2 &&
            `-${props.contextMenuItems?.length * 50}px`};
    }
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const ColoredText = styled.div`
    color: ${props =>
        props.success
            ? props.theme.main.successColor
            : props.theme.main.errorColor};
`;

export const UserAvatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    flex-shrink: 0;
    align-items: center;
    object-fit: cover;
`;

export const UserAvatarWithVip = styled.img`
    width: 61px;
    height: 61px;
    border-radius: 50%;
    background-size: cover;
    flex-shrink: 0;
    align-items: center;
    object-fit: cover;
    position: absolute;
    z-index: 1;
`;

export const AvatarContainer = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
`;

export const UserNameWithResidence = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3px;
`;

export const UserNameContainer = styled.div`
    font-size: 20px;
    padding-left: 25px;
    @media screen and (max-width: ${props => props.theme.layout.bpXXL}) {
        display: flex;
        flex-direction: column;
    }
`;

export const UserName = styled.div`
    font-size: 20px;
    padding-left: 25px;
    @media screen and (max-width: ${props => props.theme.layout.bpXXL}) {
        display: flex;
        flex-direction: column;
    }
`;

export const ResidenceImageContainer = styled.div`
    width: 32px;
    height: 32px;
`;

export const ResidenceImage = styled.img`
    width: 32px;
    height: 32px;
    object-fit: cover;
`;

export const ResidenceInternational = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
`;

export const AddGuest = styled.td`
    svg {
        width: 40px;
        height: 40px;
        color: black;
        vertical-align: middle;
        margin-right: 20px;
        cursor: pointer;
    }

    color: #828282;
    line-height: 90px;
`;

export const Show = styled.div`
    width: 300px;
    display: flex;
    p {
        width: 60px;
        line-height: 30px;
    }
`;

export const BottomBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fcf9f9;
    padding: 20px;
    height: 70px;
`;

export const ChevronDropDown = styled.button`
    svg {
        font-size: 0.6em;
    }
`;

export const SelectFilterDropDown = styled.div`
    position: absolute;
    background: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    height: auto;
    border: 1px solid rgb(224, 224, 224);
    margin-top: 5px;
    z-index: 100;
    padding-bottom: 10px;
    button {
        text-align: left;
        border-bottom: 1px solid rgb(224, 224, 224);
        padding: 10px 14px;
        font-size: 16px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:hover {
            background: rgba(241, 234, 234, 0.6);
        }
    }
`;

export const CellContainer = styled.div`
    text-align: left;
    > svg {
        margin-right: 10px;
        height: 15px;
    }
`;

export const FileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    > svg {
        font-size: 20px;
    }
`;

export const Date = styled.span`
    font-size: 18px;
`;

export const DateUnderName = styled.div`
    font-size: 15px;
    color: #1d1e45bd;
`;

export const UserLink = styled(Link)`
    color: ${props => props.theme.main.linkColor};
    font-size: 16px;
`;

export const Title = styled.h2`
    font-size: 20px;
    color: ${props => props.theme.main.linkColor};
    padding: 20px 0px 0px 0px;
    vertical-align: initial;
`;
export const Message = styled.span`
    font-size: 16px;
    padding: 20px 0;
    display: inline-flex;
    vertical-align: initial;
    max-width: 500px;
`;

export const RolesList = styled.div`
    display: flex;
    gap: 20px;
    color: #a94430;
    text-decoration: underline;
    text-transform: capitalize;
`;

export const LocationContainer = styled.div`
    span {
        color: #f2994a;
    }
`;
export const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 120px;

    button {
        width: 40px;
        height: 30px;
        > svg {
            font-size: 24px;
        }
    }
`;

export const Type = styled.h2`
    font-size: 20px;
    vertical-align: initial;
`;
export const TypeTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    padding: 20px 0px 0px 0px;
`;

export const TypeNotes = styled.div`
    font-size: 15px;
    display: inline-flex;
    vertical-align: initial;
    width: 450px;
`;

export const Notes = styled.div`
    font-size: 16px;
    padding: 10px 0;
    display: inline-flex;
    vertical-align: initial;
    width: 450px;
`;

export const Dates = styled.div`
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    vertical-align: initial;
    width: 450px;
    font-weight: 500;
`;

export const DeleteReports = styled.div`
    display: flex;
    svg {
        color: black;
        font-size: 24px;
        margin-left: 30px;
        margin-right: 10px;
    }
`;

export const NotesActions = styled.div`
    display: flex;
    svg {
        color: black;
        font-size: 24px;
        margin-left: 30px;
        margin-right: 10px;
    }
`;

export const SvgContainer = styled.div`
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 4px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::after {
        content: "VIP";
        display: block;
        color: #1d1e45;
        font-size: 16px;
        text-align: center;
    }
`;

export const SvgContainerWithAvatar = styled.div`
    padding: 2px 5px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    right: -15px;
    bottom: -15px;

    &::after {
        content: "VIP";
        display: block;
        color: #1d1e45;
        font-size: 16px;
        text-align: center;
    }
`;

export const Images = styled.img`
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    object-fit: cover;
    max-height: 140px;
    border-radius: 3px;
    margin: 5px;
    background: #fff;
    border: 1px solid ${props => props.theme.main.primaryColor};
    transition: transform 0.2s;
    object-fit: contain;
    &:hover {
        transform: scale(1.8);
    }
`;

export const SubType = styled.div`
    font-size: 16px;
    vertical-align: initial;
    font-weight: 500;
`;

export const CheckBox = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${props => (props.checked ? "#27AE60" : "#fff")};
    border: ${props => (props.checked ? "none" : "2px solid #000")};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UserNameFull = styled.div`
    font-size: 20px;
    text-transform: capitalize;
`;

export const ResponsiveDate = styled.div`
    font-size: 13px;
    margin-left: 3px;
    display: none;

    @media screen and (max-width: ${props => props.theme.layout.bpXXL}) {
        display: flex;
    }
`;

export const ArrowContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 10px;
`;
