import dayjs from "dayjs";
import { useHistory } from "react-router";
import { DataTable } from "../../../components/DataTable";
import { Entry } from "../../../types/entries";
import { columns } from "../../../utils/tableColumns";
import Loading from "../../../components/Loading";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";

function LastEntries() {
    const history = useHistory();

    const { onScroll, containerRef, isLoading, filteredData } =
        useInfiniteScroll(`/api/entries?today=1&unique=1`);

    const tableColumns = columns.recentEntries;

    const getEntries = (entryData: Entry[]) => {
        return entryData.map(entry => ({
            ...entry,
            created_at: dayjs(entry.created_at).format("DD-MM-YYYY HH:mm"),
        }));
    };

    const handleTableClick = (data: Entry) => {
        history.push(`/guest/${data.player.id}`);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {/* @ts-ignore */}
            <DataTable
                columns={tableColumns}
                data={getEntries(filteredData)}
                tableType="entry"
                onScroll={onScroll}
                containerRef={containerRef}
                onClick={handleTableClick}
                pagination={false}
            />
        </>
    );
}

export default LastEntries;
