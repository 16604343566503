import React from "react";
import * as S from "./InfoBox.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import useFetch from "../../hooks/useFetch";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
const InfoBox = ({ updateFilter }) => {
    const { isLoading, data } = useFetch("prevention-plan-records/dashboard");
    const { t } = useTranslation();
    if (isLoading) {
        return <Loading />;
    }

    return (
        <S.InfoBoxes>
            {data.map((report, index) => {
                return (
                    <S.InfoBox key={index}>
                        <S.InfoBoxHeader
                            background={report.type}
                            color={report.color}
                            onClick={() => updateFilter(report.type)}
                        >
                            {t(report.type)}
                            <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </S.InfoBoxHeader>

                        <S.InfoBoxBody>
                            <>
                                <S.InfoBoxStatus>
                                    <S.InfoTextDone>{t("done")}</S.InfoTextDone>
                                    <S.InfoNumberDone>
                                        {report.done}
                                    </S.InfoNumberDone>
                                </S.InfoBoxStatus>

                                <S.InfoBoxStatus>
                                    <S.InfoTextPending>
                                        {t("pending")}
                                    </S.InfoTextPending>
                                    <S.InfoNumberPending>
                                        {" "}
                                        {report.pending}
                                    </S.InfoNumberPending>
                                </S.InfoBoxStatus>
                            </>
                        </S.InfoBoxBody>
                    </S.InfoBox>
                );
            })}
        </S.InfoBoxes>
    );
};

export default InfoBox;
