type Options = {
    [key: string]: {
        color: string;
        twColor: string;
        twHover: string;
        icon: string;
        hover: string;
        twColorLight: string;
        textColor: string;
    };
};

export const options: Options = {
    malfunction: {
        color: "#F2994A",
        twColor: "bg-orange-400",
        twHover: "hover:bg-orange-400",
        icon: "exclamation-triangle",
        hover: "#F2994A80",
        twColorLight: "bg-orange-100",
        textColor: "#fff",
    },
    cash: {
        color: "#43B5A0",
        twColor: "bg-emerald-500",
        twHover: "hover:bg-emerald-500",
        icon: "sack-dollar",
        hover: "#43B5A040",
        twColorLight: "bg-emerald-100",
        textColor: "#fff",
    },
    handover: {
        color: "#0BE7FB",
        twColor: "bg-cyan-400",
        twHover: "hover:bg-cyan-400",
        icon: "exchange-alt",
        hover: "#0BE7FB40",
        twColorLight: "bg-cyan-100",
        textColor: "#1d1e45",
    },
    general: {
        color: "#FA448C",
        twColor: "bg-pink-500",
        twHover: "hover:bg-pink-500",
        icon: "comment-alt-lines",
        hover: "#FA448C40",
        twColorLight: "bg-pink-100",
        textColor: "#fff",
    },
    about_employee: {
        color: "#6389df",
        twColor: "bg-blue-500",
        twHover: "hover:bg-blue-500",
        icon: "user",
        hover: "#6389df40",
        twColorLight: "bg-blue-100",
        textColor: "#fff",
    },
    whitelist: {
        color: "#efefef",
        twColor: "bg-stone-200",
        twHover: "hover:bg-stone-200",
        icon: "address-book",
        hover: "#efefef40",
        twColorLight: "bg-stone-100",
        textColor: "#1d1e45",
    },
    blacklist: {
        color: "#282828",
        twColor: "bg-stone-900",
        twHover: "hover:bg-stone-900",
        icon: "address-book",
        hover: "#28282840",
        twColorLight: "bg-stone-200",
        textColor: "#fff",
    },
};
