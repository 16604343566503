import React from "react";
import * as S from "./LanguageSettings.styled";
import nlFlag from "../../assets/lang/nl-flag.svg";
import enFlag from "../../assets/lang/en-flag.svg";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

const menuItems = {
    nl: {
        title: "NL - Nederlands",
        image: nlFlag,
    },
    en: {
        title: "EN - English",
        image: enFlag,
    },
};

function LanguageSettings() {
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    return (
        <S.LanguageSettings>
            <S.SectionHeader>{t("change_language")}</S.SectionHeader>
            <S.SectionNote>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span>
                    {t("changing_language_would_only_affect_the_current_user")}
                </span>
            </S.SectionNote>
            <S.Menu>
                {Object.entries(menuItems).map(([key, item]) => (
                    <S.MenuItem key={key} active={language === key}>
                        <S.MenuIcon>
                            <img src={item.image} alt={item.title} />
                        </S.MenuIcon>
                        <span>{item.title}</span>
                    </S.MenuItem>
                ))}
            </S.Menu>
        </S.LanguageSettings>
    );
}

export default LanguageSettings;
