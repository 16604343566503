import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    &:before {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
        display: block;
        background: url(${props => props.backgroundLogin});
        background-size: cover;
        width: 100%;
        height: 100%;
        filter: blur(13px);
        background-position-y: -300px;
    }
`;

export const Mask = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
`;

export const Container = styled.div`
    margin: auto;
    text-align: center;
    color: white;

    svg {
        color: white;
    }
`;

export const UserAvatar = styled.img`
    height: 96px;
    width: 96px;
    margin-bottom: 40px;
    border-radius: 50%;
    display: inline-block;
`;

export const Logo = styled.img`
    margin-bottom: 100px;
`;

export const Text = styled.div`
    color: white;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
`;

export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
        margin-left: 15px;
        min-width: initial;
        height: 45px;
    }
`;
