import { useCallback } from "react";
import { useUserStore } from "../store/userStore";
import { Permission } from "../types/permission";

function usePermissions() {
    const { user } = useUserStore();
    const permissions = user?.permissions;

    const hasPermissions = useCallback(
        (keys: Permission[]) => {
            if (!permissions || !keys) return false;
            return keys.every(item => permissions.includes(item));
        },
        [permissions]
    );

    return { hasPermissions };
}

export default usePermissions;
