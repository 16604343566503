import { DefaultTheme } from "styled-components";

const main = {
    primaryColor: "#1D1E45",
    primaryColorHover: "#323488",
    secondaryColor: "#CA523A",
    secondaryColorHover: "#e66147",
    textColor: "#333333",
    borderColor: "#E0E0E0",
    successColor: "#27ae60",
    errorColor: " #EB5757",
    saveColor: "#C54D34",
    linkColor: "#A94430",
};

const layout = {
    gap: "20px",
    bpS: "480px",
    bpM: "768px",
    bpL: "1024px",
    bpXL: "1280px",
    bpXXL: "1600px",
    wrapperL: "1000px",
    wrapperXL: "1320px",
};

const wrapper = () => `
    margin: 0 auto;
    width: ${layout.wrapperXL};
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: ${layout.bpM}) {
        padding-left: 20px;
        padding-right: 20px;
    }
 `;

const mixins = {
    wrapper,
};

const theme: DefaultTheme = {
    main,
    layout,
    mixins,
};

export default theme;
