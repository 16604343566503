import dayjs from "dayjs";
import i18n from "i18next";

export const dateOptions = [
    {
        label: i18n.t("last_24_hours"),
        params: {
            date_from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
            date_until: dayjs(new Date()).format("YYYY-MM-DD"),
        },
    },

    {
        label: i18n.t("last_7_days"),
        params: {
            date_from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
            date_until: dayjs(new Date()).format("YYYY-MM-DD"),
        },
    },
    {
        label: i18n.t("last_30_days"),
        params: {
            date_from: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
            date_until: dayjs(new Date()).format("YYYY-MM-DD"),
        },
    },
];

export const messageIconTypes = (item: string) => {
    let icon = "";
    switch (item) {
        case "malfunction":
            icon = "exclamation-triangle";
            break;
        case "cash":
            icon = "sack-dollar";
            break;
        case "handover":
            icon = "exchange-alt";
            break;
        case "general":
            icon = "comment-alt-lines";
            break;
        case "about_employee":
            icon = "user";
            break;
        case "whitelist":
            icon = "address-book";
            break;
        case "blacklist":
            icon = "address-book";
            break;

        default:
            icon = "question";
            break;
    }
    return icon;
};
