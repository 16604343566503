import * as S from "./FormTextArea.styled";

function FormTextArea({
    formKey,
    register = false,
    required,
    errors,
    label = null,
    placeholder = "",
    readOnly = false,
    handleCommentChange,
    row,
    cols,
    maxLength = false,
    lengthState,
}) {
    return (
        <>
            <S.FormControl required={required}>
                <label>{label}</label>
                <S.TextAreaContainer error={errors[formKey]}>
                    <textarea
                        readOnly={readOnly}
                        autoComplete="off"
                        placeholder={placeholder}
                        rows={row}
                        cols={cols}
                        maxLength={maxLength || ""}
                        {...register(formKey, {
                            required,
                        })}
                        onChange={handleCommentChange}
                    />
                    {maxLength && (
                        <div>
                            {lengthState}/{maxLength}
                            {lengthState > maxLength && " ERROR"}
                        </div>
                    )}
                </S.TextAreaContainer>
            </S.FormControl>
        </>
    );
}

export default FormTextArea;
