import * as S from "../TemplateOptions.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTimes } from "@fortawesome/pro-light-svg-icons";
import usePermissions from "../../../hooks/usePermissions";

const TemplateSelectionButton = ({
    template,
    selectedTemplateId,
    setSelectedTemplateId,
    onDelete,
    setTemplatedEditId,
}) => {
    const { hasPermissions } = usePermissions();
    const hasEditPermissions = hasPermissions(["edit_settings"]);

    return (
        <>
            <S.SelectionButton
                isActive={selectedTemplateId === template.id}
                onClick={() => setSelectedTemplateId(template.id)}
            >
                <span>{template.name}</span>
            </S.SelectionButton>
            {hasEditPermissions && (
                <>
                    <S.DeleteButton onClick={() => onDelete(template.id)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </S.DeleteButton>
                    <S.EditButton
                        onClick={() => setTemplatedEditId(template.id)}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </S.EditButton>
                </>
            )}
        </>
    );
};

export default TemplateSelectionButton;
