import i18n from "i18next";

export const columnsOptions = [
    { value: "Type", label: i18n.t("type"), key: "columns" },
    { value: "Title", label: i18n.t("title"), key: "columns" },
    { value: "Message", label: i18n.t("message"), key: "columns" },
    { value: "Date", label: i18n.t("date"), key: "columns" },
    { value: "Location", label: i18n.t("location"), key: "columns" },
];

export const typeOptions = [
    { value: "malfunction", label: i18n.t("malfunction"), key: "types" },
    { value: "cash", label: i18n.t("cash"), key: "types" },
    { value: "handover", label: i18n.t("handover"), key: "types" },
    { value: "general", label: i18n.t("general"), key: "types" },
    { value: "about_employee", label: i18n.t("about_employee"), key: "types" },
    { value: "blacklist", label: i18n.t("blacklist"), key: "types" },
    { value: "whitelist", label: i18n.t("whitelist"), key: "types" },
];
