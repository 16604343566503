import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import useOnClickOutside from "../hooks/useOnClickOutside";
import nlFlag from "../assets/lang/nl-flag.svg";
import enFlag from "../assets/lang/en-flag.svg";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LanguageType } from "../types/language";

function SignatureLanguageMenu({
    currentLang,
    onLangChange,
    isDeviceProcessing = false,
}: {
    currentLang: string;
    onLangChange: (lang: string) => void;
    isDeviceProcessing?: boolean;
}) {
    const [isLanguageMenuActive, setIsLanguageMenuActive] = useState(false);

    const menuRef = useRef(null);
    useOnClickOutside(menuRef, () => setIsLanguageMenuActive(false));

    const menuItems: LanguageType = {
        nl: {
            title: "NL",
            image: nlFlag,
        },

        en: {
            title: "EN",
            image: enFlag,
        },
    };

    return (
        <button
            ref={menuRef}
            className={`relative flex h-12 items-center justify-between px-5 text-left transition hover:cursor-pointer hover:bg-zinc-100 hover:text-secondary disabled:opacity-40 disabled:hover:bg-transparent disabled:hover:text-black ${
                isLanguageMenuActive && "bg-zinc-100 text-secondary"
            }`}
            onClick={() => setIsLanguageMenuActive(prevState => !prevState)}
            disabled={isDeviceProcessing}
        >
            <div className="m-0 flex items-center p-0">
                <img
                    src={menuItems[currentLang].image}
                    alt={`${currentLang} icon`}
                    className="h-8 w-8"
                />
                <FontAwesomeIcon
                    icon={faAngleDown as IconProp}
                    className="ml-1 text-xl"
                />
            </div>
            <div
                className={`${
                    isLanguageMenuActive ? "block" : "hidden"
                } absolute right-4 top-10 z-10 mt-3 w-32 select-none rounded-md bg-[#fffbfb] drop-shadow-xl`}
            >
                {Object.entries(menuItems as LanguageType).map(
                    ([key, item]) => (
                        <div
                            className={`m-0 flex w-full items-center justify-between border py-3 px-3 text-lg first:rounded-t-md last:rounded-b-md even:border-t-0 ${
                                currentLang === key
                                    ? "pointer-events-none bg-zinc-100 text-secondary"
                                    : "text-primary hover:bg-zinc-200"
                            }`}
                            key={key}
                            onClick={() => onLangChange(key)}
                        >
                            <span>{item.title}</span>
                            <img
                                src={item.image}
                                alt={item.title}
                                className="h-8 w-8"
                            />
                        </div>
                    )
                )}
            </div>
        </button>
    );
}

export default SignatureLanguageMenu;
