import React, { useState } from "react";
import dayjs from "dayjs";
import RiskLevel from "../../components/RiskLevel";
import { useTranslation } from "react-i18next";
import { Guest } from "../../types/guest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useHistory, useParams } from "react-router-dom";

type PlayerDetails = {
    label: string;
    value: string | React.ReactElement | null;
};

const GuestInfoCard = ({ player }: { player: Guest }) => {
    const { t } = useTranslation();
    const [riskLevelContainer, setRiskLevelContainer] = useState(false);
    const history = useHistory();
    const params = useParams();

    const playerDetails: PlayerDetails[] = [
        {
            label: t("risk_level"),
            value: (
                <RiskLevel
                    player={player}
                    setRiskLevelContainer={setRiskLevelContainer}
                    riskLevelContainer={riskLevelContainer}
                />
            ),
        },
        {
            label: t("date_of_birth"),
            value: dayjs(player.date_of_birth).format("DD-MM-YYYY"),
        },
        {
            label: t("last_visit"),
            value: dayjs(player.latest_entry_at).format("DD-MM-YYYY"),
        },
        {
            label: t("has_bsn"),
            value: player.residence === "NL" ? t("yes") : t("no"),
        },
        {
            label: t("place_of_birth"),
            value: player.location_of_birth || null,
        },
    ];

    return (
        <div className="relative mr-4 h-[440px] w-full rounded-lg bg-[#FFFBFB] shadow-md">
            <div
                className="absolute right-5 top-5 cursor-pointer"
                onClick={() => history.push(`/guest/${params.id}/edit`)}
            >
                <FontAwesomeIcon icon={faPen as IconProp} />
            </div>
            <div className="flex h-[45%] w-full flex-col items-center justify-center">
                <img
                    className="relative h-32 w-32 flex-shrink-0 rounded-full object-cover shadow-md"
                    src={player.profile_photo_url}
                    alt="profile_image"
                />
                <div className="mt-3 text-xl">
                    {player.first_name} {player.inserts?.toLowerCase()}
                    {player.last_name}
                </div>
            </div>
            <div className="h-[55%] w-full overflow-hidden">
                {playerDetails.map(({ label, value }) => (
                    <div
                        key={label}
                        className="flex justify-between  py-2 pl-6 pr-2"
                    >
                        {value !== null && value !== undefined && (
                            <>
                                <div className="w-3/6">{label}</div>
                                <div className="w-3/6">{value}</div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GuestInfoCard;
