import dayjs from "dayjs";
import { matchLocalDevice } from "../utils/matchLocalDevice";
import useIdScanner from "../hooks/useIdScanner";
import useCheckinHelpers from "../hooks/useCheckinHelpers";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCheckinFields } from "../store/checkInStore";
import { residenceOptions } from "../utils/formOptions";
import useCustomToast from "./useCustomToast";

import {
    ExistingGuestScanEvent,
    IDFlipEvent,
    IDScanEvent,
} from "../types/event";

function useGlobalCheckin() {
    const { entryRequest } = useCheckinHelpers();
    const { t } = useTranslation();
    const location = useLocation();
    const { setFields } = useCheckinFields();

    const validateAndFormatDate = (date: string) => {
        const dayjsDate = dayjs(date);
        // Check if dayjs can parse a valid date
        if (dayjsDate.isValid()) return dayjsDate.format("YYYY-MM-DD");
        // Check if it's the default drivers license date format - DD/MM/YYYY
        const [day, month, year] = date.split("/");
        if (dayjs(`${year}-${month}-${day}`).isValid())
            return `${year}-${month}-${day}`;

        return null;
    };

    const { customToast } = useCustomToast();

    const scanEventCallBack = (event: IDScanEvent) => {
        if (!event.data) return;

        if (location.pathname === "/check-in") {
            if (!event.data) return;
            if (!matchLocalDevice(event.device_id, "id-scan")) return;

            if (!event.data.bsn) {
                customToast("warning", {
                    title: t("bsn_is_not_recognized"),
                    body: t("please_fill_this_in_manually"),
                });
            }

            const resetFields = {
                first_name: event.data.first_name,
                inserts: event.data.inserts,
                last_name: event.data.last_name,
                residence: residenceOptions.find(
                    option => option.value === event.data.type
                ),
                bsn: event.data.bsn,
                date_of_birth: "",
                location_of_birth: event.data.place_of_birth,
                temp_profile_photo_path: event.data.temp_profile_photo_path,
            };

            const formattedDate = validateAndFormatDate(
                event.data.date_of_birth
            );

            if (formattedDate) {
                resetFields.date_of_birth = formattedDate;
            }

            setFields(resetFields);

            customToast(
                "warning",
                {
                    title: t("form_filled"),
                    body: t("please_check_info"),
                },
                { id: "form_filled" }
            );
            return;
        }

        customToast("warning", {
            title: t("scan_success"),
            link: {
                title: t("click_to_review"),
                url: "/check-in",
                state: event.data,
            },
        });
    };

    const guestEntryEventCallBack = useCallback(
        (event: ExistingGuestScanEvent) => {
            if (
                matchLocalDevice(event.device_id, "id-scan") ||
                matchLocalDevice(event.device_id, "rfid")
            ) {
                const requestData = {
                    guest_id: event.data.guest.id,
                    date_of_birth: event.data.guest.date_of_birth,
                    location_of_birth: event.data.guest.location_of_birth,
                    type: event.data.guest.residence,
                    bsn: event.data.scan.bsn,
                };

                entryRequest(requestData);
            }
        },
        [entryRequest]
    );

    const flipEntryEventCallBack = useCallback(
        (event: IDFlipEvent) => {
            if (matchLocalDevice(event.device_id, "id-scan")) {
                customToast("warning", {
                    title: t("id_scanner"),
                    body: event.data.message,
                });
            }
        },
        [customToast, t]
    );

    useIdScanner(
        scanEventCallBack,
        guestEntryEventCallBack,
        flipEntryEventCallBack
    );
}

export default useGlobalCheckin;
