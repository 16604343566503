export const prepareOptionsForSelect = (
    options:
        | {
              name: string;
              id: number;
          }[]
        | undefined
) => {
    if (!options) return [];

    return options.map(option => {
        return {
            value: option.name,
            label: option.name,
            id: option.id,
        };
    });
};
