import styled from "styled-components";

export const SignatureContainer = styled.div`
    border: 1px solid #e0e0e0;
    position: relative;
    button {
        position: absolute;
        top: 8px;
        right: 14px;
        font-size: 20px;
    }
    img {
        width: "300px";
        height: "180px";
    }
`;
