import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import useCustomToast from "./useCustomToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-light-svg-icons";

const useCheckInNotifications = () => {
    const { t } = useTranslation();
    const { customToast } = useCustomToast();

    const notificationApproved = useCallback(
        (res, callback) => {
            customToast("success", {
                title: t("entry_approved"),
                subTitle: {
                    text: res.data.player.full_name,
                    url: `/guest/${res.data.player.id}`,
                },
                image: {
                    src: res.data.player.profile_photo_url,
                    url: `/guest/${res.data.player.id}`,
                },
            });

            callback && callback();
        },
        [customToast, t]
    );

    const toastTable = useCallback(
        table => {
            return (
                <>
                    <div className="grid grid-cols-5 gap-1 text-base">
                        {Object.entries(table).map(([key, val]) => (
                            <>
                                <div className="col-span-2 pr-2">{t(key)}:</div>
                                <div className="col-span-3">{val}</div>
                            </>
                        ))}
                    </div>
                </>
            );
        },
        [t]
    );

    const notificationOfflineApproved = useCallback(
        (res, callback) => {
            customToast("warning", {
                title: t(`entry_approved_cruks_offline`),
                body: toastTable({
                    name: `${res.data.player.full_name}`,
                    Cruks: res.data.CRUKSOnline ? "Online" : "Offline",
                }),
                image: {
                    src: res.data.player.profile_photo_url,
                    url: `/guest/${res.data.player.id}`,
                },
            });
            callback && callback();
        },
        [customToast, t, toastTable]
    );

    const notificationWarning = useCallback(
        message => {
            customToast("error", {
                title: t(`${message}`),
            });
        },
        [customToast, t]
    );

    const notificationDenied = useCallback(
        (res, callback) => {
            const checkMap = {
                BlacklistCheck: t("blacklist"),
                WhitelistCheck: t("playpause"),
                CRUKSCheck: t("cruks"),
                visitLimitCheck: t("visitLimit"),
            };

            const deniedChecks = Object.entries(res.data)
                .filter(([key, val]) => checkMap[key] && !val)
                .map(([key]) => {
                    return (
                        <div className="font-normal">
                            <div className="mb-1">
                                <FontAwesomeIcon
                                    icon={faBan}
                                    size="md"
                                    className="text-[#E34234]"
                                />
                                <span className="pl-2">
                                    {checkMap[key] === "visitLimit"
                                        ? t("visit_limit_reached")
                                        : t("registered_in_entity", {
                                              entity: checkMap[key],
                                          })}
                                </span>
                            </div>
                        </div>
                    );
                });

            customToast("denied", {
                title: `${t("entry")} ${t("denied")}`,
                subTitle: {
                    text: res.data.player.full_name,
                    url: `/guest/${res.data.player.id}`,
                },
                body: (
                    <>
                        {deniedChecks}
                        {res.data.CRUKSCheck && res.data.CRUKSMessage && (
                            <div>{res.data.CRUKSMessage}</div>
                        )}
                    </>
                ),
                image: {
                    src: res.data.player.profile_photo_url,
                    url: `/guest/${res.data.player.id}`,
                },
            });
            callback && callback();
        },
        [customToast, t]
    );

    const notificationBlackListApproved = useCallback(
        res => {
            customToast("success", {
                title: t(`success_blacklist`),
                body: res.data[0].player,
                image: {
                    src: res.data[0].player_photo,
                    url: `/guest/${res.data.player.id}`,
                },
            });
        },
        [customToast, t]
    );

    const notificationWhitelistApproved = useCallback(
        res => {
            customToast("success", {
                title: t(`success_playpause`),
                body: res.data[0].player,
                image: {
                    src: res.data[0].player_photo,
                    url: `/guest/${res.data.player.id}`,
                },
            });
        },
        [customToast, t]
    );

    return {
        notificationApproved,
        notificationOfflineApproved,
        notificationDenied,
        notificationBlackListApproved,
        notificationWarning,
        notificationWhitelistApproved,
    };
};

export default useCheckInNotifications;
