import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function CustomSelectSettingsMenu({
    options,
    isSelected = null,
    onChangeCallback,
    setIsSelected,
    setClick,
}: {
    options: { value: number; text: string }[];
    isSelected: number | null;
    onChangeCallback: (arg: number) => void;
    setIsSelected: (value: number | null) => void;
    setClick: (arg: boolean) => void;
}) {
    const [showDropDown, setShowDropdown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<{
        value: number;
        text: string;
    }>();

    const closeRef = useRef(null);

    const handleSelect = (option: { value: number; text: string }) => {
        setSelectedOption(option);

        if (setIsSelected) {
            setIsSelected(option.value);
        }
    };

    useOnClickOutside(closeRef, () => setShowDropdown(false));

    useEffect(() => {
        if (isSelected) {
            const isSelectedOption = options.find(
                option => option.value === isSelected
            );

            setSelectedOption(isSelectedOption);
        } else {
            setSelectedOption(options[0]);
        }
    }, [isSelected, options]);

    useEffect(() => {
        if (selectedOption) {
            onChangeCallback(selectedOption.value);
        }
    }, [selectedOption, onChangeCallback]);

    return (
        <div
            className={` relative flex h-12 w-full flex-grow cursor-pointer border-b border-t  px-5 pt-2 hover:bg-zinc-100 hover:text-secondary ${
                showDropDown && "bg-zinc-100 text-secondary"
            }`}
            ref={closeRef}
            onClick={() => setShowDropdown(prev => !prev)}
        >
            <div className={`flex h-8 w-full justify-between py-1`}>
                <span>{selectedOption?.text}</span>

                <FontAwesomeIcon
                    icon={faAngleDown as IconProp}
                    className="ml-1 text-xl"
                />
            </div>

            {showDropDown && (
                <div className="absolute top-14 left-0  z-30 w-full max-w-[250px] select-none rounded-md bg-[#fffbfb] drop-shadow-xl">
                    {options.map(option => (
                        <div
                            className={`select-none border bg-none  py-4 px-5 text-primary first:rounded-t-md last:rounded-b-md  last:border-b-0 even:border-x   hover:bg-zinc-200 hover:text-secondary 
                            ${
                                option.value === isSelected &&
                                "pointer-events-none bg-zinc-100 text-secondary hover:cursor-not-allowed"
                            }`}
                            onClick={() => {
                                setShowDropdown(false);
                                handleSelect(option);
                                setClick && setClick(true);
                            }}
                            key={option.value}
                        >
                            {option.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
