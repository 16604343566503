import { useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../../types/form";
import MultiSelectOptions from "./MultiSelectOptions";
import { classNames } from "../../utils/style";

type Props = {
    options: SelectOption[];
    hasError?: boolean;
    placeholder?: string;
    onChange: (arg: SelectOption[], key?: string) => void;
    value?: SelectOption[];
};

function ComboBox({
    options,
    hasError,
    placeholder,
    onChange,
    value = [],
}: Props) {
    const [query, setQuery] = useState("");
    const { t } = useTranslation();
    const comboBoxButtonRef = useRef<HTMLButtonElement>(null);
    const optionsRef = useRef(options);

    const removeSelectedOption = (
        e: React.MouseEvent<HTMLButtonElement>,
        removeValue: string
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const newOptions = [...value];
        const matchedIndex = newOptions.findIndex(
            option => option.value === removeValue
        );

        matchedIndex !== -1 && newOptions.splice(matchedIndex, 1);

        onChange && onChange(newOptions);
    };

    const selectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onChange && onChange(optionsRef.current);
    };

    const clearAll = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onChange && onChange([]);
    };

    const filteredOptions =
        query === ""
            ? optionsRef.current
            : optionsRef.current.filter(option => {
                  return option.label
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });

    return (
        <Combobox
            value={value}
            by="value"
            onChange={value => {
                onChange && onChange(value);
            }}
            multiple
        >
            {({ open }: { open: boolean }) => (
                <div className="relative w-full">
                    <div
                        className={classNames(
                            "min-h-[40px] border-b",
                            hasError ? "border-red-400" : "border-gray-300"
                        )}
                    >
                        <div
                            className="flex"
                            onClick={() => comboBoxButtonRef.current?.click()}
                        >
                            <div className="flex grow flex-wrap gap-2">
                                {value?.map(option => (
                                    <div
                                        className="flex h-7 items-center rounded-lg bg-primary text-sm text-white"
                                        key={option.value}
                                    >
                                        <span className="pl-3">
                                            {option.label}
                                        </span>
                                        <button
                                            className="mt-0 ml-2 h-full rounded-lg px-3 hover:bg-primary-light"
                                            onClick={e =>
                                                removeSelectedOption(
                                                    e,
                                                    option.value as string
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes as IconProp}
                                            />
                                        </button>
                                    </div>
                                ))}
                                <Combobox.Input
                                    className="m-1 inline-flex h-7 w-16 flex-grow flex-wrap bg-transparent"
                                    placeholder={placeholder ?? t("search")}
                                    displayValue={options => {
                                        return query;
                                    }}
                                    onChange={event =>
                                        setQuery(event.target.value)
                                    }
                                />
                            </div>
                            <Combobox.Button
                                className="flex items-center px-2 text-xl focus:outline-none"
                                ref={comboBoxButtonRef}
                            >
                                <FontAwesomeIcon
                                    icon={faAngleDown as IconProp}
                                />
                            </Combobox.Button>
                        </div>
                    </div>
                    <Transition
                        show={open}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <MultiSelectOptions
                            options={filteredOptions}
                            selectedOptions={value}
                            comboBoxButtonRef={comboBoxButtonRef}
                            selectAll={selectAll}
                            clearAll={clearAll}
                        />
                    </Transition>
                </div>
            )}
        </Combobox>
    );
}

export default ComboBox;
