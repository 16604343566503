import styled from "styled-components";
export { FormControl } from "../../../style/shared/form.styled";

export const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    textarea {
        padding: 10px;
        border: 1px solid #828282;
        ${props => props.error && `border: 2px solid #e65f5f;`};
    }
`;
