import { useState, useEffect } from "react";
import * as S from "../Popups.styled";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "jspdf-autotable";
import useFileExport from "../../../hooks/useFileExport";
import ExportDateFields from "../../ExportDateFields";
import ExportExcelFields from "../../ExcelExportFieldsEntryLog";
import usePopup from "../../../hooks/usePopup";
import Popup from "../Popup";
import { useUserStore } from "../../../store/userStore";
import Select from "react-select";
import { queryMaker } from "../../../utils/queryMaker";
import { columns } from "../../../utils/tableColumns";
import Button from "../../Button";

function ExportEntryLogPopup() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setValue,
    } = useForm();

    const { close } = usePopup();
    const { t } = useTranslation();
    const { user } = useUserStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fields, setFields] = useState();
    const [queryString, setQueryString] = useState(undefined);

    const entityName = t("entry_log");
    const { requestExportDataByDate, exportPdfTableByDate } =
        useFileExport(entityName);

    const [exportType, setExportType] = useState(null);

    const prepareExport = (responseData, formValues) => {
        const filterDates = {
            from_date: formValues.from_date,
            from_time: formValues.from_time,
            until_date: formValues.until_date,
            until_time: formValues.until_time,
        };

        const tableHeaders = columns.entryLog.map(column => {
            return column.Header;
        });

        const tableRows = responseData.map(data => {
            return [
                data.player.full_name,
                t(data.type),
                data.entry ? t("approved") : t("declined"),
                data.notes,
                dayjs(data.created_at).format("DD-MM-YYYY HH:mm"),
            ];
        });

        exportPdfTableByDate(
            tableHeaders,
            tableRows,
            filterDates,
            `${entityName} ${user.current_location_name}`,
            `${dayjs(new Date()).format("DD-MM-YYYY HH:mm")} ${
                user.current_location_name
            }`
        );
    };

    const onSubmit = async data => {
        setIsSubmitting(true);
        await requestExportDataByDate("/api/entries", data).then(
            responseData => {
                close();
                prepareExport(responseData, data);
            }
        );
        setIsSubmitting(false);
    };

    const options = [
        { value: "pdf", label: "PDF" },
        { value: "excel", label: "Excel" },
    ];

    const handleChange = value => {
        setExportType(value.value);
    };

    const allFields = watch();

    const {
        location_id: locations,
        date_from,
        date_until,
        sub_type,
        type,
        columns: column,
    } = allFields;

    useEffect(() => {
        const location_id = locations?.map(item => item.value);
        const columns = column?.map(item => item.value);
        setFields({
            filters: {
                date_from,
                date_until,
                locations: location_id,
                sub_type,
                type,
            },
            columns,
        });
    }, [column, date_from, date_until, locations, sub_type, type]);

    useEffect(() => {
        setQueryString(queryMaker(fields));
    }, [fields]);

    return (
        <Popup>
            <S.Header>
                {t("export")}: {entityName}
            </S.Header>

            <S.SelectContainer>
                <Select
                    options={options}
                    onChange={handleChange}
                    placeholder="Select export type"
                    defaultValue={{
                        label: options[0].label,
                        value: options[0].value,
                    }}
                />
            </S.SelectContainer>

            <S.EntryLogPopupForm onSubmit={handleSubmit(onSubmit)}>
                {!exportType || exportType === "pdf" ? (
                    <>
                        <ExportDateFields register={register} errors={errors} />
                        <Button
                            className="btn-secondary"
                            type="submit"
                            isLoading={isSubmitting}
                        >
                            {t("export")}
                        </Button>
                    </>
                ) : (
                    <>
                        <ExportExcelFields
                            register={register}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                        />
                        <S.StyledLink
                            href={`${
                                process.env.REACT_APP_API_BASE_URL
                            }/exports/entries?${queryString ?? ""}`}
                            download
                        >
                            {t("export")}
                        </S.StyledLink>
                    </>
                )}
            </S.EntryLogPopupForm>
        </Popup>
    );
}

export default ExportEntryLogPopup;
