/*
    This is based on stu-sdk-sigcaptx-samples-master on github
*/

import i18n from "i18next";

var WacomGSS = window.WacomGSS;
var Q = window.Q;

var m_btns; // The array of buttons that we are emulating.
var m_clickBtn = -1;
var m_usbDevices;
var tablet;
var m_capability;
var m_inkThreshold;
var m_imgData;
var m_encodingMode;
var ctx;
var canvas;
var m_penData;
var lastPoint;
var isDown;
var onOk;
var onCancel;
var onError;

let signatureText;
let buttonTexts;

function onDCAtimeout() {
    // Device Control App has timed-out and shut down
    //console.log("DCA disconnected");
    setTimeout(closeDevice, 0);
}

function Rectangle(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    this.Contains = function (pt) {
        if (
            pt.x >= this.x &&
            pt.x <= this.x + this.width &&
            pt.y >= this.y &&
            pt.y <= this.y + this.height
        ) {
            return true;
        } else {
            return false;
        }
    };
}

// In order to simulate buttons, we have our own Button class that stores the bounds and event handler.
// Using an array of these makes it easy to add or remove buttons as desired.
//  delegate void ButtonClick();
function Button() {
    this.Bounds = null; // in Screen coordinates
    this.Text = null;
    this.Click = null;
}

function Point(x, y) {
    this.x = x;
    this.y = y;
}

function prepareCanvas() {
    canvas = document.getElementById("signatureCanvas");
    canvas.height = 480;
    canvas.width = 800;
    ctx = canvas.getContext("2d");

    if (canvas.addEventListener) {
        canvas.addEventListener("click", onCanvasClick, false);
    } else if (canvas.attachEvent) {
        canvas.attachEvent("onClick", onCanvasClick);
    } else {
        canvas["onClick"] = onCanvasClick;
    }
}

function onCanvasClick(event) {
    const posX = event.offsetX;
    const posY = event.offsetY;

    for (let i = 0; i < m_btns.length; i++) {
        if (m_btns[i].Bounds.Contains(new Point(posX, posY))) {
            m_btns[i].Click();
            break;
        }
    }
}

function disconnect() {
    var deferred = Q.defer();
    if (!(undefined === tablet || null === tablet)) {
        var p = new WacomGSS.STU.Protocol();
        tablet
            .setInkingMode(p.InkingMode.InkingMode_Off)
            .then(function (message) {
                return tablet.endCapture();
            })
            .then(function (message) {
                if (m_imgData !== null) {
                    return m_imgData.remove();
                } else {
                    return message;
                }
            })
            .then(function (message) {
                m_imgData = null;
                return tablet.setClearScreen();
            })
            .then(function (message) {
                return tablet.disconnect();
            })
            .then(function (message) {
                tablet = null;
                // clear canvas
                clearCanvas(canvas, ctx);
            })
            .then(function (message) {
                deferred.resolve();
            })
            .fail(function (message) {
                //console.log("disconnect error: " + message);
                deferred.resolve();
            });
    } else {
        deferred.resolve();
    }
    return deferred.promise;
}

window.addEventListener("beforeunload", e => {
    WacomGSS.STU.onDCAtimeout = null;
    WacomGSS.STU.close();
});

// Error-derived object for Device Control App not ready exception
function DCANotReady() {}
DCANotReady.prototype = new Error();

export async function checkDeviceConnection() {
    let isConnected = false;

    try {
        const devices = await WacomGSS.STU.getUsbDevices();
        if (devices?.length) {
            isConnected = true;
        }
    } catch (e) {
        throw new Error(e);
    }

    return isConnected;
}

export function startSignature(callbacks, text = "", buttonLabels) {
    let p = new WacomGSS.STU.Protocol();
    let intf;
    let m_usingEncryption = false;

    signatureText = text;
    buttonTexts = buttonLabels;
    onOk = callbacks.ok;
    onCancel = callbacks.cancel;
    onError = callbacks.error;

    WacomGSS.STU.isDCAReady()
        .then(function (message) {
            if (!message) {
                throw new DCANotReady();
            }
            // Set handler for Device Control App timeout
            WacomGSS.STU.onDCAtimeout = onDCAtimeout;

            return WacomGSS.STU.getUsbDevices();
        })
        .then(function (message) {
            if (message == null || message.length === 0) {
                throw new Error("No STU devices found");
            }
            m_usbDevices = message;
            return WacomGSS.STU.isSupportedUsbDevice(
                m_usbDevices[0].idVendor,
                m_usbDevices[0].idProduct
            );
        })
        .then(function (message) {
            intf = new WacomGSS.STU.UsbInterface();
            return intf.Constructor();
        })
        .then(function (message) {
            return intf.connect(m_usbDevices[0], true);
        })
        .then(function (message) {
            tablet = new WacomGSS.STU.Tablet();
            return tablet.Constructor(intf, null, null);
        })
        .then(function (message) {
            intf = null;
            return tablet.getInkThreshold();
        })
        .then(function (message) {
            m_inkThreshold = message;
            return tablet.getCapability();
        })
        .then(function (message) {
            m_capability = message;
            prepareCanvas();
            return tablet.getInformation();
        })
        .then(function (message) {
            return tablet.getInkThreshold();
        })
        .then(function (message) {
            return tablet.getProductId();
        })
        .then(function (message) {
            return WacomGSS.STU.ProtocolHelper.simulateEncodingFlag(
                message,
                m_capability.encodingFlag
            );
        })
        .then(function (message) {
            var encodingFlag = message;
            if ((encodingFlag & p.EncodingFlag.EncodingFlag_24bit) !== 0) {
                return tablet.supportsWrite().then(function (message) {
                    m_encodingMode = message
                        ? p.EncodingMode.EncodingMode_24bit_Bulk
                        : p.EncodingMode.EncodingMode_24bit;
                });
            } else if (
                (encodingFlag & p.EncodingFlag.EncodingFlag_16bit) !==
                0
            ) {
                return tablet.supportsWrite().then(function (message) {
                    m_encodingMode = message
                        ? p.EncodingMode.EncodingMode_16bit_Bulk
                        : p.EncodingMode.EncodingMode_16bit;
                });
            } else {
                // assumes 1bit is available
                m_encodingMode = p.EncodingMode.EncodingMode_1bit;
            }
        })
        .then(function (message) {
            return tablet.isSupported(p.ReportId.ReportId_EncryptionStatus); // v2 encryption
        })
        .then(function (message) {
            m_usingEncryption = message;
            // if the encryption script is missing turn off encryption regardless
            if (typeof window.sjcl == "undefined") {
                //console.log("sjcl not found - encryption disabled");
                m_usingEncryption = false;
            }
            return tablet.getDHprime();
        })
        .then(function (dhPrime) {
            return WacomGSS.STU.ProtocolHelper.supportsEncryption_DHprime(
                dhPrime
            ); // v1 encryption
        })
        .then(function (message) {
            m_usingEncryption = message ? true : m_usingEncryption;
            return tablet.setClearScreen();
        })
        .then(function (message) {
            if (m_usingEncryption) {
                return tablet.startCapture(0xc0ffee);
            } else {
                return message;
            }
        })
        .then(function (message) {
            return tablet.isSupported(p.ReportId.ReportId_PenDataOptionMode);
        })
        .then(function (message) {
            if (message) {
                return tablet.getProductId().then(function (message) {
                    var penDataOptionMode =
                        p.PenDataOptionMode.PenDataOptionMode_None;
                    switch (message) {
                        case WacomGSS.STU.ProductId.ProductId_520A:
                            penDataOptionMode =
                                p.PenDataOptionMode.PenDataOptionMode_TimeCount;
                            break;
                        case WacomGSS.STU.ProductId.ProductId_430:
                        case WacomGSS.STU.ProductId.ProductId_530:
                            penDataOptionMode =
                                p.PenDataOptionMode
                                    .PenDataOptionMode_TimeCountSequence;
                            break;
                        default:
                        // console.log(
                        //     "Unknown tablet supporting PenDataOptionMode, setting to None."
                        // );
                    }
                    return tablet.setPenDataOptionMode(penDataOptionMode);
                });
            } else {
                m_encodingMode = p.EncodingMode.EncodingMode_1bit;
                return m_encodingMode;
            }
        })
        .then(function (message) {
            addInterface(buttonTexts);
            var canvasImage = canvas.toDataURL("image/jpeg");

            return WacomGSS.STU.ProtocolHelper.resizeAndFlatten(
                canvasImage,
                0,
                0,
                0,
                0,
                m_capability.screenWidth,
                m_capability.screenHeight,
                m_encodingMode,
                1,
                false,
                0,
                true
            );
        })
        .then(function (message) {
            m_imgData = message;
            return tablet.writeImage(m_encodingMode, message);
        })
        .then(function (message) {
            return tablet.setInkingMode(p.InkingMode.InkingMode_On);
        })
        .then(function (message) {
            var reportHandler = new WacomGSS.STU.ProtocolHelper.ReportHandler();
            lastPoint = { x: 0, y: 0 };
            isDown = false;
            ctx.lineWidth = 1;

            var penData = function (report) {
                m_penData.push(report);
                processButtons(report, canvas);
                processPoint(report, canvas, ctx);
            };
            var penDataEncryptedOption = function (report) {
                m_penData.push(report.penData[0], report.penData[1]);
                processButtons(report.penData[0], canvas);
                processPoint(report.penData[0], canvas, ctx);
                processButtons(report.penData[1], canvas);
                processPoint(report.penData[1], canvas, ctx);
            };

            var log = function (report) {
                //console.log("report: " + JSON.stringify(report));
            };

            var decrypted = function (report) {
                //console.log("decrypted: " + JSON.stringify(report));
            };
            m_penData = [];
            reportHandler.onReportPenData = penData;
            reportHandler.onReportPenDataOption = penData;
            reportHandler.onReportPenDataTimeCountSequence = penData;
            reportHandler.onReportPenDataEncrypted = penDataEncryptedOption;
            reportHandler.onReportPenDataEncryptedOption =
                penDataEncryptedOption;
            reportHandler.onReportPenDataTimeCountSequenceEncrypted = penData;
            reportHandler.onReportDevicePublicKey = log;
            reportHandler.onReportEncryptionStatus = log;
            reportHandler.decrypt = decrypted;
            return reportHandler.startReporting(tablet, true);
        })
        .fail(function (ex) {
            if (ex instanceof DCANotReady) {
                // Device Control App not detected
                // Reinitialize and re-try
                WacomGSS.STU.Reinitialize();
                setTimeout(() => {
                    startSignature(
                        callbacks.ok,
                        callbacks.cancel,
                        callbacks.error
                    );
                }, 1000);
            } else {
                // Some other error - Inform the user and closedown
                onError("unknown", ex);
                setTimeout(closeDevice(), 0);
            }
        });
}

function addInterface(buttonTexts) {
    m_btns = new Array(3);
    m_btns[0] = new Button();
    m_btns[1] = new Button();
    m_btns[2] = new Button();

    const buttonHeight = 60;
    const buttonPosY = 400;

    const buttonWidth = 200;
    const spacing = 20;

    m_btns[0].Bounds = new Rectangle(
        spacing,
        buttonPosY,
        buttonWidth,
        buttonHeight
    );
    m_btns[1].Bounds = new Rectangle(
        buttonWidth + spacing * 2,
        buttonPosY,
        buttonWidth,
        buttonHeight
    );
    m_btns[2].Bounds = new Rectangle(
        m_capability.screenWidth - buttonWidth - spacing,
        buttonPosY,
        buttonWidth,
        buttonHeight
    );

    m_btns[0].Text = buttonTexts?.ok || i18n.t("ok");
    m_btns[1].Text = buttonTexts?.clear || i18n.t("clear");
    m_btns[2].Text = buttonTexts?.cancel || i18n.t("cancel");
    m_btns[0].Click = btnOk_Click;
    m_btns[1].Click = btnClear_Click;
    m_btns[2].Click = btnCancel_Click;
    clearCanvas(canvas, ctx);
    drawInterface();
}

function drawInterface() {
    // This application uses the same bitmap for both the screen and client (window).

    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "black";
    ctx.font = "26px Ubuntu";

    const buttonColors = [
        { fill: "#CA523A", text: "#fff" },
        { fill: "#e9e9e9", text: "#404040" },
        { fill: "#e9e9e9", text: "#404040" },
    ];

    // Draw the buttons
    for (var i = 0; i < m_btns.length; ++i) {
        ctx.fillStyle = buttonColors[i].fill;
        ctx.fillRect(
            m_btns[i].Bounds.x,
            m_btns[i].Bounds.y,
            m_btns[i].Bounds.width,
            m_btns[i].Bounds.height
        );

        ctx.fillStyle = buttonColors[i].text;
        ctx.rect(
            m_btns[i].Bounds.x,
            m_btns[i].Bounds.y,
            m_btns[i].Bounds.width,
            m_btns[i].Bounds.height
        );
        var xPos =
            m_btns[i].Bounds.x +
            (m_btns[i].Bounds.width / 2 -
                ctx.measureText(m_btns[i].Text).width / 2);
        var yOffset;
        if (m_usbDevices[0].idProduct === WacomGSS.STU.ProductId.ProductId_300)
            yOffset = 28;
        else if (
            m_usbDevices[0].idProduct === WacomGSS.STU.ProductId.ProductId_430
        )
            yOffset = 26;
        else yOffset = 40;
        ctx.fillText(m_btns[i].Text, xPos, m_btns[i].Bounds.y + yOffset);
    }
    //ctx.stroke();
    ctx.closePath();

    const lineStartPosX = m_capability.screenWidth * 0.15;
    const lineEndPosX = m_capability.screenWidth * 0.85;
    const linePosY = 290;

    // Line
    ctx.beginPath();
    ctx.moveTo(lineStartPosX, linePosY);
    ctx.lineTo(lineEndPosX, linePosY);
    ctx.strokeStyle = "#9b9b9b";
    ctx.stroke();
    ctx.closePath();

    const acceptText = signatureText;
    const multilines = getMultiLines(
        ctx,
        acceptText,
        lineEndPosX - lineStartPosX + 100
    );

    ctx.textAlign = "start";
    ctx.font = "20px Ubuntu";

    multilines.forEach((val, index) => {
        ctx.fillText(val, lineStartPosX, linePosY + 40 + 30 * index);
    });

    ctx.restore();
}

function getMultiLines(ctx, text, maxWidth) {
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = ctx.measureText(currentLine + " " + word).width;
        if (width < maxWidth) {
            currentLine += " " + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);
    return lines;
}

function clearScreen() {
    clearCanvas(canvas, ctx);
    drawInterface();
    m_penData = [];
    tablet.writeImage(m_encodingMode, m_imgData);
}

function btnOk_Click() {
    const generatedImage = generateImage(m_penData);
    onOk(generatedImage, m_penData);
}

function btnCancel_Click() {
    onCancel();
    setTimeout(closeDevice, 0);
}

function btnClear_Click() {
    clearScreen();
}

function distance(a, b) {
    return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
}

function clearCanvas(in_canvas, in_ctx) {
    in_ctx.save();
    in_ctx.setTransform(1, 0, 0, 1, 0, 0);
    in_ctx.fillStyle = "white";
    in_ctx.fillRect(0, 0, in_canvas.width, in_canvas.height);
    in_ctx.restore();
}

function processButtons(point, in_canvas) {
    var nextPoint = {};
    nextPoint.x = Math.round(
        (in_canvas.width * point.x) / m_capability.tabletMaxX
    );
    nextPoint.y = Math.round(
        (in_canvas.height * point.y) / m_capability.tabletMaxY
    );
    var isDown2 = isDown
        ? !(point.pressure <= m_inkThreshold.offPressureMark)
        : point.pressure > m_inkThreshold.onPressureMark;

    var btn = -1;
    for (var i = 0; i < m_btns.length; ++i) {
        if (m_btns[i].Bounds.Contains(nextPoint)) {
            btn = i;
            break;
        }
    }

    if (isDown && !isDown2) {
        if (btn !== -1 && m_clickBtn === btn) {
            m_btns[btn].Click();
        }
        m_clickBtn = -1;
    } else if (btn !== -1 && !isDown && isDown2) {
        m_clickBtn = btn;
    }
    return btn === -1;
}

function processPoint(point, in_canvas, in_ctx) {
    var nextPoint = {};
    nextPoint.x = Math.round(
        (in_canvas.width * point.x) / m_capability.tabletMaxX
    );
    nextPoint.y = Math.round(
        (in_canvas.height * point.y) / m_capability.tabletMaxY
    );
    var isDown2 = isDown
        ? !(point.pressure <= m_inkThreshold.offPressureMark)
        : point.pressure > m_inkThreshold.onPressureMark;

    if (!isDown && isDown2) {
        lastPoint = nextPoint;
    }

    if (
        (isDown2 && 10 < distance(lastPoint, nextPoint)) ||
        (isDown && !isDown2)
    ) {
        in_ctx.beginPath();
        in_ctx.moveTo(lastPoint.x, lastPoint.y);
        in_ctx.lineTo(nextPoint.x, nextPoint.y);
        in_ctx.stroke();
        in_ctx.closePath();
        lastPoint = nextPoint;
    }

    isDown = isDown2;
}

function generateImage(penPoints) {
    const signatureCanvas = document.createElement("canvas");
    signatureCanvas.id = "signatureCanvas";
    signatureCanvas.height = 180;
    signatureCanvas.width = 300;
    const signatureCtx = signatureCanvas.getContext("2d");

    clearCanvas(signatureCanvas, signatureCtx);
    signatureCtx.lineWidth = 1;
    signatureCtx.strokeStyle = "black";
    lastPoint = { x: 0, y: 0 };
    isDown = false;

    for (let i = 0; i < penPoints.length; i++) {
        // Make sure pen points on buttons don't get registered
        if (penPoints[i].y < 4500) {
            processPoint(penPoints[i], signatureCanvas, signatureCtx);
        }
    }

    return signatureCanvas.toDataURL("image/jpeg");
}

export function closeDevice() {
    // Clear handler for Device Control App timeout
    WacomGSS.STU.onDCAtimeout = null;
    disconnect();
}
