import * as S from "./EntryCountChart.styled";
import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { EntryHistoryStats } from "../../../types/statistics";
import BlockHeader from "../BlockHeader/BlockHeader";

const Chart = lazy(() => import("react-apexcharts"));

function EntryCountChart({
    entryHistory,
}: {
    entryHistory: EntryHistoryStats | undefined;
}) {
    const { t } = useTranslation();

    const getEntryStats = () => {
        if (!entryHistory) return [];

        type EntryLocationStats = {
            data: number[];
            months: string[];
            name: string;
        };

        const entryStats: EntryLocationStats[] = [];

        Object.entries(entryHistory).forEach(([location, stats]) => {
            const months: string[] = [];
            const counts: number[] = [];

            Object.entries(stats).forEach(([month, count]) => {
                months.push(month);
                counts.push(count);
            });

            entryStats.push({
                name: location,
                data: counts,
                months: months,
            });
        });

        return entryStats;
    };

    const entryStats = getEntryStats();

    const options = {
        chart: {
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: entryStats[0]?.months || [],
        },
    };

    return (
        <S.EntryCountChart>
            <BlockHeader
                title={t("entry_count_overview")}
                subTitle={t("all_locations")}
                link="/log"
            />
            <Suspense fallback="">
                <Chart
                    options={options}
                    series={entryStats}
                    type="line"
                    height={350}
                />
            </Suspense>
        </S.EntryCountChart>
    );
}

export default EntryCountChart;
