import dayjs from "dayjs";

export const getTimeRangeHours = () => {
    // Temporary hard coded range
    const timeRange = {
        start: 9,
        end: 1,
    };

    let hourCount = 0;
    let currentHour = timeRange.start;

    hourCount = timeRange.end - timeRange.start;

    // When timerange continues into the next day
    if (timeRange.end < timeRange.start) {
        hourCount = 24 - timeRange.start + timeRange.end;
    }

    const hourList = [];

    for (let i = 0; i < hourCount + 1; i++) {
        const formattedHour = dayjs().hour(currentHour).format("HH:00");
        hourList.push(formattedHour);
        currentHour++;
        if (currentHour === 24) {
            currentHour = 0;
        }
    }

    return hourList;
};
