export const policies = [
    {
        background: "#CEF9D7",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#CEF9D7",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#FFEBD9",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#FFEBD9",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#FFD2CF",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#FFD2CF",
        color: "#050505",
        fullwidth: false,
    },
    {
        background: "#FBDEC6",
        color: "#050505",
        fullwidth: false,
    },
];
