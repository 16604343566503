import { useState, useEffect } from "react";
import { useTable, usePagination, useFilters, useExpanded } from "react-table";
import * as S from "./DataTable.styled";
import CustomSelect from "../CustomSelect";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import ContextMenu from "../ContextMenu/ContextMenu";
import LoadingOverlay from "../LoadingOverlay";

function SortArrows({ activeSorting, sortKey }) {
    return (
        <S.ArrowContainer>
            <FontAwesomeIcon
                icon={faCaretUp}
                size="sm"
                color={
                    activeSorting.sortKey === sortKey &&
                    activeSorting.sortDirection === "asc"
                        ? "#808080"
                        : "#dedede"
                }
            />
            <FontAwesomeIcon
                icon={faCaretDown}
                size="sm"
                style={{ marginTop: "-8px" }}
                color={
                    activeSorting.sortKey === sortKey &&
                    activeSorting.sortDirection === "desc"
                        ? "#808080"
                        : "#dedede"
                }
            />
        </S.ArrowContainer>
    );
}

export default function DataTable({
    columns,
    data,
    tableType,
    onClick,
    pagination = true,
    name,
    noDataMessage = false,
    selected = null,
    containerRef,
    onScroll,
    contextMenuItems,
    updateSortParams,
    isLoading,
    verticalAlign = "middle",
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: pagination ? 10 : 10000 },
        },
        useFilters,
        useExpanded,
        usePagination
    );

    const { t } = useTranslation();

    const [isSelected, setIsSelected] = useState(10);

    const options = [
        {
            value: 100000000,
            text: t("all_checked_name", { name }),
        },
        {
            value: 5,
            text: t("last_number_name", { number: 5, name }),
        },
        {
            value: 10,
            text: t("last_number_name", { number: 10, name }),
        },
        {
            value: 25,
            text: t("last_number_name", { number: 25, name }),
        },
        {
            value: 50,
            text: t("last_number_name", { number: 50, name }),
        },
    ];

    const selectOption = option => {
        if (pageSize && option !== pageSize) {
            setPageSize(parseInt(option));
        }
    };

    const [activeSorting, setActiveSorting] = useState({
        sortKey: undefined,
        sortDirection: undefined,
    });

    useEffect(() => {
        if (updateSortParams) {
            updateSortParams(
                activeSorting.sortKey,
                activeSorting.sortDirection
            );
        }
    }, [activeSorting, updateSortParams]);

    return (
        <S.TableStyles
            tableType={tableType}
            verticalAlign={verticalAlign}
            ref={containerRef}
            onScroll={() => onScroll && onScroll()}
        >
            {<LoadingOverlay isLoading={isLoading} />}
            {!isLoading && (
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={index}
                            >
                                {headerGroup.headers.map(column => {
                                    return (
                                        <th
                                            {...column.getHeaderProps()}
                                            onClick={() => {
                                                if (!column.sortKey) return;

                                                setActiveSorting(prev => {
                                                    const copy = { ...prev };
                                                    if (
                                                        copy.sortKey ===
                                                        column.sortKey
                                                    ) {
                                                        return {
                                                            sortKey:
                                                                column.sortKey,
                                                            sortDirection:
                                                                copy.sortDirection ===
                                                                undefined
                                                                    ? "asc"
                                                                    : copy.sortDirection ===
                                                                      "asc"
                                                                    ? "desc"
                                                                    : undefined,
                                                        };
                                                    }

                                                    return {
                                                        sortKey: column.sortKey,
                                                        sortDirection: "asc",
                                                    };
                                                });
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                {column.render("Header")}
                                                {column.Filter &&
                                                    column.render("Filter")}

                                                {column.sortKey && (
                                                    <SortArrows
                                                        sortKey={column.sortKey}
                                                        activeSorting={
                                                            activeSorting
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    );
                                })}
                                {contextMenuItems?.length && (
                                    <th
                                        style={{
                                            width: "40px",
                                            minWidth: "auto",
                                        }}
                                    ></th>
                                )}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <S.Tr
                                    contextMenuItems={contextMenuItems}
                                    {...row.getRowProps()}
                                    key={i}
                                    onClick={() => {
                                        onClick && onClick(row.original);
                                        row.toggleRowExpanded();
                                    }}
                                    selectedColor={selected === row.original.id}
                                >
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={index}
                                                style={{
                                                    width: cell.column.width,
                                                }}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    {contextMenuItems?.length && (
                                        <td
                                            style={{
                                                width: "40px",
                                            }}
                                        >
                                            <ContextMenu
                                                menuItems={contextMenuItems.map(
                                                    item => {
                                                        return {
                                                            ...item,
                                                            action: () =>
                                                                item.action(
                                                                    row.original
                                                                ),
                                                        };
                                                    }
                                                )}
                                            />
                                        </td>
                                    )}
                                </S.Tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {noDataMessage && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "50px",
                        fontSize: "28px",
                        color: "#b7b7b7",
                    }}
                >
                    {t("no_suggestions")}
                </div>
            )}

            {pagination && (
                <>
                    <S.BottomBar>
                        {data.length > 0 && (
                            <S.Show>
                                <p>{t("show")} :</p>
                                <CustomSelect
                                    options={options}
                                    onChangeCallback={selectOption}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    optionsPosition="bottom"
                                />
                            </S.Show>
                        )}
                    </S.BottomBar>
                </>
            )}
        </S.TableStyles>
    );
}
