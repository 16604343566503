import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DataTable } from "../../components/DataTable";
import Filter from "../../components/Filter";

import PageHeader from "../../components/PageHeader";
import { dateOptions } from "../../utils/filterOptions";
import { columns } from "../../utils/tableColumns";
import usePopup from "../../hooks/usePopup";
import usePermissions from "../../hooks/usePermissions";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";

export default function EntryLog() {
    const tableColumns = columns.entryLog;
    const history = useHistory();
    const { t } = useTranslation();

    const { hasPermissions } = usePermissions();
    const { popup } = usePopup();

    const {
        onScroll,
        updateFilterParams,
        containerRef,
        isLoading,
        updateSortParams,
        filteredData,
    } = useInfiniteScroll("/api/entries");

    const hasExportPermissions = hasPermissions(["export_entries"]);

    const getEntries = entries =>
        entries.map(entry => ({
            ...entry,
            player_photo: entry.profile_photo_url,
            id: entry.player.id,
            created_at:
                entry.created_at &&
                dayjs(entry.created_at).format("DD-MM-YYYY HH:mm"),
        }));

    const handleTableClick = data => {
        history.push(`/guest/${data.id}`);
    };

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    const buttons = hasExportPermissions
        ? [
              {
                  text: t("export"),
                  clickHandler: () =>
                      popup({
                          type: "EXPORT_ENTRY_LOG",
                      }),
                  type: "pageHeader",
                  faIcon: faFileExport,
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title={t("guestbook")}
                showRegisterButton={false}
                placeholder={t("search_player")}
                showSearch={false}
                buttons={buttons}
            />
            <Filter filters={filters} updateFilterParams={updateFilterParams} />

            <DataTable
                columns={tableColumns}
                data={getEntries(filteredData)}
                tableType="entry"
                onScroll={onScroll}
                containerRef={containerRef}
                onClick={handleTableClick}
                name={t("guests")}
                pagination={false}
                updateSortParams={updateSortParams}
                isLoading={isLoading}
            />
        </>
    );
}
