import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as S from "./ExcelExportFieldsEntryLog.styled";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useUserStore } from "../../store/userStore";
import MultiSelect from "../MultiSelect/MultiSelect";
import {
    nationalityOptions,
    columnsOptions,
    checkOptions,
} from "../../utils/exportOptionsEntryLog";

function ExcelExportFieldsEntryLog({ register, errors, control, setValue }) {
    const { t } = useTranslation();

    const { user } = useUserStore();

    const locationOptions =
        user?.locations.map(location => {
            return {
                value: location.id,
                label: location.name,
                key: "locations",
            };
        }) ?? [];

    const handleSelect = value => {
        setValue(value.key, value.value);
    };

    return (
        <>
            <S.FormControl error={errors.date_from} required={false}>
                <label>{t("from_date")}:</label>
                <input
                    type="date"
                    min={dayjs(new Date("2020/06/01")).format("YYYY-MM-DD")}
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("date_from", { required: false })}
                />
            </S.FormControl>

            <S.FormControl error={errors.date_until} required={false}>
                <label>{t("until_date")}:</label>
                <input
                    type="date"
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("date_until", { required: false })}
                />
            </S.FormControl>

            <S.FormControl>
                <label>{t("location") + "/" + t("columns")}:</label>
                <S.MultiSelectContainer>
                    {locationOptions.length > 1 && (
                        <Controller
                            name="location_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        options={locationOptions}
                                        onChange={field.onChange}
                                        placeholder={t("add_locations") + ".."}
                                        selectKey="location_id"
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    )}
                    <Controller
                        name="columns"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={columnsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("add_columns") + ".."}
                                    selectKey="columns"
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </S.MultiSelectContainer>
            </S.FormControl>

            <S.FormControl>
                <label>{t("has_bsn")}:</label>
                <S.SelectContainer>
                    <Select
                        options={nationalityOptions}
                        onChange={handleSelect}
                        placeholder={t("has_bsn")}
                    />
                </S.SelectContainer>
            </S.FormControl>

            <S.FormControl>
                <label>{t("entry_status")}:</label>
                <S.SelectContainer>
                    <Select
                        options={checkOptions}
                        onChange={handleSelect}
                        placeholder={t("entry_status")}
                    />
                </S.SelectContainer>
            </S.FormControl>
        </>
    );
}

export default ExcelExportFieldsEntryLog;
