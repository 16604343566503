import React from "react";
import * as S from "./TabsBox.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
const TabsBox = ({
    title,
    headerColor,
    headerTextColor,
    icon,
    iconColor,
    options,
}) => {
    return (
        <S.TabsBoxContainer>
            <S.Header
                headerColor={headerColor}
                headerTextColor={headerTextColor}
                iconColor={iconColor}
            >
                <FontAwesomeIcon icon={icon} size="lg" />
                {title}
            </S.Header>

            <S.TabsContainer>
                {options.map((option, index) => {
                    return (
                        <S.Tab
                            to={{ pathname: option.link, state: option.state }}
                            key={index}
                        >
                            {option.text}
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                        </S.Tab>
                    );
                })}
            </S.TabsContainer>
        </S.TabsBoxContainer>
    );
};

export default TabsBox;
