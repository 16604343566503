import client from "../client";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import useCustomToast from "./useCustomToast";

function useTemplateMutations() {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { customToast } = useCustomToast();

    const saveTemplate = name => {
        if (!name) return;

        client
            .post("/api/facility-check-templates", {
                name: name,
                facility_check_items: [],
            })
            .then(res => {
                queryClient.invalidateQueries("facility-check-templates");
            });
    };

    const deleteTemplate = id => {
        client
            .delete(`/api/facility-check-templates/${id}`)
            .then(res => {
                customToast("success", {
                    title: t(`entity_successfully_deleted`, {
                        entity: t("template"),
                    }),
                });
                queryClient.invalidateQueries("facility-check-templates");
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    const editTemplate = (id, name) => {
        if (!name) return;

        client
            .put(`/api/facility-check-templates/${id}`, {
                name: name,
            })
            .then(res => {
                customToast("success", {
                    title: t(`entity_successfully_updated`, {
                        entity: t("template"),
                    }),
                });
                queryClient.invalidateQueries("facility-check-templates");
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    return { saveTemplate, deleteTemplate, editTemplate };
}

export default useTemplateMutations;
