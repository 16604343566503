import styled from "styled-components";

export const ContextMenu = styled.div`
    position: relative;
`;

export const ContextMenuButton = styled.button`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    transition: 0.2s ease-in-out;
    ${props => props.isActive && `opacity: 1;`}
    &:hover {
        opacity: 1;
    }
    > svg {
        font-size: 24px;
    }
`;

export const ContextMenuOptions = styled.ul`
    position: absolute;
    top: 90%;
    z-index: 10;
    right: 75px;
    background: #fff;
    width: 220px;
    transition: 0.2s ease-in-out;
    display: none;
    ${props => props.isActive && `display: block;`}
    box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
    button {
        text-align: left;
        padding: 14px 20px;
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.main.borderColor};
        &:hover {
            background: #f1eaea99;
        }
    }
    li {
        &:last-child {
            button {
                border: none;
            }
        }
    }
`;
