import React, { useState } from "react";
import * as S from "./FormSelectPeriodAndDate.styled";
import CustomSelect from "../../CustomSelect";
import { endDateOptions } from "../../../utils/formOptions";
import day from "dayjs";

const convertedEndDateOptions = endDateOptions.map(option => ({
    text: option.label,
    value: option.value,
}));

function FormSelectPeriodAndDate({
    formKey,
    register,
    setValue,
    label = "",
    placeholder = "",
    defaultOption = "indefinitely",
}) {
    const [dateOption, setDateOption] = useState(null);

    const selectDateOption = option => {
        setDateOption(option);

        if (option && option !== "custom" && option !== "indefinitely") {
            const [dayNumber, timePeriod] = option.split("_");

            const newDay = day()
                .add(dayNumber, timePeriod)
                .format("YYYY-MM-DD");

            setValue(formKey, newDay);
        }

        if (option === "indefinitely") {
            setValue(formKey, "");
        }
    };

    return (
        <S.FormControl>
            <label>{label}</label>

            <S.CustomSelectWrapper>
                <CustomSelect
                    options={convertedEndDateOptions}
                    onChangeCallback={selectDateOption}
                    placeholder={placeholder}
                    isSelected={defaultOption}
                />
            </S.CustomSelectWrapper>

            <input
                type="date"
                min={new Date().toJSON().slice(0, 10)}
                {...register(formKey, {
                    required: dateOption && dateOption !== "indefinitely",
                })}
                readOnly={dateOption && dateOption !== "custom"}
                disabled={dateOption && dateOption !== "custom"}
            />
        </S.FormControl>
    );
}

export default FormSelectPeriodAndDate;
