import { useQuery } from "react-query";
import client from "../client";

const fetchData = <TResponse>(key: string, queryParams: string) => {
    let endpoint = `/api/${key}`;
    if (queryParams) {
        endpoint += `?${queryParams}`;
    }
    return client.get<TResponse>(endpoint).then(res => res.data);
};

export default function useFetch<TResponse>(
    key: string,
    queryParams: string = "",
    withoutFocus: boolean = true
) {
    return useQuery(key, () => fetchData<TResponse>(key, queryParams), {
        refetchOnWindowFocus: withoutFocus,
    });
}
