import React, { useRef } from "react";
import * as S from "./RiskLevel.styled";
import { useParams } from "react-router-dom";
import client from "../../client";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useCustomToast from "../../hooks/useCustomToast";

const RiskLevel = ({ player, setRiskLevelContainer, riskLevelContainer }) => {
    const params = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const closeRef = useRef(null);
    useOnClickOutside(closeRef, () => setRiskLevelContainer(null));
    const { customToast } = useCustomToast();

    const riskLevels = [
        {
            riskText: t("recreational"),
            riskLevel: "recreational",
            color: "#27AE60",
        },
        { riskText: t("at_risk"), riskLevel: "at_risk", color: "#F2994A" },
        {
            riskText: t("problematic"),
            riskLevel: "problematic",
            color: "#EB5757",
        },
    ];
    const riskValue = riskLevels.find(
        ({ riskLevel }) => riskLevel === player?.risk_level
    );

    const handleRiskSelected = risk => {
        client
            .put(`/api/guests/${params.id}/risk-level`, {
                risk_level: risk.riskLevel,
            })
            .then(response => {
                customToast("success", {
                    title: t("guest_details_updated"),
                    link: {
                        title: t("view_guest"),
                        url: `/guest/${params.id}`,
                    },
                });
                queryClient.invalidateQueries(`player/${params.id}`);

                setRiskLevelContainer(false);
            })
            .catch(err => {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.message,
                });
                setRiskLevelContainer(false);
            });
    };

    return (
        <div ref={closeRef}>
            <S.RiskLevel
                onClick={() => {
                    setRiskLevelContainer(!riskLevelContainer);
                }}
            >
                <S.RiskBullet color={riskValue?.color} />
                {riskValue?.riskText}
            </S.RiskLevel>

            <S.RiskLevelContainer riskLevelContainer={riskLevelContainer}>
                <S.ArrowUp />
                {riskLevels.map(risk => {
                    return (
                        <S.RiskLevelLine
                            key={risk.color}
                            onClick={() => {
                                handleRiskSelected(risk);
                            }}
                        >
                            <S.RiskBullet color={risk.color} />
                            <S.RiskText>{risk.riskText}</S.RiskText>
                        </S.RiskLevelLine>
                    );
                })}
            </S.RiskLevelContainer>
        </div>
    );
};

export default RiskLevel;
