import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Note } from "../../types/releaseNote";
import { useTranslation } from "react-i18next";

export const ReleaseNotesOverview = ({
    releaseNotes,
}: {
    releaseNotes: Note[];
}) => {
    const { t } = useTranslation();

    return (
        <div className="grow overflow-hidden overflow-y-scroll px-4 pt-6 sm:px-6 lg:px-8">
            <div className="border-gray grid grid-cols-5 items-end gap-4 border-b pb-2 text-base font-medium md:text-xl">
                <div>{t("date_released")}</div>
                <div className="pl-4 ">{t("details")}</div>
            </div>

            <dl className="divide-y divide-gray-200">
                {releaseNotes.map(
                    (note: Note) =>
                        note.approved_at && (
                            <div
                                key={note.id}
                                className="grid grid-cols-5 gap-4 pt-6 pb-8"
                            >
                                <dt className="pt-1 text-sm text-gray-900 md:text-base">
                                    <time dateTime={note.created_at}>
                                        {dayjs(note.created_at).format(
                                            "DD-MM-YYYY HH:mm"
                                        )}
                                    </time>
                                </dt>
                                <dd className="col-span-4  flex w-full justify-between md:w-2/3">
                                    <div className="block w-full">
                                        <p className="flex pl-4 text-3xl font-semibold text-gray-900">
                                            {note.title}
                                        </p>

                                        <div className=" w-full text-gray-500">
                                            <ReactQuill
                                                theme="bubble"
                                                modules={{ toolbar: null }}
                                                readOnly
                                                value={JSON.parse(
                                                    note.description
                                                )}
                                            />
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        )
                )}
            </dl>
        </div>
    );
};
