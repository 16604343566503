import React, { useState, useMemo } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import Filter from "../../components/Filter";

import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import { useParams } from "react-router-dom";
import { dateOptions } from "../../utils/filterOptions";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

function PreventionPlanTypeOverview() {
    const { t } = useTranslation();

    const [filteredData, setFilteredData] = useState([]);

    let { type } = useParams();

    const tableColumns = columns.preventionPlanTypeOverview;
    const signalingColumns = columns.preventionPlanTypeSignalingOverview;

    const requestParams = useMemo(() => {
        return {
            type: type,
            per_page: 20,
        };
    }, [type]);

    const { onScroll, allData, updateData, containerRef, isLoading } =
        useInfiniteScroll(`/api/prevention-plan-records`, requestParams);

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
        // {
        //     type: "default",
        //     key: "location.name",
        //     label: t("filter_by_location"),
        // },
    ];

    return (
        <>
            {allData && (
                <>
                    {" "}
                    <PageHeader
                        title={t("prevention_plan_overview_type", {
                            type: t(type),
                        })}
                        showRegisterButton={false}
                        placeholder={t("search_player")}
                        showSearch={false}
                        showExportButton={false}
                    />
                    <>
                        <Filter
                            filters={filters}
                            data={allData}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            updateData={updateData}
                        />
                    </>
                    <DataTable
                        columns={
                            type === "signalling"
                                ? signalingColumns
                                : tableColumns
                        }
                        data={allData}
                        onScroll={onScroll}
                        containerRef={containerRef}
                        pagination={false}
                        isLoading={isLoading}
                    />
                </>
            )}
        </>
    );
}

export default PreventionPlanTypeOverview;
