import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import * as S from "./Popups.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import Button from "../Button";

const DeletePopup = ({ setShowPopup, value, deleteHandler, text }) => {
    const { t } = useTranslation();
    const params = useParams();
    const popupRef = useRef(null);

    useOnClickOutside(popupRef, () => setShowPopup(false));

    return (
        <S.DeleteContainer>
            <S.DeleteFormContainer
                ref={popupRef}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                        "--fa-primary-color": "#1D1E45",
                        "--fa-secondary-color": "#1D1E45",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "0.9",
                    }}
                    onClick={() => setShowPopup(false)}
                />

                <S.Title>
                    {t(
                        "delete_this_entity",
                        { entity: t(text) },
                        "delete_this_entity"
                    )}
                </S.Title>
                <S.DeleteRequest>
                    {t(
                        `this_entity_will_be_deleted_permenantly_from_all_locations`,
                        { entity: t(text) }
                    )}
                    <br />
                    {t("you_cannot_undo_this_action")}
                </S.DeleteRequest>
                <S.ButtonsDelete>
                    <Button
                        className="btn-alternative"
                        onClick={e => {
                            e.stopPropagation();
                            setShowPopup(false);
                        }}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        className="btn-primary"
                        onClick={() => deleteHandler(value, params)}
                    >
                        {t("delete")}
                    </Button>
                </S.ButtonsDelete>
            </S.DeleteFormContainer>
        </S.DeleteContainer>
    );
};

export default DeletePopup;
