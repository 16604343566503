import styled from "styled-components";

export const Errors = styled.div``;

export const Error = styled.div`
    color: ${({ theme }) => theme.main.errorColor};
    margin: 0 0 10px 0;
    display: flex;
    svg {
        margin-right: 6px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
