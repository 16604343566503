import { useEffect, useState, useRef, ChangeEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDebounce from "../../hooks/useDebounce";
import client from "../../client";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Guest } from "../../types/guest";
import { ResponseWithPagination } from "../../types/api/api";

export default function Search({ placeholder }: { placeholder?: string }) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [results, setResults] = useState<Guest[]>([]);
    const [showResults, setShowResults] = useState<boolean>(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 150);
    const history = useHistory();
    const resultsRef = useRef(null);

    useOnClickOutside(resultsRef, () => setShowResults(false));

    useEffect(() => {
        if (debouncedSearchTerm) {
            client
                .get<ResponseWithPagination<Guest>>(
                    `/api/players/search?name=${debouncedSearchTerm}`
                )
                .then(res => {
                    setResults(res.data.data);
                    setShowResults(true);
                });
        } else {
            setResults([]);
        }
    }, [debouncedSearchTerm]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="relative mx-auto flex h-11 items-center rounded-md bg-alternative drop-shadow-[3px_3px_3px_rgba(0,0,0,0.10)] 2xl:mr-10">
            <FontAwesomeIcon
                className="z-10 mx-4 h-4"
                icon={faSearch as IconProp}
            />
            <input
                className="h-12 min-w-[150px] flex-grow border-none bg-transparent text-base outline-none 2xl:min-w-[250px]"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                placeholder={placeholder}
                value={searchTerm}
                data-testid="search-input"
            />
            ​
            {results.length > 0 && showResults && (
                <div
                    className="absolute top-12 z-10 min-w-[300px] max-w-[500px]  rounded-md border bg-[#fffbfb] "
                    ref={resultsRef}
                    data-testid="search-results"
                >
                    {results.map(
                        (result: {
                            id: number;
                            profile_photo_url: string;
                            full_name: string;
                        }) => (
                            <div
                                className="flex h-12 w-full cursor-pointer items-center gap-3 border-b px-4 py-8 text-base transition-all last:border-b-0 hover:bg-zinc-200 hover:text-secondary"
                                onClick={() => {
                                    history.push(`/guest/${result.id}`);
                                    setShowResults(false);
                                    setSearchTerm("");
                                }}
                                key={result.id}
                            >
                                <img
                                    className="relative h-12 w-12 rounded-full "
                                    alt="avatar"
                                    src={result.profile_photo_url}
                                />
                                <span>{result.full_name}</span>
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
}
