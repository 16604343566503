import React from "react";
import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import PageHeader from "../../components/PageHeader";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Filter from "../../components/Filter";
import { dateOptions } from "../../utils/filterOptions";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import usePopup from "../../hooks/usePopup";
import usePermissions from "../../hooks/usePermissions";
import { WhiteListItem } from "../../types/list";
import { Button } from "../../types/button";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function Whitelist() {
    const tableColumns = columns.whitelist;
    const history = useHistory();
    const { t } = useTranslation();
    const { hasPermissions } = usePermissions();
    const { popup } = usePopup();

    const {
        onScroll,
        filteredData,
        updateFilterParams,
        containerRef,
        isLoading,
        updateSortParams,
    } = useInfiniteScroll("/api/whitelist-records");

    const hasExportPermissions = hasPermissions(["view_whitelist"]);

    const handleTableClick = (data: WhiteListItem) => {
        history.push(`/guest/${data.player_id}`);
    };

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    const buttons: Button[] = hasExportPermissions
        ? [
              {
                  text: t("export"),
                  clickHandler: () =>
                      popup({
                          type: "EXPORT_PLAYPAUSE",
                      }),
                  type: "pageHeader",
                  faIcon: faFileExport as IconProp,
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                showSearch={false}
                title={t("playpause")}
                buttons={buttons}
            />
            {/* 
            // @ts-ignore */}
            <Filter filters={filters} updateFilterParams={updateFilterParams} />

            {/* 
            // @ts-ignore */}
            <DataTable
                columns={tableColumns}
                data={filteredData}
                tableType="whitelist"
                onClick={handleTableClick}
                containerRef={containerRef}
                pagination={false}
                onScroll={onScroll}
                updateSortParams={updateSortParams}
                isLoading={isLoading}
            />
        </>
    );
}
