import React, { useRef, useEffect, useState } from "react";
import * as S from "./FacilitiesCheckRow.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import DeletePopup from "../../../components/Popups/OldDeletePopup";

function FacilitiesCheckRow({
    item,
    adminMode,
    canEdit,
    dispatch,
    user,
    modifyItem,
}) {
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const exitEdit = () => {
        if (name) {
            modifyItem("update", item.id, item.name);
        }

        dispatch({
            type: "stopEdit",
            payload: { id: item.id },
        });
    };

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [item.isNameEditable]);

    useOnClickOutside(inputRef, () => {
        exitEdit();
    });

    const handleKeypress = e => {
        e.key === "Enter" && exitEdit();
    };

    const handleUpdate = (text, key) => {
        setName(text);
        dispatch({
            type: "update",
            payload: { id: item.id, [key]: text, fieldToUpdate: key },
        });
    };

    const deleteHandler = () => {
        dispatch({
            type: "remove",
            payload: { id: item.id },
        });
        modifyItem("delete", item.id);
    };

    return (
        <S.Row>
            {showDeletePopup && (
                <DeletePopup
                    setShowPopup={setShowDeletePopup}
                    deleteHandler={deleteHandler}
                    text="Checklist item"
                />
            )}
            <S.Cel canEditName={adminMode}>
                {adminMode && (
                    <S.RemoveItem onClick={() => setShowDeletePopup(true)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </S.RemoveItem>
                )}
                {item.isNameEditable ? (
                    <input
                        ref={inputRef}
                        type="text"
                        defaultValue={item.name}
                        onChange={e => handleUpdate(e.target.value, "name")}
                        onKeyPress={handleKeypress}
                    />
                ) : (
                    <span
                        onClick={() =>
                            dispatch({
                                type: "startEdit",
                                payload: { id: item.id },
                            })
                        }
                    >
                        {item.name}
                    </span>
                )}
            </S.Cel>
            <S.Cel>
                {!adminMode && (
                    <input
                        type="checkbox"
                        checked={item.checked}
                        disabled={!canEdit}
                        onChange={() =>
                            dispatch({
                                type: item.checked ? "uncheck" : "check",
                                payload: { id: item.id, user_id: user.id },
                            })
                        }
                    />
                )}
            </S.Cel>
            <S.Cel>{!adminMode && item.checked_by}</S.Cel>
            <S.Cel>
                {!adminMode && (
                    <textarea
                        defaultValue={item.notes}
                        placeholder={t("type_notes_here")}
                        onChange={e => handleUpdate(e.target.value, "notes")}
                        disabled={!canEdit}
                    />
                )}
            </S.Cel>
        </S.Row>
    );
}

export default FacilitiesCheckRow;
