import React from "react";
import Dropdown from "./Dropdown";
import * as S from "./Filter.styled";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MultiSelect({
    options,
    label,
    setAppliedFilters,
    appliedFilters,
}) {
    const applyFilter = value => {
        setAppliedFilters(prev => {
            const copy = [...prev];

            const existingIndex = copy.findIndex(
                item => item.selectedValue.label === value.label
            );

            if (existingIndex !== -1) {
                copy.splice(existingIndex, 1);
                return copy;
            }

            copy.push({
                key: "multi",
                selectedValue: value,
            });
            return copy;
        });
    };

    return (
        <Dropdown label={label}>
            {options.map((value, index) => (
                <S.MultiSelect key={index}>
                    <button onClick={() => applyFilter(value)}>
                        <div>
                            {value.icon && (
                                <FontAwesomeIcon
                                    icon={["fal", value.icon]}
                                    style={{
                                        "--fa-primary-color": "#000",
                                        "--fa-secondary-color": "#000",
                                        "--fa-primary-opacity": "1.0",
                                        "--fa-secondary-opacity": "1.0",
                                    }}
                                    size="lg"
                                />
                            )}
                            <span>{value.label}</span>
                        </div>

                        <div>
                            {appliedFilters.findIndex(
                                filter =>
                                    filter.selectedValue.label === value.label
                            ) !== -1 ? (
                                <S.CheckBox active={true}>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{
                                            "--fa-primary-color": "#000",
                                            "--fa-secondary-color": "#000",
                                            "--fa-primary-opacity": "1.0",
                                            "--fa-secondary-opacity": "1.0",
                                        }}
                                        size="sm"
                                    />
                                </S.CheckBox>
                            ) : (
                                <S.CheckBox />
                            )}
                        </div>
                    </button>
                </S.MultiSelect>
            ))}
        </Dropdown>
    );
}
