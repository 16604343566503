import Echo from "laravel-echo";
import { Channel } from "pusher-js";
import client from "./client";

require("pusher-js");

const secure = process.env.REACT_APP_WS_SECURE !== "false";

const socket = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_WS_KEY || "acarat-staging-key",
    wsHost: process.env.REACT_APP_WS_HOST || "ws.staging.acarat.nl",
    enabledTransports: secure ? ["ws", "wss"] : ["ws"],
    wsPort: process.env.REACT_APP_WS_PORT || 443,
    forceTLS: secure,

    authorizer: (channel: Channel) => {
        return {
            authorize: (
                socketId: string,
                callback: (error: boolean, data: any) => any
            ) => {
                client
                    .post("/api/broadcasting/auth", {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            },
        };
    },
});

export default socket;
