import { useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import StaffControl from "../../components/StaffControl/StaffControl";

const EditUser = () => {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t("edit_staff_member")} />
            <StaffControl edit={true} />
        </>
    );
};

export default EditUser;
