import styled from "styled-components";
export { Cel, Row } from "../FacilitiesCheck.styled";

export const RemoveItem = styled.button`
    margin-right: 4px;
    transition: 0.2s ease-in-out;
    &:hover {
        color: red;
    }
`;
