import React, { useEffect, useState, useCallback } from "react";
import * as S from "./FormSearchPlayer.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDebounce from "../../../hooks/useDebounce";
import client from "../../../client";
import { useParams } from "react-router-dom";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function FormSearchPlayer({
    label,
    register,
    errors,
    setFocus,
    setValue,
    required = false,
}) {
    const [results, setResults] = useState([]);
    const [currentSelected, setCurrentSelected] = useState("");
    const debouncedSearchTerm = useDebounce(currentSelected, 400);
    const [isOptionsOpen, setIsOptionsOpen] = useState(true);
    const [loader, setLoader] = useState(null);
    const params = useParams();
    const { t } = useTranslation();
    const handleChange = e => {
        if (e.target.value.match(/^ *$/) !== null) {
            setCurrentSelected("");
            return false;
        }

        setResults([]);
        setCurrentSelected(e.target.value);
        if (currentSelected !== "") {
            setLoader("Loading...");
        } else {
            setResults([]);
        }

        if (results.length !== 0) {
            setIsOptionsOpen(true);
        }
    };

    const handleSelect = useCallback(result => {
        setIsOptionsOpen(prev => !prev);
        setCurrentSelected(result);
        setResults(result);
    }, []);

    useEffect(() => setFocus("search"), [setFocus]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            client
                .get(`/api/players/search?name=${debouncedSearchTerm}`)
                .then(res => {
                    if (res.data.data.length === 0) {
                        setLoader("No results");
                    } else {
                        setResults(res.data.data);
                    }
                });
        } else {
            setResults([]);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (params.id) {
            client.get(`/api/player/${params.id}`).then(res => {
                handleSelect(res.data.player);
                setResults(res.data);
            });
        }
    }, [params.id, handleSelect]);

    useEffect(() => {
        if (currentSelected) {
            setValue("player_id", currentSelected.id);
        } else {
            setValue("player_id", null);
        }
    }, [setValue, currentSelected]);

    return (
        <S.FormControl required={required}>
            <label>{label}</label>
            <S.SearchContainer error={errors.search}>
                <S.Search
                    type="text"
                    autoComplete="off"
                    placeholder={t("search_guest")}
                    value={currentSelected.full_name || currentSelected}
                    {...register("search", {
                        required: required,
                    })}
                    onChange={handleChange}
                    error={errors.search}
                    autocomplete="false"
                />
                {currentSelected && results.length > 0
                    ? isOptionsOpen && (
                          <S.ResultsContainer>
                              {results.map(result => {
                                  return (
                                      <button
                                          onClick={() => handleSelect(result)}
                                          key={result.id}
                                      >
                                          {result.full_name}
                                      </button>
                                  );
                              })}
                          </S.ResultsContainer>
                      )
                    : currentSelected &&
                      results.length === 0 && (
                          <S.ResultsContainer>
                              <div>{loader}</div>
                          </S.ResultsContainer>
                      )}

                <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                        "--fa-primary-color": "#000",
                        "--fa-secondary-color": "#000",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "1.0",
                    }}
                    size="1x"
                />
            </S.SearchContainer>
        </S.FormControl>
    );
}
