import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { SelectOption } from "../../types/form";
import Select from "react-select";
import { classNames } from "../../utils/style";
import { useTranslation } from "react-i18next";
import { checkInMessagePeriodOptions } from "../../utils/formOptions";
import useSelectPeriodAndData from "../../hooks/useSelectPeriodAndDate";
import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import useFormResponseError from "../../hooks/useFormResponseError";
import { useHistory, useParams } from "react-router";
import Button from "../Button";
import { displayCharacterCount } from "../../utils/form";
import { useQueryClient, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { TranslationsKeys } from "../../types/translations";
import day from "dayjs";
import { CheckInMessage } from "../../types/checkInMessage";

type FormFields = {
    message: string;
    active_from_option: SelectOption;
    active_from_date: string;
};

const CheckInMessageAction = () => {
    const { customToast } = useCustomToast();
    const location = useLocation();
    const history = useHistory();
    const actionParams = new URLSearchParams(location.search);
    const { formResponseError } = useFormResponseError();
    const { id: playerId } = useParams<{ id: string }>();
    const messageId = actionParams.get("id");
    const [messageCheckIn, setMessageCheckIn] = useState<CheckInMessage>();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        setError,
        watch,
        reset,
    } = useForm<FormFields>();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();
    const message = watch("message");

    const { data: checkInMessage, isLoading } = useQuery(
        [`/players/messages/${messageId}`],
        async () => {
            try {
                const checkInMessage = await client.get(
                    `api/players/messages/${messageId}`
                );

                return checkInMessage.data.data;
            } catch (err: any) {
                customToast("error", {
                    title: t("error"),
                    body: err.message,
                });
            }
        },
        {
            enabled: !!messageId,
        }
    );

    const onSubmit: SubmitHandler<FormFields> = data => {
        setIsSubmitting(true);

        const postData = {
            body: data.message,
            active_from: data.active_from_date,
        };

        const requestMap = {
            put: {
                endpoint: `/api/players/messages/${messageId}`,
                succesText: t(`entity_successfully_updated`, {
                    entity: t("check_in_message"),
                }),
            },
            post: {
                endpoint: `api/players/${playerId}/messages`,
                succesText: t(`entity_successfully_created`, {
                    entity: t("check_in_message"),
                }),
            },
        };

        const type = checkInMessage ? "put" : "post";

        client[type](requestMap[type].endpoint, postData)
            .then(() => {
                history.push(`/guest/${playerId}`);
                customToast("success", {
                    title: t("entity_successfully_created", {
                        entity: t("check_in_message"),
                    }),
                });
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
                queryClient.invalidateQueries(
                    `players/${playerId}/messages?active=1`
                );
            });
    };

    const { t } = useTranslation();

    const updateDayValue = useCallback(
        (dayValue: string) => {
            setValue("active_from_date", dayValue);
        },
        [setValue]
    );

    const { selectDateOption, dateOption } =
        useSelectPeriodAndData(updateDayValue);

    useEffect(() => {
        if (!isLoading && checkInMessage) {
            setMessageCheckIn(checkInMessage);
            selectDateOption(checkInMessage?.active_from ? "custom" : "");
        }
    }, [isLoading, checkInMessage, selectDateOption, reset]);

    useEffect(() => {
        const defaultFormValues: Partial<FormFields> =
            messageCheckIn && messageId
                ? {
                      message: messageCheckIn.body,
                      active_from_option: {
                          value: "custom",
                          label: t("custom_date" as TranslationsKeys) as string,
                      },
                      active_from_date: day(messageCheckIn.active_from).format(
                          "YYYY-MM-DD"
                      ),
                  }
                : {
                      message: "",
                      active_from_date: "",
                  };

        reset(defaultFormValues);
    }, [reset, t, isLoading, messageCheckIn, messageId]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control">
                <label className="form-label">{t("show")}:</label>
                <Controller
                    name="active_from_option"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                        return (
                            <Select
                                {...field}
                                options={checkInMessagePeriodOptions}
                                placeholder={t("select_period")}
                                onChange={(option: SelectOption) => {
                                    field.onChange(option);
                                    selectDateOption(option.value as string);
                                }}
                                className={classNames(
                                    "form-select",
                                    fieldState.error !== undefined && "error"
                                )}
                            />
                        );
                    }}
                />
                <input
                    className="form-input"
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    {...register("active_from_date", {
                        required: true,
                    })}
                    disabled={dateOption !== "custom"}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("message")}</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="message"
                        placeholder={t("message") + ".."}
                        maxLength={150}
                        {...register("message", { required: true })}
                    />
                    <div>{displayCharacterCount(message)}/150</div>
                </div>
            </div>
            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2 ml-2.5">
                    <Button
                        type="submit"
                        className="btn-primary"
                        isLoading={isSubmitting}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default CheckInMessageAction;
