import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import * as S from "./FormErrors.styled";
import { FieldError } from "react-hook-form";

function FormErrors({
    errors,
}: {
    errors: { [key: string]: FieldError | undefined };
}) {
    return (
        <S.Errors>
            {Object.entries(errors)
                .filter(([key, error]) => error?.message)
                .map(([key, error]) => (
                    <S.Error key={key}>
                        <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                            size="lg"
                        />
                        <span>{error?.message}</span>
                    </S.Error>
                ))}
        </S.Errors>
    );
}

export default FormErrors;
