import { useRef } from "react";
import { FieldValues, useForm } from "react-hook-form";
import client from "../../client";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import useCustomToast from "../../hooks/useCustomToast";
import Button from "../Button";
import Popup from "./Popup/Popup";

type PasswordValues = {
    password: string;
    password_confirmation: string;
};

const ChangePasswordPopup = ({
    setShowPopupPasswords,
}: {
    setShowPopupPasswords: (show: boolean) => void;
}) => {
    const { t } = useTranslation();
    const popupRef = useRef(null);
    useOnClickOutside(popupRef, () => setShowPopupPasswords(false));
    const { customToast } = useCustomToast();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<PasswordValues>();

    const password = watch("password");

    const passwordHandler = (data: FieldValues) => {
        client
            .put(`/user/password`, {
                password: data.password,
                password_confirmation: data.password_confirmation,
            })
            .then(res => {
                customToast("success", {
                    title: t("entity_successfully_updated", {
                        entity: t("password"),
                    }),
                });
                setShowPopupPasswords(false);
            })
            .catch(err => {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.errors.message,
                });

                setShowPopupPasswords(false);
            });
    };

    return (
        <Popup>
            <div
                className=" z-50 m-auto flex w-[400px] flex-col gap-8 rounded-md bg-white"
                ref={popupRef}
            >
                <div className="flex items-center gap-3 text-2xl font-normal leading-7 text-primary">
                    {t("change_password")}
                </div>
                <div className="flex flex-col text-base font-normal leading-4 text-black ">
                    <div className="flex justify-between first:mb-5">
                        <label>{t("new_password")}:</label>

                        <input
                            type="password"
                            className={`flex border-b border-primary ${
                                errors.password &&
                                "border-b-red-600 text-red-600"
                            }`}
                            autoComplete="new-password"
                            {...register("password", {
                                required: true,
                                minLength: {
                                    value: 8,
                                    message: t(
                                        "password_must_have_at_least_8_characters"
                                    ),
                                },
                            })}
                        />
                    </div>

                    <div className="flex justify-between ">
                        <label>{t("confirm_password")}:</label>
                        <input
                            type="password"
                            className={`flex border-b border-primary ${
                                errors.password_confirmation &&
                                "border-b-red-600 text-red-600"
                            }`}
                            autoComplete="new-password"
                            {...register("password_confirmation", {
                                required: true,
                                validate: value =>
                                    value === password ||
                                    t("the_passwords_do_not_match"),
                            })}
                        />
                    </div>
                    <div className="mt-1">
                        {errors.password && (
                            <p className="mt-1 text-sm text-red-600 before:inline">
                                {errors.password.message}
                            </p>
                        )}
                        {errors.password_confirmation && (
                            <p className="mt-1 mb-2 text-sm text-red-600 before:inline">
                                {errors.password_confirmation.message}
                            </p>
                        )}
                    </div>
                </div>

                <div className="flex gap-4 ">
                    <Button
                        className="btn-alternative"
                        onClick={() => setShowPopupPasswords(false)}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={handleSubmit(data => passwordHandler(data))}
                        className="btn-secondary"
                    >
                        {t("save_password")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default ChangePasswordPopup;
