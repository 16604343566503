import PageHeader from "../../components/PageHeader";
import { useHistory } from "react-router-dom";
import client from "../../client";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
    Form,
    FormSelect,
    FormTextArea,
    FormFiles,
} from "../../components/formPartials";
import * as S from "./AddMessage.styled";
import useFormResponseError from "../../hooks/useFormResponseError";
import useCustomToast from "../../hooks/useCustomToast";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

export default function AddMessage(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const state = props.location.state && props.location.state;
    const { formResponseError } = useFormResponseError();
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        setError,
        formState: { errors, isSubmitted },
    } = useForm();
    const { customToast } = useCustomToast();

    const formFunctions = {
        register,
        errors,
        setValue,
        setFocus,
        isSubmitted,
        defaultValue: state,
    };

    const { isLoading, data } = useFetch("message-types");

    if (isLoading) {
        return <Loading />;
    }

    const reportTypeOptions = data?.map(item => ({
        value: item,
        text: t(`${item}`),
    }));

    const onSubmit = formData => {
        setIsSubmitting(true);
        const apiData = new FormData();
        apiData.append("title", formData.title);
        apiData.append("description", formData.description);
        apiData.append("type", formData.type);

        files.forEach(file => {
            apiData.append("files[]", file);
        });

        client
            .post("/api/reports", apiData)
            .then(res => {
                customToast("success", {
                    title: t("report_created"),
                    link: {
                        title: t("see_created_report"),
                        url: `/messages/${res.data.id}`,
                    },
                });

                history.push("/messages");
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            <PageHeader
                title={t("add_report")}
                back={true}
                showSearch={false}
            />

            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormSelect
                    formKey="type"
                    label={t("type")}
                    options={reportTypeOptions}
                    required={true}
                    placeholder={t("type")}
                    {...formFunctions}
                />

                <S.FormControl error={errors["title"]} required={true}>
                    <label>{t("title")}</label>
                    <input
                        type="text"
                        placeholder={t("title")}
                        {...register("title", {
                            required: true,
                        })}
                    />
                </S.FormControl>

                <FormTextArea
                    formKey="description"
                    label={t("description")}
                    placeholder={t("description")}
                    row={"10"}
                    columns={"50"}
                    required={true}
                    {...formFunctions}
                />
                <FormFiles
                    formKey="files"
                    label={t("files")}
                    setFiles={setFiles}
                    {...formFunctions}
                />
                <div style={{ paddingLeft: "200px" }}>
                    <Button className="btn-primary" isLoading={isSubmitting}>
                        {t("save")}
                    </Button>
                </div>
            </Form>
        </>
    );
}
