function checklistReducer(state, { type, payload }) {
    const itemIndex = state.findIndex(item => item.id === payload.id);
    let newState = [...state];

    switch (type) {
        case "listReady":
            newState = payload;
            break;
        case "remove":
            newState.splice(itemIndex, 1);
            break;
        case "check":
            newState[itemIndex].checked = true;
            newState[itemIndex].checked_at = new Date().toISOString();
            newState[itemIndex].user_id = payload.user_id;
            newState[itemIndex].isModified = true;
            break;
        case "uncheck":
            newState[itemIndex].checked = false;
            newState[itemIndex].checked_at = null;
            newState[itemIndex].user_id = null;
            newState[itemIndex].isModified = true;
            break;
        case "startEdit":
            newState[itemIndex].isNameEditable = true;
            break;
        case "stopEdit":
            newState[itemIndex].isNameEditable = false;
            newState[itemIndex].new = false;
            break;
        case "update":
            newState[itemIndex][payload.fieldToUpdate] =
                payload[payload.fieldToUpdate];
            newState[itemIndex].isModified = true;
            break;
        default:
            throw new Error();
    }

    return newState;
}

export default checklistReducer;
