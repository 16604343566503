import styled from "styled-components";
import { motion } from "framer-motion";

export const ReportElements = styled.div`
    transition: 0.2s;

    cursor: ${props => (props.click ? "pointer" : "initial")};
    box-shadow: 0px 2px 11px rgb(0 0 0 / 15%);
    margin-top: 33px;
    min-height: ${props => (props.showGrid ? "" : "150px")};
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    display: inline-flex;
    width: 100%;

    :hover {
        background: ${props => props.hover && props.hover};
    }
    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        width: 100%;
        height: 300px;
    }
`;

export const ReportIcon = styled.div`
    border-radius: 10px 0px 0px 10px;
    width: 54px;
    min-width: 54px;
    height: auto;
    background: ${props => props.color && props.color};
    padding: 14px 10px;
    display: flex;
    justify-content: center;
`;

export const ReportElement = styled.div`
    padding: 20px 20px 45px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
`;
export const ReportElementDetails = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    align-items: flex-end;
    gap: 10px; ;
`;

export const ReportType = styled.div`
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 700;
`;
export const ReportTitle = styled.div`
    color: #a94430;
    font-size: 20px;
    line-height: 23px;
    width: ${props => (props.showGrid ? "250px" : "")};
`;
export const ReportMessage = styled.div`
    width: 70%;
    font-size: 16px;
    line-height: 18px;
    color: #050505;
    max-width: 80%;
    display: flex;
    overflow-wrap: anywhere;
`;
export const ReportDate = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #050505;
`;
export const ReportLocation = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #f2994a;
`;
export const ReportCreator = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #a94430;
`;
export const ReportViews = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 8px;
    background: ${props => props.hover && props.hover};
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    position: relative;
    > svg {
        margin-right: 5px;
    }
    :hover {
        background: ${props => props.color && props.color};
    }
`;

export const IconsDisplay = styled.div`
    width: 120px;
    padding: 10px 10px 10px 2px;
    display: flex;
    gap: 20px;
    cursor: pointer;
`;

export const DeleteReport = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 8px;
    background: #f2524a60;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

    :hover {
        background: #f2524a;
    }
`;

export const ReportButtons = styled.div`
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 10px;
    right: 30px;
`;

export const ReportComments = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #1d1e45;
    padding: 8px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    > svg {
        margin-right: 5px;
    }
    :hover {
        background: #1d1e4560;
    }
`;

export const ReportDropdown = styled(motion.div)`
    position: absolute;
    top: 98%;
    right: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 340px;
    padding: 32px;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    transition-property: opacity, left, top, height;
    transition-duration: 3s, 5s;
    > svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`;

export const ViewersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 2px;

    :nth-child(even) {
        background: #f5f3f3;
    }
`;

export const Viewers = styled.div`
    font-weight: normal;
    line-height: 18px;
    color: #000000;
    margin: 32px 0px 15px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 170px;
    min-height: 170px;
    width: 100%;
`;

export const Time = styled.div`
    font-size: 14px;
`;

export const User = styled.div`
    font-size: 16px;
    margin: 5px 10px;
`;

export const Button = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 4;
`;

export const Title = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d1e45;
    align-items: center;
    display: flex;
    gap: 10px;
`;

export const SeeText = styled.div`
    text-decoration: underline;
`;

export const CreateNewButton = styled.div`
    background: #e66147;
    color: #fefefe;
    padding: 10px;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
`;
