import React, { useEffect, useReducer, useRef, useState } from "react";
import * as S from "../FacilitiesCheck/FacilitiesCheck.styled";
import PageHeader from "../../components/PageHeader";
import FacilitiesCheckRow from "../FacilitiesCheck/FacilitiesCheckRow";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useUserStore } from "../../store/userStore";
import checklistReducer from "../../reducers/checklistReducer";
import TemplateOptions from "../../components/TemplateOptions";
import usePermissions from "../../hooks/usePermissions";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useFacilitiesCheckMutations from "../../hooks/useFacilitiesCheckMutations";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useSetSelectedTemplate } from "../../hooks/useSetSelectedTemplate";
import Button from "../../components/Button";

function FacilitiesCheckSettings() {
    const { t } = useTranslation();
    const [checklist, dispatch] = useReducer(checklistReducer, []);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const { user } = useUserStore();
    const history = useHistory();

    const inputRef = useRef(null);
    const [newField, setNewField] = useState("");
    const [editNewField, setEditNewField] = useState(false);

    const { hasPermissions } = usePermissions();
    const hasEditPermissions = hasPermissions(["edit_settings"]);

    const { modifyItem } = useFacilitiesCheckMutations(
        selectedTemplateId,
        newField,
        setNewField
    );

    let urlData = useLocation();
    useSetSelectedTemplate(urlData, setSelectedTemplateId);

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [editNewField]);

    useOnClickOutside(inputRef, () => {
        exitEdit();
    });

    const exitEdit = () => {
        setEditNewField(false);
        if (newField) {
            modifyItem("add", null, newField);
        }
    };

    const handleKeypress = e => {
        e.key === "Enter" && exitEdit();
    };

    const saveClose = () => {
        history.push({
            pathname: "/facilities-check/new",
            state: selectedTemplateId,
        });
    };

    return (
        <>
            <PageHeader
                title={t("facilities_check_settings")}
                showLists={false}
                showSearch={false}
                back={true}
            />
            <TemplateOptions
                dispatch={dispatch}
                selectedTemplateId={selectedTemplateId}
                setSelectedTemplateId={setSelectedTemplateId}
            />
            <S.Table>
                <S.RowHeader>
                    <S.Cel>{t("item")}</S.Cel>
                    <S.Cel disabled={true}>{t("checked")}</S.Cel>
                    <S.Cel disabled={true}>{t("by")}</S.Cel>
                    <S.Cel disabled={true}>{t("notes")}</S.Cel>
                </S.RowHeader>
                {checklist?.map(item => (
                    <FacilitiesCheckRow
                        key={item.id}
                        item={item}
                        adminMode={hasEditPermissions}
                        dispatch={dispatch}
                        user={user}
                        modifyItem={modifyItem}
                    />
                ))}
                {editNewField && (
                    <S.Row>
                        <S.Cel>
                            <input
                                ref={inputRef}
                                type="text"
                                onChange={e => setNewField(e.target.value)}
                                onKeyDown={handleKeypress}
                            />
                        </S.Cel>
                    </S.Row>
                )}
                {hasEditPermissions && selectedTemplateId && (
                    <S.Row>
                        <S.Cel>
                            <S.AddNewField
                                onClick={() => {
                                    setEditNewField(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <span>{t("add_new_field")}</span>
                            </S.AddNewField>
                        </S.Cel>
                    </S.Row>
                )}
            </S.Table>
            <S.ButtonContainer>
                {hasEditPermissions && (
                    <Button className="btn-primary" onClick={saveClose}>
                        {t("save")}
                    </Button>
                )}
            </S.ButtonContainer>
        </>
    );
}

export default FacilitiesCheckSettings;
