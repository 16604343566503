import styled from "styled-components";

export const Block = styled.div`
    box-shadow: rgb(29 30 69 / 15%) 0 2px 10px;
    border-radius: 0 0 8px 8px;
`;

export const BlockHeader = styled.div`
    font-size: 24px;
    padding: 20px;
    background: ${props => props.theme.main.primaryColor};
    color: #fff;
    border-radius: 8px 8px 0 0;
`;

export const BlockContent = styled.div`
    padding: 40px 30px;
    background: #fff;
    min-height: 300px;
`;
