import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { faTimesSquare } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import client from "../../../client";

type FileObject = {
    url?: string | undefined;
    src: string | undefined;
    alt: string;
    id?: number;
};

export default function FormFiles({
    setFiles,
    existingFiles = [],
    isSubmitted,
}: {
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    existingFiles?: FileObject[];
    isSubmitted?: boolean;
}) {
    const { t } = useTranslation();
    const [filesToDelete, setFilesToDelete] = useState<FileObject[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileObject[]>([]);

    useEffect(() => {
        if (existingFiles) {
            setSelectedFiles(existingFiles);
        }
    }, [existingFiles]);

    useEffect(() => {
        if (isSubmitted && filesToDelete.length > 0) {
            filesToDelete.forEach(file => {
                client.delete(`/api/files/${file.id}`).then();
            });
        }
    }, [isSubmitted, filesToDelete]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files) {
            setSelectedFiles(prev => {
                const imageHandler = Object.entries(files).map(([key, val]) => {
                    return {
                        src: URL.createObjectURL(val),
                        alt: val.name,
                    };
                });

                return prev.concat(imageHandler);
            });

            setFiles(prev => {
                const imageHandler = Object.entries(files).map(([key, val]) => {
                    return val;
                });
                return prev.concat(imageHandler);
            });
        }
    };

    const handleDelete = (img: FileObject) => {
        if (img) {
            setFilesToDelete(prev => {
                const copy = [...prev];
                copy.push(img);
                return copy;
            });
        }

        setSelectedFiles(prev => {
            const copy = [...prev];
            const index = img.id
                ? copy.findIndex(file => file.id === img.id)
                : copy.findIndex(file => file.alt === img.alt);

            copy.splice(index, 1);
            return copy;
        });

        const fileInput = document.getElementById("img") as HTMLInputElement;
        fileInput.value = "";

        setFiles(prev => {
            const copy = [...prev];
            const index = copy.findIndex(file => file.name === img.alt);
            copy.splice(index, 1);
            return copy;
        });
    };

    return (
        <>
            <div>
                <div>
                    <>
                        <input
                            type="file"
                            id="img"
                            name="photo"
                            accept="image/*"
                            onChange={handleInputChange}
                            hidden
                            multiple
                        />
                        <div>
                            <label
                                htmlFor="img"
                                className="flex items-center gap-2 align-middle text-lg "
                            >
                                <FontAwesomeIcon icon={faCamera as IconProp} />
                                {selectedFiles.length > 0
                                    ? t("add_more_files")
                                    : t("add_files")}
                            </label>
                        </div>
                    </>

                    <div>
                        {selectedFiles.length > 0 && (
                            <div className="flex h-[140px] w-[450px] overflow-y-scroll rounded-sm border border-[#E0E0E0] p-3">
                                {selectedFiles.map(
                                    (
                                        img,
                                        index: React.Key | null | undefined
                                    ) => {
                                        return (
                                            <div
                                                className="relative"
                                                key={index}
                                            >
                                                <img
                                                    src={img.url || img.src}
                                                    alt={img.alt}
                                                    className="border-sm mr-3 mt-2 mb-2 ml-3 h-[100px] max-h-[140px] min-w-[100px] max-w-[100px] rounded-md border border-primary object-cover"
                                                />

                                                <FontAwesomeIcon
                                                    icon={
                                                        faTimesSquare as IconProp
                                                    }
                                                    style={
                                                        {
                                                            "--fa-primary-color":
                                                                "#fff",
                                                            "--fa-secondary-color":
                                                                "#000",
                                                            "--fa-primary-opacity":
                                                                "1.0",
                                                            "--fa-secondary-opacity":
                                                                "1.0",
                                                        } as React.CSSProperties
                                                    }
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleDelete(img);
                                                    }}
                                                    size="lg"
                                                    className="absolute top-0 right-1 cursor-pointer rounded-sm bg-primary"
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
