import Login from "../pages/Login/Login";
import { RouteType } from "../types/routes";

const routes: RouteType[] = [
    {
        path: "/",
        component: Login,
        exact: true,
        title: "Login",
        permissionsRequired: [],
    },
];

export default routes;
