import { Control, Controller } from "react-hook-form";
import MultiSelect from "../MultiSelect/MultiSelect";

import { useTranslation } from "react-i18next";

import { useUserStore } from "../../store/userStore";
import {
    columnsOptions,
    typeOptions,
    signalOptions,
    reactionsOptions,
} from "../../utils/exportOptionsPrevention";
import { PreventionFormValue } from "../../types/exportForm";

function ExportPreventionFields({
    control,
}: {
    control: Control<PreventionFormValue>;
}) {
    const { t } = useTranslation();
    const { user } = useUserStore();

    const locationOptions =
        user?.locations.map(location => {
            return {
                value: location.id.toString(),
                label: location.name,
                key: "locations",
            };
        }) ?? [];

    return (
        <>
            {locationOptions.length > 1 && (
                <div className="form-control">
                    <label className="form-label">{t("location")}:</label>
                    <div className="col-span-2">
                        <Controller
                            name="locations"
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        options={locationOptions}
                                        onChange={field.onChange}
                                        placeholder={t("add_locations") + ".."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="form-control">
                <label className="form-label">{t("columns")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="columns"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={columnsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("add_columns") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <div className="form-control">
                <label className="form-label">{t("type")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="types"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={typeOptions}
                                    onChange={field.onChange}
                                    placeholder={t("type") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <div className="form-control">
                <label className="form-label">{t("signals")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="signals"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={signalOptions}
                                    onChange={field.onChange}
                                    placeholder={t("signals") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <div className="form-control">
                <label className="form-label">{t("guest_reaction")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="reactions"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={reactionsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("guest_reaction") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default ExportPreventionFields;
