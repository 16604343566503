import React from "react";
import * as S from "./HandOverColumn.styled";
import dayjs from "dayjs";
import client from "../../client";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { options } from "../../utils/optionsMessages";
import Loading from "../Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import useCustomToast from "../../hooks/useCustomToast";

const HandOverColumn = () => {
    const per_page = 50;
    const { t } = useTranslation();
    const { isLoading, data } = useQuery("reports", () => {
        return client
            .get(`/api/reports`, { params: { per_page } })
            .then(res => res.data);
    });
    const history = useHistory();
    const { customToast } = useCustomToast();

    const handleTableClick = resData => {
        history.push(`/messages/${resData}`);
        client
            .post(`/api/reports/addView`, {
                report_id: resData,
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.errors.message,
                });
            });
    };

    if (isLoading) {
        return <Loading />;
    }

    const item = data.data.find(item => item.type === "handover");

    const HandleSeeAll = e => {
        e.stopPropagation();
        history.push("/messages");
    };

    const HandleCreateNew = e => {
        e.stopPropagation();
        history.push({
            pathname: "/add-report",
            state: "handover",
        });
    };

    return (
        <>
            {item ? (
                <S.ReportElements
                    key={item?.id}
                    click={true}
                    hover={options[item?.type]?.hover}
                    onClick={() => {
                        handleTableClick(item?.id);
                    }}
                >
                    <S.ReportIcon color={options[item?.type]?.color}>
                        <FontAwesomeIcon
                            icon={["fal", `${options[item?.type]?.icon}`]}
                            size="lg"
                            style={{
                                color: `${options[item?.type]?.textColor}`,
                            }}
                        />
                    </S.ReportIcon>
                    <S.ReportElement>
                        <S.ReportType color={options[item?.type]?.color}>
                            {t(item?.type)}
                        </S.ReportType>
                        <S.ReportTitle>{item?.title}</S.ReportTitle>
                        <S.ReportMessage>{item?.description}</S.ReportMessage>
                    </S.ReportElement>
                    <S.ReportElementDetails>
                        <S.SeeText
                            onClick={e => {
                                HandleSeeAll(e);
                            }}
                        >
                            {t("see_all")}{" "}
                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                        </S.SeeText>
                        <S.ReportDate>
                            {dayjs(item?.created_at).format("DD-MM-YYYY HH:mm")}
                        </S.ReportDate>
                        <S.ReportLocation>
                            {item?.location.name}
                        </S.ReportLocation>
                        <S.ReportCreator>
                            {item?.employee.full_name}
                        </S.ReportCreator>
                    </S.ReportElementDetails>
                </S.ReportElements>
            ) : (
                <S.ReportElements click={false}>
                    <S.ReportElement>
                        <S.ReportType>{t("no_handover_messages")}</S.ReportType>
                        <S.CreateNewButton
                            onClick={e => {
                                HandleCreateNew(e);
                            }}
                        >
                            {t("click_to_create")}{" "}
                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                        </S.CreateNewButton>
                    </S.ReportElement>
                </S.ReportElements>
            )}
        </>
    );
};

export default HandOverColumn;
