import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClipboardListCheck,
    faBook,
    faCommentAltLines,
    faPenField,
    // faTriangleExclamation,
    faUsersCog,
    faChartColumn,
} from "@fortawesome/pro-light-svg-icons";
import { faSquareList } from "@fortawesome/pro-light-svg-icons";
import { faSquareList as fatest } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties, ReactNode } from "react";
import { Permission } from "../types/permission";
import useFetch from "../hooks/useFetch";

const defaultStyle = {
    "--fa-primary-color": "#000",
    "--fa-secondary-color": "#fff",
    "--fa-primary-opacity": "1.0",
    "--fa-secondary-opacity": "0.9",
    width: "21px",
    height: "24px",
};

export type MenuItem = {
    title: string;
    group: string;
    path: string;
    icon: JSX.Element;
    permissionsRequired: Permission[];
    CountComponent?: () => JSX.Element;
};

const CountCircle = ({ children }: { children: ReactNode }) => {
    return (
        <span className="absolute -right-1.5 bottom-0.5 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#E34234] text-xs text-white">
            {children}
        </span>
    );
};

const UnreadMessagesCount = () => {
    const {
        data: count,
        isLoading,
        isError,
    } = useFetch<number>("user/unread-messages-count");

    if (isLoading || count === 0) {
        return <></>;
    } else if (isError || count === undefined) {
        return <CountCircle>!</CountCircle>;
    } else {
        return <CountCircle>{count}</CountCircle>;
    }
};

export const menuItemsByRole: MenuItem[] = [
    {
        title: i18n.t("check_in"),
        group: i18n.t("guests"),
        path: "/check-in",
        icon: (
            <FontAwesomeIcon
                icon={faClipboardListCheck as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: [],
    },
    {
        title: i18n.t("guestbook"),
        group: i18n.t("guests"),
        path: "/log",
        icon: (
            <FontAwesomeIcon
                icon={faBook as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: ["view_entry_log"],
    },
    {
        title: i18n.t("playpause"),
        group: i18n.t("guests"),
        path: "/playpause",
        icon: (
            <FontAwesomeIcon
                icon={fatest as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: ["view_whitelist"],
    },
    {
        title: i18n.t("blacklist"),
        group: i18n.t("guests"),
        path: "/blacklist",
        icon: (
            <FontAwesomeIcon
                icon={faSquareList as IconProp}
                style={
                    {
                        color: "#fff",
                        width: "21px",
                        height: "24px",
                    } as CSSProperties
                }
            />
        ),
        permissionsRequired: ["view_blacklist"],
    },
    {
        title: i18n.t("messages"),
        group: i18n.t("operations"),
        path: "/messages",
        icon: (
            <FontAwesomeIcon
                icon={faCommentAltLines as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: ["view_messages"],
        CountComponent: UnreadMessagesCount,
    },
    {
        title: i18n.t("actions"),
        group: i18n.t("operations"),
        path: "/actions",
        icon: (
            <FontAwesomeIcon
                icon={faPenField as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: [],
    },
    // {
    //     title: i18n.t("risk"),
    //     group: i18n.t("operations"),
    //     path: "/risk-dashboard",
    //     icon: (
    //         <FontAwesomeIcon
    //             icon={faTriangleExclamation as IconProp}
    //             style={defaultStyle as CSSProperties}
    //         />
    //     ),
    //     permissionsRequired: [],
    // },
    {
        title: i18n.t("staff"),
        group: i18n.t("management"),
        path: "/staff",
        icon: (
            <FontAwesomeIcon
                icon={faUsersCog as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: ["view_staff"],
    },
    {
        title: i18n.t("statistics"),
        group: i18n.t("management"),
        path: "/statistics",
        icon: (
            <FontAwesomeIcon
                icon={faChartColumn as IconProp}
                style={defaultStyle as CSSProperties}
            />
        ),
        permissionsRequired: ["view_statistics"],
    },
];
