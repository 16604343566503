import React, { useRef } from "react";
import * as S from "./Popups.styled";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { preventionSinglePdf } from "../../utils/singlePreventionPdfReport";
import "jspdf-autotable";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useLogo from "../../hooks/useLogo";
import useCustomToast from "../../hooks/useCustomToast";
import Button from "../Button";

export default function PreventionPlanReportPopup({
    setReportPopup,
    reportPopup,
}) {
    const { handleSubmit } = useForm();
    const welcomeBackPopupRef = useRef(null);
    const { customToast } = useCustomToast();
    const { getClientLogo } = useLogo();
    const { t } = useTranslation();
    const onSubmit = () => {
        customToast("success", {
            title: t("report_exported"),
        });

        preventionSinglePdf(reportPopup, getClientLogo());

        setReportPopup({
            ...reportPopup,
            visible: !reportPopup.visible,
        });
    };
    useOnClickOutside(welcomeBackPopupRef, () =>
        setReportPopup({
            ...reportPopup,
            visible: !reportPopup.visible,
        })
    );

    return (
        <S.ExportPlanPopup>
            <S.ExportPlanPopupContainer ref={welcomeBackPopupRef}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                        "--fa-primary-color": "#1D1E45",
                        "--fa-secondary-color": "#1D1E45",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "0.9",
                    }}
                    onClick={() => {
                        setReportPopup({
                            ...reportPopup,
                            visible: !reportPopup.visible,
                        });
                    }}
                />

                <S.Header>
                    Prevention report{" "}
                    <strong>{reportPopup.state.guest.full_name}</strong>
                </S.Header>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ alignItems: "flex-start" }}
                >
                    <ul style={{ marginBottom: "30px" }}>
                        <li>
                            <strong>{t("type_of_intervention")}:</strong>
                            <br /> {t(reportPopup.state.type).toUpperCase()}
                        </li>
                        <br />
                        <strong> {t("signals")}:</strong>
                        {reportPopup?.state?.signals?.map((signal, index) => {
                            return <li key={index}> {t(signal)} </li>;
                        })}
                        <br />

                        <li>
                            <strong> {t("notes")}:</strong>
                            <br />
                            <div
                                style={{
                                    overflowY: "scroll",
                                    maxHeight: "125px",
                                }}
                            >
                                {reportPopup.state.notes}
                            </div>
                        </li>

                        <br />
                        <li>
                            <strong> {t("guest_reaction")}:</strong>
                            <br />

                            {t(reportPopup.state.reaction)}
                        </li>
                        <br />
                        <li>
                            <strong>{t("advice_agreement")}:</strong>
                            <br />

                            <div
                                style={{
                                    overflowY: "scroll",
                                    maxHeight: "125px",
                                    wordBreak: "break-word",
                                }}
                            >
                                {reportPopup.state.conclusion}
                            </div>
                        </li>
                        <br />
                        <li>
                            <strong> {t("added_by")}:</strong>
                            <br />

                            {t(reportPopup.state.employee.full_name)}
                        </li>
                        <br />
                        <li>
                            <strong> {t("prevention_report_created")}:</strong>
                            <br />

                            {dayjs(reportPopup.state.created_at).format(
                                "DD-MM-YYYY HH:mm"
                            )}
                        </li>
                    </ul>

                    <Button className="btn-secondary" type="submit">
                        {t("export")}
                    </Button>
                </form>
            </S.ExportPlanPopupContainer>
        </S.ExportPlanPopup>
    );
}
