import styled from "styled-components";
export { FormControl } from "../../../style/shared/form.styled";

export const Comments = styled.div`
    width: 100%;
    height: auto;
    min-height: 120px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-flow: column-reverse;
`;

export const Comment = styled.div`
    width: 97%;
    margin: 10px 0px;
    height: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
`;

export const CommentTitle = styled.div`
    max-width: 70%;
    width: 400px;
    display: flex;
    height: auto;
    overflow-wrap: anywhere;
    padding: 10px;
`;

export const CommentAuthor = styled.div`
    color: #a94430;
    font-size: 18px;
`;

export const CommentDetails = styled.div`
    text-align: right;
    position: relative;
    align-items: end;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

export const CommentTime = styled.div`
    font-size: 15px;
    width: 120px;
`;

export const DeleteReport = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 8px;
    background: #f2524a;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    width: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    :hover {
        background: #ed281f;
    }
`;

export const EditReport = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 8px;
    background: #ca523a;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    width: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    :hover {
        background: #e66147;
    }
`;

export const Buttons = styled.div`
    display: flex;
    gap: 10px;
    padding-left: 200px;
    padding-bottom: 30px;
`;

export const ProfileImage = styled.img`
    border-radius: 50%;
    margin: 10px 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
`;

export const LeftSide = styled.div`
    background: ${props => props.color && props.color};
    border-radius: 10px 0px 0px 10px;
`;
