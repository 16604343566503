import SwitchUser from "../pages/SwitchUser";
import EntryLog from "../pages/EntryLog";
import Whitelist from "../pages/Whitelist/Whitelist";
import BlackList from "../pages/Blacklist/Blacklist";
import GuestDetails from "../pages/GuestDetails/GuestDetails";
import Info from "../pages/Info";
import Messages from "../pages/Messages";
import PreventionPlan from "../pages/PreventionPlan/PreventionPlan";
import AddReport from "../pages/AddMessage";
import Account from "../pages/Account";
import Staff from "../pages/Staff";
import AddUser from "../pages/AddUser";
import EditUser from "../pages/EditUser";
import ViewReport from "../pages/ViewMessage";
import WorkInProgress from "../pages/WorkInProgress";
import EditGuest from "../pages/EditGuest";
import Customize from "../pages/Customize";
import PreventionPolicy from "../pages/PreventionPolicy";
import Welcome from "../pages/Welcome/Welcome";
import FacilitiesCheck from "../pages/FacilitiesCheck";
import UpdateFloorNumbers from "../pages/UpdateFloorNumbers";
import i18n from "i18next";
import FacilitiesCheckOverview from "../pages/FacilitiesCheckOverview";
import FacilitiesCheckSettings from "../pages/FacilitiesCheckSettings";
import PreventionPlanOverview from "../pages/PreventionPlanOverview";
import PreventionPlanTypeOverview from "../pages/PreventionPlanTypeOverview";
import FloorNumbersOverview from "../pages/FloorNumbersOverview/FloorNumbersOverview";
import Statistics from "../components/Statistics/Statistics";
import Systems from "../pages/Systems/Systems";
import Checkin from "../pages/Checkin/Checkin";
import VersionInformation from "../pages/VersionInformation/VersionInformation";
import Export from "../pages/Export";
import ReleaseNotes from "../pages/ReleaseNotes";
import { RouteType } from "../types/routes";
import RiskDashboard from "../pages/RiskDashboard";

const routes: RouteType[] = [
    {
        path: "/switch-user",
        component: SwitchUser,
        exact: true,
        title: "Switch User",
        permissionsRequired: [],
    },
    {
        path: "/workinprogress",
        component: WorkInProgress,
        exact: true,
        title: "Work In Progress",
        permissionsRequired: [],
    },
    {
        path: "/welcome",
        component: Welcome,
        exact: true,
        title: "Welcome",
        noMenu: true,
        permissionsRequired: [],
    },
    {
        path: "/check-in",
        component: Checkin,
        exact: true,
        title: "Check-in",
        permissionsRequired: [],
    },
    {
        path: "/log",
        component: EntryLog,
        exact: true,
        title: "Guest entry log",
        permissionsRequired: ["view_entry_log"],
    },
    {
        path: "/playpause",
        component: Whitelist,
        exact: true,
        title: "Play pause",
        permissionsRequired: ["view_whitelist"],
    },

    {
        path: "/blacklist",
        component: BlackList,
        exact: true,
        title: "Blacklist",
        permissionsRequired: ["view_blacklist"],
    },
    {
        path: "/guest/:id",
        component: GuestDetails,
        exact: true,
        title: "Guest details",
        permissionsRequired: ["view_guests"],
    },
    {
        path: "/guest/:id/edit",
        component: EditGuest,
        exact: true,
        title: "Edit guest",
        permissionsRequired: ["edit_guests"],
    },
    {
        path: "/actions",
        component: Info,
        exact: true,
        title: "Actions",
        permissionsRequired: [],
    },
    {
        path: "/statistics",
        component: Statistics,
        exact: true,
        title: "Statistics",
        permissionsRequired: ["view_statistics"],
    },
    {
        path: "/messages",
        component: Messages,
        exact: true,
        title: "Messages",
        permissionsRequired: ["view_messages"],
    },
    {
        path: "/messages/:id",
        component: ViewReport,
        exact: true,
        title: "View report",
        permissionsRequired: ["view_messages"],
    },
    {
        path: "/prevention-plan/:id?",
        component: PreventionPlan,
        exact: true,
        title: "Prevention plan",
        permissionsRequired: ["view_prevention_plan"],
    },
    {
        path: "/add-report",
        component: AddReport,
        exact: true,
        title: "Add report",
        permissionsRequired: ["add_messages"],
    },
    {
        path: "/my-account",
        component: Account,
        exact: true,
        title: "My account",
        permissionsRequired: [],
    },
    {
        path: "/staff",
        component: Staff,
        exact: true,
        title: "Staff",
        permissionsRequired: ["view_staff"],
    },
    {
        path: "/staff/:id",
        component: Account,
        exact: true,
        title: "Staff profile",
        permissionsRequired: ["view_staff"],
    },
    {
        path: "/add-user",
        component: AddUser,
        exact: true,
        title: "Add new staff",
        permissionsRequired: ["add_staff"],
    },
    {
        path: "/edit-user/:id",
        component: EditUser,
        exact: true,
        title: "Edit staff profile",
        permissionsRequired: [],
    },
    {
        path: "/customize",
        component: Customize,
        exact: true,
        title: "Customize",
        permissionsRequired: ["view_customize"],
    },
    {
        path: "/prevention-plan-policy",
        component: PreventionPolicy,
        exact: true,
        title: i18n.t("casino_prevention_plan_policy"),
        permissionsRequired: ["view_prevention_plan_policy"],
    },
    {
        path: "/settings/facilities-check",
        component: FacilitiesCheckSettings,
        exact: true,
        title: "Settings - Facilities check",
        permissionsRequired: ["view_settings"],
    },
    {
        path: "/facilities-check",
        component: FacilitiesCheckOverview,
        exact: true,
        title: "Facilities check",
        permissionsRequired: ["view_facilities_check"],
    },
    {
        path: "/facilities-check/:id",
        component: FacilitiesCheck,
        exact: true,
        title: "Facilities check",
        permissionsRequired: ["view_facilities_check"],
    },
    {
        path: "/update-floor-numbers",
        component: UpdateFloorNumbers,
        exact: true,
        title: "Update Floor Numbers",
        permissionsRequired: ["view_floor_numbers"],
    },
    {
        path: "/floor-numbers-overview",
        component: FloorNumbersOverview,
        exact: true,
        title: "Floor Numbers Overview",
        permissionsRequired: ["view_floor_numbers"],
    },
    {
        path: "/prevention-plan-overview",
        component: PreventionPlanOverview,
        exact: true,
        title: "Prevention plan overview",
        permissionsRequired: ["view_prevention_plan_overview"],
    },
    {
        path: "/prevention-plan-overview/:type?",
        component: PreventionPlanTypeOverview,
        exact: true,
        title: "PreventionPlanTypeOverview",
        permissionsRequired: ["view_prevention_plan_overview"],
    },
    {
        path: "/systems",
        component: Systems,
        exact: true,
        title: "System",
        permissionsRequired: [],
    },
    {
        path: "/version-information",
        component: VersionInformation,
        exact: true,
        title: "Version Information",
        permissionsRequired: [],
    },
    {
        path: "/export",
        component: Export,
        exact: true,
        title: "Exports",
        permissionsRequired: [],
    },
    {
        path: "/release-notes",
        component: ReleaseNotes,
        exact: true,
        title: i18n.t("release_notes"),
        permissionsRequired: [],
    },
    {
        path: "/risk-dashboard",
        component: RiskDashboard,
        exact: true,
        title: "Risk Dashboard",
        permissionsRequired: [],
    },
];

export default routes;
