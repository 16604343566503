import client from "../client";
import produce from "immer";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import useCustomToast from "./useCustomToast";

function useFacilitiesCheckMutations(
    selectedTemplateId,
    newField,
    setNewField
) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { customToast } = useCustomToast();

    const updateItems = useCallback(() => {
        return client.post("/api/facility-check-items", {
            name: newField,
            facility_check_template_id: selectedTemplateId,
        });
    }, [newField, selectedTemplateId]);

    const addMutation = useMutation(updateItems, {
        onMutate: async newItem => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries("facility-check-templates");

            const previousTemplates = queryClient.getQueryData(
                "facility-check-templates"
            );

            // Optimistically update to the new value
            queryClient.setQueryData("facility-check-templates", old => {
                const newTemplates = produce(old, draft => {
                    const selectedTemplateItems = draft.data.find(
                        template => selectedTemplateId === template.id
                    ).facility_check_items;

                    selectedTemplateItems.push(newItem);
                });

                return newTemplates;
            });

            return { previousTemplates };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newItem, context) => {
            customToast("error", {
                title: t("error_data_could_not_be_sent"),
                body: err.response.data.message,
            });
            queryClient.setQueryData(
                "facility-check-templates",
                context.previousTemplates
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries("facility-check-templates");
        },
    });

    const modifyItem = useCallback(
        (action, id, name = "") => {
            try {
                switch (action) {
                    case "add":
                        addMutation.mutate({
                            id: new Date().toISOString(),
                            name: newField,
                            deleted: false,
                        });
                        setNewField("");
                        break;
                    case "delete":
                        client
                            .delete(`/api/facility-check-items/${id}`)
                            .then(res => {
                                queryClient.invalidateQueries(
                                    "facility-check-templates"
                                );
                            });
                        break;
                    case "update":
                        client
                            .put(`/api/facility-check-items/${id}`, {
                                name,
                            })
                            .then(res => {
                                queryClient.invalidateQueries(
                                    "facility-check-templates"
                                );
                            });
                        break;
                    default:
                        return;
                }
            } catch (err) {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.message,
                });
            }
        },
        [addMutation, customToast, newField, queryClient, setNewField, t]
    );

    return { modifyItem };
}

export default useFacilitiesCheckMutations;
