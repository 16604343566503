import { Control, Controller } from "react-hook-form";
import MultiSelect from "../MultiSelect/MultiSelect";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils/style";
import { useUserStore } from "../../store/userStore";
import {
    columnsOptions,
    nationalityOptions,
    checkOptions,
} from "../../utils/exportOptionsEntryLog";
import { EntryLogFormValues } from "../../types/exportForm";

function ExportEntryLogFields({
    control,
}: {
    control: Control<EntryLogFormValues>;
}) {
    const { t } = useTranslation();
    const { user } = useUserStore();

    const locationOptions =
        user?.locations.map(location => {
            return {
            value: location.id.toString(),
                label: location.name,
                key: "locations",
            };
        }) ?? [];

    return (
        <>
            {locationOptions.length > 1 && (
                <div className="form-control">
                    <label className="form-label">{t("location")}:</label>
                    <div className="col-span-2">
                        <Controller
                            name="locations"
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        options={locationOptions}
                                        onChange={field.onChange}
                                        placeholder={t("add_locations") + ".."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="form-control">
                <label className="form-label">{t("columns")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="columns"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={columnsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("add_columns") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("residence")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="type"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    {...field}
                                    options={nationalityOptions}
                                    placeholder={t("residence")}
                                    isClearable
                                    className={classNames(
                                        "form-select",
                                        fieldState.error !== undefined &&
                                            "error"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("entry_status")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="check_status"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    {...field}
                                    options={checkOptions}
                                    placeholder={t("entry_status")}
                                    isClearable
                                    className={classNames(
                                        "form-select",
                                        fieldState.error !== undefined &&
                                            "error"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default ExportEntryLogFields;
