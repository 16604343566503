import styled from "styled-components";
export { FormControl } from "../../../style/shared/form.styled";

export const FilesLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 20px;
    > svg {
        margin-right: 10px;
    }
    cursor: pointer;
`;

export const ImagesContainer = styled.div`
    border-radius: 4px;
    border: 1px solid ${props => props.theme.main.borderColor};
    padding: 10px;
    width: 450px;
    height: 140px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
`;

export const ImageElement = styled.div`
    position: relative;
    > svg {
        position: absolute;
        right: 5px;
        top: 0;
        background: ${props => props.theme.main.primaryColor};
        border: 1px solid ${props => props.theme.main.primaryColor};
        border-radius: 4px;
        fill: #fff;
        cursor: pointer;
    }
`;
 
export const Images = styled.img`
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    max-height: 140px;
    border-radius: 3px;
    margin: 5px 10px;
    border: 1px solid ${props => props.theme.main.primaryColor};
`;

export const ContainerInputLabel = styled.div`
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 20px;
    }
`;
