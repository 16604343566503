import React, { useState } from "react";
import * as S from "./FloorNumbersUpdate.styled";
import client from "../../client";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import useCustomToast from "../../hooks/useCustomToast";

const FloorNumbersUpdate = ({ title, floorNumbers }) => {
    const { t } = useTranslation();
    const [peopleState, setPeopleState] = useState({
        men: 0,
        women: 0,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { customToast } = useCustomToast();
    const queryClient = useQueryClient();

    const headers = [
        { key: t("men") },
        { key: t("women") },
        { key: t("totals") },
    ];

    const handleChange = (e, type) => {
        if (e.target.value !== "") {
            setPeopleState({
                ...peopleState,
                [type]: parseInt(e.target.value),
            });
        } else {
            setPeopleState({ ...peopleState, [type]: 0 });
        }
    };

    const handleIncreaseNumber = (e, type) => {
        setPeopleState({
            ...peopleState,
            [type]: peopleState[type] + 1,
        });
    };

    const handleDecreaseNumber = (e, type) => {
        setPeopleState({
            ...peopleState,
            [type]: peopleState[type] - 1,
        });
    };

    const updateNumbers = () => {
        setIsSubmitting(true);
        client
            .post(`/api/guest-count-logs`, {
                men_count: peopleState.men,
                women_count: peopleState.women,
            })
            .then(res => {
                setPeopleState({
                    men: 0,
                    women: 0,
                });
                queryClient.invalidateQueries("guest-count-logs");
            })
            .catch(err => {
                customToast("error", {
                    title: err.message,
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const renderInputContainer = key => {
        return (
            <S.InputContainer>
                <S.MinButton
                    onClick={e =>
                        peopleState[key] > 0 && handleDecreaseNumber(e, key)
                    }
                >
                    <FontAwesomeIcon icon={faMinus} size="1x" />
                </S.MinButton>
                <S.Inputs
                    weightText={true}
                    readOnly={false}
                    onChange={e => {
                        handleChange(e, key);
                    }}
                    value={peopleState[key]}
                />
                <S.MaxButton
                    onClick={e => {
                        handleIncreaseNumber(e, key);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} size="1x" />
                </S.MaxButton>
            </S.InputContainer>
        );
    };

    return (
        <>
            <S.Header>
                <S.HeaderTitle>{title}</S.HeaderTitle>
                {headers.map(type => {
                    return <S.SubTypes key={type.key}> {type.key}</S.SubTypes>;
                })}
            </S.Header>

            <S.Container>
                <S.Types>
                    <S.Type>
                        <S.TypeText>{t("current")}</S.TypeText>
                        <S.InputsContainer>
                            {renderInputContainer("men")}
                            {renderInputContainer("women")}
                            <S.InputContainer>
                                <S.TotalInput
                                    value={peopleState.men + peopleState.women}
                                    readOnly={true}
                                />
                            </S.InputContainer>
                        </S.InputsContainer>
                        <S.ButtonContainer>
                            <S.Button
                                disabled={isSubmitting}
                                onClick={() => {
                                    updateNumbers();
                                }}
                            >
                                {isSubmitting ? (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="fa-spin"
                                    />
                                ) : (
                                    t("save")
                                )}
                            </S.Button>
                        </S.ButtonContainer>
                    </S.Type>
                    {floorNumbers.map(row => {
                        return (
                            <S.Type key={row.id}>
                                <S.TypeTextLoaded>{row.time}</S.TypeTextLoaded>
                                <S.LoadedData>{row.men}</S.LoadedData>
                                <S.LoadedData>{row.women}</S.LoadedData>
                                <S.TotalLoadedData>
                                    {row.total}
                                </S.TotalLoadedData>
                            </S.Type>
                        );
                    })}
                </S.Types>
            </S.Container>
        </>
    );
};

export default FloorNumbersUpdate;
