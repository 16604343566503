import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { ActiveVisitLimit } from "../../../types/guest";
import { TranslationsKeys } from "../../../types/translations";
import * as S from "./FormMessages.styled";
import { useTranslation } from "react-i18next";
import { CheckInFields } from "../../../types/checkIn";
import FormErrors from "../../formPartials/FormErrors/FormErrors";

type Props = {
    errors: {
        [key in keyof Partial<CheckInFields>]: any;
    };
    activeVisitLimit: ActiveVisitLimit | undefined;
    isSignatureRequired: boolean;
};

function FormMessages({
    errors,
    activeVisitLimit,
    isSignatureRequired,
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            {errors && (
                <S.ErrorsContainer>
                    <FormErrors errors={errors} />
                </S.ErrorsContainer>
            )}

            {activeVisitLimit && (
                <S.PlayerAction
                    visitLimit={
                        activeVisitLimit?.guest_period_visits ===
                        activeVisitLimit?.limit
                    }
                >
                    <FontAwesomeIcon
                        icon={faExclamationCircle as IconProp}
                        size="2x"
                    />
                    {t("guest_has_visit_limit")}
                    <br />
                    {activeVisitLimit.guest_period_visits}/
                    {activeVisitLimit.limit}{" "}
                    {t(`visits_this`, {
                        entity: t(
                            activeVisitLimit.period as TranslationsKeys
                        ) as string,
                    })}
                </S.PlayerAction>
            )}
            {isSignatureRequired && (
                <S.PlayerAction>
                    <FontAwesomeIcon
                        icon={faExclamationCircle as IconProp}
                        size="2x"
                    />
                    {t("guest_needs_to_declare_bsn")}
                </S.PlayerAction>
            )}
        </>
    );
}

export default FormMessages;
