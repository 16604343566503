import React, { useState, Fragment } from "react";
import * as S from "./FormComments.styled";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import DeletePopup from "../../Popups/OldDeletePopup";
import EditCommentPopup from "../../Popups/EditCommentPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import client from "../../../client";
import { useQueryClient } from "react-query";
import { useUserStore } from "../../../store/userStore";
import { useTranslation } from "react-i18next";
import { options } from "../../../utils/optionsMessages";
import { faPencilAlt, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import usePermissions from "../../../hooks/usePermissions";
import { FormTextArea } from "../../../components/formPartials";
import useCustomToast from "../../../hooks/useCustomToast";
import Button from "../../Button";

function FormComments({
    formKey,
    register = false,
    required,
    errors,
    label = "",
    placeholder = "",
    type = "text", // input, email, password text
    value,
    readOnly = false,
    typeOption,
    setValue,
    setFocus,
    comment,
    setComment,
}) {
    const { t } = useTranslation();
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [editItemId, setEditItemId] = useState(null);

    const [checkActive, setCheckActive] = useState(false);
    const queryClient = useQueryClient();
    const params = useParams();
    const currentUser = useUserStore().user;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { hasPermissions } = usePermissions();
    const hasDeletePermissions = hasPermissions(["delete_messages"]);
    const { customToast } = useCustomToast();

    const handleDelete = value => {
        client.delete(`/api/report-comments/${value}`).then(res => {
            customToast("success", {
                title: t("entity_successfully_deleted", {
                    entity: t("comment"),
                }),
            });

            queryClient.invalidateQueries(`reports/${params.id}`);
            setDeleteItemId(null);
            setShowDeletePopup(false);
        });
    };

    const handleUpdate = (id, newValue) => {
        setCheckActive(true);
        client
            .put(`/api/report-comments/${id}`, { message: newValue })
            .then(res => {
                customToast("success", {
                    title: t("entity_successfully_updated", {
                        entity: t("comment"),
                    }),
                });
                setCheckActive(false);
                queryClient.invalidateQueries(`reports/${params.id}`);
                setEditItemId(null);
                setShowEditPopup(false);
            });
    };

    const handleComment = () => {
        setIsSubmitting(true);
        client
            .post(`/api/report-comments`, {
                report_id: params.id,
                message: comment,
            })
            .then(res => {
                customToast("success", {
                    title: t("entity_successfully_created", {
                        entity: t("comment"),
                    }),
                });
                queryClient.invalidateQueries(`reports/${params.id}`);
                setValue("your_comment", null);
                setComment(null);
            })
            .catch(err => {
                customToast("error", {
                    title: t("error_data_could_not_be_sent", {
                        entity: t("comment"),
                    }),
                    body: err.response.data.errors.message,
                });

                setFocus("your_comment");
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const formFunctions = {
        register,
        errors,
        setValue,
        setFocus,
    };
    const handleCommentChange = e => {
        setComment(e.target.value);
    };
    return (
        <>
            {showDeletePopup && (
                <DeletePopup
                    setShowPopup={setShowDeletePopup}
                    value={deleteItemId}
                    deleteHandler={handleDelete}
                    text="comment"
                />
            )}
            {showEditPopup && (
                <EditCommentPopup
                    setShowPopup={setShowEditPopup}
                    id={editItemId}
                    value={comment}
                    setComment={setComment}
                    handleUpdate={handleUpdate}
                    text={t("comment")}
                    checkActive={checkActive}
                />
            )}

            <FormTextArea
                formKey="your_comment"
                label={t("add_comment")}
                placeholder={t("your_comment")}
                handleCommentChange={handleCommentChange}
                row={"3"}
                columns={"50"}
                {...formFunctions}
                readOnly={false}
            />

            <S.Buttons>
                <Button
                    className="btn-secondary"
                    isLoading={isSubmitting}
                    disabled={!comment}
                    type="button"
                    onClick={e => {
                        e.preventDefault();
                        handleComment();
                    }}
                >
                    {t("save_comment")}
                </Button>
            </S.Buttons>

            <S.FormControl error={errors[formKey]}>
                <label>{label}</label>
                {value.comments.length > 0 ? (
                    <S.Comments
                        readOnly={readOnly}
                        type={type}
                        value={value}
                        placeholder={placeholder}
                        {...register(formKey)}
                    >
                        {value.comments.map(comment => {
                            return (
                                <S.Comment key={comment.id}>
                                    <S.LeftSide
                                        color={
                                            options[typeOption || value?.type]
                                                ?.color
                                        }
                                    >
                                        <S.ProfileImage
                                            src={
                                                comment.employee
                                                    ?.profile_photo_url
                                            }
                                        />
                                    </S.LeftSide>
                                    <S.CommentTitle>
                                        {comment.message
                                            .split("\n")
                                            .map((item, key) => {
                                                return (
                                                    <Fragment key={key}>
                                                        {item}
                                                        <br />
                                                    </Fragment>
                                                );
                                            })}
                                    </S.CommentTitle>
                                    <S.CommentDetails>
                                        <S.CommentAuthor>
                                            {comment.employee?.full_name}
                                        </S.CommentAuthor>
                                        <S.CommentTime>
                                            {dayjs(comment.created_at).format(
                                                "DD-MM-YYYY HH:mm"
                                            )}
                                        </S.CommentTime>

                                        <S.Buttons>
                                            {" "}
                                            {comment?.employee?.id ===
                                                currentUser.id && (
                                                <S.EditReport
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setShowEditPopup(
                                                            !showEditPopup
                                                        );
                                                        setEditItemId(
                                                            comment.id
                                                        );
                                                        setComment(
                                                            comment.message
                                                        );
                                                    }}
                                                >
                                                    {" "}
                                                    <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                        size="lg"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    />
                                                </S.EditReport>
                                            )}
                                            {(comment?.employee?.id ===
                                                currentUser.id ||
                                                hasDeletePermissions) && (
                                                <S.DeleteReport
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setShowDeletePopup(
                                                            !showDeletePopup
                                                        );
                                                        setDeleteItemId(
                                                            comment.id
                                                        );
                                                    }}
                                                >
                                                    {" "}
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        size="lg"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    />
                                                </S.DeleteReport>
                                            )}
                                        </S.Buttons>
                                    </S.CommentDetails>
                                </S.Comment>
                            );
                        })}
                    </S.Comments>
                ) : (
                    <div>{t("no_comments_found")}</div>
                )}
            </S.FormControl>
        </>
    );
}

export default FormComments;
