import { Fragment, MouseEventHandler, ReactNode } from "react";
import { Transition } from "@headlessui/react";
import toast, { Toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ToastType, ToastContent } from "../types/toast";
import { classNames } from "../utils/style";
import { useHistory } from "react-router";
import Confetti from "react-confetti";
import {
    faCircleXmark,
    faExclamationCircle,
    faCheckCircle,
    faBirthdayCake,
} from "@fortawesome/pro-light-svg-icons";
import Button from "./Button";

type Props = {
    toastState: Toast;
    type: ToastType;
    content: ToastContent;
};

export default function CustomToast({ toastState, type, content }: Props) {
    const { t } = useTranslation();
    const history = useHistory();

    const typeMap: {
        [key in ToastType]: { icon: ReactNode; style: string };
    } = {
        success: {
            icon: <FontAwesomeIcon icon={faCheckCircle as IconProp} />,
            style: "bg-[#1DC690]",
        },
        warning: {
            icon: (
                <FontAwesomeIcon
                    icon={faExclamationCircle as IconProp}
                    className="text-[#050533]"
                />
            ),
            style: "bg-[#F9A825]",
        },
        error: {
            icon: <FontAwesomeIcon icon={faExclamationCircle as IconProp} />,
            style: "bg-[#E34234]",
        },
        denied: {
            icon: <FontAwesomeIcon icon={faCircleXmark as IconProp} />,
            style: "bg-[#E34234]",
        },
        birthday: {
            icon: <FontAwesomeIcon icon={faBirthdayCake as IconProp} />,
            style: "bg-[#68689D]",
        },
        checkInMessage: {
            icon: (
                <FontAwesomeIcon
                    icon={faExclamationCircle as IconProp}
                    className="text-[#FBFBFF]"
                />
            ),
            style: "bg-dark-secondary",
        },
    };

    const handleButtonClick = (
        onClickFunction: MouseEventHandler<HTMLButtonElement> | undefined
    ) => {
        return function (e: React.MouseEvent<HTMLButtonElement>) {
            if (onClickFunction) {
                onClickFunction(e);
            }
            toast.dismiss(toastState.id);
        };
    };

    const redirectAndDismiss = (url: string | undefined) => {
        if (url) {
            history.push(url);
        }
        toast.dismiss(toastState.id);
    };

    return (
        <Transition
            appear={true}
            show={toastState.visible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="pointer-events-auto relative top-20 w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5">
                {type === "birthday" && (
                    <div className="absolute top-0 left-0 w-full opacity-40">
                        <Confetti
                            width={384}
                            height={200}
                            numberOfPieces={100}
                        />
                    </div>
                )}
                <div>
                    <div className="flex items-stretch">
                        <div
                            className={classNames(
                                "flex w-20 shrink-0 items-center justify-center text-[40px] text-white",
                                typeMap[type as ToastType].style
                            )}
                        >
                            {typeMap[type as ToastType].icon}
                        </div>
                        <div className="grow p-7 pr-14">
                            <div className="flex items-center">
                                {content.image && (
                                    <div className="relative flex flex-shrink-0 items-center pr-2 tracking-wide">
                                        <button
                                            onClick={() =>
                                                redirectAndDismiss(
                                                    content.image?.url
                                                )
                                            }
                                        >
                                            <img
                                                src={content.image.src}
                                                alt=""
                                                className="h-12 w-12 rounded-full object-cover"
                                            />
                                        </button>
                                    </div>
                                )}
                                <div>
                                    <p className="text-2xl font-normal leading-tight text-black">
                                        {content.title}
                                    </p>
                                    {content?.subTitle && (
                                        <p className="text-lg leading-tight text-black">
                                            {content.subTitle.url ? (
                                                <button
                                                    className="text-black underline"
                                                    onClick={() => {
                                                        redirectAndDismiss(
                                                            content.subTitle
                                                                ?.url
                                                        );
                                                    }}
                                                >
                                                    {content.subTitle.text}
                                                </button>
                                            ) : (
                                                content.subTitle.text
                                            )}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {content.body && (
                                <div className="font-regular mt-2 text-base text-black">
                                    {content.body}
                                </div>
                            )}
                            {content.link && (
                                <div className="mt-1">
                                    <button
                                        type="button"
                                        className="underline"
                                        onClick={() => {
                                            history.push({
                                                pathname: content.link?.url,
                                                state: content.link?.state,
                                            });
                                            toast.dismiss(toastState.id);
                                        }}
                                    >
                                        {content.link.title}
                                    </button>
                                </div>
                            )}
                            {content.subText && (
                                <div className="mt-1 text-sm text-gray-400">
                                    {content?.subText}
                                </div>
                            )}
                            {content.buttons && (
                                <div className="mt-5 flex space-x-4">
                                    <Button
                                        {...content.buttons.primary}
                                        onClick={handleButtonClick(
                                            content.buttons.primary?.onClick
                                        )}
                                    />
                                    {content.buttons.secondary && (
                                        <Button
                                            {...content.buttons.secondary}
                                            onClick={handleButtonClick(
                                                content.buttons.secondary
                                                    ?.onClick
                                            )}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="absolute top-2 right-4 ml-4 flex flex-shrink-0 p-1">
                            <button
                                type="button"
                                className="bg-whitetext-gray-400 inline-flex h-6 w-6 items-center justify-center rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => {
                                    toast.dismiss(toastState.id);
                                }}
                            >
                                <span className="sr-only">{t("close")}</span>
                                <FontAwesomeIcon
                                    icon={faTimes as IconProp}
                                    className="text-lg"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}
