import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Search from "../Search/Search";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Button as ButtonType, ButtonVariant } from "../../types/button";
import Button from "../Button";

type Props = {
    icon?: IconProp;
    title: string;
    back?: boolean;
    showRegisterButton?: boolean;
    showSearch?: boolean;
    showLists?: boolean;
    placeholder?: string;
    buttons?: ButtonType[];
};

export default function PageHeader({
    icon,
    title,
    back = true,
    showRegisterButton = false,
    showSearch = false,
    showLists = false,
    placeholder,
    buttons,
}: Props) {
    const history = useHistory();
    const { t } = useTranslation();

    const renderButton = (
        {
            faIcon,
            link,
            text,
            type,
            clickHandler,
            loading,
            disabled = false,
        }: ButtonType,
        index: number
    ) => {
        const typeMap: {
            [key in ButtonVariant]: string;
        } = {
            primary: "btn-primary",
            secondary: "btn-secondary",
            pageHeader: "btn-primary",
            pageHeaderButtonAlternative: "btn-secondary",
        };

        type Props = {
            key: number;
            as: string;
            to?: string;
            disabled: boolean;
            onClick?: () => void;
        };

        const props: Props = {
            key: index,
            as: clickHandler ? "button" : Link,
            to: link,
            disabled: disabled,
        };

        if (clickHandler) {
            props.onClick = () => clickHandler();
        }

        return (
            <Button {...props} className={typeMap[type]}>
                {loading ? (
                    <FontAwesomeIcon
                        icon={faSpinner as IconProp}
                        className="fa-spin"
                    />
                ) : (
                    <>
                        {faIcon && (
                            <FontAwesomeIcon
                                icon={faIcon as IconProp}
                                className="text-xl"
                            />
                        )}
                        <span>{text}</span>
                    </>
                )}
            </Button>
        );
    };

    return (
        <div className="mb-30 h-16.5 absolute left-0 top-0 flex min-h-[68px] w-full flex-wrap items-center justify-between bg-dark-secondary px-10 py-3.5 text-2xl text-alternative-white md:flex-row">
            <div className=" flex w-1/2 items-center  md:w-fit">
                {back && (
                    <div
                        className="mr-5 flex h-8 w-8 cursor-pointer items-center justify-center self-center rounded-full border-2"
                        onClick={() => history.goBack()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft as IconProp}
                            className="m-0 h-5 p-0"
                        />
                    </div>
                )}
                {icon && <FontAwesomeIcon icon={icon} className="mr-5" />}
                <span>{title}</span>
            </div>
            <div className="flex w-1/2 justify-end  md:w-fit md:justify-between">
                <div className="mr-16 flex gap-10">
                    {showLists && (
                        <Link
                            className="text-base text-alternative-black decoration-0"
                            to="/playpause"
                        >
                            {t("playpause")}
                        </Link>
                    )}
                    {showLists && (
                        <Link
                            className="text-base text-alternative-black decoration-0"
                            to="/blacklist"
                        >
                            {t("blacklist")}
                        </Link>
                    )}
                </div>
                {showSearch && <Search placeholder={placeholder} />}
                <div className="flex space-x-4">
                    {buttons &&
                        buttons.map((item: ButtonType, i: number) =>
                            renderButton(item, i)
                        )}
                    {showRegisterButton && (
                        <Button
                            className="btn-secondary"
                            onClick={() => history.push("/register-player")}
                        >
                            {t("register_player")}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
