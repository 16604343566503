import React, { useState, useEffect, useRef } from "react";
import AppliedFilters from "./AppliedFilters";
import * as S from "./Filter.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { renderFilter } from "./renderFilter";
import { useFiltersStore } from "../../store/filterStore";

export default function Filter({
    filters,
    updatedAppliedFilters,
    updateFilterParams,
    filterId,
}) {
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [dateRange, setDateRange] = useState({});
    const updateGlobalFilters = useFiltersStore(state => state.updateFilters);
    const globalFilters = useFiltersStore(state => state.filters);

    const { t } = useTranslation();
    const globalFilterInitRef = useRef(false);

    useEffect(() => {
        if (!filterId) return;

        // Make sure that the global filter is not updated on initial render
        if (!globalFilterInitRef.current) {
            globalFilterInitRef.current = true;
            return;
        }

        updateGlobalFilters(appliedFilters, filterId);
    }, [appliedFilters, filterId, updateGlobalFilters]);

    useEffect(() => {
        if (
            (!updatedAppliedFilters || !updatedAppliedFilters.length) &&
            globalFilters[filterId]
        ) {
            setAppliedFilters(globalFilters[filterId]);
        }

        updatedAppliedFilters && setAppliedFilters(updatedAppliedFilters);
    }, [filterId, globalFilters, updatedAppliedFilters]);

    useEffect(() => {
        const singleParams = [...appliedFilters].reduce((prev, curr) => {
            if (curr.key !== "multi")
                return { ...prev, ...curr.selectedValue.params };
            return prev;
        }, {});

        const multiParams = {};
        appliedFilters.forEach(item => {
            if (item.key === "multi") {
                const key = Object.keys(item.selectedValue.params)[0];
                const value = item.selectedValue.params[key][0];
                if (multiParams[key]) {
                    multiParams[key].push(value);
                } else {
                    multiParams[key] = [value];
                }
            }
        });

        const params = { ...singleParams, ...multiParams };
        updateFilterParams(params);
    }, [appliedFilters, updateFilterParams]);

    return (
        <>
            <S.FilterContainer>
                {filters.map((filter, index) => (
                    <div key={index}>
                        {renderFilter({
                            ...filter,
                            setAppliedFilters,
                            appliedFilters,
                            dateRange,
                            setDateRange,
                        })}
                    </div>
                ))}

                <S.ClearFilters
                    onClick={() => {
                        setAppliedFilters([]);
                        updateFilterParams({});
                        setDateRange({});
                    }}
                    active={appliedFilters.length ? true : false}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                            "--fa-primary-color": "#000",
                            "--fa-secondary-color": "#000",
                            "--fa-primary-opacity": "1.0",
                            "--fa-secondary-opacity": "1.0",
                        }}
                        size="lg"
                    />
                    {t("clear_filters")}
                </S.ClearFilters>
            </S.FilterContainer>

            {appliedFilters.length > 0 && (
                <AppliedFilters
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    setDateRange={setDateRange}
                />
            )}
        </>
    );
}
