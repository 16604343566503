import React, { useRef } from "react";
import * as S from "./Popups.styled";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";

import { usePopupStore } from "../../store/popupStore";
import Button from "../Button";

const DeletePopup = ({ deleteHandler, entityName }) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const { setPopupType } = usePopupStore();

    const closePopup = () => {
        setPopupType({
            popupType: null,
            data: null,
        });
    };

    useOnClickOutside(containerRef, () => closePopup());

    return (
        <S.DeleteContainer>
            <S.DeleteFormContainer
                ref={containerRef}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                        "--fa-primary-color": "#1D1E45",
                        "--fa-secondary-color": "#1D1E45",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "0.9",
                    }}
                    onClick={() => closePopup()}
                />

                <S.Title>
                    {t(
                        "delete_this_entity",
                        { entity: entityName },
                        "delete_this_entity"
                    )}
                </S.Title>
                <S.DeleteRequest>
                    {t(
                        `this_entity_will_be_deleted_permenantly_from_all_locations`,
                        { entity: entityName }
                    )}
                    <br />
                    {t("you_cannot_undo_this_action")}
                </S.DeleteRequest>
                <S.ButtonsDelete>
                    <Button
                        className="btn-alternative"
                        onClick={e => {
                            e.stopPropagation();
                            closePopup();
                        }}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        className="btn-primary"
                        onClick={() => {
                            deleteHandler();
                            closePopup();
                        }}
                    >
                        {t("delete")}
                    </Button>
                </S.ButtonsDelete>
            </S.DeleteFormContainer>
        </S.DeleteContainer>
    );
};

export default DeletePopup;
