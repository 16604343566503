import dayjs, { ManipulateType } from "dayjs";
import { useMemo, useState } from "react";

function useSelectPeriodAndDate(updateDayValue: (arg: string) => void) {
    const [dateOption, setDateOption] = useState<null | string>(null);

    const optionActions: { [key: string]: Function | undefined } = useMemo(
        () => ({
            custom: () => {
                /* do nothing */
            },
            indefinitely: () => updateDayValue(""),
            today: () => updateDayValue(dayjs().format("YYYY-MM-DD")),
        }),
        [updateDayValue]
    );

    const selectDateOption = (option: string) => {
        setDateOption(option);
        const action = optionActions[option];

        if (action) {
            action();
        } else if (/^\d+_(day|week|month)s?$/.test(option)) {
            const [dayNumber, timePeriod] = option.split("_");

            const newDay = dayjs()
                .add(parseInt(dayNumber), timePeriod as ManipulateType)
                .format("YYYY-MM-DD");

            updateDayValue(newDay);
        } else {
            throw new Error(`Unsupported option: ${option}`);
        }
    };

    return { selectDateOption, dateOption };
}

export default useSelectPeriodAndDate;
