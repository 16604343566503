import React from "react";
import PageHeader from "../../components/PageHeader";

import { useHistory } from "react-router-dom";
import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import usePermissions from "../../hooks/usePermissions";
import { StaffMember } from "../../types/staff";
import { Button } from "../../types/button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";

const Staff = () => {
    const history = useHistory();
    const tableColumns = columns.users;
    const { t } = useTranslation();
    const { hasPermissions } = usePermissions();

    const {
        onScroll,
        filteredData,
        containerRef,
        isLoading,
        updateSortParams,
    } = useInfiniteScroll("/api/employee");

    const handleTableClick = (data: StaffMember) => {
        history.push(`/staff/${data.id}`);
    };

    const hasAddPermissions = hasPermissions(["add_staff"]);

    const buttons: Button[] = hasAddPermissions
        ? [
              {
                  text: t("add_staff"),
                  clickHandler: () => history.push("/add-user"),
                  type: "pageHeader",
                  faIcon: faUserPlus as IconProp,
              },
          ]
        : [];

    return (
        <>
            {/* 
            // @ts-ignore */}
            <PageHeader title={t("staff")} buttons={buttons} />
            {/* 
            // @ts-ignore */}
            <DataTable
                columns={tableColumns}
                data={filteredData}
                onClick={handleTableClick}
                containerRef={containerRef}
                pagination={false}
                onScroll={onScroll}
                updateSortParams={updateSortParams}
                isLoading={isLoading}
            />
        </>
    );
};

export default Staff;
