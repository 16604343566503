import * as S from "./VersionInformationContent.styled";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
import React from "react";

function VersionInformationContent({
    versionsToShow,
}: {
    versionsToShow: number;
}) {
    const { t } = useTranslation();

    const versionInformationHeaders = [
        {
            name: t("version"),
        },
        { name: t("details") },
        { name: t("date_updated") },
    ];
    //this part will be deleted after the backend is ready
    const versions = [
        {
            version: "0.0.1",
            change_notes: `* Cooming soon...
* Cooming soon...
* Cooming soon...`,
            created_at: new Date(),
        },
    ];

    return (
        <>
            <S.HeadersVersion>
                {versionInformationHeaders.map((header, index) => (
                    <S.HeaderVersionInformation key={index}>
                        {header.name}
                    </S.HeaderVersionInformation>
                ))}
            </S.HeadersVersion>
            <S.VersionInformation versionsToShow={versionsToShow}>
                {versions.slice(0, versionsToShow).map((version, index) => (
                    <React.Fragment key={version.version}>
                        <S.Version key={index}>{version.version}</S.Version>
                        <S.VersionDetails>
                            <ReactMarkdown children={version.change_notes} />
                        </S.VersionDetails>
                        <S.VersionUpdate>
                            {dayjs(version.created_at).format(
                                "DD-MM-YYYY, HH:mm"
                            )}
                        </S.VersionUpdate>
                    </React.Fragment>
                ))}
            </S.VersionInformation>
        </>
    );
}

export default VersionInformationContent;
