import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { LocationSelectOption } from "../../types/location";
import { SelectOption } from "../../types/form";
import Select from "react-select";
import { classNames } from "../../utils/style";
import { useTranslation } from "react-i18next";
import {
    endDateOptions,
    locationOptions,
    reasonOptionsBlackList,
} from "../../utils/formOptions";
import MultiSelect from "../MultiSelect/MultiSelect";
import { prepareOptionsForSelect } from "../../utils/locations";
import { useUserStore } from "../../store/userStore";
import useSelectPeriodAndData from "../../hooks/useSelectPeriodAndDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import useFormResponseError from "../../hooks/useFormResponseError";
import { useHistory, useParams } from "react-router";
import FormFiles from "../formPartials/FormFiles/FormFiles";
import { RootDataGuest } from "../../types/guest";
import usePopup from "../../hooks/usePopup";
import { useQuery, useQueryClient } from "react-query";
import day from "dayjs";
import { TranslationsKeys } from "../../types/translations";
import { FileObject } from "../../types/note";

type Location = {
    id: number;
    name: string;
};

type BlacklistRecord = {
    id: number;
    player_id: number;
    player: string;
    player_photo: string;
    added_by: string;
    police_contacted: boolean;
    reason: string;
    reasons: string[];
    entry_code?: string;
    entry_note?: string;
    removal_reason?: string;
    removal_note?: string;
    start_date: string;
    end_date: string;
    global: string;
    locations?: Location[];
    files: FileObject[];
};

type FormFields = {
    description: string;
    location: string;
    locations: LocationSelectOption[];
    reasons: SelectOption[];
    end_date_options: SelectOption;
    end_date: string;
    police_contacted: boolean;
};

const BlacklistAction = ({ playerData }: { playerData: RootDataGuest }) => {
    const { user } = useUserStore();
    const { customToast } = useCustomToast();
    const history = useHistory();
    const { formResponseError } = useFormResponseError();
    const { id: playerId } = useParams<{ id: string }>();
    const [files, setFiles] = useState<File[]>([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const { popup } = usePopup();
    const queryClient = useQueryClient();

    const { data: blackListData, isLoading } = useQuery(
        [`/blacklist-records/${playerData?.blacklist?.id}`],
        async () => {
            try {
                const blackListData = await client.get(
                    `api/blacklist-records/${playerData?.blacklist?.id}`
                );

                return blackListData.data.data;
            } catch (err: any) {
                customToast("error", {
                    title: t("error"),
                    body: err.message,
                });
            }
        },
        {
            enabled: !!playerData?.blacklist?.id,
        }
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        formState: { isSubmitted },
        setError,
    } = useForm<FormFields>({
        defaultValues: {
            location: "all_locations",
            locations: prepareOptionsForSelect(
                user?.locations.filter(
                    location => location.id === user.current_location_id
                )
            ),
        },
    });

    const [blacklist, setBlacklist] = useState<BlacklistRecord>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit: SubmitHandler<FormFields> = data => {
        if (!user) return;

        setErrorMessage("");
        setIsSubmitting(true);

        const apiData = new FormData();

        if (blacklist) {
            apiData.append("_method", "PUT");
        }

        const postData = {
            player_id: playerId.toString(),
            start_date: dayjs().format("YYYY-MM-DD"),
            end_date: data.end_date,
            entry_note: data.description,
            police_contacted: data.police_contacted ? "1" : "0",
        };

        Object.entries(postData).forEach(([key, value]) => {
            if (value !== null) {
                apiData.append(key, value);
            }
        });

        data.reasons.forEach((reason: SelectOption) =>
            apiData.append("reasons[]", reason.value.toString())
        );

        if (user?.locations.length === 1) {
            data.location = "all_locations";
        }

        switch (data.location) {
            default:
            case "this_location":
                apiData.append(
                    "locations[]",
                    user.current_location_id.toString()
                );
                break;
            case "all_locations":
                apiData.append("global", "1");
                break;
            case "select_locations_manually":
                apiData.append("global", "0");
                data.locations?.forEach((location: { id: number }) =>
                    apiData.append("locations[]", location?.id.toString())
                );
                break;
        }

        files.forEach(file => {
            apiData.append("files[]", file as File);
        });

        const requestMap = {
            put: {
                endpoint: `/api/blacklist-records/${blacklist?.id}`,
                succesText: t(`entity_successfully_updated`, {
                    entity: t("blacklist"),
                }),
            },
            post: {
                endpoint: `/api/blacklist-records`,
                succesText: t(`entity_successfully_created`, {
                    entity: t("blacklist"),
                }),
            },
        };

        const type = blacklist ? "put" : "post";

        client["post"](requestMap[type].endpoint, apiData)
            .then(res => {
                queryClient.invalidateQueries(`player/${playerId.toString()}`);
                history.push(
                    `/guest/${playerId.toString()}?action=activity_overview`
                );
                customToast("success", {
                    title: t("success_blacklist", {
                        entity: res.data[0].player,
                    }),

                    link: {
                        title: t("view_guest"),
                        url: `/guest/${playerId}`,
                    },
                });
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const { t } = useTranslation();

    const updateDayValue = useCallback(
        (dayValue: string) => {
            setValue("end_date", dayValue);
        },
        [setValue]
    );

    const { selectDateOption, dateOption } =
        useSelectPeriodAndData(updateDayValue);

    useEffect(() => {
        if (!isLoading && blackListData) {
            setBlacklist(blackListData);
            selectDateOption(blacklist?.end_date ? "custom" : "indefinitely");
        }
    }, [isLoading, blacklist, reset, blackListData, selectDateOption]);

    useEffect(() => {
        const defaultFormValues: Partial<FormFields> =
            blacklist && playerData?.player?.is_black_listed
                ? {
                      location: blacklist.global
                          ? "all_locations"
                          : "select_locations_manually",
                      description: blacklist.entry_note,
                      reasons: blacklist.reasons.map((reason: string) => ({
                          value: reason,
                          label: t(reason as TranslationsKeys) as string,
                      })),

                      police_contacted: blacklist.police_contacted,

                      locations:
                          blacklist?.locations?.map((location: Location) => ({
                              value: location.name,
                              label: location.name,
                              id: location.id,
                          })) || [],

                      end_date: blacklist.end_date
                          ? day(blacklist?.end_date).format("YYYY-MM-DD")
                          : "",
                      end_date_options: blacklist?.end_date
                          ? {
                                value: "custom_end_date",
                                label: t("custom_end_date"),
                            }
                          : { value: "indefinitely", label: t("indefinitely") },
                  }
                : {};

        if (!isLoading && blacklist && playerData?.player?.is_black_listed) {
            setExistingFiles(blackListData.files);
        }

        reset(defaultFormValues);
    }, [
        reset,
        t,
        blacklist,
        isLoading,
        blackListData,
        playerData?.player?.is_black_listed,
    ]);

    const location = watch("location");

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control required">
                <label className="form-label">{t("end_date")}:</label>
                <Controller
                    name="end_date_options"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <Select
                                {...field}
                                options={endDateOptions}
                                placeholder={t("select_period")}
                                onChange={(option: SelectOption) => {
                                    field.onChange(option);
                                    selectDateOption(option.value as string);
                                }}
                                className={classNames(
                                    "form-select",
                                    fieldState.error !== undefined && "error"
                                )}
                            />
                        );
                    }}
                />
                <input
                    className="form-input"
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    {...register("end_date", {
                        required: dateOption !== "indefinitely",
                    })}
                    readOnly={dateOption !== "custom"}
                />
            </div>
            <div className="form-control required">
                <label className="form-label">{t("reason")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="reasons"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={reasonOptionsBlackList}
                                    onChange={field.onChange}
                                    placeholder={t("select_reason") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="form-control">
                <div className="form-checkbox-container col-span-2 col-start-2">
                    <input
                        type="checkbox"
                        id="police_contacted"
                        {...register("police_contacted")}
                    />
                    <label htmlFor="police_contacted">
                        {t("police_was_called")}
                    </label>
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("description")}</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="description"
                        placeholder={t("notes") + ".."}
                        {...register("description")}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("location")}</label>
                <div className="col-span-2">
                    {prepareOptionsForSelect(user?.locations).length > 1 &&
                        locationOptions.map(option => (
                            <div
                                className="form-radio-container col-span-2"
                                key={option.value}
                            >
                                <input
                                    type="radio"
                                    id={option.value}
                                    value={option.value}
                                    {...register("location")}
                                    name="location"
                                />
                                <label
                                    className="[&_span]:font-bold [&_span]:underline"
                                    htmlFor={option.value}
                                    dangerouslySetInnerHTML={{
                                        __html: option.label,
                                    }}
                                ></label>
                            </div>
                        ))}
                </div>
            </div>
            {location === "select_locations_manually" &&
                prepareOptionsForSelect(user?.locations).length > 1 && (
                    <div className="form-control">
                        <div className="col-span-2 col-start-2">
                            <Controller
                                name="locations"
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <MultiSelect
                                            onChange={field.onChange}
                                            options={prepareOptionsForSelect(
                                                user?.locations
                                            )}
                                            placeholder={
                                                t("add_locations") + ".."
                                            }
                                            hasError={
                                                fieldState.error !== undefined
                                            }
                                            value={field.value}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}

            <div className="form-control items-center">
                <label className="form-label">{t("add_files")}:</label>
                <div className="col-span-2 col-start-2">
                    <FormFiles
                        setFiles={setFiles}
                        existingFiles={existingFiles}
                        isSubmitted={isSubmitted}
                    />
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2 ml-2.5">
                    <button
                        type="submit"
                        className="btn-primary mr-3 mb-3"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner as IconProp}
                                className="fa-spin"
                            />
                        ) : playerData?.player?.is_black_listed ? (
                            t("update_blacklist")
                        ) : (
                            t("add_blacklist")
                        )}
                    </button>
                    {playerData?.player?.is_black_listed && (
                        <button
                            type="button"
                            className="btn-secondary"
                            disabled={isSubmitting}
                            onClick={() =>
                                popup({
                                    type: "BLACKLIST_EXPIRE_TALK",
                                    data: {
                                        recordId:
                                            playerData.player
                                                .blacklist_record_id,
                                        playerId: playerData.player.id,
                                    },
                                })
                            }
                        >
                            {t("remove_blacklist")}
                        </button>
                    )}
                </div>
            </div>
            {errorMessage && (
                <div className="sm:grid sm:grid-cols-3">
                    <div className="col-span-2 col-start-2 my-5 rounded-sm bg-red-300 p-4 text-gray-500">
                        <p>{errorMessage}</p>
                    </div>
                </div>
            )}
        </form>
    );
};

export default BlacklistAction;
