import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const initBeforeUnLoad = showExitPrompt => {
    window.onbeforeunload = event => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};

export default function useExitPrompt(isActive) {
    window.onload = function () {
        initBeforeUnLoad(isActive);
    };

    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        initBeforeUnLoad(isActive);
        const unblock = history.block((location, action) => {
            if (isActive) {
                return window.confirm(t("unsaved_changes_message"));
            }
            return true;
        });

        return () => {
            window.onbeforeunload = null;
            unblock();
        };
    }, [history, isActive, t]);
}
