export const convertBase64ToBlob = async (
    base64Img: string
): Promise<Blob | null> => {
    if (!base64Img) return null;
    const base64Response = await fetch(base64Img);
    const blob = await base64Response.blob();
    return blob;
};

export const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
