import styled from "styled-components";

export const SystemStatusBlock = styled.div`
    width: 100%;
    padding-bottom: 60px;
    margin-bottom: 30px;
`;

export const Headers = styled.div`
    border-bottom: 1px solid #050505;
    padding: 10px 10px 10px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
`;

export const Header = styled.div`
    font-size: 20px;
`;

export const Systems = styled.div``;

export const SystemNames = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 24px;
    padding: 30px 0;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
    height: 90px;
`;

export const SystemsBody = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

export const SystemStatus = styled.div<{ statusText: string }>`
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 30px 0;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
    color: ${props => (props.statusText === "online" ? "#27AE60" : "red")};
    height: 90px;
`;

export const SystemCheckedTime = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 30px 0;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
    color: #050505;
    height: 90px;
`;

export const SystemCheckMaintanance = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 30px 0;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
    color: #828282;
    height: 90px;
`;
