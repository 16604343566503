import * as S from "./CheckInForm.styled";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, Dispatch, SetStateAction, useMemo, useState } from "react";
import Select from "react-select";
import { useUserStore } from "../../store/userStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router";
import { Guest } from "../../types/guest";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { SearchFields } from "../../types/advancedSearch";
import FormMessages from "./FormMessages/FormMessages";
import { residenceOptions } from "../../utils/formOptions";
import useCheckInForm from "./useCheckInform";
import { CheckInFields, SelectedGuestFormFields } from "../../types/checkIn";
import { validateDateOfBirth } from "../../utils/date";
import { useCheckinFields } from "../../store/checkInStore";
import usePopup from "../../hooks/usePopup";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
    faAddressCard,
    faArrowsRotate,
    faUserCheck,
} from "@fortawesome/pro-light-svg-icons";
import Button from "../Button";
import dayjs from "dayjs";

function CheckInForm({
    updateSuggestions,
    selectedGuest,
    setSelectedGuest,
    formFields,
    setFormFields,
}: {
    updateSuggestions: (arg: SearchFields) => void;
    selectedGuest: Guest | null;
    setSelectedGuest: Dispatch<SetStateAction<Guest | null>>;
    formFields: SelectedGuestFormFields | null;
    setFormFields: Dispatch<SetStateAction<SelectedGuestFormFields | null>>;
}) {
    const { t } = useTranslation();
    const { user } = useUserStore();
    const history = useHistory();
    const { fields } = useCheckinFields();

    const { popup } = usePopup();
    const [generatedSignature, setGeneratedSignature] = useState<null | string>(
        null
    );

    const removeSignature = () => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => setGeneratedSignature(null),
                entityName: t("signature"),
            },
        });
    };

    const addSignature = () => {
        popup({
            type: "ADD_SIGNATURE",
            data: {
                setGeneratedImage: setGeneratedSignature,
                signatureTextKey: "i_declare_bsn",
                signatureLanguage: "en",
            },
        });
    };

    const defaultValues = useMemo(
        () => ({
            residence:
                selectedGuest?.residence === "INT"
                    ? {
                          value: "INT",
                          label: t("no"),
                      }
                    : residenceOptions[0],
            bsn: "",
            date_of_birth: "",
            first_name: "",
            inserts: "",
            last_name: "",
            location_of_birth: selectedGuest?.location_of_birth || "",
        }),
        [selectedGuest, t]
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        control,
        watch,
        reset,
    } = useForm<CheckInFields>({
        defaultValues: defaultValues,
    });

    const isEdited = isDirty || selectedGuest;

    const isAlphabetic = (val: string) => {
        return val.trim().match(/^[A-Za-z]+$/);
    };

    const { isChecking, handleFieldUpdate, onSubmit, resetForm } =
        useCheckInForm(
            reset,
            setFormFields,
            setSelectedGuest,
            selectedGuest,
            updateSuggestions,
            defaultValues,
            {
                generatedSignature,
                setGeneratedSignature,
                text: t("i_declare_bsn", { lng: "en" }),
            }
        );

    useEffect(() => {
        if (Object.keys(fields).length > 0) {
            reset(fields);

            updateSuggestions({
                first_name: fields.first_name,
                last_name: fields.last_name,
                date_of_birth: fields.date_of_birth,
            });
        }
    }, [fields, reset, updateSuggestions]);

    useEffect(() => {
        if (formFields) {
            reset({
                ...defaultValues,
                first_name: formFields.first_name,
                last_name: formFields.last_name,
                inserts: formFields.inserts,
                date_of_birth: formFields.date_of_birth,
            });
        }
    }, [defaultValues, formFields, reset]);

    const residence = watch("residence");
    const last_name = watch("last_name");
    const isGuestSelected = selectedGuest !== null;

    const dateOfBirthOptional = useMemo(
        () => selectedGuest?.has_cruks_code,
        [selectedGuest]
    );

    const isSignatureRequired = useMemo(() => {
        if (!user?.current_location.has_signature_pad) {
            return false;
        }

        if (selectedGuest === null) {
            return residence?.value === "INT";
        }

        return selectedGuest.needs_to_sign_for_no_bsn;
    }, [
        residence?.value,
        selectedGuest,
        user?.current_location.has_signature_pad,
    ]);

    const minAge = user?.current_location.settings.age_requirement;

    return (
        <S.Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <S.FormControl error={errors.first_name}>
                <label>{t("first_name")}</label>
                <input
                    type="text"
                    {...register("first_name", {
                        disabled: !!selectedGuest,
                        onChange: e => {
                            handleFieldUpdate(e.target.value, "first_name");
                        },
                    })}
                    placeholder={t("example_jan")}
                />
            </S.FormControl>
            <S.FormControl error={errors.inserts}>
                <label>{t("middle_name")}</label>
                <input
                    type="text"
                    {...register("inserts", {
                        disabled: !!selectedGuest,
                        onChange: e => {
                            handleFieldUpdate(e.target.value, "inserts");
                        },
                    })}
                    placeholder={selectedGuest ? "" : t("example_van_der")}
                />
            </S.FormControl>
            <S.FormControl error={errors.last_name} required={true}>
                <label>{t("last_name")}</label>
                <input
                    type="text"
                    {...register("last_name", {
                        required: true,
                        disabled: !!selectedGuest,
                        onChange: e => {
                            handleFieldUpdate(e.target.value, "last_name");
                        },
                    })}
                    placeholder={t("example_bergen")}
                />
            </S.FormControl>
            <S.FormControl error={errors?.residence}>
                <label>{t("has_bsn")}</label>
                {selectedGuest?.has_NL_cruks_code ? (
                    <S.CheckMark>
                        <FontAwesomeIcon icon={faCheck as IconProp} />
                    </S.CheckMark>
                ) : (
                    <S.Select>
                        <Controller
                            name="residence"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select {...field} options={residenceOptions} />
                            )}
                        />
                    </S.Select>
                )}
            </S.FormControl>

            {residence?.value === "NL" && !selectedGuest?.has_NL_cruks_code && (
                <S.FormControl error={errors.bsn} required={true}>
                    <label>{t("bsn_number")}</label>

                    <input
                        type="number"
                        {...register("bsn", {
                            required: true,
                            pattern: {
                                value: /^[0-9]{9}$/,
                                message: t("bsn_must_contain_9_numbers"),
                            },
                        })}
                        placeholder="111112222"
                    />
                </S.FormControl>
            )}
            {(selectedGuest?.residence === "INT" ||
                residence?.value === "INT") && (
                <S.FormControl error={errors.location_of_birth} required={true}>
                    <label>{t("place_of_birth")}</label>
                    <input
                        type="text"
                        {...register("location_of_birth", {
                            required: true,
                            disabled: !!selectedGuest,
                        })}
                        placeholder={t("paris")}
                    />
                </S.FormControl>
            )}
            <S.FormControl
                error={errors.date_of_birth}
                required={!dateOfBirthOptional}
            >
                <label>{t("date_of_birth")}</label>
                <input
                    type="date"
                    min={dayjs().subtract(120, "years").format("YYYY-MM-DD")}
                    max={
                        minAge
                            ? dayjs()
                                  .subtract(parseInt(minAge), "years")
                                  .format("YYYY-MM-DD")
                            : undefined
                    }
                    {...register("date_of_birth", {
                        required: !dateOfBirthOptional,
                        disabled: !!selectedGuest,
                        onChange: e => {
                            handleFieldUpdate(e.target.value, "date_of_birth");
                        },
                        validate: !dateOfBirthOptional
                            ? date =>
                                  validateDateOfBirth(
                                      date,
                                      user?.current_location.settings
                                          .age_requirement as string
                                  )
                            : () => true,
                    })}
                />
            </S.FormControl>
            {(isSignatureRequired || generatedSignature) && (
                <S.FormControl>
                    <label>{t("signature")}</label>
                    {!generatedSignature && (
                        <>
                            <S.AddItem onClick={addSignature}>
                                <FontAwesomeIcon icon={faPlus as IconProp} />
                                <span>{t("add_signature")}</span>
                            </S.AddItem>
                        </>
                    )}
                    {generatedSignature && (
                        <S.SignatureContainer>
                            <img alt="signature" src={generatedSignature} />
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    removeSignature();
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes as IconProp} />
                            </button>
                        </S.SignatureContainer>
                    )}
                </S.FormControl>
            )}
            <S.FormWarnings>
                {last_name && !isAlphabetic(last_name) && (
                    <S.FormWarning>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle as IconProp}
                            size="lg"
                        />
                        <span>{t("make_sure_married_original_name")}</span>
                    </S.FormWarning>
                )}
            </S.FormWarnings>
            <FormMessages
                errors={errors}
                activeVisitLimit={selectedGuest?.active_visit_limit}
                isSignatureRequired={isSignatureRequired}
            />
            <div className="mt-14 flex justify-center">
                <div className="grid w-10/12 gap-3">
                    <Button
                        type="submit"
                        className="btn-primary col-span-2 w-full py-3"
                        isLoading={isChecking}
                    >
                        <FontAwesomeIcon icon={faUserCheck as IconProp} />
                        <span>{t("check")}</span>
                    </Button>
                    <Button
                        type="button"
                        className="btn-secondary w-full "
                        disabled={!isEdited}
                        onClick={e => {
                            e.preventDefault();
                            resetForm();
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate as IconProp} />
                        <span>{t("reset_form")}</span>
                    </Button>
                    <Button
                        type="button"
                        className="btn-secondary w-full "
                        disabled={!isGuestSelected}
                        onClick={() => {
                            history.push(`/guest/${selectedGuest?.id}`);
                        }}
                    >
                        <FontAwesomeIcon icon={faAddressCard as IconProp} />
                        <span>{t("details")}</span>
                    </Button>
                </div>
            </div>
        </S.Form>
    );
}

export default CheckInForm;
