import styled from "styled-components";
export { Block } from "../Statistics.styled";

export const StatSquares = styled.div`
    display: flex;
`;

export const StatSquare = styled.div`
    width: 120px;
    background: #cef9d7;
    border-radius: 10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
    color: #000;
    &:nth-child(2) {
        background: #ffd2cf;
    }
    &:last-child {
        margin: 0;
    }
`;

export const StatSquareNumber = styled.div`
    font-size: 36px;
    margin-bottom: 6px;
`;

export const StatSquareName = styled.div`
    font-size: 16px;
`;

export const GuestCountList = styled.div`
    margin-top: 30px;
`;

export const GuestCountListItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #000;
    &:last-child {
        margin: 0;
    }
`;
