import DefaultSelect from "./DefaultSelect";
import MultiSelect from "./MultiSelect";
import DateSelect from "./DateSelect";
import RangeSelect from "./RangeSelect";

export const renderFilter = props => {
    switch (props.type) {
        case "default":
            return <DefaultSelect {...props} />;
        case "multi":
            return <MultiSelect {...props} />;
        case "date":
            return <DateSelect {...props} />;
        case "range":
            return <RangeSelect {...props} />;
        case undefined:
            return null;
        default:
            return <div>This filter has no implementation</div>;
    }
};
