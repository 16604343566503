import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { dateOptions } from "../../utils/filterOptions";
import DataTable from "../DataTable/DataTable";
import Filter from "../Filter/Filter";
import { columns } from "../../utils/tableColumns";
import { TranslationsKeys } from "../../types/translations";
import { RootDataGuest } from "../../types/guest";
import usePopup from "../../hooks/usePopup";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import { useQueryClient } from "react-query";

const Prevention = ({ playerData }: { playerData: RootDataGuest }) => {
    const { t } = useTranslation();
    const params = useParams();
    const { prevention } = columns;
    const {
        onScroll,
        containerRef,
        isLoading,
        filteredData,
        updateFilterParams,
    } = useInfiniteScroll(`/api/guests/${params.id}/prevention?page=1`);

    const queryClient = useQueryClient();
    const { popup } = usePopup();
    const { customToast } = useCustomToast();
    const preventionTypes = playerData.prevention_types.map(label => {
        return {
            label: t(label as TranslationsKeys),
            params: {
                types: [label],
            },
        };
    });

    const deleteItem = (id: number) => {
        client
            .delete(`api/prevention-plan-records/${id}`)
            .then(res => {
                customToast("success", {
                    title: t(`entity_successfully_deleted`, {
                        entity: t("prevention_plan"),
                    }),
                });
                queryClient.invalidateQueries(
                    `guests/${params.id}/prevention?page=1`
                );
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    const contextMenuItems = [
        {
            title: t("delete_talk"),
            action: (data: { id: number }) => {
                popup({
                    type: "DELETE_ENTITY",
                    data: {
                        deleteHandler: () => deleteItem(data.id),
                        entityName: t("record"),
                    },
                });
            },
        },
    ];
    const filters = [
        {
            type: "date",
            label: t("date"),
            options: dateOptions,
        },
        {
            type: "multi",
            key: "type",
            label: t("type"),
            options: preventionTypes,
        },
    ];

    return (
        <div>
            <Filter
                filters={filters}
                updateFilterParams={updateFilterParams}
                updatedAppliedFilters={undefined}
                filterId={undefined}
            />
            <DataTable
                columns={prevention}
                data={filteredData}
                onScroll={onScroll}
                containerRef={containerRef}
                tableType="guest"
                pagination={false}
                isLoading={isLoading}
                onClick={undefined}
                name={undefined}
                contextMenuItems={contextMenuItems}
                updateSortParams={undefined}
                verticalAlign="top"
            />
        </div>
    );
};

export default Prevention;
