import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import DataTable from "../DataTable/DataTable";
import { columns } from "../../utils/tableColumns";
import usePopup from "../../hooks/usePopup";
import client from "../../client";
import { useQueryClient } from "react-query";
import useCustomToast from "../../hooks/useCustomToast";

const GuestMessages = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { popup } = usePopup();
    const { guestMessage } = columns;
    const { onScroll, containerRef, isLoading, filteredData } =
        useInfiniteScroll(`/api/players/${params.id}/messages?active=1`);
    const history = useHistory();
    const queryClient = useQueryClient();
    const { customToast } = useCustomToast();

    const deleteItem = (id: number, player: number) => {
        client
            .delete(`api/players/messages/${id}`)
            .then(res => {
                customToast("success", {
                    title: t(`entity_successfully_deleted`, {
                        entity: t("check_in_message"),
                    }),
                });

                queryClient.invalidateQueries(
                    `players/${player}/messages?active=1`
                );
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    const contextMenuItems = [
        {
            title: t("edit_message"),
            action: (data: { id: number; player_id: number }) => {
                let params = new URLSearchParams(history.location.search);
                params.set("action", "edit_check_in_message");
                history.push({
                    pathname: history.location.pathname,
                    search: `?${params.toString()}&id=${data.id}`,
                });
            },
        },
        {
            title: t("delete_message"),
            action: (data: { id: number; player_id: number }) => {
                popup({
                    type: "DELETE_ENTITY",
                    data: {
                        deleteHandler: () =>
                            deleteItem(data.id, data.player_id),
                        entityName: t("record"),
                    },
                });
            },
        },
    ];

    return (
        <DataTable
            columns={guestMessage}
            data={filteredData}
            onScroll={onScroll}
            containerRef={containerRef}
            tableType="guest"
            pagination={false}
            isLoading={isLoading}
            onClick={undefined}
            name={undefined}
            contextMenuItems={contextMenuItems}
            updateSortParams={undefined}
            verticalAlign="top"
        />
    );
};

export default GuestMessages;
