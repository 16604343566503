import { Table as TableType, flexRender } from "@tanstack/react-table";
import Loading from "./Loading";

export default function Table<T>({
    table,
    onRowClick,
    isLoading,
}: {
    table: TableType<T>;
    onRowClick: (row: T) => void;
    isLoading?: boolean;
}) {
    if (isLoading) {
        return (
            <div className="flex h-full items-center justify-center">
                <Loading />
            </div>
        );
    }

    return (
        <table className="min-w-full divide-y divide-gray-500">
            <thead className="sticky top-0 bg-[#FFFBFB] shadow-md">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th
                                key={header.id}
                                className="py-3.5 px-2 text-left text-xl font-normal text-gray-900"
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className="divide-y divide-gray-200 ">
                {table.getRowModel().rows.map(row => (
                    <tr
                        key={row.id}
                        className="hover:cursor-pointer hover:bg-[#F8F6F1]"
                        onClick={() => onRowClick(row.original)}
                    >
                        {row.getVisibleCells().map(cell => (
                            <td
                                key={cell.id}
                                className="whitespace-nowrap py-2 px-2 text-base font-normal text-gray-900"
                            >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.footer,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
    );
}
