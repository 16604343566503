import styled from "styled-components";

export const Box = styled.div`
    width: 500px;
    max-width: 100%;
    background: #fffbfb;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 400px;
`;

export const Header = styled.div`
    padding: 0 10px 20px;
    border-bottom: 1px solid #bdbdbd;
    font-size: 24px;
    color: ${({ theme }) => theme.main.primaryColor};
`;

export const ScanOptions = styled.div`
    display: flex;
    margin: 40px 0 50px 0;
`;

export const ScanContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    height: 200px;
    span {
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
    }
`;

export const Bottom = styled.div`
    margin-top: auto;
    text-align: center;
    padding-top: 20px;
    button {
        ${({ isActive }: { isActive: boolean }) =>
            !isActive &&
            `
            opacity: 0.5;
            pointer-events: none;
            `}
    }
`;

export const ScanImage = styled.div`
    display: flex;
    align-items: center;
    height: 200px;
    > svg {
        font-size: 60px;
        color: ${({ theme }) => theme.main.successColor};
    }
`;

export const Devices = styled.div`
    margin-top: 30px;
`;

export const DevicesHeader = styled.div`
    font-size: 20px;
    margin-bottom: 20px;
`;

export const DevicesBody = styled.div``;

export const DeviceId = styled.div`
    p {
        font-weight: bold;
    }
    span {
        margin-top: 6px;
        font-size: 14px;
    }
`;

export const Device = styled.button<{ isActive: boolean }>`
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 70px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    padding: 0 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
    width: 100%;
    transition: 0.2s ease-in-out;
    font-size: 18px;
    text-align: left;
    ${({ isActive }) =>
        isActive &&
        `        
        background: #1d1e45;
        color: #fff;
    `}
    &:hover {
        background: #1d1e45;
        color: #fff;
    }
    &:last-child {
        margin: 0;
    }
`;
