import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./lang/i18n";
import { ThemeProvider } from "styled-components";
import Container from "./components/containers/Container";
import theme from "./style/theme";
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Toaster } from "react-hot-toast";
import { ReactNode } from "react";

// Dynamic icons for messages
import {
    faExclamationTriangle,
    faSackDollar,
    faBellOn,
    faExchangeAlt,
    faCommentAltLines,
    faUser,
    faAddressBook,
    faQuestion,
} from "@fortawesome/pro-light-svg-icons";
library.add(
    faExclamationTriangle as IconDefinition,
    faSackDollar as IconDefinition,
    faBellOn as IconDefinition,
    faExchangeAlt as IconDefinition,
    faCommentAltLines as IconDefinition,
    faUser as IconDefinition,
    faAddressBook as IconDefinition,
    faQuestion as IconDefinition
);

// temp fix for react 18 type definitions
declare module "react-query/types/react/QueryClientProvider" {
    interface QueryClientProviderProps {
        children?: ReactNode;
    }
}

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <Toaster position="top-right" />
                        <Container />
                    </Router>
                </QueryClientProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
