import styled from "styled-components";
export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 30px;
    @media screen and (max-width: ${({ theme }) => theme.layout.bpXXL}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const Block = styled.div`
    padding: 20px;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
`;
