import styled from "styled-components";

export {
    Block,
    BlockHeader,
    BlockContent,
} from "../../style/shared/block.styled";

export { MenuItem, MenuIcon } from "../../style/shared/settingsMenu.styled";

export const Customize = styled.div`
    display: flex;
    > div {
        &:nth-child(1) {
            flex: 0 0 60%;
        }
        &:nth-child(2) {
            flex-grow: 1;
            margin-left: 40px;
        }
    }
`;

export const AppearanceContent = styled.div`
    display: flex;
`;

export const AppearanceMenu = styled.div`
    flex: 0 0 50%;
`;

export const AppearanceSection = styled.div`
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    min-height: 500px;
`;

export const ButtonContainer = styled.div`
    margin-top: auto;
`;
