import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import backgroundLoginImage from "../../assets/backgroundLogin.jpg";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/logo.png";
import client from "../../client";
import { useUserStore } from "../../store/userStore";
import {
    faExclamationCircle,
    faEye,
    faEyeSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { TranslationsKeys } from "../../types/translations";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "../../components/Button";

function FormControl({ children }: { children: React.ReactNode }) {
    return <div className="login-form-control">{children}</div>;
}

const Login = () => {
    const { setUser } = useUserStore();
    const { t } = useTranslation();
    const history = useHistory();
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [loginType, setLoginType] = useState<{
        value: string;
        label: string;
    }>({
        value: window.localStorage.getItem("loginType") ?? "SignInWithUsername",
        label: t(
            (window.localStorage.getItem(
                "loginTypeLabel"
            ) as TranslationsKeys) ?? "username_plus_password"
        ) as string,
    });
    const [showSelect, setShowSelect] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [tagNumber, setTagNumber] = useState("");
    const [warning, setWarning] = useState(false);

    const userLoginType = [
        { value: "SignInWithUsername", label: t("username_plus_password") },
        { value: "SignInWithTag", label: t("casino_employee_tag") },
    ];

    const selectRef = useRef(null);
    useOnClickOutside(selectRef, () => setShowSelect(false));

    const togglePassword = () => {
        setIsPasswordShown(prev => !prev);
    };

    const handleEnter = (e: React.KeyboardEvent) => {
        e.key === "Enter" && handleSubmit();
    };

    const handleSubmit = () => {
        if (loginType.value === "SignInWithUsername" && username && password) {
            client
                .post("/login", {
                    username: username,
                    password: password,
                })
                .then(res => {
                    setUser(res.data.user);

                    if (res.data.user.locations.length > 1) {
                        history.push("/welcome");
                    } else {
                        history.push("/check-in");
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            setShowErrorMessage(true);
                        }
                    }
                });
        }
        if (loginType.value === "SignInWithTag" && tagNumber) {
            client
                .post("/api/tag-login", {
                    tag_number: tagNumber,
                })
                .then(res => {
                    setUser(res.data.user);

                    if (res.data.user.locations.length > 1) {
                        history.push("/welcome");
                    } else {
                        history.push("/check-in");
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            setShowErrorMessage(true);
                        }
                    }
                });
        }
    };

    const onKeyDown = (keyEvent: React.KeyboardEvent) => {
        if (keyEvent.getModifierState("CapsLock")) {
            setWarning(true);
        } else {
            setWarning(false);
        }
        keyEvent.key === "Enter" && handleSubmit();
    };

    return (
        <div className="h-screen w-screen bg-black bg-opacity-60">
            <div className="relative flex h-screen w-full flex-col content-center items-center">
                <div
                    className="absolute top-0 left-0 right-0 z-[-1] block h-full w-full"
                    style={{
                        backgroundImage: `url(${backgroundLoginImage})`,
                        backgroundSize: "cover",
                        filter: "blur(13px)",
                        backgroundPositionY: "-300px",
                    }}
                ></div>

                <div className="m-auto">
                    <img src={Logo} alt="logo" className="mb-[100px]" />
                    <div className="max-w-500 relative flex w-full flex-col items-center">
                        {showErrorMessage && (
                            <div className="absolute top-[-90px] flex w-[350px] items-center justify-center rounded-xl bg-white bg-opacity-70 p-[14px]">
                                <FontAwesomeIcon
                                    icon={faExclamationCircle as IconProp}
                                    className="text-[#eb5757} mr-[12px] text-[26px]"
                                    style={
                                        {
                                            "--fa-primary-color": "#fff",
                                            "--fa-secondary-color": "#eb5757",
                                            "--fa-primary-opacity": "0.8",
                                            "--fa-secondary-opacity": "1.0",
                                        } as React.CSSProperties
                                    }
                                />
                                <div>
                                    <span className="mb-[6px] block text-[22px] text-[#eb5757]">
                                        {t("the_given_data_was_invalid")}
                                    </span>
                                    <span className="block text-[14px]">
                                        {t("please_contact_your_administrator")}
                                    </span>
                                </div>
                            </div>
                        )}
                        {loginType.value === "SignInWithUsername" && (
                            <>
                                <FormControl>
                                    <input
                                        placeholder={t("username")}
                                        value={username}
                                        onChange={e => {
                                            setShowErrorMessage(false);
                                            setUsername(e.target.value);
                                        }}
                                        autoFocus
                                        onKeyDown={handleEnter}
                                    />
                                </FormControl>
                                <FormControl>
                                    <input
                                        placeholder={t("password")}
                                        type={
                                            isPasswordShown
                                                ? "text"
                                                : "password"
                                        }
                                        value={password}
                                        onChange={e => {
                                            setShowErrorMessage(false);
                                            setPassword(e.target.value);
                                        }}
                                        onKeyDown={onKeyDown}
                                        onKeyUp={onKeyDown}
                                    />

                                    {isPasswordShown ? (
                                        <FontAwesomeIcon
                                            icon={faEye as IconProp}
                                            style={
                                                {
                                                    "--fa-primary-color":
                                                        "#fff",
                                                    "--fa-secondary-color":
                                                        "#fff",
                                                    "--fa-primary-opacity":
                                                        "1.0",
                                                    "--fa-secondary-opacity":
                                                        "0.9",
                                                } as React.CSSProperties
                                            }
                                            size="lg"
                                            onClick={togglePassword}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faEyeSlash as IconProp}
                                            style={
                                                {
                                                    "--fa-primary-color":
                                                        "#fff",
                                                    "--fa-secondary-color":
                                                        "#fff",
                                                    "--fa-primary-opacity":
                                                        "1.0",
                                                    "--fa-secondary-opacity":
                                                        "0.9",
                                                } as React.CSSProperties
                                            }
                                            size="lg"
                                            onClick={togglePassword}
                                        />
                                    )}
                                </FormControl>
                                {warning && (
                                    <div className="text-white [&>svg]:mr-1">
                                        <FontAwesomeIcon
                                            icon={
                                                faExclamationCircle as IconProp
                                            }
                                            style={
                                                {
                                                    "--fa-primary-color":
                                                        "#fff",
                                                    "--fa-secondary-color":
                                                        "#eb5757",
                                                    "--fa-primary-opacity":
                                                        "0.8",
                                                    "--fa-secondary-opacity":
                                                        "1.0",
                                                } as React.CSSProperties
                                            }
                                        />
                                        {/* {t("caps_lock_is_on")} */}
                                    </div>
                                )}
                            </>
                        )}

                        {loginType.value === "SignInWithTag" && (
                            <FormControl>
                                <input
                                    placeholder={`${t("scan_tag_now")}...`}
                                    type="text"
                                    id="tagNumber"
                                    value={tagNumber}
                                    onChange={e => setTagNumber(e.target.value)}
                                    autoFocus
                                    ref={element => element?.focus?.()}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            {/* Temporarily hide sign in method select */}
                            {
                                <div
                                    onClick={() => {
                                        setShowSelect(prev => !prev);
                                    }}
                                    className="flex h-[45px] w-[350px] cursor-pointer select-none flex-row items-center justify-start gap-[20px] text-white"
                                >
                                    {loginType.label}
                                    <FontAwesomeIcon
                                        icon={faChevronDown as IconProp}
                                    />
                                </div>
                            }
                            {showSelect && (
                                <div
                                    className="absolute top-[40px] flex w-[300px] cursor-pointer select-none flex-col rounded-sm bg-white text-black"
                                    ref={selectRef}
                                >
                                    {userLoginType.map(loginType => {
                                        return (
                                            <>
                                                <div
                                                    className="w-full select-none rounded-sm bg-transparent bg-opacity-30 p-5 hover:bg-gray-200"
                                                    key={loginType.value}
                                                    onClick={() => {
                                                        window.localStorage.setItem(
                                                            "loginType",
                                                            loginType.value
                                                        );
                                                        window.localStorage.setItem(
                                                            "loginTypeLabel",
                                                            loginType.label
                                                        );
                                                        setLoginType({
                                                            value: loginType.value,
                                                            label: loginType.label,
                                                        });
                                                        setShowSelect(
                                                            prev => !prev
                                                        );
                                                        if (
                                                            loginType.value ===
                                                            "SignInWithTag"
                                                        ) {
                                                        }
                                                    }}
                                                >
                                                    {
                                                        t(
                                                            loginType.label as TranslationsKeys
                                                        ) as string
                                                    }
                                                </div>
                                                <div className="m-auto h-[1px] w-[90%] bg-[#bdbdbd] last-of-type:hidden"></div>
                                            </>
                                        );
                                    })}
                                </div>
                            )}
                        </FormControl>
                        <Button
                            className="btn-primary"
                            onClick={() => handleSubmit()}
                        >
                            {t("sign_in")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
