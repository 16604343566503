import PageHeader from "../../components/PageHeader";
import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import Filter from "../../components/Filter";
import { dateOptions } from "../../utils/filterOptions";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";

function FacilitiesCheckOverview() {
    const { t } = useTranslation();
    const history = useHistory();

    const {
        onScroll,
        filteredData,
        updateFilterParams,
        updateSortParams,
        containerRef,
        isLoading,
    } = useInfiniteScroll("/api/facility-checks");

    const tableColumns = columns.checklists;

    const pageHeaderButtons = [
        {
            text: t("perform_check"),
            clickHandler: () => history.push("/facilities-check/new"),
            type: "pageHeader",
            faIcon: faListCheck,
        },
    ];

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    const getChecklists = checklists =>
        checklists.map(checklist => ({
            ...checklist,
            created_at:
                checklist.created_at &&
                dayjs(checklist.created_at).format("DD-MM-YYYY HH:mm"),
            status: `${checklist.facility_check_items_checked_count}/${checklist.facility_check_items_count}`,
        }));

    const handleTableClick = data => {
        history.push(`/facilities-check/${data.id}`);
    };

    const reversedFilteredData = [...filteredData].reverse();

    return (
        <>
            <PageHeader
                title={t("facilities_check_overview")}
                buttons={pageHeaderButtons}
            />
            <Filter filters={filters} updateFilterParams={updateFilterParams} />
            <DataTable
                columns={tableColumns}
                data={getChecklists(reversedFilteredData)}
                onClick={handleTableClick}
                name={t("checklists")}
                containerRef={containerRef}
                pagination={false}
                onScroll={onScroll}
                updateSortParams={updateSortParams}
                isLoading={isLoading}
            />
        </>
    );
}

export default FacilitiesCheckOverview;
