import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../store/userStore";
import { prepareOptionsForSelect } from "../../utils/locations";
import {
    endDateOptions,
    locationOptions,
    reasonOptionsPlayPause,
} from "../../utils/formOptions";
import Select from "react-select";
import { SelectOption } from "../../types/form";
import { classNames } from "../../utils/style";
import useSelectPeriodAndDate from "../../hooks/useSelectPeriodAndDate";
import MultiSelect from "../MultiSelect/MultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import day from "dayjs";
import { convertBase64ToBlob } from "../../utils/convert";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import { useHistory, useParams } from "react-router";
import useFormResponseError from "../../hooks/useFormResponseError";
import usePopup from "../../hooks/usePopup";
import useSignature from "../../hooks/useSignature";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useQuery, useQueryClient } from "react-query";
import { RootDataGuest } from "../../types/guest";
import { TranslationsKeys } from "../../types/translations";
import { FileObject } from "../../types/note";

type LocationSelect = {
    label: string;
    value: string;
    id: number;
};

type Location = {
    id: number;
    name: string;
};

type EntryCode = {
    label: string;
    value: string;
    text?: number;
};

type FormFields = {
    player_id: string;
    reason: EntryCode;
    description: string;
    location: string;
    locations: LocationSelect[];
    end_date_options: SelectOption;
    end_date: string;
};

type RequestFields = {
    player_id: string;
    start_date: string;
    end_date: string;
    entry_note: string;
    entry_code: string;
    locations?: number[];
    global?: 0 | 1;
    signature_src?: Blob | null;
};

type PlaypauseRecord = {
    id: number;
    player_id: number;
    player: string;
    player_photo: string;
    added_by: string;
    entry_code: string;
    entry_note: string;
    removal_reason?: string;
    removal_note?: string;
    start_date: string;
    end_date: string;
    files: FileObject[];
    locations?: Location[];
    global: number;
    signature?: Blob | null;
};

const PlayPauseAction = ({ playerData }: { playerData: RootDataGuest }) => {
    const { user } = useUserStore();
    const { t } = useTranslation();
    const { formResponseError } = useFormResponseError();
    const { customToast } = useCustomToast();
    const { popup } = usePopup();
    const { hasConnectedDevice } = useSignature();
    const history = useHistory();
    const { id: playerId } = useParams<{ id: string }>();
    const [generatedSignature, setGeneratedSignature] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();
    const [playpause, setPlaypause] = useState<PlaypauseRecord>();

    const { data: playPauseData, isLoading } = useQuery(
        [`/whitelist-records/${playerData?.whitelist?.id}`],
        async () => {
            try {
                const playPauseData = await client.get(
                    `/api/whitelist-records/${playerData?.whitelist?.id}`
                );

                return playPauseData.data.data;
            } catch (err: any) {
                customToast("error", {
                    title: t("error"),
                    body: err.message,
                });
            }
        },
        {
            enabled: !!playerData?.whitelist?.id,
        }
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        watch,
        setError,
    } = useForm<FormFields>({
        defaultValues: {
            location: "all_locations",
            locations: prepareOptionsForSelect(
                user?.locations.filter(
                    location => location.id === user.current_location_id
                )
            ),
        },
    });

    const removeSignature = () => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => setGeneratedSignature(null),
                entityName: t("signature"),
            },
        });
    };

    const addSignature = () => {
        popup({
            type: "ADD_SIGNATURE",
            data: {
                setGeneratedImage: setGeneratedSignature,
                signatureTextKey: "signature_playpause_text",
            },
        });
    };

    const onSubmit: SubmitHandler<FormFields> = async data => {
        if (!user) return;
        setIsSubmitting(true);

        const formData = new FormData();

        if (playpause) {
            formData.append("_method", "PUT");
        }

        const fields: RequestFields = {
            player_id: playerId.toString(),
            start_date: day().format("YYYY-MM-DD"),
            end_date: data.end_date,
            entry_note: data.description,
            entry_code: data.reason.value,
        };

        Object.entries(fields).forEach(([key, value]) => {
            if (value !== null) {
                formData.append(key, value as string | Blob);
            }
        });

        if (user?.locations.length === 1) {
            data.location = "all_locations";
        }

        switch (data.location) {
            default:
            case "this_location":
                formData.append(
                    "locations[]",
                    user.current_location_id.toString()
                );
                break;
            case "all_locations":
                formData.append("global", "1");
                break;
            case "select_locations_manually":
                formData.append("global", "0");
                data.locations?.forEach(location =>
                    formData.append("locations[]", location?.id.toString())
                );
                break;
        }

        if (generatedSignature) {
            const signatureBlob = await convertBase64ToBlob(generatedSignature);
            fields.signature_src = signatureBlob;
        }

        const requestMap = {
            put: {
                endpoint: `/api/whitelist-records/${playpause?.id}`,
                succesText: t(`entity_successfully_updated`, {
                    entity: t("playpause"),
                }),
            },
            post: {
                endpoint: `/api/whitelist-records`,
                succesText: t(`entity_successfully_created`, {
                    entity: t("playpause"),
                }),
            },
        };

        const type = playpause ? "put" : "post";

        client["post"](requestMap[type].endpoint, formData)
            .then(res => {
                queryClient.invalidateQueries(`player/${playerId.toString()}`);
                history.push(
                    `/guest/${playerId.toString()}?action=activity_overview`
                );

                customToast("success", {
                    title: t("success_playpause", {
                        entity: res.data[0].player,
                    }),
                    link: {
                        title: t("view_guest"),
                        url: `/guest/${playerId}`,
                    },
                });
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => setIsSubmitting(false));
    };

    const updateDayValue = useCallback(
        (dayValue: string) => {
            setValue("end_date", dayValue);
        },
        [setValue]
    );

    const { selectDateOption, dateOption } =
        useSelectPeriodAndDate(updateDayValue);

    useEffect(() => {
        if (!isLoading && playPauseData) {
            setPlaypause(playPauseData);
            selectDateOption(playpause?.end_date ? "custom" : "indefinitely");
        }
    }, [isLoading, playpause, reset, playPauseData, selectDateOption]);

    useEffect(() => {
        const defaultFormValues: Partial<FormFields> = playpause
            ? {
                  location: playpause.global
                      ? "all_locations"
                      : "select_locations_manually",

                  description: playpause.entry_note,
                  reason: {
                      value: playpause.entry_code,
                      label: t(
                          playpause.entry_code as TranslationsKeys
                      ) as string,
                  },

                  locations:
                      playpause?.locations?.map((location: Location) => ({
                          value: location.name,
                          label: location.name,
                          id: location.id,
                      })) || [],

                  end_date: playpause.end_date
                      ? day(playpause?.end_date).format("YYYY-MM-DD")
                      : "",

                  end_date_options: playpause?.end_date
                      ? {
                            value: "custom_end_date",
                            label: t(
                                "custom_end_date" as TranslationsKeys
                            ) as string,
                        }
                      : { value: "indefinitely", label: t("indefinitely") },
              }
            : {};

        reset(defaultFormValues);
    }, [reset, t, playpause, isLoading, playPauseData]);

    const location = watch("location");

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control required">
                <label className="form-label">{t("end_date")}:</label>
                <Controller
                    name="end_date_options"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <Select
                                {...field}
                                options={endDateOptions}
                                placeholder={t("select_period")}
                                onChange={(option: SelectOption) => {
                                    field.onChange(option);
                                    selectDateOption(option.value as string);
                                }}
                                className={classNames(
                                    "form-select",
                                    fieldState.error !== undefined && "error"
                                )}
                            />
                        );
                    }}
                />

                <input
                    className="form-input"
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    {...register("end_date", {
                        required: dateOption !== "indefinitely",
                    })}
                    readOnly={dateOption !== "custom"}
                />
            </div>
            <div className="form-control required">
                <label className="form-label">{t("initiative_by")}:</label>
                <div className="form-select col-span-2">
                    <Controller
                        name="reason"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    options={reasonOptionsPlayPause}
                                    onChange={field.onChange}
                                    placeholder={t("select_reason") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                    className={classNames(
                                        "form-select",
                                        fieldState.error !== undefined &&
                                            "error"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("description")}:</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="description"
                        placeholder={t("notes") + ".."}
                        {...register("description")}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("location")}</label>
                <div className="col-span-2">
                    {prepareOptionsForSelect(user?.locations).length > 1 &&
                        locationOptions.map(option => (
                            <div
                                className="form-radio-container col-span-2"
                                key={option.value}
                            >
                                <input
                                    type="radio"
                                    id={option.value}
                                    value={option.value}
                                    {...register("location")}
                                    name="location"
                                />
                                <label
                                    className="[&_span]:font-bold [&_span]:underline"
                                    htmlFor={option.value}
                                    dangerouslySetInnerHTML={{
                                        __html: option.label,
                                    }}
                                ></label>
                            </div>
                        ))}
                </div>
            </div>
            {location === "select_locations_manually" &&
                prepareOptionsForSelect(user?.locations).length > 1 && (
                    <div className="form-control">
                        <div className="col-span-2 col-start-2">
                            <Controller
                                name="locations"
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <MultiSelect
                                            onChange={field.onChange}
                                            options={prepareOptionsForSelect(
                                                user?.locations
                                            )}
                                            placeholder={
                                                t("add_locations") + ".."
                                            }
                                            hasError={
                                                fieldState.error !== undefined
                                            }
                                            value={field.value}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}{" "}
            {(hasConnectedDevice || generatedSignature) && (
                <div className="form-control">
                    <label>{t("signature")}</label>
                    {!generatedSignature && (
                        <>
                            <div
                                className="flex items-center hover:cursor-pointer"
                                onClick={() => addSignature()}
                            >
                                <FontAwesomeIcon
                                    className="mr-2.5"
                                    icon={faPlus as IconProp}
                                />
                                <span>{t("add_signature")}</span>
                            </div>
                        </>
                    )}
                    {generatedSignature && (
                        <div className="border-1 relative border-[#e0e0e0]">
                            <img
                                alt="signature"
                                src={generatedSignature}
                                className="h-44 w-[300px]"
                            />
                            <button
                                className="absolute top-2 right-3.5 text-xl"
                                onClick={e => {
                                    e.preventDefault();
                                    removeSignature();
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes as IconProp} />
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2 ml-2.5">
                    <button
                        type="submit"
                        className="btn-primary mr-3 mb-3"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner as IconProp}
                                className="fa-spin"
                            />
                        ) : playpause ? (
                            t("update_playpause")
                        ) : (
                            t("add_playpause")
                        )}
                    </button>
                    {playerData?.player?.is_white_listed && (
                        <button
                            type="button"
                            className="btn-secondary"
                            disabled={isSubmitting}
                            onClick={() =>
                                popup({
                                    type: "WHITELIST_EXPIRE_TALK",
                                    data: {
                                        recordId:
                                            playerData.player
                                                .whitelist_record_id,
                                        playerId: playerData.player.id,
                                    },
                                })
                            }
                        >
                            {t("remove_playpause")}
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};

export default PlayPauseAction;
