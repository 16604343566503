import { useState, useMemo, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { RootDataGuest } from "../../types/guest";
import Activity from "../Activity/Activity";
import BlacklistAction from "../playerActions/BlacklistAction";
import PreventionAction from "../playerActions/PreventionAction";
import PlayPauseAction from "../playerActions/PlayPauseAction";
import Prevention from "../Prevention/Prevention";
import CheckInMessageAction from "../playerActions/CheckInMessageAction";
import VisitLimitAction from "../playerActions/VisitLimitAction";
import GuestMessages from "../GuestMessages/GuestMessages";
import GuestNotes from "../GuestNotes/GuestNotes";
import GuestNoteAction from "../playerActions/GuestNoteAction";

type Tabs = {
    label: string;
    count: number;
    key: string;
};

type View = {
    [key: string]: {
        label: string;
        component: JSX.Element;
        type: "tab" | "action";
    };
};

const PlayerActionContainer = ({
    playerData,
}: {
    playerData: RootDataGuest;
}) => {
    const [activeTab, setActiveTab] = useState("activity_overview");
    const location = useLocation();
    const history = useHistory();
    const actionParams = new URLSearchParams(location.search);
    const actionParamsValue =
        (actionParams.get("action") as keyof typeof viewMap) ||
        "activity_overview";
    const { t } = useTranslation();

    const viewMap: View = useMemo(() => {
        return {
            activity_overview: {
                label: t("activity"),
                component: <Activity playerData={playerData} />,
                type: "tab",
            },
            prevention_overview: {
                label: t("prevention"),
                component: <Prevention playerData={playerData} />,
                type: "tab",
            },
            statistic_overview: {
                label: t("statistic"),
                component: <div>statistic overview component</div>,
                type: "tab",
            },
            prevention_action: {
                label: t("add_prevention_action"),
                component: <PreventionAction />,
                type: "action",
            },
            visit_limit: {
                label: playerData.player.active_visit_limit
                    ? t("update_visit_limit")
                    : t("add_visit_limit"),
                component: <VisitLimitAction />,
                type: "action",
            },
            playpause: {
                label: playerData.player.whitelist_record_id
                    ? t("update_playpause")
                    : t("add_playpause"),
                component: <PlayPauseAction playerData={playerData} />,
                type: "action",
            },
            blacklist: {
                label: playerData.player.blacklist_record_id
                    ? t("update_blacklist")
                    : t("add_blacklist"),
                component: <BlacklistAction playerData={playerData} />,
                type: "action",
            },
            check_in_message: {
                label: t("add_check_in_message"),
                component: <CheckInMessageAction />,
                type: "action",
            },
            edit_check_in_message: {
                label: t("edit_check_in_message"),
                component: <CheckInMessageAction />,
                type: "action",
            },
            export_guest_info: {
                label: t("export_guest_info"),
                component: <div>export guest info component</div>,
                type: "action",
            },
            guest_messages: {
                label: t("guest_messages"),
                component: <GuestMessages />,
                type: "action",
            },
            guest_notes: {
                label: t("guest_notes"),
                component: <GuestNotes />,
                type: "action",
            },
            note: {
                label: t("add_guest_note"),
                component: <GuestNoteAction />,
                type: "action",
            },
            edit_guest_note: {
                label: t("edit_guest_note"),
                component: <GuestNoteAction />,
                type: "action",
            },
        };
    }, [playerData, t]);

    useEffect(() => {
        Object.entries(viewMap).forEach(([key, value]) => {
            if (key === actionParamsValue && value.type === "tab") {
                setActiveTab(actionParamsValue);
            }
        });
    }, [actionParamsValue, viewMap]);

    const updateActionParam = (value: string) => {
        const params = new URLSearchParams();

        params.set("action", value);

        history.push({
            pathname: history.location.pathname,
            search: `?${params.toString()}`,
        });
    };

    const tabs = [
        {
            key: "activity_overview",
            label: t("activity"),
            count: playerData.activity_count,
        },
        {
            key: "prevention_overview",
            label: t("prevention"),
            count: playerData.prevention_count,
        },
        // {
        //     key: "statistic_overview",
        //     label: t("statistic"),
        //     count: 0, // change this when this data is available
        // },
    ];

    return (
        <div className="relative row-start-1 row-end-6 rounded-lg bg-[#FFFBFB] shadow-md">
            <div className="flex space-x-1">
                {viewMap[actionParamsValue]?.type === "action" ? (
                    <div className="relative flex h-12 w-full cursor-pointer items-center justify-center rounded-t-lg bg-dark-secondary text-xl text-[#FFFBFB]">
                        <FontAwesomeIcon
                            icon={faChevronCircleLeft as IconProp}
                            size="lg"
                            className="absolute left-6"
                            onClick={() => {
                                updateActionParam(activeTab);
                            }}
                        />
                        {viewMap[actionParamsValue].label}
                    </div>
                ) : (
                    tabs.map((tab: Tabs) => (
                        <div
                            key={tab.key}
                            onClick={() => {
                                updateActionParam(tab.key);
                                setActiveTab(tab.key);
                            }}
                            className={`flex h-12 w-full cursor-pointer items-center justify-center rounded-t-lg ${
                                actionParamsValue === tab.key
                                    ? "bg-dark-secondary "
                                    : " bg-[#BBC5D1] text-dark-secondary hover:bg-[#0D698B] hover:text-[#FFFBFB]"
                            } text-xl text-[#FFFBFB]`}
                        >
                            {tab.label} ({tab.count})
                        </div>
                    ))
                )}
            </div>
            {actionParamsValue && (
                <div className="p-6">
                    {actionParamsValue && viewMap[actionParamsValue]?.component}
                </div>
            )}
        </div>
    );
};

export default PlayerActionContainer;
