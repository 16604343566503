import styled from "styled-components";

export const BlockBody = styled.div`
    padding: 15px 20px;
`;
export const BlockTitle = styled.div`
    color: #fffbfb;
    background: #1d1e45;
    border-radius: 4px 4px 0 0;
    font-size: 20px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    > button {
        color: #fffbfb;
    }
`;

export const HeaderVersionInformation = styled.div`
    font-size: 20px;
    :nth-child(1) {
        grid-column: span 1 / span 1;
    }
    :nth-child(2) {
        grid-column: span 3 / span 3;
    }
    :nth-child(3) {
        grid-column: span 1 / span 1;
    }
`;

export const HeadersVersion = styled.div`
    border-bottom: 1px solid #050505;
    padding: 10px 10px 10px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
`;

export const VersionInformationBlocks = styled.div`
    grid-column: span 2 / span 3;
    background: yellow;
    background: #fffbfb;
    box-shadow: 0px 2px 11px rgba(29, 30, 69, 0.15);
    border-radius: 4px;
    height: 100%;
    max-height: 450px;
`;

export const VersionInformation = styled.div<{
    versionsToShow: number;
}>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0 10px;
    max-height: ${({ versionsToShow }) => versionsToShow <= 3 && "300px"};

    height: auto;
    overflow-y: ${({ versionsToShow }) => versionsToShow <= 3 && "scroll"};

    > * {
        border-bottom: 1px solid #e0e0e0;
        height: auto;
    }
`;

export const Version = styled.div`
    grid-column: span 1 / span 1;
    font-size: 16px;
    padding: 30px 0;
    line-height: 25px;
`;

export const VersionDetails = styled.div`
    grid-column: span 3 / span 3;
    font-size: 16px;
    padding: 30px 0;
    line-height: 25px;

    ul {
        list-style: inside;
        list-style-type: disc;
    }
    li {
        list-style: inside;
        list-style-type: disc;
    }
`;

export const VersionUpdate = styled.div`
    grid-column: span 1 / span 1;
    font-size: 16px;
    padding: 30px 0;
    line-height: 25px;
`;
