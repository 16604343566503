import styled from "styled-components";
import { Status } from "./types";

export const Box = styled.div`
    width: 500px;
    max-width: 100%;
    background: #fffbfb;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 360px;
`;

export const Header = styled.div`
    padding: 0 10px 20px;
    border-bottom: 1px solid #bdbdbd;
    font-size: 24px;
    color: ${({ theme }) => theme.main.primaryColor};
`;

export const ScanOptions = styled.div`
    display: flex;
    margin: 40px 0 50px 0;
`;

export const ScanContent = styled.div`
    span {
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
        width: 300px;
        display: block;
        margin: 0 auto;
    }
`;

export const Bottom = styled.div`
    margin-top: auto;
    text-align: center;
    padding-top: 20px;
    button {
        ${({ isActive }: { isActive: boolean }) =>
            !isActive &&
            `
            opacity: 0.5;
            pointer-events: none;
            `}
    }
`;

export const ScanImage = styled.div<{ status: Status }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    > svg {
        font-size: 60px;
        ${({ theme, status }) =>
            status === "scanned" && `color: ${theme.main.successColor};`}
    }
`;
