import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { SelectOption } from "../../types/form";
import { useTranslation } from "react-i18next";
import MultiSelect from "../MultiSelect/MultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import useFormResponseError from "../../hooks/useFormResponseError";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { classNames } from "../../utils/style";
import {
    guestReactionOptions,
    reportTypeOptions,
    signalOptions,
} from "../../utils/preventionTalkOptions";
import FormFiles from "../formPartials/FormFiles/FormFiles";
import { displayCharacterCount } from "../../utils/form";

type FormFields = {
    player_id: number;
    type: SelectOption;
    email: string;
    notes: string;
    reaction: SelectOption;
    conclusion: string;
    permanent_playpause: string;
    signals: SelectOption[];
};

const PreventionAction = () => {
    const { customToast } = useCustomToast();
    const history = useHistory();
    const { formResponseError } = useFormResponseError();
    const { id: playerId } = useParams<{ id: string }>();
    const [files, setFiles] = useState<File[]>([]);

    const { register, handleSubmit, control, setError, watch } =
        useForm<FormFields>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const signals = watch("signals");
    const type = watch("type");
    const notes = watch("notes");
    const conclusion = watch("conclusion");

    const isSignallingSelected = type?.value === "signalling";
    const isOtherSelected =
        signals?.find(signal => signal.value === "other") !== undefined;

    const onSubmit: SubmitHandler<FormFields> = data => {
        setIsSubmitting(true);
        const apiData = new FormData();
        apiData.append("player_id", playerId);
        apiData.append("type", data.type.value.toString());
        apiData.append("notes", data.notes || "");
        !isSignallingSelected &&
            apiData.append("reaction", data.reaction.value.toString());
        apiData.append("conclusion", data.conclusion || "");
        apiData.append(
            "permanent_playpause",
            data.permanent_playpause ? "1" : "0"
        );
        files.forEach(file => {
            apiData.append("files[]", file as File);
        });

        const signals = data.signals || [];

        signals.forEach(signal => {
            apiData.append("signals[]", signal.value.toString());
        });

        client
            .post("/api/prevention-plan-records", apiData)
            .then(() => {
                customToast("success", {
                    title: t("prevention_report_created"),
                });

                history.push("/prevention-plan-overview");
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const { t } = useTranslation();

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control required">
                <label className="form-label">
                    {t("type_of_intervention")}:
                </label>
                <div className="col-span-2">
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    {...field}
                                    options={reportTypeOptions}
                                    placeholder={t("select_type_talk")}
                                    isClearable
                                    className={classNames(
                                        "form-select",
                                        fieldState.error !== undefined &&
                                            "error"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div
                className={classNames(
                    "form-control",
                    isSignallingSelected && "required"
                )}
            >
                <label className="form-label">{t("signals")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="signals"
                        control={control}
                        rules={{ required: isSignallingSelected }}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={signalOptions}
                                    onChange={field.onChange}
                                    placeholder={t("select_signals") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div
                className={classNames(
                    "form-control",
                    isOtherSelected && "required"
                )}
            >
                <label className="form-label">{t("notes")}</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="notes"
                        placeholder={t("observation") + ".."}
                        maxLength={1000}
                        {...register("notes", {
                            required: isOtherSelected,
                        })}
                    />
                    <div>{displayCharacterCount(notes)}/1000</div>
                </div>
            </div>
            {!isSignallingSelected && (
                <>
                    <div className="form-control required">
                        <label className="form-label">
                            {t("guest_reaction")}:
                        </label>
                        <div className="col-span-2">
                            <Controller
                                name="reaction"
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Select
                                            {...field}
                                            options={guestReactionOptions}
                                            placeholder={t(
                                                "choose_what_was_the_guest_reaction"
                                            )}
                                            isClearable
                                            className={classNames(
                                                "form-select",
                                                fieldState.error !==
                                                    undefined && "error"
                                            )}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-control">
                        <label className="form-label">
                            {t("advice_agreement")}
                        </label>
                        <div className="col-span-2">
                            <textarea
                                className="form-textarea"
                                id="conclusion"
                                placeholder={
                                    t("describe_what_was_agreed") + ".."
                                }
                                {...register("conclusion")}
                            />
                            <div>
                                {displayCharacterCount(conclusion)}
                                /1000
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="form-control">
                <div className="form-checkbox-container col-span-2 col-start-2">
                    <input
                        type="checkbox"
                        id="permanent_playpause"
                        {...register("permanent_playpause")}
                    />
                    <label htmlFor="permanent_playpause">
                        {t("add_permanent_playpause")}
                    </label>
                </div>
            </div>
            <div className="form-control items-center">
                <label className="form-label">{t("add_files")}:</label>
                <div className="col-span-2 col-start-2">
                    <FormFiles setFiles={setFiles} />
                </div>
            </div>
            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2">
                    <button
                        type="submit"
                        className="btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner as IconProp}
                                className="fa-spin"
                            />
                        ) : (
                            t("save")
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PreventionAction;
