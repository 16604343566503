import React, { useRef, useState } from "react";
import * as S from "./SwitchUser.styled";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import backgroundLoginImage from "../../assets/backgroundLogin.jpg";
import { useTranslation } from "react-i18next";
import client from "../../client";
import { useUserStore } from "../../store/userStore";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";
import Button from "../../components/Button";

const SwitchUser = () => {
    const { user, setUser } = useUserStore();
    const { t } = useTranslation();
    const history = useHistory();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [loginType] = useState({
        value: "SignInWithTag",
        label: t("casino_employee_tag"),
    });
    const [setShowSelect] = useState(false);
    const [tagNumber, setTagNumber] = useState("");

    const selectRef = useRef();
    useOnClickOutside(selectRef, () => setShowSelect(false));

    const handleKeypress = e => {
        e.key === "Enter" && handleSubmit();
    };

    const handleSubmit = () => {
        if (loginType.value === "SignInWithTag" && tagNumber) {
            client
                .post("/api/switch-tags", {
                    tag_number: tagNumber,
                    current_location_id: user.current_location_id,
                })
                .then(res => {
                    setUser(res.data.user);
                    history.push("/check-in");
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            setShowErrorMessage(true);
                        }
                    }
                });
        }
    };

    return (
        <S.Mask>
            <S.Wrapper backgroundLogin={backgroundLoginImage}>
                <S.Container>
                    <S.Title>{t("scan_tag_to_switch")}</S.Title>
                    <S.Inputs>
                        {showErrorMessage && (
                            <S.ErrorContainer>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    style={{
                                        "--fa-primary-color": "#fff",
                                        "--fa-secondary-color": "#eb5757",
                                        "--fa-primary-opacity": "0.8",
                                        "--fa-secondary-opacity": "1.0",
                                    }}
                                />
                                <S.ErrorMessage>
                                    <S.ErrorTitle>
                                        {t("the_given_data_was_invalid")}
                                    </S.ErrorTitle>
                                    <S.ErrorText>
                                        {t("please_contact_your_administrator")}
                                    </S.ErrorText>
                                </S.ErrorMessage>
                            </S.ErrorContainer>
                        )}

                        {loginType.value === "SignInWithTag" && (
                            <S.FormControl>
                                <input
                                    placeholder={`${t("scan_tag_now")}...`}
                                    type="text"
                                    value={tagNumber}
                                    autoFocus
                                    onChange={e => setTagNumber(e.target.value)}
                                    onKeyPress={handleKeypress}
                                />
                            </S.FormControl>
                        )}

                        <Button
                            className="btn-secondary"
                            onClick={() => handleSubmit()}
                        >
                            {t("switch_user")}
                        </Button>
                    </S.Inputs>
                </S.Container>
            </S.Wrapper>
        </S.Mask>
    );
};

export default SwitchUser;
