import styled from "styled-components";

export const BlockBody = styled.div`
    padding: 15px 20px;
`;
export const BlockTitle = styled.div`
    color: #fffbfb;
    background: #1d1e45;
    border-radius: 4px 4px 0 0;
    font-size: 20px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    > button {
        color: #fffbfb;
    }
`;

export const VersionInformationBlocks = styled.div`
    grid-column: span 2 / span 3;
    background: yellow;
    background: #fffbfb;
    box-shadow: 0px 2px 11px rgba(29, 30, 69, 0.15);
    border-radius: 4px;
    height: 100%;
    max-height: 450px;
`;
