import { DeviceType, DeviceTypeMap } from "../types/devices";

const map: DeviceTypeMap = {
    "id-scan": "acarat_id_scanner",
    rfid: "acarat_rfid_reader",
};

export const matchLocalDevice = (
    identifier: string,
    type: DeviceType
): boolean => {
    return window.localStorage.getItem(map[type]) === identifier;
};
