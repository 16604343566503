import styled from "styled-components";

export const ExportTablePopupForm = styled.form`
    width: 550px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        border: none;
        background: none;
        padding-right: 11px;
        width: 100%;
        border-bottom: 1px solid #bdbdbd;
    }

    textarea {
        width: 100%;
        margin-top: 25px;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        height: 210px;
        padding: 10px;
        resize: none;
        overflow: auto;

        &::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
    }
`;

export const EntryLogPopupForm = styled.form`
    width: 750px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        border: none;
        background: none;
        padding-right: 11px;
        width: 100%;
        border-bottom: 1px solid #bdbdbd;
    }

    textarea {
        width: 100%;
        margin-top: 25px;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        height: 210px;
        padding: 10px;
        resize: none;
        overflow: auto;

        &::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
    }
`;
