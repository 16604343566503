import { useEffect, useCallback } from "react";
import Dropdown from "./Dropdown";

export default function DefaultSelect({
    options,
    label,
    setAppliedFilters,
    filterType,
    selectedValue,
}) {
    const applyFilters = useCallback(
        value => {
            setAppliedFilters(prev => {
                let copy = [...prev];

                const isDuplicate = copy.some(item => item.label === label);
                const filterObject = {
                    filterType: filterType,
                    label,
                    selectedValue: value,
                };

                if (!isDuplicate) {
                    copy.push(filterObject);
                    return copy;
                }

                const dupIndex = copy.findIndex(item => item.label === label);

                if (copy[dupIndex].selectedValue.value !== value.value) {
                    copy.splice(dupIndex, 1);
                    copy.push({
                        filterType: filterType,
                        label,
                        selectedValue: value,
                    });
                    return copy;
                }
                return copy;
            });
        },
        [setAppliedFilters, filterType, label]
    );

    useEffect(() => {
        if (selectedValue) {
            applyFilters(selectedValue);
        }
    }, [selectedValue, applyFilters]);

    return (
        <Dropdown label={label}>
            {options.map((item, index) => (
                <button key={index} onClick={() => applyFilters(item)}>
                    {item.value}
                </button>
            ))}
        </Dropdown>
    );
}
