import React, { useState } from "react";
import { menuItemsByRole } from "../../utils/menuItems";
import MenuSection from "../MenuSection";
import acaratLogo from "../../assets/logo.png";
import usePermissions from "../../hooks/usePermissions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { groupBy } from "lodash-es";

export type MenuStatus = {
    [key: string]: boolean;
};
export default function SideMenu({
    showMobileMenu,
    setShowMobileMenu,
}: {
    showMobileMenu: boolean;
    setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { hasPermissions } = usePermissions();
    const { t } = useTranslation();

    const [menuStatus, setMenuStatus] = useState<MenuStatus>({
        guests: true,
        operations: true,
        manager: true,
    });

    const renderMenuSections = () => {
        const sections = groupBy(
            menuItemsByRole.filter(item =>
                hasPermissions(item.permissionsRequired)
            ),
            "group"
        );

        return Object.entries(sections).map(([groupTitle, items], index) => {
            return (
                <MenuSection
                    title={groupTitle}
                    items={items}
                    menuId={groupTitle}
                    menuStatus={menuStatus}
                    setMenuStatus={setMenuStatus}
                    setShowMobileMenu={setShowMobileMenu}
                    key={index}
                />
            );
        });
    };

    return (
        <>
            <div
                className={`fixed bottom-0 left-0 top-0 z-10 flex w-[200px] flex-col overflow-auto bg-[#1D1E45] p-5 pt-36 shadow-md transition-all 3xl:translate-x-0 ${
                    showMobileMenu ? "translate-x-0" : "-translate-x-96"
                }`}
            >
                <div>{renderMenuSections()}</div>
                <div className="mt-auto flex justify-center">
                    <img src={acaratLogo} alt="Acarat Logo" className="h-9" />
                </div>
                <div className="text-center text-white">
                    <Link
                        to="/release-notes"
                        className="text-white underline hover:cursor-pointer hover:text-gray-300"
                    >
                        {t("release_notes")}
                    </Link>
                </div>
            </div>
        </>
    );
}
