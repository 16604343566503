import { useEffect, useMemo } from "react";
import PageHeader from "../../components/PageHeader";
import Filter from "../../components/Filter";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageColumns from "../../components/MessageColumns";
import client from "../../client";
import { dateOptions } from "../../utils/filterOptions";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import usePermissions from "../../hooks/usePermissions";
import usePopup from "../../hooks/usePopup";
import useFetch from "../../hooks/useFetch";
import { messageIconTypes } from "../../utils/filterOptions";
import useCustomToast from "../../hooks/useCustomToast";
import { useUserStore } from "../../store/userStore";
import { TranslationsKeys } from "../../types/translations";
import { Message } from "../../types/messages";
import { Button } from "../../types/button";
import {
    faFileExport,
    faMessageLines,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useQueryClient } from "react-query";

function Messages() {
    const {
        onScroll,
        updateFilterParams,
        containerRef,
        isLoading,
        filteredData,
    } = useInfiniteScroll("/api/reports");

    const { data } = useFetch<string[]>("message-types");
    const { customToast } = useCustomToast();

    const { popup } = usePopup();

    const { hasPermissions } = usePermissions();

    const { t } = useTranslation();
    const history = useHistory();

    const hasExportPermissions = hasPermissions(["export_messages"]);
    const { user } = useUserStore();

    const queryClient = useQueryClient();

    useEffect(() => {
        window.history.replaceState({}, document.title);
    }, []);

    const statusOptions = useMemo(
        () => [
            {
                value: t("read"),
                label: t("read"),
                params: {
                    message_read: 1,
                },
            },
            {
                value: t("unread"),
                label: t("unread"),
                params: {
                    message_read: 0,
                },
            },
        ],
        [t]
    );

    const locationOptions = useMemo(
        () =>
            user
                ? user.locations?.map(item => {
                      return {
                          value: item.name,
                          label: item.name,
                          params: {
                              location_id: item.id,
                          },
                      };
                  })
                : [],
        [user]
    );

    const messageTypes = useMemo(
        () =>
            data
                ? data.map((item: string) => {
                      return {
                          label: t(item as TranslationsKeys),
                          icon: messageIconTypes(item),
                          params: {
                              types: [item],
                          },
                      };
                  })
                : [],
        [data, t]
    );

    const filters = useMemo(
        () => [
            {
                type: "multi",
                key: "type",
                label: t("filter_by_type"),
                options: messageTypes,
            },
            {
                type: "date",
                key: "date",
                label: t("filter_by_date"),
                options: dateOptions,
            },
            user &&
                user.locations.length > 1 && {
                    type: "default",
                    key: "location",
                    label: t("filter_by_location"),
                    options: locationOptions,
                },
            {
                type: "default",
                key: "status",
                label: t("filter_by_status"),
                options: statusOptions,
            },
        ],
        [user, t, messageTypes, locationOptions, statusOptions]
    );

    const handleTableClick = (resData: number) => {
        history.push(`/messages/${resData}`);
        client
            .post(`/api/reports/addView`, {
                report_id: resData,
            })
            .then(res => {
                queryClient.invalidateQueries(`user/unread-messages-count`);
                return res;
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            });
    };

    const withDate = (resData: Message[]) => {
        const copy = [...resData];
        copy.forEach(item => {
            item.date = new Date(item.created_at);
        });
        return copy;
    };

    const buttons = (): Button[] => {
        const exportButton: Button = {
            text: t("export"),
            clickHandler: () =>
                //@ts-ignore
                popup({
                    type: "EXPORT_MESSAGES",
                }),
            type: "pageHeaderButtonAlternative",
            faIcon: faFileExport as IconProp,
        };

        const addReportButton: Button = {
            text: t("add_report"),
            clickHandler: () => history.push("/add-report"),
            type: "pageHeader",
            faIcon: faMessageLines as IconProp,
        };

        const buttons = [addReportButton];

        if (hasExportPermissions) {
            buttons.push(exportButton);
        }

        return buttons;
    };

    return (
        <>
            <PageHeader
                title={t("messages")}
                showLists={false}
                showSearch={false}
                showRegisterButton={false}
                placeholder={t("search_in_reports")}
                buttons={buttons()}
            />

            {/* @ts-ignore */}
            <Filter
                filters={filters}
                updateFilterParams={updateFilterParams}
                filterId="messages"
            />

            <MessageColumns
                data={withDate(filteredData)}
                click={handleTableClick}
                scrollRef={
                    containerRef as unknown as React.MutableRefObject<HTMLDivElement>
                }
                onScroll={onScroll}
                isLoading={isLoading}
            />
        </>
    );
}

export default Messages;
