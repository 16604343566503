import create from "zustand";

type SelectedValue = {
    label: string;
    icon: string;
    params: { [key: string]: string | string[] };
};

type Filter = {
    key: string;
    selectedValue: SelectedValue;
};

type FiltersStore = {
    filters: { [key: string]: Filter };
    updateFilters: (filter: Filter, key: string) => void;
};

export const useFiltersStore = create<FiltersStore>(set => ({
    filters: {},
    updateFilters: (filter, key) =>
        set(state => {
            const updatedFilters = { ...state.filters };
            updatedFilters[key] = filter;
            return { ...state, filters: updatedFilters };
        }),
}));
