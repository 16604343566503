import { useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import { ReleaseNotesOverview } from "../../components/ReleaseNotesOverview/ReleaseNotesOverview";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Loading";
import { Response } from "../../types/api/api";
import { Note } from "../../types/releaseNote";

const ReleaseNotes = () => {
    const { t } = useTranslation();
    const { isLoading, data: releaseNotes } =
        useFetch<Response<Note[]>>("release_notes");
    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader
                title={t("release_notes")}
                showLists={false}
                showSearch={false}
                back={true}
            />

            <ReleaseNotesOverview releaseNotes={releaseNotes?.data || []} />
        </>
    );
};

export default ReleaseNotes;
