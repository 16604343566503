import i18n from "i18next";

export const endDateOptions = [
    {
        value: "custom",
        label: i18n.t("custom_end_date"),
    },
    {
        value: "1_week",
        label: i18n.t("number_week", { number: 1 }),
    },
    {
        value: "2_week",
        label: i18n.t("number_weeks", { number: 2 }),
    },
    {
        value: "3_week",
        label: i18n.t("number_weeks", { number: 3 }),
    },
    {
        value: "1_month",
        label: i18n.t("number_month", { number: 1 }),
    },
    {
        value: "3_month",
        label: i18n.t("number_months", { number: 3 }),
    },
    {
        value: "6_month",
        label: i18n.t("number_months", { number: 6 }),
    },
    {
        value: "indefinitely",
        label: i18n.t("indefinitely"),
    },
];

export const checkInMessagePeriodOptions = [
    {
        value: "today",
        label: i18n.t("next_visit"),
    },
    {
        value: "1_week",
        label: i18n.t("after_entity", {
            entity: i18n.t("number_week", { number: 1 }),
        }),
    },
    {
        value: "2_week",
        label: i18n.t("after_entity", {
            entity: i18n.t("number_weeks", { number: 2 }),
        }),
    },
    {
        value: "1_month",
        label: i18n.t("after_entity", {
            entity: i18n.t("number_month", { number: 1 }),
        }),
    },
    {
        value: "custom",
        label: i18n.t("custom_date"),
    },
];

export const reasonOptionsPlayPause = [
    {
        value: "guest",
        label: i18n.t("guest"),
        text: i18n.t("guest"),
    },
    {
        value: "casino",
        label: i18n.t("casino"),
        text: i18n.t("casino"),
    },
    {
        value: "extern",
        label: i18n.t("extern"),
        text: i18n.t("extern"),
    },
];

export const reasonOptionsBlackList = [
    {
        value: "physically_aggressive",
        label: i18n.t("physically_aggressive"),
    },
    {
        value: "verbally_aggressive",
        label: i18n.t("verbally_aggressive"),
    },
    {
        value: "fraud",
        label: i18n.t("fraud"),
    },
    {
        value: "not_following_house_rules",
        label: i18n.t("not_following_house_rules"),
    },
    {
        label: i18n.t("hangers_sent_away"),
        value: "hangers_sent_away",
    },
    {
        value: "harassing_other_visitors",
        label: i18n.t("harassing_other_visitors"),
    },
    {
        value: "consuming_food_alcohol_drugs",
        label: i18n.t("consuming_food_alcohol_drugs"),
    },
    {
        value: "reported_spoken_complaints",
        label: i18n.t("reported_spoken_complaints"),
    },
];

export const locationOptions = [
    {
        value: "all_locations",
        label: i18n.t("add_to_all_locations"),
    },
    {
        value: "select_locations_manually",
        label: i18n.t("select_locations_manually"),
    },
];

export const periodOptions = [
    {
        value: "week",
        label: i18n.t("per_week"),
        text: i18n.t("per_week"),
    },
    {
        value: "month",
        label: i18n.t("per_month"),
        text: i18n.t("per_month"),
    },
];

export const residenceOptions = [
    {
        value: "NL",
        label: i18n.t("yes"),
    },
    {
        value: "INT",
        label: i18n.t("no"),
    },
];

export const genderOptions = [
    {
        value: "male",
        label: i18n.t("male"),
    },
    {
        value: "female",
        label: i18n.t("female"),
    },
    {
        value: "other",
        label: i18n.t("other"),
    },
];
