import React from "react";
import * as S from "./PreventionPolicy.styled";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/pro-duotone-svg-icons";
import { policies } from "../../utils/preventionPolicies";
import { Link } from "react-router-dom";

const PreventionPolicy = () => {
    const { t } = useTranslation();

    const constructLink = text => {
        if (typeof text === "object" && text.path) {
            return <Link to={text.path}>{text.text}</Link>;
        }
        return text;
    };

    return (
        <>
            <PageHeader
                title={t("casino_prevention_plan_policy")}
                placeholder={t("search_in_reports")}
            />
            <S.Container>
                {t("prevention_policies", { returnObjects: true }).map(
                    (policy, index) => {
                        const policyOption = policies[index];
                        return (
                            <S.Box
                                fullwidth={policyOption.fullwidth}
                                key={index}
                            >
                                <S.BoxHeader
                                    background={policyOption.background}
                                    color={policyOption.color}
                                >
                                    <FontAwesomeIcon
                                        icon={faAddressBook}
                                        style={{
                                            "--fa-primary-color": "#000",
                                            "--fa-secondary-color": "#f9f9e9",
                                            "--fa-primary-opacity": "1.0",
                                            "--fa-secondary-opacity": "1.0",
                                        }}
                                    />
                                    {policy.title}
                                </S.BoxHeader>
                                <S.BoxContent>
                                    {policy.intro}
                                    <S.BoxSubHeader>
                                        {policy.questions_title}
                                    </S.BoxSubHeader>
                                    {policy.questions && (
                                        <ul>
                                            {policy.questions.map(
                                                (question, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {constructLink(
                                                                question
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    )}
                                    <S.BoxSubHeader>
                                        {policy.actions_title}
                                    </S.BoxSubHeader>
                                    {policy.actions && (
                                        <ol>
                                            {policy.actions.map(
                                                (action, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {constructLink(
                                                                action
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ol>
                                    )}
                                </S.BoxContent>
                            </S.Box>
                        );
                    }
                )}
            </S.Container>
        </>
    );
};

export default PreventionPolicy;
