import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langEn from "../lang/locales/en/translation.json";
import langNl from "../lang/locales/nl/translation.json";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: langEn,
            },
            nl: {
                translation: langNl,
            },
        },
        lng: window.localStorage.getItem("lang") || "nl",
        fallbackLng: "nl",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
