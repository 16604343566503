import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";

export default function CustomSelect({
    options,
    readOnly = false,
    optionsPosition,
    placeholder = null,
    isSelected = null,
    onChangeCallback,
    setIsSelected,
    setClick,
    errors,
    formKey,
    required,
    showError,
    afterBottom = null,
    borderStyle = false,
}) {
    const [showDropDown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const selectRef = useRef(null);
    const closeRef = useRef(null);

    const handleSelect = option => {
        setSelectedOption(option);

        if (setIsSelected) {
            setIsSelected(option.value);
        }
    };

    useOnClickOutside(closeRef, () => setShowDropdown(null));

    useEffect(() => {
        if (isSelected) {
            const isSelectedOption = options.find(
                option => option.value === isSelected
            );

            setSelectedOption(isSelectedOption);
        } else if (!isSelected && !placeholder) {
            setSelectedOption(options[0]);
        }
    }, [isSelected, options, placeholder, errors, formKey]);

    useEffect(() => {
        if (selectedOption) {
            onChangeCallback(selectedOption.value);
        }
    }, [selectedOption, onChangeCallback]);

    return (
        <div
            className={`relative flex w-full flex-grow items-center border-b  border-[#828282] ${
                showError && "border-b-2 border-secondary"
            } ${borderStyle && "border-t px-4"}`}
            ref={(selectRef, closeRef)}
            required={required}
        >
            <div
                className={`flex h-8 w-full justify-between  py-1 ${
                    readOnly
                        ? "pointer-events-none text-[#616161]"
                        : "cursor-pointer"
                }`}
                onClick={() => setShowDropdown(prev => !prev)}
            >
                {placeholder && !selectedOption ? (
                    <div className="text-base text-[#bdbdbd]">
                        {placeholder}
                    </div>
                ) : (
                    <span>{selectedOption?.text}</span>
                )}
                <FontAwesomeIcon icon={faAngleDown} className="ml-1 text-xl" />
            </div>

            {!readOnly && showDropDown && (
                <div
                    className={`absolute top-12 z-30 w-full max-w-[250px] select-none rounded-md bg-[#fffbfb] drop-shadow-xl   hover:text-secondary ${
                        optionsPosition === "bottom" && "top-auto bottom-12"
                    } `}
                >
                    {options.map(option => (
                        <div
                            className="cursor-pointer select-none border border-b-[#bdbdbd] bg-none  py-4 px-5 text-primary first:rounded-t-md last:rounded-b-md last:border-b-0 hover:cursor-pointer hover:bg-zinc-100 hover:text-secondary"
                            onClick={() => {
                                setShowDropdown(false);
                                handleSelect(option);
                                setClick && setClick(true);
                            }}
                            key={option.value}
                        >
                            {option.text}
                        </div>
                    ))}
                </div>
            )}
            {afterBottom}
        </div>
    );
}
