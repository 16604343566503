import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import client from "../../client";
import { useHistory, useParams } from "react-router-dom";
import { prepareOptionsForSelect } from "../../utils/locations";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useUserStore } from "../../store/userStore";
import day from "dayjs";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import {
    periodOptions,
    endDateOptions,
    locationOptions,
} from "../../utils/formOptions";
import usePermissions from "../../hooks/usePermissions";
import useCustomToast from "../../hooks/useCustomToast";
import { SelectOption } from "../../types/form";
import { classNames } from "../../utils/style";
import useSelectPeriodAndDate from "../../hooks/useSelectPeriodAndDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSignature from "../../hooks/useSignature";
import usePopup from "../../hooks/usePopup";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useQueryClient } from "react-query";
import {
    LocationSelect,
    ActiveVisitLimit,
    RootDataGuest,
} from "../../types/guest";

type Period = {
    value: string;
    label: string;
    text: string;
};

type FormFields = {
    player_id: string;
    period: string | Period;
    description: string;
    location: string;
    locations: LocationSelect[];
    end_date_option: SelectOption;
    ends_at: string;
    limit: number;
    global: number;
};

const VisitLimitAction = () => {
    const params = useParams();
    const { data, isLoading } = useFetch<RootDataGuest>(`player/${params.id}`);
    const history = useHistory();
    const { customToast } = useCustomToast();
    const { popup } = usePopup();
    const { hasConnectedDevice } = useSignature();
    const { t } = useTranslation();
    const [generatedSignature, setGeneratedSignature] = useState(null);
    const { hasPermissions } = usePermissions();
    const hasDeletePermissions = hasPermissions(["delete_visit_limits"]);
    const [visitLimit, setVisitLimit] = useState<ActiveVisitLimit>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user } = useUserStore();
    const queryClient = useQueryClient();
    const { register, handleSubmit, control, setValue, watch, reset } =
        useForm<FormFields>({
            defaultValues: {
                end_date_option: {
                    value: "indefinitely",
                    label: t("indefinitely"),
                },
                period: {
                    value: "month",
                    label: t("per_month"),
                },
                location: "all_locations",
                locations: prepareOptionsForSelect(
                    user?.locations.filter(
                        location => location.id === user.current_location_id
                    )
                ),
            },
        });

    const requestMap = {
        put: {
            endpoint: `/api/visit-limits/${visitLimit?.id}`,
            succesText: t(`entity_successfully_updated`, {
                entity: t("visit_limit"),
            }),
        },
        post: {
            endpoint: "/api/visit-limits",
            succesText: t(`entity_successfully_created`, {
                entity: t("visit_limit"),
            }),
        },
    };

    const removeSignature = () => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => setGeneratedSignature(null),
                entityName: t("signature"),
            },
        });
    };

    const addSignature = () => {
        popup({
            type: "ADD_SIGNATURE",
            data: {
                setGeneratedImage: setGeneratedSignature,
                signatureTextKey: "signature_visitlimit_text",
            },
        });
    };

    const deleteVisitLimit = useCallback(() => {
        client.delete(`/api/visit-limits/${visitLimit?.id}`).then(() => {
            queryClient.invalidateQueries(`player/${params.id}`);
            history.push(`/guest/${params.id}?action=activity_overview`);
            customToast("success", {
                title: t(`entity_successfully_deleted`, {
                    entity: t("visit_limit"),
                }),
            });
        });
    }, [visitLimit, history, params.id, customToast, t, queryClient]);

    const onSubmit: SubmitHandler<FormFields> = async data => {
        setIsSubmitting(true);
        type RequestFields = {
            player_id: number;
            description: string;
            location: string;
            locations?: number[];
            end_date_option: SelectOption;
            ends_at: string;
            limit: number;
            global: number;
            period: string | undefined;
        };
        const postData: RequestFields = {
            player_id: params.id,
            description: data.description,
            location: data.location,
            end_date_option: data.end_date_option,
            ends_at: data.ends_at,
            limit: data.limit,
            global: data.global,
            period:
                typeof data.period === "string"
                    ? data.period
                    : data.period.value,
        };

        if (user?.locations.length === 1) {
            data.location = "all_locations";
        }

        switch (data.location) {
            default:
            case "all_locations":
                postData.global = 1;
                break;
            case "select_locations_manually":
                postData.global = 0;
                postData.locations = data.locations.map(
                    location => location.id
                );
                break;
        }

        const type = visitLimit ? "put" : "post";

        client[type](requestMap[type].endpoint, postData)
            .then(() => {
                queryClient.invalidateQueries(`player/${params.id}`);
                history.push(`/guest/${params.id}?action=activity_overview`);

                customToast("success", {
                    title: requestMap[type].succesText,
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const updateDayValue = useCallback(
        (dayValue: string) => {
            setValue("ends_at", dayValue);
        },

        [setValue]
    );

    const { selectDateOption } = useSelectPeriodAndDate(updateDayValue);

    useEffect(() => {
        if (!isLoading && data) {
            setVisitLimit(data.player.active_visit_limit);
        }
    }, [isLoading, data, visitLimit, selectDateOption]);

    useEffect(() => {
        const defaultFormValues: Partial<FormFields> = visitLimit
            ? {
                  location: visitLimit.global
                      ? "all_locations"
                      : "select_locations_manually",
                  limit: visitLimit.limit,
                  description: visitLimit.description || "",
                  period:
                      periodOptions.find(
                          option => option.value === visitLimit.period
                      ) || "",
                  locations: prepareOptionsForSelect(visitLimit.locations),
                  ends_at: visitLimit.ends_at
                      ? day(visitLimit.ends_at).format("YYYY-MM-DD")
                      : "",
                  end_date_option: visitLimit?.ends_at
                      ? { value: "custom", label: t("custom_end_date") }
                      : { value: "indefinitely", label: t("indefinitely") },
              }
            : {};
        reset(defaultFormValues);
    }, [data, reset, visitLimit, t]);

    const location = watch("location");
    const end_date_option = watch("end_date_option");

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control required">
                <label className="form-label">{t("visit_limit")}:</label>

                <input
                    type="number"
                    className="form-input"
                    min={1}
                    placeholder={t("times")}
                    max={50}
                    autoComplete="off"
                    {...register("limit", {
                        required: true,
                    })}
                />
                <Controller
                    name="period"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                        return (
                            <Select
                                {...field}
                                options={periodOptions}
                                placeholder={t("visit_limit")}
                                onChange={(selectedOption: { value: string }) =>
                                    field.onChange(selectedOption)
                                }
                                className={classNames(
                                    "form-select",
                                    fieldState.error !== undefined && "error"
                                )}
                            />
                        );
                    }}
                />
            </div>
            <div className="form-control required">
                <label className="form-label">{t("end_date")}:</label>

                <Controller
                    name="end_date_option"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <Select
                                {...field}
                                options={endDateOptions}
                                placeholder={t("select_period")}
                                onChange={(option: SelectOption) => {
                                    field.onChange(option);
                                    selectDateOption(option.value as string);
                                }}
                                className={classNames(
                                    "form-select",
                                    fieldState.error !== undefined && "error"
                                )}
                            />
                        );
                    }}
                />
                <input
                    className="form-input"
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    {...register("ends_at", {
                        required: end_date_option?.value !== "indefinitely",
                    })}
                    readOnly={end_date_option?.value !== "custom"}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("description")}:</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="description"
                        placeholder={t("description")}
                        {...register("description")}
                    />
                </div>
            </div>
            <div className="form-control">
                <label className="form-label">{t("location")}:</label>
                <div className="col-span-2">
                    {prepareOptionsForSelect(user?.locations).length > 1 &&
                        locationOptions.map(option => (
                            <div
                                className="form-radio-container col-span-2"
                                key={option.value}
                            >
                                <input
                                    type="radio"
                                    id={option.value}
                                    value={option.value}
                                    {...register("location")}
                                    name="location"
                                />
                                <label
                                    className="[&_span]:font-bold [&_span]:underline"
                                    htmlFor={option.value}
                                    dangerouslySetInnerHTML={{
                                        __html: option.label,
                                    }}
                                ></label>
                            </div>
                        ))}
                </div>
            </div>
            {location === "select_locations_manually" &&
                prepareOptionsForSelect(user?.locations).length > 1 && (
                    <div className="form-control">
                        <div className="col-span-2 col-start-2">
                            <Controller
                                name="locations"
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <MultiSelect
                                            onChange={field.onChange}
                                            options={prepareOptionsForSelect(
                                                user?.locations
                                            )}
                                            placeholder={
                                                t("add_locations") + ".."
                                            }
                                            hasError={
                                                fieldState.error !== undefined
                                            }
                                            value={field.value}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}
            {(hasConnectedDevice || generatedSignature) && (
                <div className="form-control">
                    <label>{t("signature")}</label>
                    {!generatedSignature && (
                        <>
                            <div
                                className="flex items-center hover:cursor-pointer"
                                onClick={() => addSignature()}
                            >
                                <FontAwesomeIcon
                                    className="mr-2.5"
                                    icon={faPlus as IconProp}
                                />
                                <span>{t("add_signature")}</span>
                            </div>
                        </>
                    )}
                    {generatedSignature && (
                        <div className="border-1 relative border-[#e0e0e0]">
                            <img
                                alt="signature"
                                src={generatedSignature}
                                className="h-44 w-[300px]"
                            />
                            <button
                                className="absolute top-2 right-3.5 text-xl"
                                onClick={e => {
                                    e.preventDefault();
                                    removeSignature();
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes as IconProp} />
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2 ml-2.5 flex space-x-3">
                    <button
                        type="submit"
                        className="btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner as IconProp}
                                className="fa-spin"
                            />
                        ) : visitLimit ? (
                            t("update_visit_limit")
                        ) : (
                            t("add_visit_limit")
                        )}
                    </button>
                    {hasDeletePermissions && visitLimit && (
                        <button
                            type="button"
                            className="btn-secondary"
                            disabled={isSubmitting}
                            onClick={deleteVisitLimit}
                        >
                            {t("remove_visit_limit")}
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};
export default VisitLimitAction;
