import React, { useRef } from "react";
import * as S from "./TemplateEditField.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

function TemplateEditField({
    template,
    setEditTemplateText,
    updateTemplate,
    exitEdit,
}) {
    const editFieldInputRef = useRef(null);
    const editFieldRef = useRef(null);

    const { t } = useTranslation();

    useOnClickOutside(editFieldRef, exitEdit);

    const handleEditKeyPress = (e, templateId) => {
        if (e.key !== "Enter") return;
        updateTemplate(templateId);
    };

    return (
        <S.AddContainer>
            <S.AddField ref={editFieldRef}>
                <input
                    placeholder={t("template_name")}
                    onChange={e => setEditTemplateText(e.target.value)}
                    onKeyPress={e => handleEditKeyPress(e, template.id)}
                    defaultValue={template.name}
                    ref={editFieldInputRef}
                />
                <S.AddFieldConfirm onClick={() => updateTemplate(template.id)}>
                    <FontAwesomeIcon icon={faCheck} />
                </S.AddFieldConfirm>
            </S.AddField>
        </S.AddContainer>
    );
}

export default TemplateEditField;
