import styled from "styled-components";

export const RiskLevelContainer = styled.div`
    background: #fffbfb;
    border-radius: 4px;
    width: 200px;
    height: 147px;
    filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.15));
    position: absolute;
    margin-top: 4px;
    z-index: 4;
    display: ${props => (props.riskLevelContainer ? "block" : "none")};
`;

export const RiskLevelLine = styled.div`
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: #f3f3f3;
    }
    &:nth-last-child(1) {
        border-bottom: none;
    }
`;

export const RiskBullet = styled.div`
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: ${props => props.color};
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
`;

export const RiskText = styled.div`
    color: #050505;
    font-size: 18px;
    line-height: 18px;
`;

export const RiskLevel = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    &:hover {
        text-decoration: underline;
    }
`;

export const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fffbfb;
    position: absolute;
    left: 90px;
    top: -8px;
    filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.15));
`;
