import styled from "styled-components";

import { Link } from "react-router-dom";

export const TabsBoxContainer = styled.div`
    width: 100%;
    min-width: 560px;
    height: 100%;
    max-height: 400px;
    margin-top: 50px;
    background: #fffbfb;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        margin-top: 0px;
    }
    @media screen and (max-width: ${props => props.theme.layout.bpM}) {
        min-width: 450px;
    }
`;

export const Header = styled.div`
    font-size: 20px;
    font-weight: 400;
    height: 55px;
    color: ${props => props.headerTextColor && props.headerTextColor};
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    > span {
        color: #fff;
        font-size: 15px;
        margin: 5px 0 0 5px;
    }
    background: ${props => props.headerColor && props.headerColor};

    > svg {
        margin: 0 10px 0 13px;
        color: ${props => props.iconColor && props.iconColor};
    }
`;

export const TabsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Tab = styled(Link)`
    border-bottom: 1px solid #f0f0f0;
    padding: 20px 20px 20px 30px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    :hover {
        background-color: #eee;
        transition: 0.2s;
        color: #333333;
    }
`;
