import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import client from "../../client";
import useCustomToast from "../../hooks/useCustomToast";
import useFormResponseError from "../../hooks/useFormResponseError";
import { useHistory, useParams } from "react-router";
import Button from "../Button";
import { displayCharacterCount } from "../../utils/form";
import { useQueryClient, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { FileObject, Note } from "../../types/note";
import FormFiles from "../formPartials/FormFiles/FormFiles";

type FormFields = {
    notes: string;
    files: FileObject[];
};

const GuestNoteAction = () => {
    const { customToast } = useCustomToast();
    const location = useLocation();
    const history = useHistory();
    const actionParams = new URLSearchParams(location.search);
    const { formResponseError } = useFormResponseError();
    const { id: playerId } = useParams<{ id: string }>();
    const noteId = actionParams.get("id");
    const [files, setFiles] = useState<File[]>([]);
    const [existingFiles, setExistingFiles] = useState<FileObject[]>([]);
    const [notes, setNotes] = useState<Note | null>(null);
    const {
        register,
        handleSubmit,
        setError,
        watch,
        reset,
        formState: { isSubmitted },
    } = useForm<FormFields>();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();
    const noteWatch = watch("notes");

    const { data: notesData, isLoading } = useQuery<Note>(
        [`/player/notes/${noteId}`],
        async () => {
            try {
                const notesData = await client.get<Note>(
                    `api/player/notes/${noteId}`
                );

                return notesData.data;
            } catch (err: any) {
                customToast("error", {
                    title: t("error"),
                    body: err.message,
                });
                return Promise.reject(err);
            }
        },
        {
            enabled: !!noteId,
        }
    );

    const onSubmit: SubmitHandler<FormFields> = data => {
        setIsSubmitting(true);

        const apiData = new FormData();
        if (notes) {
            apiData.append("_method", "PUT");
        }

        const postData = {
            notes: data.notes,
        };

        Object.entries(postData).forEach(([key, value]) => {
            if (value !== null) {
                apiData.append(key, value);
            }
        });

        files.forEach(file => {
            apiData.append("files[]", file as File);
        });

        const requestMap = {
            put: {
                endpoint: `/api/player/notes/${noteId}`,
                succesText: t(`entity_successfully_updated`, {
                    entity: t("note"),
                }),
            },
            post: {
                endpoint: `/api/player/${playerId}/notes`,
                succesText: t(`entity_successfully_created`, {
                    entity: t("note"),
                }),
            },
        };

        const type = notesData ? "put" : "post";

        client["post"](requestMap[type].endpoint, apiData)
            .then(() => {
                history.push({
                    pathname: history.location.pathname,
                    search: `?action=guest_notes`,
                });

                customToast("success", {
                    title: t("entity_successfully_created", {
                        entity: t("note"),
                    }),
                });
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
                queryClient.invalidateQueries(`player/${playerId}`);
            });
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoading && notesData) {
            setNotes(notesData);
            setExistingFiles(notesData.files);
        }
    }, [isLoading, notesData, reset]);

    useEffect(() => {
        const defaultFormValues: Partial<FormFields> =
            notes && noteId
                ? {
                      notes: notes.notes,
                  }
                : {
                      notes: "",
                  };

        reset(defaultFormValues);
    }, [reset, t, isLoading, notes, noteId]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-3xl space-y-6 sm:space-y-5"
        >
            <div className="form-control">
                <label className="form-label">{t("notes")}</label>
                <div className="col-span-2">
                    <textarea
                        className="form-textarea"
                        id="notes"
                        placeholder={t("notes") + ".."}
                        maxLength={150}
                        {...register("notes", { required: true })}
                    />
                    <div>{displayCharacterCount(noteWatch)}/150</div>
                </div>
            </div>

            <div className="form-control items-center">
                <label className="form-label">{t("add_files")}:</label>
                <div className="col-span-2 col-start-2">
                    <FormFiles
                        setFiles={setFiles}
                        existingFiles={existingFiles}
                        isSubmitted={isSubmitted}
                    />
                </div>
            </div>
            <div className="sm:grid sm:grid-cols-3">
                <div className="col-span-2 col-start-2 ml-2.5">
                    <Button
                        type="submit"
                        className="btn-primary"
                        isLoading={isSubmitting}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default GuestNoteAction;
