import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useCustomToast from "./useCustomToast";

type Error = {
    response: Response;
};

type Response = {
    status: number;
    data: ErrorTypes;
};

type ErrorTypes = {
    exception: string;
    file: string;
    line: number;
    message: string;
    trace: string[];
    errors: string;
};

function useFormResponseError() {
    const { t } = useTranslation();
    const { customToast } = useCustomToast();

    const formResponseError = useCallback(
        (err: Error, setError: any) => {
            const { status, data } = err.response;

            switch (status) {
                case 422:
                    const errorMessages: ReactNode[] = [];

                    Object.entries(data.errors).forEach(([key, val]) => {
                        errorMessages.push(<div key={key}>{val}</div>);
                    });

                    customToast("error", {
                        title: t("validation_error"),
                        body: errorMessages,
                    });

                    break;
                default:
                    customToast("error", {
                        title: t("error_sending_data"),
                        body: data.message,
                    });
                    break;
            }
        },
        [customToast, t]
    );
    return { formResponseError };
}

export default useFormResponseError;
