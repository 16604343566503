import { useRef, useCallback } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import Spinner from "../../assets/loader.gif";
import Button from "../Button";
import Popup from "./Popup/Popup";

type Photo = {
    url: string;
    file: File;
};

export default function WebcamPopup({
    setShowWebcamPopup,
    setUserPhoto,
}: {
    setShowWebcamPopup: (show: boolean) => void;
    setUserPhoto: (image: Photo) => void;
}) {
    const welcomeBackPopupRef = useRef(null);
    const webcamRef = useRef<Webcam>(null);

    const { t } = useTranslation();

    const capture = useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        if (imageSrc) {
            const url = imageSrc;
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "File name", {
                        type: "image/png",
                    });
                    setUserPhoto({
                        url: imageSrc,
                        file: file,
                    });
                    setShowWebcamPopup(false);
                });
        }
    }, [webcamRef, setUserPhoto, setShowWebcamPopup]);

    useOnClickOutside(welcomeBackPopupRef, () => setShowWebcamPopup(false));

    return (
        <Popup>
            <div
                className="m-auto w-[500px] rounded text-black"
                ref={welcomeBackPopupRef}
            >
                <div className="flex items-center justify-center gap-3 pb-10 text-2xl font-normal leading-7 text-primary">
                    {t("take_a_profile_picture")}
                </div>

                <div className="relative m-auto h-full max-h-[400px] min-h-[400px] w-full min-w-[280px] max-w-[400px] rounded-full border object-cover">
                    <div className="absolute z-10 m-auto flex h-full w-full items-center justify-center rounded-full object-cover">
                        <img
                            src={Spinner}
                            alt="Loader"
                            className="absolute z-10"
                        />
                        <Webcam
                            className="absolute z-30 h-full w-full rounded-full object-cover"
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-4 pt-10">
                    <Button className="btn-primary" onClick={capture}>
                        {t("capture_photo")}
                    </Button>
                    <Button
                        className="btn-alternative"
                        onClick={() => setShowWebcamPopup(false)}
                    >
                        {t("cancel")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
}
