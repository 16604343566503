import React, { useState } from "react";
import * as S from "../Popups.styled";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "jspdf-autotable";
import { columns } from "../../../utils/tableColumns";
import useFileExport from "../../../hooks/useFileExport";
import ExportDateFields from "../../ExportDateFields";
import usePopup from "../../../hooks/usePopup";
import Popup from "../Popup";
import { FormSelect } from "../../formPartials";
import { oldReportTypeOptions } from "../../../utils/preventionTalkOptions";
import Button from "../../Button";

function ExportPreventionPlanOverviewPopup() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const formFunctions = {
        register,
        errors,
        setValue,
    };

    const { close } = usePopup();
    const { t } = useTranslation();

    const entityName = t("prevention_plan_overview");
    const { requestExportDataByDate, exportPdfTableByDate } =
        useFileExport(entityName);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const prepareExport = (responseData, formValues) => {
        const filterDates = {
            from_date: formValues.from_date,
            from_time: formValues.from_time,
            until_date: formValues.until_date,
            until_time: formValues.until_time,
        };

        const tableHeaders = columns.preventionPlanOverviewForReport.map(
            column => {
                return column.Header;
            }
        );

        const tableRows = responseData.map(data => {
            return [
                data.guest.full_name,
                t(data.type),
                data?.signals?.map(signal => t(signal)),
                data.notes,
                data.followed_up ? t("done") : t("followup"),
                dayjs(data.created_at).format("DD-MM-YYYY HH:mm"),
            ];
        });

        exportPdfTableByDate(tableHeaders, tableRows, filterDates);
    };

    const onSubmit = async data => {
        setIsSubmitting(true);
        await requestExportDataByDate("/api/prevention-plan-records", data)
            .then(responseData => {
                close();
                prepareExport(responseData, data);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Popup>
            <S.Header>
                {t("export")}: {entityName}
            </S.Header>
            <S.ExportTablePopupForm onSubmit={handleSubmit(onSubmit)}>
                <ExportDateFields register={register} errors={errors} />
                <FormSelect
                    formKey="type"
                    label={t("type_of_intervention") + ":"}
                    options={oldReportTypeOptions}
                    placeholder={t("select_type_talk")}
                    required={false}
                    {...formFunctions}
                />
                <Button
                    className="btn-secondary"
                    type="submit"
                    isLoading={isSubmitting}
                >
                    {t("export")}
                </Button>
            </S.ExportTablePopupForm>
        </Popup>
    );
}

export default ExportPreventionPlanOverviewPopup;
