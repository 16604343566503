import React, { useState, useEffect } from "react";
import * as S from "./SystemStatusBlock.styled";
import { useTranslation } from "react-i18next";
import client from "../../client";
import dayjs from "dayjs";
import { TranslationsKeys } from "../../types/translations";
import useCustomToast from "../../hooks/useCustomToast";

type Status = { cloud: string; cruks: string };

function SystemStatusBlock() {
    const { t } = useTranslation();
    const [status, setStatus] = useState<Status | null>(null);
    const [time, setTime] = useState<number | null>(null);
    const { customToast } = useCustomToast();

    const headers = [
        {
            name: t("module"),
        },
        { name: t("status") },
        { name: t("last_checked") },
        { name: t("maintenance") },
    ];

    useEffect(() => {
        client
            .get(`/api/system/status`)
            .then(res => {
                setTime(new Date().getTime());
                setStatus(res.data);
            })
            .catch(err => {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.message,
                });
            });
    }, [customToast, t]);

    if (!status) return null;

    return (
        <S.SystemStatusBlock>
            <S.Headers>
                {headers.map((header, index) => (
                    <S.Header key={index}>{header.name}</S.Header>
                ))}
            </S.Headers>

            {Object.entries(status).map(([key, value]) => {
                return (
                    <S.SystemsBody key={key}>
                        <S.Systems>
                            <S.SystemNames>
                                {t(key as TranslationsKeys) as string}
                            </S.SystemNames>
                        </S.Systems>
                        <S.Systems>
                            <S.SystemStatus statusText={value}>
                                {t(value as TranslationsKeys) as string}
                            </S.SystemStatus>
                        </S.Systems>
                        <S.Systems>
                            <S.SystemCheckedTime>
                                {dayjs(time).format("DD-MM-YYYY, HH:mm")}{" "}
                            </S.SystemCheckedTime>
                        </S.Systems>
                        <S.Systems>
                            <S.SystemCheckMaintanance>
                                Coming soon...
                            </S.SystemCheckMaintanance>
                        </S.Systems>
                    </S.SystemsBody>
                );
            })}
        </S.SystemStatusBlock>
    );
}

export default SystemStatusBlock;
