import {
    ChangeEventHandler,
    CSSProperties,
    Dispatch,
    SetStateAction,
    useState,
} from "react";
import userChip from "../../assets/chip-user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import WebcamPopup from "../Popups/WebcamPopup";

const StaffProfilePhoto = ({
    edit,
    userPhoto,
    setUserPhoto,
    profilePhoto,
}: {
    edit: boolean;
    userPhoto: {
        url: string;
        file: File;
    } | null;
    setUserPhoto: Dispatch<
        SetStateAction<{
            url: string;
            file: File;
        } | null>
    >;
    profilePhoto?: string;
}) => {
    const [showWebcamPopup, setShowWebcamPopup] = useState<boolean>(false);

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = e => {
        e.target.files &&
            e.target.files[0] &&
            setUserPhoto({
                url: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
            });
    };

    return (
        <>
            {showWebcamPopup && (
                <WebcamPopup
                    setUserPhoto={setUserPhoto}
                    setShowWebcamPopup={setShowWebcamPopup}
                />
            )}
            <div className="relative">
                {edit ? (
                    <img
                        className="h-72 w-72 rounded-full border"
                        src={userPhoto?.url || profilePhoto}
                        alt="profile"
                    />
                ) : (
                    <img
                        className="h-72 w-72 rounded-full border"
                        src={userPhoto?.url || userChip}
                        alt="profile"
                    />
                )}
                <div className="absolute right-7 bottom-0 h-16 w-16 rounded-full bg-[#e0e0e0]">
                    <input
                        type="file"
                        id="img"
                        name="photo"
                        accept="image/*"
                        onChange={handleFileChange}
                        hidden
                    />
                    <label
                        htmlFor="img"
                        className="flex h-full w-full cursor-pointer items-center justify-center"
                    >
                        <FontAwesomeIcon
                            icon={faPlus as IconProp}
                            size="2x"
                            style={
                                {
                                    "--fa-primary-color": "#000",
                                    "--fa-secondary-color": "#000",
                                    "--fa-primary-opacity": "1.0",
                                    "--fa-secondary-opacity": "0.9",
                                } as CSSProperties
                            }
                        />
                    </label>
                </div>
                <button
                    className="absolute left-7 bottom-0 h-16 w-16 rounded-full bg-[#e0e0e0]"
                    onClick={() => {
                        setShowWebcamPopup(true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCamera as IconProp}
                        size="2x"
                        style={
                            {
                                "--fa-primary-color": "#000",
                                "--fa-secondary-color": "#000",
                                "--fa-primary-opacity": "1.0",
                                "--fa-secondary-opacity": "0",
                            } as CSSProperties
                        }
                    />
                </button>
            </div>
        </>
    );
};

export default StaffProfilePhoto;
