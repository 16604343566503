import { useCallback } from "react";
import { usePopupStore } from "../store/popupStore";
import { PopupData, PopupType } from "../types/popup";

const usePopup = () => {
    const { setPopupType, setData } = usePopupStore();

    const popup = useCallback(
        ({ type, data }: { type: PopupType; data?: PopupData }) => {
            if (!type) return;
            setPopupType(type);
            data && setData(data);
        },
        [setPopupType, setData]
    );

    const close = useCallback(() => {
        setPopupType(null);
        setData(null);
    }, [setData, setPopupType]);

    return { popup, close };
};

export default usePopup;
