import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import client from "../client";

import useCustomToast from "./useCustomToast";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

type Params = {
    [key: string]: string | number | boolean;
};

function useInfiniteScroll(url: string, requestParams?: Params) {
    const containerRef = useRef();
    const [filteredData, setFilteredData] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const { customToast } = useCustomToast();
    const { t } = useTranslation();
    const fetchKey = url.split("/api/")[1];

    const [sortParams, setSortParams] = useState<{
        sort: string | null;
        sort_direction: "desc" | "asc" | null;
    }>({
        sort: null,
        sort_direction: null,
    });
    const [params, setParams] = useState({});

    const defaultParams = useMemo(() => {
        return (
            requestParams ?? {
                per_page: 20,
            }
        );
    }, [requestParams]);

    const fetchData = (params: Params) => {
        return client
            .get(url, {
                params: { ...defaultParams, ...params, ...sortParams },
            })
            .then(res => res.data)
            .catch(err =>
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.message,
                })
            );
    };

    const { data: allData, isFetching: isLoading } = useQuery(
        [fetchKey, params],
        () =>
            fetchData({
                ...params,
            }),
        {
            keepPreviousData: true,
        }
    );

    useEffect(() => {
        if (allData) {
            setFilteredData(allData.data);
            setNextPageUrl(allData?.links?.next);
        }
    }, [allData]);

    const onScroll = useCallback(() => {
        if (!containerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (scrollTop + clientHeight === scrollHeight) {
            nextPageUrl &&
                client.get(`${nextPageUrl}`).then(res => {
                    setFilteredData(filteredData.concat(res.data.data));
                    setNextPageUrl(res.data.links.next);
                });
        }
    }, [nextPageUrl, filteredData]);

    const updateSortParams = useCallback(
        (sortKey: string, direction: "desc" | "asc", useKey = false) => {
            setSortParams(() => {
                if (direction) {
                    return {
                        sort: sortKey,
                        sort_direction: direction,
                    };
                }

                if (useKey && !direction) {
                    return { sort: sortKey, sort_direction: null };
                }

                return { sort: null, sort_direction: null };
            });
        },
        []
    );

    const updateFilterParams = useCallback((params: Params) => {
        setParams(prevParams => {
            // Only reset the page url if the params have changed
            if (JSON.stringify(params) !== JSON.stringify(prevParams)) {
                setNextPageUrl(null);
            }
            return params;
        });
    }, []);

    return {
        onScroll,
        updateFilterParams,
        containerRef,
        isLoading,
        updateSortParams,
        filteredData,
    };
}

export default useInfiniteScroll;
