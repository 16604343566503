import styled from "styled-components";

export const InfoBoxes = styled.div`
    display: flex;
    gap: 50px;
`;
export const InfoBox = styled.div`
    width: 155px;
    height: 170px;
    background: #fffbfb;
    border-radius: 4px;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
`;

const handleColorType = background => {
    switch (background) {
        case "signalling":
            return "#CEF9D7";
        case "pamphlet":
            return "#FFEBD9";
        case "trace_card":
            return "#FFEBD9";
        case "play_pause_offer":
            return "#FFD2CF";
        case "involuntary_cruks":
            return "#FFD2CF";
        case "re-entry_talk":
            return "#FBDEC6";
        default:
            return "#fff";
    }
};

export const InfoBoxHeader = styled.button`
    background: ${({ background }) => handleColorType(background)};
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
    height: 64px;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: ${props => props.color};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    text-decoration: none;
    word-break: break-word;
    width: 100%;
    text-align: left;
`;
export const InfoBoxBody = styled.div`
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
`;

export const InfoTextDone = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
`;
export const InfoNumberDone = styled.div`
    font-size: 20px;
    line-height: 23px;

    color: #27ae60;
`;
export const InfoTextPending = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
`;
export const InfoNumberPending = styled.div`
    font-size: 20px;
    line-height: 23px;
    color: #eb5757;
`;

export const InfoBoxStatus = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
