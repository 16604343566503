import { useCallback } from "react";
import Dropdown from "./Dropdown";
import * as S from "./Filter.styled";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";

export default function DateSelect({
    label,
    setAppliedFilters,
    options,
    dateRange,
    setDateRange,
}) {
    const { t } = useTranslation();

    const applyFilter = useCallback(
        value => {
            setAppliedFilters(prev => {
                const copy = [...prev];

                const index = copy.findIndex(filter => filter.key === "date");
                let params = {};

                value?.params?.date_from &&
                    (params.date_from = `${value.params.date_from}T00:00:00`);
                value?.params?.date_until &&
                    (params.date_until = `${value.params.date_until}T23:59:59`);

                const data = {
                    ...value,
                    params,
                };
                if (index === -1) {
                    copy.push({
                        key: "date",
                        selectedValue: data,
                    });
                    return copy;
                }
                copy[index].selectedValue = data;
                return copy;
            });
        },
        [setAppliedFilters]
    );

    const handleChange = e => {
        const { name: key, value } = e.target;

        setDateRange(prev => {
            const prevFrom = prev.params?.date_from;
            const prevUntil = prev.params?.date_until;

            let updatedFromDate = key === "date_from" ? value : prevFrom;
            let updatedUntilDate = key === "date_until" ? value : prevUntil;

            // Handle both being empty
            if (!value && (!prevUntil || !prevFrom)) {
                setAppliedFilters(prev => {
                    const copy = [...prev];
                    const index = copy.findIndex(
                        filter => filter.key === "date"
                    );

                    if (index !== -1) {
                        copy.splice(index, 1);
                    }

                    return copy;
                });
                return {};
            }

            let label = `${dayjs(updatedFromDate).format(
                "DD-MM-YYYY"
            )} - ${dayjs(updatedUntilDate).format("DD-MM-YYYY")}`;

            if (updatedFromDate && !updatedUntilDate) {
                label = `${t("from")}: ${dayjs(updatedFromDate).format(
                    "DD-MM-YYYY"
                )}`;
            }

            if (!updatedFromDate && updatedUntilDate) {
                label = `${t("to")}: ${dayjs(updatedUntilDate).format(
                    "DD-MM-YYYY"
                )}`;
            }

            const newDateRange = {
                label,
                params: {
                    date_from: updatedFromDate,
                    date_until: updatedUntilDate,
                },
            };

            applyFilter(newDateRange);

            return newDateRange;
        });
    };

    return (
        <Dropdown label={label}>
            <S.DateSelect>
                {options.map((value, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            applyFilter(value);
                            document.getElementById("date-form").reset();
                            setDateRange({});
                        }}
                    >
                        {value.label}
                    </button>
                ))}
                <form onChange={handleChange} id="date-form">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between">
                                    <span>{t("choose_dates")}...</span>
                                    <FontAwesomeIcon
                                        icon={
                                            open ? faChevronUp : faChevronDown
                                        }
                                        style={{
                                            "--fa-primary-color": "#000",
                                            "--fa-secondary-color": "#000",
                                            "--fa-primary-opacity": "1.0",
                                            "--fa-secondary-opacity": "1.0",
                                        }}
                                        size="1x"
                                    />
                                </Disclosure.Button>

                                <Disclosure.Panel className="px-4">
                                    <div>
                                        <S.DateSelectContainer>
                                            <span>{t("from")}:</span>
                                            <input
                                                type="date"
                                                defaultValue={
                                                    dateRange.params?.date_from
                                                }
                                                min="2020-06-01"
                                                max={dayjs(new Date()).format(
                                                    "YYYY-MM-DD"
                                                )}
                                                name="date_from"
                                                required={true}
                                            />
                                        </S.DateSelectContainer>
                                    </div>
                                    <S.DateSelectContainer>
                                        <span>{t("to")}:</span>
                                        <input
                                            type="date"
                                            max={dayjs(new Date()).format(
                                                "YYYY-MM-DD"
                                            )}
                                            defaultValue={
                                                dateRange.params?.date_until
                                            }
                                            name="date_until"
                                            required={true}
                                        />
                                    </S.DateSelectContainer>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </form>
            </S.DateSelect>
        </Dropdown>
    );
}
