import * as S from "./Statistics.styled";
import PageHeader from "../PageHeader";
import { useTranslation } from "react-i18next";
import PreventionPlanActions from "./PreventionPlanActions";
// import BannedGuests from "./BannedGuests";
//import GuestRiskLevel from "./GuestRiskLevel";
import GuestsToday from "./GuestToday/GuestsToday";
import EntryCountChart from "./EntryCountChart/EntryCountChart";
import GuestCountMap from "./GuestCountMap/GuestCountMap";
import Filter from "../Filter";
import { useCallback, useMemo, useState } from "react";
import { dateOptions } from "../../utils/filterOptions";
import dayjs from "dayjs";
import client from "../../client";
import { Stats } from "../../types/statistics";
import { useUserStore } from "../../store/userStore";
import useCustomToast from "../../hooks/useCustomToast";

function Statistics() {
    const { t } = useTranslation();
    const [filteredStats, setFilteredStats] = useState<Stats | null>(null);
    const { user } = useUserStore();
    const { customToast } = useCustomToast();

    const dateFrom = useMemo(
        () => dayjs().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss"),
        []
    );

    const updateData = useCallback(
        async (params: any) => {
            if (!user) return;

            const defaultParams = {
                date_from: dateFrom,
            };

            try {
                const res = await client.get("/api/statistics", {
                    params: { ...defaultParams, ...params },
                });
                setFilteredStats(res.data);
            } catch (err: any) {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            }
        },
        [customToast, dateFrom, t, user]
    );

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    return (
        <>
            <PageHeader title={t("statistics")} />
            {/* 
            // @ts-ignore */}
            <Filter filters={filters} updateFilterParams={updateData} />
            <S.Grid>
                <EntryCountChart entryHistory={filteredStats?.entry_history} />
                <GuestCountMap floorNumbers={filteredStats?.guest_counts} />
                <GuestsToday entriesStats={filteredStats?.entries} />
                <PreventionPlanActions
                    preventionStats={filteredStats?.prevention}
                />
                {/* <BannedGuests /> */}
                {/* <GuestRiskLevel /> */}
            </S.Grid>
        </>
    );
}

export default Statistics;
