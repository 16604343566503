import styled from "styled-components";
export {
    Form,
    FormControl,
    Select,
    AddItem,
    RemoveItem,
} from "../../style/shared/form.styled";
export { SignatureContainer } from "../../style/shared/signature.styled";

export const FormBottom = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    justify-content: space-around;
`;

export const CheckMark = styled.div`
    top: -3px;
    position: relative;
    svg {
        color: ${({ theme }) => theme.main.successColor};
        font-size: 30px;
    }
`;

export const FormWarnings = styled.div`
    margin: 0 0 20px 200px;
`;

export const FormWarning = styled.div`
    color: ${({ theme }) => theme.main.secondaryColor};
    margin: 0 0 10px 0;
    display: flex;
    svg {
        margin-right: 6px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ResetButton = styled.button`
    margin-top: 30px;
    font-size: 20px;
    border-bottom: 2px solid black;
    padding: 0 0 2px 0;
    svg {
        margin-right: 4px;
    }

    &:disabled {
        opacity: 25%;
        cursor: no-drop;
    }
`;

export const PlayerAction = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 20px auto 0px;
    max-width: 400px;
    border-radius: 4px;
    background: #ffebd9;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    svg {
        color: #f2994a;
        margin-right: 10px;
    }
`;
