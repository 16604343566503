import axios from "axios";
import { SanctumOptions } from "./types/sanctum";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-USER-LANGUAGE": window.localStorage.getItem("lang") || "en",
        Accept: "application/json",
    },
} as SanctumOptions);

client.get("/sanctum/csrf-cookie");

export default client;
