import { jsPDF } from "jspdf";
import dayjs from "dayjs";
import i18n from "i18next";

export const preventionSinglePdf = (reportPopup, logo) => {
    const doc = new jsPDF({
        orientation: "p",
    });

    doc.addImage(logo, "PNG", 17, 10, 40, 18);

    doc.setFontSize(11);
    doc.setFont("Helvetica", "", 700);
    doc.text(150, 18, i18n.t("date"));
    doc.setFont("Helvetica", "", 400);
    doc.text(150, 23, dayjs(new Date()).format("DD-MM-YYYY HH:mm"));
    const boldText = () => doc.setFont("Helvetica", "", 700);
    const lightText = () => doc.setFont("Helvetica", "", 400);
    const textAdd = (
        sequenceNummer,
        text,
        customlength = 0,
        typeFont,
        pageAllowed = true
    ) => {
        let offsetY = 6.797777777777778;
        let lineHeight = 6.49111111111111;
        let maxWidth = 170;
        let xPos = 20;
        let yPos = 40;

        typeFont === "bold" ? boldText() : lightText();

        const pageHeight = doc.internal.pageSize.height;

        if (
            yPos + lineHeight * sequenceNummer + offsetY * customlength >
            pageHeight
        ) {
            pageAllowed &&
                doc.text(20, 280, i18n.t("form_continues_next_page"));
            pageAllowed && doc.addPage();

            yPos = 40;
            sequenceNummer = pageAllowed === false ? 1 : 0;
            customlength = 0;
        }

        doc.text(
            xPos,
            yPos + lineHeight * sequenceNummer + offsetY * customlength,
            text ? text : "",
            { maxWidth: maxWidth }
        );
    };
    textAdd(0, i18n.t("guest_name") + ":", 0, "bold");
    textAdd(1, reportPopup.state.guest.full_name, 0, "light");
    textAdd(2, i18n.t("prevention_report_created") + ":", 0, "bold");
    textAdd(
        3,
        dayjs(reportPopup.state.created_at).format("DD-MM-YYYY HH:mm"),
        0,
        "light"
    );
    textAdd(4, i18n.t("type_of_intervention") + ":", 0, "bold");
    textAdd(5, reportPopup.state.type, 0, "light");
    textAdd(6, i18n.t("signals") + ":", 0, "bold");
    textAdd(
        7,
        reportPopup?.state?.signals?.map(
            singal => {
                return i18n.t(singal);
            },
            0,
            "light"
        )
    );

    let signalLength = reportPopup?.state?.signals?.length;
    textAdd(8, i18n.t("guest_reaction") + ":", signalLength / 1.7, "bold");
    textAdd(9, reportPopup.state.reaction, signalLength / 1.7, "light");

    let splitNotes = doc.splitTextToSize(reportPopup.state.notes, 165);

    if (splitNotes.length > 33) {
        const half = Math.ceil(splitNotes.length / 2);

        const firstHalf = splitNotes.slice(0, half);

        const secondHalf = splitNotes.slice(-half);
        textAdd(10, i18n.t("notes") + ":", signalLength / 1.7, "bold");
        textAdd(11, firstHalf.toString(), signalLength / 1.7, "light");
        doc.setFont("Helvetica", "", 700);
        doc.text(20, 280, i18n.t("form_continues_next_page"));
        doc.addPage();
        textAdd(0, secondHalf.toString(), 0, "light");
        textAdd(
            1,
            i18n.t("advice_agreement") + ":",
            splitNotes.length < 4 ? 4 : secondHalf.length / 1.7,
            "bold"
        );

        textAdd(
            2,
            reportPopup.state.conclusion,
            splitNotes.length < 4 ? 4 : secondHalf.length / 1.7,
            "light"
        );
    } else {
        textAdd(10, i18n.t("notes") + ":", signalLength / 1.7, "bold");
        textAdd(11, reportPopup.state.notes, signalLength / 1.7, "light");

        textAdd(
            12,
            i18n.t("advice_agreement") + ":",
            splitNotes.length < 4 ? 4 : splitNotes.length / 0.9,
            "bold"
        );

        textAdd(
            13,
            reportPopup.state.conclusion,
            splitNotes.length < 4 ? 4 : splitNotes.length / 0.9,
            "light",
            false
        );
    }

    doc.save(
        `${
            reportPopup.state.guest.full_name +
            "_" +
            dayjs(new Date()).format("DD-MM-YYYY HH:mm")
        }`
    );
};
