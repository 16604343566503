import { useCallback, useRef, useState } from "react";
import ReactSlider from "react-slider";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SelectBox from "./SelectBox";
import DropDownContainer from "./DropDownContainer";

type AgeRange = [number, number];

type Props = {
    label: string;
    setAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFilter[]>>;
};

type SelectedValue = {
    value: string;
    label: string;
    params: {
        [key: string]: string | number | boolean;
    };
};

type AppliedFilter = {
    label: string;
    selectedValue: SelectedValue;
    key: string;
};

const lowerLimit = 18;
const upperLimit = 90;

function RangeSelect({ label, setAppliedFilters }: Props) {
    const [ageRange, setAgeRange] = useState<AgeRange>([
        lowerLimit,
        upperLimit,
    ]);
    const [localAgeRange, setLocalAgeRange] = useState<AgeRange>([
        lowerLimit,
        upperLimit,
    ]);

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(dropdownRef, () => setIsOpen(false));

    function handleRangeChange(value: AgeRange) {
        let minAge = lowerLimit;
        let maxAge = upperLimit;

        if (isNaN(value[0]) || isNaN(value[1])) {
            minAge = ageRange[0];
            maxAge = ageRange[1];
        } else {
            minAge = Math.max(lowerLimit, Math.min(value[0], value[1]));
            maxAge = Math.min(upperLimit, Math.max(value[0], value[1]));
        }

        setAgeRange([minAge, maxAge]);
        setLocalAgeRange([minAge, maxAge]);
    }

    function handleInputChange(value: AgeRange) {
        setLocalAgeRange(value);
    }

    const applyFilter = useCallback(() => {
        setAppliedFilters(prev => {
            const copy = [...prev];
            const index = copy.findIndex(filter => filter.key === "age");

            const data = {
                value: `${ageRange[0]} - ${ageRange[1]}`,
                label: `${ageRange[0]} - ${ageRange[1]}`,
                params: {
                    age_from: ageRange[0],
                    age_to: ageRange[1],
                },
            };

            if (index === -1) {
                copy.push({
                    key: "age",
                    label,
                    selectedValue: data,
                });
                return copy;
            }
            copy[index].selectedValue = data;
            return copy;
        });
    }, [ageRange, label, setAppliedFilters]);

    return (
        <div ref={dropdownRef}>
            <SelectBox label={label} isOpen={isOpen} setIsOpen={setIsOpen} />
            <DropDownContainer isOpen={isOpen}>
                <div className="p-6">
                    <div className="mb-6 text-base">{t("select_age")}</div>
                    <div className="mb-2 flex h-12 w-48">
                        <input
                            type="number"
                            value={localAgeRange[0]}
                            onChange={e =>
                                handleInputChange([
                                    parseInt(e.target.value),
                                    ageRange[1],
                                ])
                            }
                            onBlur={e =>
                                handleRangeChange([
                                    parseInt(e.target.value),
                                    ageRange[1],
                                ])
                            }
                            className="flex h-full w-full grow appearance-none items-center justify-center bg-[#F8F6F1] pl-4 text-center shadow"
                        />
                        <div className="w-10 shrink-0 text-center">-</div>
                        <input
                            type="number"
                            value={localAgeRange[1]}
                            onChange={e =>
                                handleInputChange([
                                    ageRange[0],
                                    parseInt(e.target.value),
                                ])
                            }
                            onBlur={e =>
                                handleRangeChange([
                                    ageRange[0],
                                    parseInt(e.target.value),
                                ])
                            }
                            className="flex h-full w-full grow appearance-none items-center justify-center bg-[#F8F6F1] pl-4 text-center shadow"
                        />
                    </div>
                    <ReactSlider
                        className="mb-4 flex h-12 w-48 cursor-pointer items-center"
                        defaultValue={[lowerLimit, upperLimit]}
                        ariaLabel={[t("lower_thumb"), t("upper_thumb")]}
                        ariaValuetext={state =>
                            `${t("thumb_value")} ${state.valueNow}`
                        }
                        renderThumb={props => (
                            <div
                                {...props}
                                className="h-6 w-6 rounded-full bg-blue-light"
                            ></div>
                        )}
                        renderTrack={(props, state) => {
                            return (
                                <div
                                    {...props}
                                    className={`h-0.5 ${
                                        state.index === 0 || state.index === 2
                                            ? "bg-gray-300"
                                            : "bg-blue-light"
                                    }`}
                                />
                            );
                        }}
                        pearling
                        minDistance={2}
                        min={lowerLimit}
                        max={upperLimit}
                        value={ageRange}
                        onChange={handleRangeChange}
                    />
                    <div className="flex justify-center">
                        <button
                            className="btn-secondary w-30"
                            onClick={() => applyFilter()}
                        >
                            {t("apply")}
                        </button>
                    </div>
                </div>
            </DropDownContainer>
        </div>
    );
}

export default RangeSelect;
