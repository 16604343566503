import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import mainRoutes from "../routes/mainRoutes";
import entryRoutes from "../routes/userEntryRoutes";
import client from "../client";
import { useUserStore } from "../store/userStore";
import handleAsync from "../utils/handleAsync";
import { User } from "../types/user";
import { AxiosResponse } from "axios";

function useAuth() {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();

    const matchedAuthPath = mainRoutes.find(route =>
        matchPath(location.pathname, route)
    );

    const matchedEntryPath = entryRoutes.find(route =>
        matchPath(location.pathname, route)
    );

    const { setUser } = useUserStore();

    const getAuthRedirectPath = useCallback(async () => {
        const [userValidResponse, userError] = await handleAsync<
            AxiosResponse<User>
        >(client.get<User>("/api/user"));

        userValidResponse && setUser(userValidResponse.data);

        const defaultPage = "/check-in";

        if (userValidResponse && matchedAuthPath) {
            const user = userValidResponse.data;

            const hasRequiredPermissions = matchedAuthPath.hasOwnProperty(
                "permissionsRequired"
            )
                ? matchedAuthPath.permissionsRequired.every(item =>
                      user.permissions.includes(item)
                  )
                : true;

            if (hasRequiredPermissions) return null;

            return defaultPage;
        }

        if (userValidResponse && matchedEntryPath) {
            return defaultPage;
        }

        if (userValidResponse && !matchedAuthPath && !matchedEntryPath) {
            return defaultPage;
        }

        if (userError && matchedAuthPath) return "/";

        return null;
    }, [matchedAuthPath, matchedEntryPath, setUser]);

    useEffect(() => {
        getAuthRedirectPath().then(url => {
            url && history.push(url);
            setIsLoading(false);
        });
    }, [getAuthRedirectPath, history]);

    return { isLoading };
}

export default useAuth;
