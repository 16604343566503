import { useState } from "react";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";
import mainRoutes from "../../routes/mainRoutes";
import { useLocation } from "react-router-dom";
import PopupContainer from "../Popups/PopupContainer";
import useGuestCountReminder from "../../hooks/useGuestCountReminder";
import useGlobalCheckin from "../../hooks/useGlobalCheckin";

export default function MainContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
    const location = useLocation();

    const currentRoute = mainRoutes.filter(route => {
        return route.path === location.pathname;
    });

    useGlobalCheckin();

    useGuestCountReminder();

    const renderLayout = () => {
        if (currentRoute[0] && currentRoute[0].noMenu) {
            return (
                <div className="relative min-h-screen">
                    <div className="pl-0">
                        <div className="max-w-full">{children}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="relative min-h-screen">
                    <PopupContainer />
                    <SideMenu
                        showMobileMenu={showMobileMenu}
                        setShowMobileMenu={setShowMobileMenu}
                    />
                    <Header setShowMobileMenu={setShowMobileMenu} />
                    <div className="pt-16 pl-0 3xl:pl-[200px]">
                        <div className="5xl:py-10 relative flex min-h-[calc(100vh_-_90px)] max-w-full flex-col p-24 xl:px-9">
                            {children}
                        </div>
                    </div>
                </div>
            );
        }
    };

    return <>{renderLayout()}</>;
}
