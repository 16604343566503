import { CheckInFields } from "../types/checkIn";
import create from "zustand";

type FieldsStore = {
    fields: Partial<CheckInFields>;
    setFields: (fields: CheckInFields | {}) => void;
};
export const useCheckinFields = create<FieldsStore>(set => ({
    fields: {},
    setFields: fields =>
        set(state => {
            return { ...state, fields };
        }),
}));
