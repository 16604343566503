import { lazy, Suspense } from "react";
import * as S from "./Statistics.styled";
import { useTranslation } from "react-i18next";
import { PreventionStats } from "../../types/statistics";
import { TranslationsKeys } from "../../types/translations";
import BlockHeader from "./BlockHeader/BlockHeader";

const Chart = lazy(() => import("react-apexcharts"));

function PreventionPlanActions({
    preventionStats,
}: {
    preventionStats: PreventionStats | undefined;
}) {
    const { t } = useTranslation();

    const chartData = {
        options: {
            chart: {
                id: "basic-bar",
            },
            xaxis: {
                categories: Object.keys(preventionStats ?? {}).map(key =>
                    t(key as TranslationsKeys)
                ),
            },
            colors: ["#9998a9"],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                },
            },
        },
        series: [
            {
                name: t("actions"),
                data: Object.values(preventionStats ?? {}),
            },
        ],
    };

    return (
        <S.Block>
            <BlockHeader
                title={t("prevention_plan_actions")}
                subTitle={t("all_locations")}
                link="/prevention-plan-overview"
            />
            <Suspense fallback="Loading chart...">
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={250}
                />
            </Suspense>
        </S.Block>
    );
}

export default PreventionPlanActions;
