export const queryMaker = (formFields: {}, prefix?: string): string => {
    const str = [];

    for (let p in formFields) {
        if (
            formFields.hasOwnProperty(p) &&
            formFields[p as keyof typeof formFields]
        ) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = formFields[p as keyof typeof formFields];

            str.push(
                typeof v === "object"
                    ? queryMaker(v, k)
                    : decodeURIComponent(k) + "=" + encodeURIComponent(v)
            );
        }
    }
    return str.join("&");
};

export default queryMaker;
