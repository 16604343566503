import create from "zustand";
import client from "../client";
import { User } from "../types/user";

type UserStore = {
    user: User | null;
    setUser: (user: User | null) => void;
    getUser: () => void;
};

export const useUserStore = create<UserStore>(set => ({
    user: null,
    setUser: user =>
        set(state => {
            return { ...state, user };
        }),
    getUser: () => {
        client.get("/api/user").then(response => {
            set({ user: response.data });
        });
    },
}));
