import { ChangeEvent, useState } from "react";
import * as S from "./PreventionPlan.styled";
import PageHeader from "../../components/PageHeader";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, useWatch, Controller, SubmitHandler } from "react-hook-form";
import client from "../../client";
import Select from "react-select";
import { SelectOption } from "../../types/form";
import useFormResponseError from "../../hooks/useFormResponseError";
import {
    reportTypeOptions,
    guestReactionOptions,
    signalOptions,
} from "../../utils/preventionTalkOptions";
import {
    Form,
    FormBottom,
    FormTextArea,
    FormFiles,
    FormSearchPlayer,
    FormInputGroup,
} from "../../components/formPartials";
import useCustomToast from "../../hooks/useCustomToast";

type FormValues = {
    player_id: number;
    type: SelectOption;
    email: string;
    notes: string;
    reaction: SelectOption;
    conclusion: string;
    permanent_playpause: string;
    signals: string[];
};

const PreventionPlan = () => {
    const [textLengthNotes, setTextLengthNotes] = useState(0);
    const [textLengthConclusion, setTextLengthConclusion] = useState(0);
    const [files, setFiles] = useState([]);
    const history = useHistory();
    const { search } = useLocation();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { formResponseError } = useFormResponseError();
    const searchParams = new URLSearchParams(search);
    const defaultType = searchParams.get("type");

    const { customToast } = useCustomToast();

    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        watch,
        control,
        setError,
        formState: { errors, isSubmitted },
    } = useForm<FormValues>({
        defaultValues: {
            type: reportTypeOptions.find(
                option => option.value === defaultType
            ),
        },
    });

    const formFunctions = {
        register,
        errors,
        setValue,
        setFocus,
        isSubmitted,
        watch,
        control,
    };

    const signals = useWatch({ name: "signals", control: control });
    const includesSignals = signals ? signals?.includes("other") : false;

    const type = watch("type");

    const isSignallingSelected = type?.value === "signalling";

    const onSubmit: SubmitHandler<FormValues> = data => {
        setIsSubmitting(true);
        const apiData = new FormData();
        apiData.append("player_id", data.player_id.toString());
        apiData.append("type", data.type.value.toString());
        apiData.append("notes", data.notes);
        !isSignallingSelected &&
            apiData.append("reaction", data.reaction.value.toString());
        apiData.append("conclusion", data.conclusion);
        apiData.append("permanent_playpause", data.permanent_playpause);
        files.forEach(file => {
            apiData.append("files[]", file);
        });

        const signal = data.signals;
        signal.forEach(signal => {
            apiData.append("signals[]", signal);
        });

        client
            .post("/api/prevention-plan-records", apiData)
            .then(res => {
                customToast("success", {
                    title: t("prevention_report_created"),
                });

                history.push("/prevention-plan-overview");
            })
            .catch(err => {
                formResponseError(err, setError);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };
    const maxLengthNotes = 1000;
    const calc = (e: ChangeEvent<HTMLInputElement>) => {
        return e.target.value === " "
            ? 0
            : setTextLengthNotes(
                  e.target.value.replace(/[\t\n]/gm, " ").length
              );
    };

    const calConclusion = (e: ChangeEvent<HTMLInputElement>) => {
        return e.target.value === " "
            ? 0
            : setTextLengthConclusion(
                  e.target.value.replace(/[\t\n]/gm, " ").length
              );
    };

    const addPermanentPlaypause = [
        {
            value: 1,
            text: t("add_permanent_playpause"),
        },
    ];

    const convertedSignalOptions = signalOptions.map(option => ({
        text: option.label,
        value: option.value,
    }));

    return (
        <>
            <PageHeader
                title={t("prevention_plan")}
                showLists={false}
                showSearch={false}
                back={true}
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormSearchPlayer
                    required={true}
                    label={t("guest_details")}
                    {...formFunctions}
                />
                <S.FormControl error={errors.type} required={true}>
                    <label>{t("type_of_intervention")}</label>
                    <S.Select>
                        <Controller
                            name="type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder={t("select_type_talk")}
                                    options={reportTypeOptions}
                                />
                            )}
                        />
                    </S.Select>
                </S.FormControl>
                <FormInputGroup
                    formKey="signals"
                    label={t("signals")}
                    options={convertedSignalOptions}
                    type="checkbox"
                    required={type?.value === "signalling" ? true : false}
                    {...formFunctions}
                />
                <FormTextArea
                    formKey="notes"
                    // @ts-ignore
                    label={t("notes")}
                    placeholder={t("observation")}
                    row={"10"}
                    columns={"50"}
                    // @ts-ignore
                    maxLength={maxLengthNotes}
                    required={includesSignals}
                    lengthState={textLengthNotes}
                    handleCommentChange={calc}
                    {...formFunctions}
                />
                {!isSignallingSelected && (
                    <>
                        <S.FormControl error={errors.reaction} required={true}>
                            <label>{t("guest_reaction")}</label>
                            <S.Select>
                                <Controller
                                    name="reaction"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={t(
                                                "choose_what_was_the_guest_reaction"
                                            )}
                                            options={guestReactionOptions}
                                        />
                                    )}
                                />
                            </S.Select>
                        </S.FormControl>
                        <FormTextArea
                            formKey="conclusion"
                            // @ts-ignore
                            label={t("advice_agreement")}
                            placeholder={t("describe_what_was_agreed")}
                            row={"10"}
                            columns={"50"}
                            // @ts-ignore
                            maxLength={maxLengthNotes}
                            lengthState={textLengthConclusion}
                            handleCommentChange={calConclusion}
                            {...formFunctions}
                        />
                    </>
                )}
                <FormInputGroup
                    formKey="permanent_playpause"
                    label={t("empty_label")}
                    options={addPermanentPlaypause}
                    type="checkbox"
                    {...formFunctions}
                />
                {/* 
            // @ts-ignore */}
                <FormFiles
                    formKey="files"
                    label={t("files")}
                    setFiles={setFiles}
                    {...formFunctions}
                />
                <FormBottom buttonText={t("save")} isLoading={isSubmitting} />
            </Form>
        </>
    );
};

export default PreventionPlan;
