import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../store/userStore";
import { StaffFormValues } from "../../types/staff";
import { TranslationsKeys } from "../../types/translations";
import MultiSelect from "../MultiSelect/MultiSelect";

const StaffDetails = ({ control }: { control: Control<StaffFormValues> }) => {
    const { t } = useTranslation();
    const { user } = useUserStore();

    const availableLocations = () => {
        return (
            user?.locations.map(location => {
                return {
                    value: location.id,
                    label: location.name,
                };
            }) || []
        );
    };

    const availableRoles = () => {
        return (
            user?.roles
                .map(role => {
                    return {
                        value: role.id,
                        label: t(role.name as TranslationsKeys) as string,
                    };
                })
                .filter(role => {
                    // Make sure you can't add an admin
                    return role.value !== 1;
                }) || []
        );
    };

    return (
        <>
            <div className="mt-12">
                <label htmlFor="locations" className="form-label">
                    {t("locations")}:
                </label>
                <div className="form-control">
                    <div className="col-span-3">
                        <Controller
                            name="locations"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        onChange={field.onChange}
                                        options={availableLocations()}
                                        placeholder={t("add_locations") + "..."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <label className="mb-2 block text-xl" htmlFor="roles">
                    {t("roles")}:
                </label>
                <div className="form-control">
                    <div className="col-span-3">
                        <Controller
                            name="roles"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        onChange={field.onChange}
                                        options={availableRoles()}
                                        placeholder={t("add_roles") + "..."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaffDetails;
