import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, MouseEventHandler, useEffect, useState } from "react";
import {
    FieldErrorsImpl,
    UseFormRegister,
    UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StaffFormValues } from "../../types/staff";
import { classNames } from "../../utils/style";
import { faEye, faEyeSlash } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const isPasswordValid = (string: string) => {
    return !!string.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm
    );
};

const StaffForm = ({
    register,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    edit,
    setValue,
}: {
    register: UseFormRegister<StaffFormValues>;
    errors: Partial<FieldErrorsImpl<StaffFormValues>>;
    handleSubmit: any;
    watch: any;
    onSubmit: any;
    edit: boolean;
    setValue: UseFormSetValue<StaffFormValues>;
}) => {
    const { t } = useTranslation();
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
    const [isUsernameChanged, setIsUsernameChanged] = useState<boolean>(false);

    const togglePassword: MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        setIsPasswordShown(prev => !prev);
    };

    const firstName = watch("first_name");
    const lastName = watch("last_name");
    const password = watch("password");

    useEffect(() => {
        if (!firstName || !lastName || isUsernameChanged || edit) {
            return;
        }

        setValue(
            "username",
            `${firstName.toLowerCase()}.${lastName.toLowerCase()}`
        );
    }, [edit, firstName, isUsernameChanged, lastName, setValue]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[768px] max-w-full space-y-6 sm:space-y-5"
        >
            <div className="form-control">
                <label className="form-label">{t("email")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.email !== undefined && "error"
                    )}
                    type="email"
                    {...register("email", {
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                        },
                    })}
                />
            </div>
            <div className={classNames("form-control")}>
                <label className="form-label">{t("new_password")}</label>
                <div className="relative col-span-2">
                    <input
                        autoComplete="new-password"
                        className={classNames(
                            "form-input",
                            errors.password !== undefined && "error"
                        )}
                        type={isPasswordShown ? "text" : "password"}
                        {...register("password", {
                            required: !edit,
                            validate: value =>
                                !value
                                    ? true
                                    : isPasswordValid(value) ||
                                      t("password_strength_error"),
                        })}
                    />

                    <button
                        onClick={togglePassword}
                        className="absolute right-3 top-2"
                    >
                        <FontAwesomeIcon
                            icon={
                                isPasswordShown
                                    ? (faEye as IconProp)
                                    : (faEyeSlash as IconProp)
                            }
                            style={
                                {
                                    "--fa-primary-color": "#1D1E45",
                                    "--fa-secondary-color": "#1D1E45",
                                    "--fa-primary-opacity": "1.0",
                                    "--fa-secondary-opacity": "0.9",
                                    cursor: "pointer",
                                } as CSSProperties
                            }
                        />
                    </button>
                </div>
            </div>

            {errors?.password && (
                <div className="text-red-400">{errors.password.message}</div>
            )}

            <div className={classNames("form-control")}>
                <label className="form-label">{t("password_repeat")}</label>
                <div className="relative col-span-2">
                    <input
                        autoComplete="new-password"
                        className={classNames(
                            "form-input",
                            errors.password_repeat !== undefined && "error"
                        )}
                        type={isPasswordShown ? "text" : "password"}
                        {...register("password_repeat", {
                            validate: value => {
                                if (edit && !password && !value) {
                                    return true;
                                }
                                return (
                                    value === password ||
                                    t("password_matching_error")
                                );
                            },
                        })}
                    />
                    <button
                        onClick={togglePassword}
                        className="absolute right-3 top-2"
                    >
                        <FontAwesomeIcon
                            icon={
                                isPasswordShown
                                    ? (faEye as IconProp)
                                    : (faEyeSlash as IconProp)
                            }
                            style={
                                {
                                    "--fa-primary-color": "#1D1E45",
                                    "--fa-secondary-color": "#1D1E45",
                                    "--fa-primary-opacity": "1.0",
                                    "--fa-secondary-opacity": "0.9",
                                    cursor: "pointer",
                                } as CSSProperties
                            }
                        />
                    </button>
                </div>
            </div>
            {errors?.password_repeat && (
                <div className="text-red-400">
                    {errors.password_repeat.message}
                </div>
            )}
            <div className="form-control required">
                <label className="form-label">{t("first_name")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.first_name !== undefined && "error"
                    )}
                    type="input"
                    {...register("first_name", { required: true })}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("middle_name")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.middle_name !== undefined && "error"
                    )}
                    type="input"
                    {...register("middle_name")}
                />
            </div>
            <div className="form-control required">
                <label className="form-label">{t("last_name")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.last_name !== undefined && "error"
                    )}
                    type="input"
                    {...register("last_name", { required: true })}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("username")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.username !== undefined && "error"
                    )}
                    type="input"
                    {...register("username", {
                        onChange: e => {
                            setIsUsernameChanged(true);
                        },
                    })}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("date_of_birth")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.date_of_birth !== undefined && "error"
                    )}
                    type="date"
                    {...register("date_of_birth")}
                />
            </div>
            <div className="form-control">
                <label className="form-label">{t("working_since")}:</label>
                <input
                    className={classNames(
                        "form-input col-span-2",
                        errors.started_at !== undefined && "error"
                    )}
                    type="date"
                    {...register("started_at")}
                />
            </div>
        </form>
    );
};

export default StaffForm;
