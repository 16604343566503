import * as S from "./BlockHeader.styled";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
    title: string;
    subTitle?: string;
    link?: string;
};

function BlockHeader({ title, subTitle, link }: Props) {
    return (
        <S.BlockHeader>
            <S.BlockTitle>
                <span>{title}</span>
                {link && (
                    <Link to={link}>
                        <FontAwesomeIcon icon={faAngleRight as IconProp} />
                    </Link>
                )}
            </S.BlockTitle>
            {subTitle && <S.BlockSubTitle>{subTitle}</S.BlockSubTitle>}
        </S.BlockHeader>
    );
}

export default BlockHeader;
