import React from "react";
import PageHeader from "../../components/PageHeader";
import workInProgressImg from "../../assets/under-construction.jpg";
import { useTranslation } from "react-i18next";

export default function WorkInProgress() {
    const { t } = useTranslation();
    return (
        <>
            <PageHeader
                title={t("work_in_progress")}
                showLists={false}
                showSearch={false}
            />
            <img src={workInProgressImg} alt="" />
        </>
    );
}
