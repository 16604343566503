import styled from "styled-components";

export const Table = styled.div``;

export const RowHeader = styled.div`
    display: flex;
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
`;

export const Row = styled.div`
    margin-bottom: 10px;
    display: flex;
    &:last-child {
        margin: 0;
    }
`;

export const Cel = styled.div`
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    ${props => props.disabled && `opacity: 0.3;`}

    &:nth-child(1) {
        flex: 0 0 400px;
    }

    &:nth-child(2) {
        flex: 0 0 200px;
    }

    &:nth-child(3) {
        flex: 0 0 200px;
    }

    &:nth-child(4) {
        flex-grow: 1;
    }

    > input[type="text"] {
        height: 30px;
        padding: 10px;
        flex-grow: 1;
    }

    > span {
        cursor: pointer;
        ${props => !props.canEditName && `pointer-events: none;`}
    }

    > textarea {
        width: 100%;
        padding: 6px;
        font-size: 14px;
        border: 1px solid ${props => props.theme.main.borderColor};
    }
`;

export const AddNewField = styled.button`
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0 0 0 24px;
    padding: 0;
    svg {
        margin-right: 6px;
    }

    &:after {
        content: "";
        height: 1px;
        bottom: -3px;
        left: 0;
        right: 0;
        background: black;
        position: absolute;
    }
`;

export const Bottom = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    button + a {
        margin: 0 0 0 30px;
    }
`;

export const Edit = styled.button`
    color: ${props => props.theme.main.saveColor};
    > span {
        text-decoration: underline;
    }
    > svg {
        margin-right: 10px;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 30px;
`;
