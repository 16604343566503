import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    width: 1380px;
    gap: 20px;
`;
export const Box = styled.div`
    background: #fffbfb;
    border-radius: 10px;
    width: ${props => props.fullwidth} calc(100% * (4 / 8) - 10px - 1px);
    height: 450px;
    filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.1));
`;
export const BoxHeader = styled.div`
    color: ${props => props.color};
    background: ${props => props.background};
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
    > svg {
        margin-right: 10px;
        filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.15));
    }
    font-size: 20px;
    line-height: 23px;
`;
export const BoxPlans = styled.div`
    padding: 20px 40px;
    list-style: none;
    > ul {
        text-indent: -25px; /* key property */
        margin-left: 25px; /* key property */
    }
    font-size: 16px;
    line-height: 18px;
    color: #050505;
`;

export const BoxQuestion = styled.div`
    color: #c54d34;
    font-size: 20px;
    line-height: 23px;
    margin-top: 10px;
    margin-bottom: 15px;
`;

export const BoxQuestions = styled.li`
    margin-top: 5px;
    margin-left: 10px;
    font-size: 18px;
    line-height: 21px;
    list-style-type: disc;
    list-style-position: inside;
`;
export const BoxAction = styled.div`
    color: #c54d34;
    font-size: 20px;
    line-height: 23px;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export const BoxActions = styled.div`
    margin-top: 8px;
    margin-left: 20px;
    font-size: 18px;
    line-height: 21px;
`;

export const BoxLink = styled(Link)`
    font-size: 18px;
    line-height: 30px;
    display: flex;
    svg {
        margin-left: 10px;
    }
`;

export const BoxText = styled.li`
    font-size: 18px;
    line-height: 30px;
`;
