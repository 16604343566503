import React from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import * as S from "./CurrentGuests.styled";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAbacus } from "@fortawesome/pro-solid-svg-icons";
import useFetch from "../../hooks/useFetch";
import Button from "../Button";

const CurrentGuests = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { data: guestCountLog } = useFetch("guest-count-logs/last");

    const prepareFloorNumbers = guestCountLog => {
        const floorNumbers = guestCountLog?.data;

        return [
            {
                title: t("men"),
                count: floorNumbers?.men_count ?? 0,
            },
            {
                title: t("women"),
                count: floorNumbers?.women_count ?? 0,
            },
            {
                title: t("totals"),
                count: floorNumbers?.total_count ?? 0,
            },
        ];
    };

    const floorNumbers = prepareFloorNumbers(guestCountLog);

    return (
        <S.CurrentGuestsContainer>
            <S.Header>
                <FontAwesomeIcon icon={faAbacus} size="lg" />
                {t("current_counts")}{" "}
                {guestCountLog && (
                    <span>
                        {t("updated_at")}{" "}
                        {dayjs(guestCountLog.data.updated_at).format("H:mm")}
                    </span>
                )}
            </S.Header>

            <S.Floors>
                {floorNumbers.map((item, i) => (
                    <S.Floor key={i}>
                        <S.FloorLabel>{item.title}</S.FloorLabel>
                        <S.FloorCount>{item.count}</S.FloorCount>
                    </S.Floor>
                ))}
            </S.Floors>
            <div className="mb-6 flex justify-center">
                <Button
                    className="btn-primary"
                    onClick={() => {
                        history.push("/update-floor-numbers");
                    }}
                >
                    {t("update")}
                </Button>
            </div>
        </S.CurrentGuestsContainer>
    );
};

export default CurrentGuests;
