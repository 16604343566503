import React from "react";
import dayjs from "dayjs";
import * as S from "./UpdateFloorNumbers.styled";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import FloorNumbersUpdate from "../../components/FloorNumbersUpdate";
import useFetch from "../../hooks/useFetch";
import { useHistory } from "react-router-dom";
import { faAbacus } from "@fortawesome/pro-regular-svg-icons";

const UpdateFloorNumbers = () => {
    const { t } = useTranslation();
    const { data: guestCountLogs } = useFetch("guest-count-logs");
    const history = useHistory();
    const floorNumbers = guestCountLogs
        ? guestCountLogs.data.map(item => ({
              id: item.id,
              time: dayjs(item.updated_at).format("H:mm"),
              men: item.men_count,
              women: item.women_count,
              total: item.total_count,
          }))
        : [];

    const pageHeaderButtons = [
        {
            text: t("floor_numbers_overview"),
            clickHandler: () => history.push("/floor-numbers-overview"),
            type: "pageHeader",
            faIcon: faAbacus,
        },
    ];

    return (
        <>
            <PageHeader
                title={t("update_floor_numbers")}
                buttons={pageHeaderButtons}
            />
            <S.Container>
                <FloorNumbersUpdate
                    title={t("time")}
                    floorNumbers={floorNumbers}
                />
            </S.Container>
        </>
    );
};

export default UpdateFloorNumbers;
