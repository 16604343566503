import * as S from "./AddDevicePopup.styled";
import Popup from "../Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { useCallback, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";
import socket from "../../../socket";
import usePopup from "../../../hooks/usePopup";
import useFetch from "../../../hooks/useFetch";
import client from "../../../client";
import { Device, DeviceLocalKey, DeviceType } from "../../../types/devices";
import { Response } from "../../../types/api/api";
import Button from "../../Button";

function AddDevicePopup({
    type,
    set,
}: {
    type: DeviceType;
    set: (type: DeviceType, identifier: string) => void;
}) {
    const [devices, setDevices] = useState<Device[]>([]);
    const [newDevices, setNewDevices] = useState<Device[]>([]);
    const [key, setKey] = useState<DeviceLocalKey>("acarat_id_scanner");

    const { t } = useTranslation();
    const [activeDeviceId, setActiveDeviceId] = useState<string | null>(null);
    const { close } = usePopup();

    const { data } = useFetch<Response<Device[]>>("devices");

    useEffect(() => {
        if (type) {
            setKey(
                type === "id-scan" ? "acarat_id_scanner" : "acarat_rfid_reader"
            );
        }
    }, [type]);

    useEffect(() => {
        if (data?.data) {
            setDevices([...data.data, ...newDevices]);
        }
    }, [newDevices, data]);

    useEffect(() => {
        if (window.localStorage.getItem(key)) {
            setActiveDeviceId(window.localStorage.getItem(key));
        }
    }, [key]);

    const save = useCallback(() => {
        const device = devices.find(
            (device: Device) => device.identifier.toString() === activeDeviceId
        );

        if (device) {
            client
                .post(`/api/devices`, {
                    identifier: device?.identifier,
                    type,
                })
                .then(() => {
                    window.localStorage.setItem(key, device.identifier);
                    set(type, device.identifier);
                });
        }

        close();
    }, [close, activeDeviceId, devices, key, type, set]);

    const addDevice = useCallback((device: Device) => {
        setNewDevices(old => {
            return [...old, device];
        });
    }, []);

    useEffect(() => {
        socket
            .private("Devices")
            .listen("UnregisteredDeviceCaptured", addDevice);

        return () => {
            socket
                .private("Devices")
                .stopListening("UnregisteredDeviceCaptured");
        };
    }, [addDevice]);

    return (
        <Popup>
            <S.Box>
                <S.Header>
                    {type === "id-scan"
                        ? t("add_id_scanner")
                        : t("add_rfid_reader")}
                </S.Header>
                <S.Devices>
                    <S.DevicesHeader>
                        {t("available_devices_for_this_location")}
                    </S.DevicesHeader>
                    <S.DevicesBody>
                        {devices
                            ?.filter(device => device.type === type)
                            .map(device => {
                                const isActive =
                                    activeDeviceId === device.identifier;

                                return (
                                    <S.Device
                                        key={device.identifier}
                                        isActive={isActive}
                                        onClick={() =>
                                            setActiveDeviceId(device.identifier)
                                        }
                                    >
                                        <S.DeviceId>
                                            <p>{device.identifier}</p>
                                            {!device.id && (
                                                <span>* {t("new_device")}</span>
                                            )}
                                        </S.DeviceId>
                                        {isActive && (
                                            <FontAwesomeIcon
                                                icon={faCheck as IconProp}
                                            />
                                        )}
                                    </S.Device>
                                );
                            })}
                    </S.DevicesBody>
                </S.Devices>
                <S.Bottom isActive={activeDeviceId !== null}>
                    <Button className="btn-secondary" onClick={save}>
                        {t("save")}
                    </Button>
                </S.Bottom>
            </S.Box>
        </Popup>
    );
}

export default AddDevicePopup;
