import styled from "styled-components";

export const Statistics = styled.div`
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }

    @media screen and (max-width: ${props => props.theme.layout.bpL}) {
        align-items: center;
    }
`;

export const ActionBoxes = styled.div`
    display: flex;
    gap: 50px;
    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        display: flex;
        flex-direction: column;
    }
`;

export const InfoContainer = styled.div`
    max-width: 1200px;
    margin-left: 50px;
    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        margin-right: 50px;
    }

    @media screen and (max-width: ${props => props.theme.layout.bpM}) {
        margin-right: 10px;
        margin-left: 10px;
    }
`;

export const Stats = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${props => props.theme.layout.bpM}) {
        display: flex;
        flex-direction: column;
    }
`;
