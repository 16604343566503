import { useTranslation } from "react-i18next";
import PageHeader from "../components/PageHeader";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import Table from "../components/Table";
import { useMemo } from "react";
import Filter from "../components/Filter/Filter";
import { useUserStore } from "../store/userStore";
import { useHistory } from "react-router";
import { TranslationsKeys } from "../types/translations";
import { capitalize } from "../utils/convert";

export default function RiskDashboard() {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<RiskItem>();
    const { user } = useUserStore();
    const history = useHistory();

    const locationOptions = user
        ? user.locations?.map(item => {
              return {
                  value: item.name,
                  label: item.name,
                  params: {
                      location_id: item.id,
                  },
              };
          })
        : [];

    const filters = [
        user &&
            user.locations.length > 1 && {
                type: "default",
                key: "location",
                label: t("filter_by_location"),
                options: locationOptions,
            },
        {
            type: "range",
            key: "age",
            label: t("filter_by_age"),
        },
    ];

    type VisitLimit = {
        id: number;
        global: number;
        limit: number;
        period: string;
        ends_at: string;
        has_ended: boolean;
        description: string | null;
        guest_period_visits: number;
    };

    type RiskItem = {
        id: number;
        profile_photo_url: string;
        name: string;
        entries_count: number;
        visit_limit: VisitLimit | null;
        average_entries: number;
        age: number;
        prevention_actions_count: number;
    };

    const {
        onScroll,
        filteredData,
        updateFilterParams,
        containerRef,
        isLoading,
    } = useInfiniteScroll("/api/risk/shooting-stars");

    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                header: () => t("guest_name"),
                cell: info => {
                    return (
                        <div className="flex items-center">
                            <img
                                className="mr-3 h-8 w-8 rounded-full"
                                src={info.row.original.profile_photo_url}
                                alt=""
                            />
                            <span>{info.getValue()}</span>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("entries_count", {
                header: () => t("entries"),
            }),
            columnHelper.accessor("visit_limit", {
                header: () => capitalize(t("limit")),
                cell: info => {
                    const limit = info.getValue()?.limit;
                    const period = t(
                        ("per_" + info.getValue()?.period) as TranslationsKeys
                    )
                        .toString()
                        .toLowerCase();

                    return limit ? `${limit} ${period}` : "-";
                },
            }),
            columnHelper.accessor("average_entries", {
                header: () =>
                    `${t("visit_average")} - ${t("number_months", {
                        number: 3,
                    })}`,
            }),
            columnHelper.accessor("age", {
                header: t("age"),
            }),
            columnHelper.accessor("prevention_actions_count", {
                header: t("prevention_action"),
            }),
        ],
        [columnHelper, t]
    );

    const table = useReactTable<RiskItem>({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const onRowClick = (row: RiskItem) => {
        history.push(`/guest/${row.id}`);
    };

    return (
        <>
            <PageHeader title={t("risk_dashboard")} />
            {/* 
            // @ts-ignore */}
            <Filter filters={filters} updateFilterParams={updateFilterParams} />
            <div
                className="mt-8 h-[575px] grow overflow-hidden bg-[#FFFBFB] p-8 shadow-md"
                // @ts-ignore
                ref={containerRef}
                onScroll={onScroll}
            >
                <div className="h-full overflow-auto">
                    <Table<RiskItem>
                        table={table}
                        onRowClick={onRowClick}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </>
    );
}
