import React, { useRef, useEffect } from "react";
import * as S from "./TemplateAdd.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

function TemplateAdd({
    isCreatingTemplate,
    setAddTemplateText,
    addTemplate,
    setIsCreatingTemplate,
    exitCreate,
}) {
    const { t } = useTranslation();
    const addFieldInputRef = useRef(null);
    const addFieldRef = useRef(null);

    useEffect(() => {
        addFieldInputRef.current && addFieldInputRef.current.focus();
    }, [isCreatingTemplate]);

    useOnClickOutside(addFieldRef, exitCreate);

    const handleAddKeyPress = e => {
        if (e.key !== "Enter") return;
        addTemplate();
    };

    return (
        <S.AddContainer>
            {isCreatingTemplate ? (
                <S.AddField ref={addFieldRef}>
                    <input
                        placeholder={t("template_name")}
                        onChange={e => setAddTemplateText(e.target.value)}
                        onKeyDown={handleAddKeyPress}
                        ref={addFieldInputRef}
                    />
                    <button
                        class="ml-2 flex h-7 w-7 min-w-0 items-center justify-center rounded-full bg-primary text-lg text-white"
                        onClick={() => addTemplate()}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </S.AddField>
            ) : (
                <S.AddNewField
                    onClick={() => {
                        setIsCreatingTemplate(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>{t("add_new_template")}</span>
                </S.AddNewField>
            )}
        </S.AddContainer>
    );
}

export default TemplateAdd;
