import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./FormFiles.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import client from "../../../client";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { faTimesSquare } from "@fortawesome/pro-duotone-svg-icons";

export default function FormFiles({
    formKey,
    label,
    errors,
    readOnly,
    existingFiles = [],
    setFiles,
    isSubmitted,
}) {
    const { t } = useTranslation();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    useEffect(() => {
        if (existingFiles.length) {
            setSelectedFiles(existingFiles);
        }
    }, [existingFiles]);

    useEffect(() => {
        if (isSubmitted && filesToDelete.length > 0) {
            filesToDelete.forEach(file => {
                client.delete(`/api/files/${file.id}`).then();
            });
        }
    }, [isSubmitted, filesToDelete]);

    const handleInputChange = e => {
        const { files } = e.target;

        setSelectedFiles(prev => {
            const imageHandler = Object.entries(files).map(([key, val]) => {
                return { src: URL.createObjectURL(val), alt: val.name };
            });
            return prev.concat(imageHandler);
        });

        setFiles(prev => {
            const imageHandler = Object.entries(files).map(([key, val]) => {
                return val;
            });
            return prev.concat(imageHandler);
        });
    };

    const handleDelete = img => {
        if (img.url) {
            setFilesToDelete(prev => {
                const copy = [...prev];
                copy.push(img);
                return copy;
            });
        }

        setSelectedFiles(prev => {
            const copy = [...prev];
            const index = img.id
                ? copy.findIndex(file => file.id === img.id)
                : copy.findIndex(file => file.alt === img.alt);

            copy.splice(index, 1);
            return copy;
        });

        setFiles(prev => {
            const copy = [...prev];
            const index = copy.findIndex(file => file.alt === img.alt);
            copy.splice(index, 1);
            return copy;
        });
    };

    return (
        <>
            <S.FormControl error={errors[formKey]}>
                <label>{label}</label>
                <S.ContainerInputLabel>
                    {!readOnly && (
                        <>
                            <input
                                type="file"
                                id="img"
                                name="photo"
                                accept="image/*"
                                onChange={handleInputChange}
                                multiple
                                hidden
                            />
                            <div>
                                <S.FilesLabel htmlFor="img">
                                    <FontAwesomeIcon icon={faCamera} />
                                    {selectedFiles.length > 0
                                        ? t("add_more_files")
                                        : t("add_files")}
                                </S.FilesLabel>
                            </div>
                        </>
                    )}

                    <div>
                        {selectedFiles.length > 0 && (
                            <S.ImagesContainer>
                                {selectedFiles.map((img, i) => {
                                    return (
                                        <S.ImageElement>
                                            <a
                                                href={img.url}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                                key={i}
                                            >
                                                <S.Images
                                                    src={img.src || img.url}
                                                    alt={img.alt}
                                                />
                                            </a>
                                            {!readOnly && (
                                                <FontAwesomeIcon
                                                    icon={faTimesSquare}
                                                    style={{
                                                        "--fa-primary-color":
                                                            "#fff",
                                                        "--fa-secondary-color":
                                                            "#000",
                                                        "--fa-primary-opacity":
                                                            "1.0",
                                                        "--fa-secondary-opacity":
                                                            "1.0",
                                                    }}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleDelete(img);
                                                    }}
                                                    size="lg"
                                                />
                                            )}{" "}
                                        </S.ImageElement>
                                    );
                                })}
                            </S.ImagesContainer>
                        )}
                    </div>
                </S.ContainerInputLabel>
            </S.FormControl>
        </>
    );
}
