import React from "react";
import { motion } from "framer-motion";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Message } from "../../types/messages";
import Button from "../Button";

type Props = {
    setShowViewId: React.Dispatch<React.SetStateAction<number | null>>;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    item: Message;
};

export default function ViewsPopup({
    setShowViewId,
    setShowPopup,
    item,
}: Props) {
    const closeDropDown = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowViewId(null);
        setShowPopup(false);
    };
    const { t } = useTranslation();

    return (
        <motion.div
            className={`absolute top-[98%] right-[20px] z-10 m-auto flex h-[340px] w-[440px] flex-col rounded-md bg-white p-[32px] shadow-lg`}
            initial={{
                scale: 0.5,
            }}
            animate={{
                scale: 1,
            }}
            transition={{ duration: 0.3 }}
            onClick={e => {
                closeDropDown(e);
            }}
        >
            <FontAwesomeIcon
                icon={faTimes as IconProp}
                size="lg"
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                    closeDropDown(e);
                }}
                className="absolute right-[15px] top-[15px] cursor-pointer"
            />
            <div className="color-[#1d1e45] text-2xl">{t("report_views")} </div>

            <div className="mt-[32px] mb-[15px] max-h-[170px] min-h-[170px] w-full overflow-y-scroll rounded-sm border border-[#c7c7c7]  leading-4 text-black">
                {item.views.map(viewer => {
                    return (
                        <div
                            key={viewer.employee.id}
                            className="flex justify-between px-2 py-2 odd:bg-white even:bg-[#f5f3f3]"
                        >
                            <div>{viewer.employee.full_name}</div>
                            <div>
                                {" "}
                                {dayjs(viewer.created_at).format(
                                    "DD-MM-YYYY HH:mm"
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="z-10 flex w-full justify-center">
                <Button
                    className="btn-secondary"
                    onClick={(e: React.MouseEvent) => {
                        closeDropDown(e);
                    }}
                >
                    {t("close")}
                </Button>
            </div>
        </motion.div>
    );
}
