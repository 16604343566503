import * as S from "./FormInputGroup.styled";

function FormInputGroup({
    formKey,
    required = false,
    readOnly = false,
    label = "",
    options = [],
    register,
    type = "radio", // radio or checkbox
    errors,
}) {
    const renderControl = (
        value,
        type,
        label = "",
        required = false,
        readOnly = false
    ) => {
        const uniqueId = `${formKey}_${value}`;

        let inputProps = {
            type: type,
            name: formKey,
            id: uniqueId,
            value: value,
            ...register(formKey, {
                required: required,
            }),
        };

        return (
            <div key={value}>
                <input readOnly={readOnly} {...inputProps} />
                {label && (
                    <label
                        htmlFor={uniqueId}
                        dangerouslySetInnerHTML={{
                            __html: label,
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <S.FormControl required={required}>
            <label>{label}</label>
            <S.FormGroup type={type} error={errors[formKey]}>
                {options.map(option =>
                    renderControl(
                        option.value,
                        type,
                        option.text,
                        required,
                        readOnly
                    )
                )}
            </S.FormGroup>
        </S.FormControl>
    );
}

export default FormInputGroup;
