import create from "zustand";

type PopupData = {
    [key: string]: any;
} | null;

type PopupStore = {
    popupType: string | null;
    data: PopupData;
    setPopupType: (type: string | null) => void;
    setData: (data: PopupData) => void;
};

export const usePopupStore = create<PopupStore>(set => ({
    popupType: null,
    data: null,
    setPopupType: popupType =>
        set(state => {
            return { ...state, popupType };
        }),
    setData: data =>
        set(state => {
            return { ...state, data };
        }),
}));
