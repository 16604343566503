import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import CheckInForm from "../../components/CheckInForm/CheckInForm";
import { useState, useCallback } from "react";
import client from "../../client";
import { Guest } from "../../types/guest";
import { DataTable } from "../../components/DataTable";
import { columns } from "../../utils/tableColumns";
import { SearchFields } from "../../types/advancedSearch";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import LastEntries from "./LastEntries/LastEntries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons";
import { SelectedGuestFormFields } from "../../types/checkIn";

function Checkin() {
    const { t } = useTranslation();

    const [suggestionItems, setSuggestionItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] =
        useState<SelectedGuestFormFields | null>(null);
    const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);

   

    const tableColumns = columns.suggestionsTable;

    const onTableRowClick = (guest: Guest) => {
        if (!guest) return;
        setSelectedGuest(guest);
        setFormFields({
            first_name: guest.first_name ?? "",
            inserts: guest.inserts?.toLowerCase() ?? "",
            last_name: guest.last_name ?? "",
            date_of_birth: guest.date_of_birth ?? "",
        });
    };

    const updateSuggestions = useCallback((searchFields: SearchFields) => {
        setIsLoading(true);
        client
            .post(`/api/player/advancedsearch`, {
                first_name: searchFields.first_name,
                inserts: searchFields.inserts,
                last_name: searchFields.last_name,
                dob: searchFields.date_of_birth,
            })
            .then(res => {
                // temp hack because api returns all players
                const fieldsList = Object.values(searchFields).filter(
                    value => value
                );
                setIsLoading(false);
                if (fieldsList.length < 1) {
                    setSuggestionItems([]);
                } else {
                    setSuggestionItems(res.data);
                }
            });
    }, []);

    return (
        <>
            <PageHeader
                back={false}
                title={t("check_in")}
                showRegisterButton={false}
                icon={faClipboardListCheck as IconProp}
                placeholder={t("search_guest")}
            />
            <div className="relative mb-10 rounded-md pb-7 shadow-lg">
                <div className="flex h-[72px] cursor-pointer items-center justify-between rounded-t-lg bg-primary px-7 text-white">
                    <div className="text-2xl leading-7 text-white">
                        {t("check-in_guests")}
                    </div>
                </div>
                <div className="flex flex-col px-5 py-7 xl:flex-row">
                    <div className="first:mb-8 first:mr-10 first:w-full first:max-w-[500px] first:flex-shrink last:flex-grow xl:first:mb-0">
                        <div className="pb-5 text-2xl">
                            {t("enter_guest_details")}
                        </div>
                        <CheckInForm
                            updateSuggestions={updateSuggestions}
                            selectedGuest={selectedGuest}
                            setSelectedGuest={setSelectedGuest}
                            formFields={formFields || null}
                            setFormFields={setFormFields}
                        />
                    </div>
                    <div className="flex-grow">
                        <div className="pb-5 text-2xl">{t("suggestions")}</div>
                        {/* 
                            // @ts-ignore */}
                        <DataTable
                            columns={tableColumns}
                            data={suggestionItems}
                            tableType="entry"
                            onClick={onTableRowClick}
                            pagination={false}
                            noDataMessage={
                                suggestionItems?.length < 1 && !isLoading
                            }
                            selected={selectedGuest?.id as any}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <div className="relative mb-10 rounded-md pb-7 shadow-lg">
                <div className="flex h-[72px] cursor-pointer items-center justify-between rounded-t-lg bg-primary px-7 text-white">
                    <div className="text-2xl leading-7 text-white">
                        {t("guests_today")}
                    </div>
                    <a
                        className="flex items-center text-lg text-white no-underline hover:text-secondary"
                        href="/log"
                    >
                        <span>{t("see_all")}</span>
                        <FontAwesomeIcon
                            icon={faChevronRight as IconProp}
                            className="ml-2"
                        />
                    </a>
                </div>
                <LastEntries />
            </div>
        </>
    );
}
export default Checkin;
