import "../assets/logo.png";
import { useCallback } from "react";
import { useUserStore } from "../store/userStore";
import acaratLogo from "../assets/logo.png";

function useLogo() {
    const { user } = useUserStore();

    const getClientLogo = useCallback(() => {
        return user?.client_logo
            ? user.client_logo.replace(
                  "https://api.acarat.nl",
                  process.env.REACT_APP_API_BASE_URL || ""
              )
            : acaratLogo;
    }, [user?.client_logo]);

    return { getClientLogo };
}

export default useLogo;
