import React, { useEffect, useState } from "react";
import * as S from "./EditGuest.styled";
import PageHeader from "../../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    faCamera,
    faPlus as fadPlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import client from "../../client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useFetch from "../../hooks/useFetch";
import useExitPrompt from "../../hooks/useExitPrompt";
import { useHistory } from "react-router-dom";
import WebcamPopup from "../../components/Popups/WebcamPopup";
import DeletePopup from "../../components/Popups/OldDeletePopup";
import { useQueryClient } from "react-query";
import usePopup from "../../hooks/usePopup";
import { convertBase64ToBlob } from "../../utils/convert";
import { residenceOptions, genderOptions } from "../../utils/formOptions";
import FormErrors from "../../components/formPartials/FormErrors/FormErrors";
import useCustomToast from "../../hooks/useCustomToast";
import useSignature from "../../hooks/useSignature";
import { startCase } from "lodash-es";
import Button from "../../components/Button";

export default function EditGuest() {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitted },
        reset,
        watch,
        setValue,
        control,
    } = useForm();

    const { popup } = usePopup();

    const { hasConnectedDevice } = useSignature();

    const history = useHistory();
    const params = useParams();

    const [showPopup, setShowPopup] = useState(false);
    const { t } = useTranslation();
    const { customToast } = useCustomToast();

    const [userPhoto, setUserPhoto] = useState("");

    const queryClient = useQueryClient();
    const [showWebcamPopup, setShowWebcamPopup] = useState(false);
    const { isLoading, data } = useFetch(`player/${params.id}`);

    const [showDeletePopupPicture, setShowDeletePopupPicture] = useState(false);

    const [loyaltyCard, setLoyaltyCard] = useState(null);

    const [generatedSignature, setGeneratedSignature] = useState(null);

    useExitPrompt(isDirty && !isSubmitted);

    const houseNumber = watch("house_number");
    const zip = watch("postcode");
    const street = watch("street_name");
    const city = watch("city");
    const residence = watch("residence");

    useEffect(() => {
        if (!params.id) return;

        (async () => {
            try {
                const signaturesResponse = await client.get(
                    `/api/signatures?guest_id=${params.id}`
                );
                const profileSignature = signaturesResponse?.data?.data.find(
                    signature => signature.signable_type === "player_profile"
                );

                if (!profileSignature) return;

                const signatureBlobResponse = await client.get(
                    `/api/signature-image/${profileSignature.id}`,
                    {
                        responseType: "blob",
                    }
                );

                const objectURL = URL.createObjectURL(
                    signatureBlobResponse.data
                );
                setGeneratedSignature(objectURL);
            } catch (e) {
                throw new Error(e);
            }
        })();
    }, [data, params.id]);

    useEffect(() => {
        const loyaltyCard = data?.player.loyalty_card;
        if (!loyaltyCard) return;

        setLoyaltyCard(loyaltyCard.rfid);
    }, [data?.player.loyalty_card]);

    useEffect(() => {
        if (!street && !city) {
            if (zip && houseNumber) {
                const zipFormat = Object.keys(zip);
                if (zipFormat.length === 6) {
                    client
                        .get(
                            `/api/postcode/${zip}/${houseNumber
                                .toString()
                                .replace(/\D/g, "")}`
                        )
                        .then(response => {
                            if (!response.data.title) {
                                setValue("city", response.data.city);
                                setValue("street_name", response.data.street);
                            }
                        });
                }
            }
        }
    }, [zip, houseNumber, setValue, city, street]);

    const updateLoyaltyCard = code => {
        if (!code) return;

        const formData = new FormData();
        formData.append("loyalty_card", code);

        client
            .post(`/api/players/${params.id}/loyalty-card`, formData)
            .then(response => {
                setLoyaltyCard(code);
            })
            .catch(e => {
                if (e.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: e.response.data.message,
                    });
                }
            });
    };

    const deleteLoyaltyCard = () => {
        client
            .delete(`/api/players/${params.id}/loyalty-card`)
            .then(response => {
                setLoyaltyCard(null);
            })
            .catch(e => {
                if (e.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: e.response.data.message,
                    });
                }
            });
    };

    useEffect(() => {
        if (data) {
            reset({
                ...data.player,
                ...data.player.profile,
                residence: residenceOptions.find(
                    option => option.value === data.player.residence
                ),
                gender: genderOptions.find(
                    option => option.value === data.player.profile?.gender
                ),
            });
        }
    }, [data, reset]);

    if (isLoading) return <div>{t("loading")}...</div>;

    const handleFileChange = e => {
        e.target.files &&
            e.target.files[0] &&
            setUserPhoto({
                url: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
            });
    };

    const onSubmit = async data => {
        const fields = {
            ...data,
            residence: data?.residence?.value || "",
            type: data?.residence?.value || "",
            gender: data?.gender?.value || "",
            first_name: startCase(data.first_name),
            inserts: data.inserts?.toLowerCase() || "",
            last_name: startCase(data.last_name),
            location_of_birth: startCase(data.location_of_birth),
        };

        if (generatedSignature) {
            const signatureBlob = await convertBase64ToBlob(generatedSignature);
            fields.signature_src = signatureBlob;
        }

        const formData = new FormData();

        if (userPhoto) {
            formData.append("profile_photo", userPhoto.file);
        }

        Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value === null ? "" : value);
        });

        formData.append("player_id", params.id);

        const updatePlayerProfile = () => {
            return client
                .post(`/api/player/${params.id}/profile`, formData)
                .then(response => {
                    customToast("success", {
                        title: t("guest_details_updated"),
                        link: {
                            title: t("view_guest"),
                            url: `/guest/${params.id}/edit`,
                        },
                    });

                    history.push(`/guest/${params.id}`);

                    reset({
                        ...response.data.player,
                        ...response.data.player.profile,
                    });
                })
                .catch(e => {
                    if (e.isAxiosError) {
                        customToast("error", {
                            title: t("error_data_could_not_be_sent"),
                            body: e.response.data.message,
                        });
                    }
                });
        };

        const updatePlayerBsn = () => {
            return client
                .post(`/api/guests/${params.id}/bsn`, {
                    bsn: data.bsn_number,
                })
                .catch(e => {
                    if (e.isAxiosError) {
                        customToast("error", {
                            title: t("error_data_could_not_be_sent"),
                            body: e.response.data.message,
                        });
                        return Promise.reject();
                    }
                });
        };

        if (data.bsn_number) {
            updatePlayerBsn().then(() => updatePlayerProfile());
        } else {
            updatePlayerProfile();
        }
    };

    const deletePicture = () => {
        client.delete(`/api/player/${params.id}/photo`).then(() => {
            customToast("success", {
                title: t(`entity_successfully_deleted`, {
                    entity: t("photo"),
                }),
            });
            queryClient.invalidateQueries(`player/${params.id}`);
            setShowDeletePopupPicture(false);
            setUserPhoto("");
        });
    };

    const deleteProfile = () => {
        client.delete(`/api/player/${params.id}`).then(() => {
            customToast("success", {
                title: t(`entity_successfully_deleted`, {
                    entity: t("guest"),
                }),
            });
            queryClient.invalidateQueries(`player/${params.id}`);
            setShowPopup(false);
            history.push("/check-in");
        });
    };

    const removeSignature = () => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => setGeneratedSignature(null),
                entityName: t("signature"),
            },
        });
    };

    const addSignature = () => {
        popup({
            type: "ADD_SIGNATURE",
            data: {
                setGeneratedImage: setGeneratedSignature,
                signatureTextKey: "signature_loyalty_text",
            },
        });
    };

    return (
        <>
            {showDeletePopupPicture && (
                <DeletePopup
                    setShowPopup={setShowDeletePopupPicture}
                    deleteHandler={deletePicture}
                    text="picture"
                />
            )}
            {showPopup && (
                <DeletePopup
                    setShowPopup={setShowPopup}
                    deleteHandler={deleteProfile}
                    text="profile"
                />
            )}
            <PageHeader
                title={t("edit_member_details")}
                back={true}
                showSearch={false}
            />
            <S.RegisterPlayer>
                {showWebcamPopup && (
                    <WebcamPopup
                        setUserPhoto={setUserPhoto}
                        setShowWebcamPopup={setShowWebcamPopup}
                    />
                )}
                <S.PhotoArea>
                    <input
                        type="file"
                        id="photo-upload"
                        onChange={handleFileChange}
                        hidden
                    />
                    <label htmlFor="photo-upload"></label>

                    {data.player.profile_photo_url ? (
                        <S.EditPhoto>
                            <S.UserPhoto
                                src={
                                    userPhoto.url
                                        ? userPhoto.url
                                        : data.player.profile_photo_url
                                }
                                alt="profile image"
                            />

                            <label htmlFor="photo-upload">
                                <FontAwesomeIcon
                                    icon={fadPlus}
                                    style={{
                                        "--fa-primary-color": "#000",
                                        "--fa-secondary-color": "#000",
                                        "--fa-primary-opacity": "1.0",
                                        "--fa-secondary-opacity": "0.9",
                                    }}
                                />
                            </label>
                        </S.EditPhoto>
                    ) : (
                        <S.AddPhoto>
                            <S.UserPhoto src={userPhoto.url} alt="" />
                            <FontAwesomeIcon
                                icon={faCamera}
                                style={{
                                    "--fa-primary-color": "#000",
                                    "--fa-secondary-color": "#000",
                                    "--fa-primary-opacity": "1.0",
                                    "--fa-secondary-opacity": "0",
                                }}
                            />
                            <span>+ {t("take_picture")}</span>
                        </S.AddPhoto>
                    )}
                    <S.IconProfileWebcamPhoto>
                        {data.player.profile_photo_url.includes("ui-avatars") &&
                        !userPhoto ? (
                            <button
                                onClick={() => {
                                    setShowWebcamPopup(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCamera}
                                    style={{
                                        "--fa-primary-color": "#000",
                                        "--fa-secondary-color": "#000",
                                        "--fa-primary-opacity": "1.0",
                                        "--fa-secondary-opacity": "0",
                                    }}
                                />
                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    setShowDeletePopupPicture(
                                        !showDeletePopupPicture
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        )}
                    </S.IconProfileWebcamPhoto>
                </S.PhotoArea>
                <S.FormSection>
                    <S.Form onSubmit={handleSubmit(onSubmit)}>
                        <S.FormControl error={errors.first_name}>
                            <label>{t("first_name")}</label>
                            <input type="text" {...register("first_name")} />
                        </S.FormControl>
                        <S.FormControl error={errors.inserts}>
                            <label>{t("middle_name")}</label>
                            <input
                                type="text"
                                {...register("inserts", { disabled: true })}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.last_name} required={true}>
                            <label>{t("last_name")}</label>
                            <input
                                type="text"
                                {...register("last_name", {
                                    required: true,
                                    disabled: true,
                                })}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.gender} required={false}>
                            <label>{t("gender")}</label>
                            <S.Select>
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={genderOptions}
                                        />
                                    )}
                                />
                            </S.Select>
                        </S.FormControl>
                        <S.FormControl error={errors.date_of_birth}>
                            <label>{t("date_of_birth")}</label>
                            <input
                                type="date"
                                {...register("date_of_birth", {
                                    disabled: true,
                                })}
                            />
                        </S.FormControl>
                        <S.FormControl required={true}>
                            <label>{t("has_bsn")}</label>
                            <S.Select>
                                <Controller
                                    name="residence"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={residenceOptions}
                                            isDisabled={
                                                data.player.residence === "NL"
                                            }
                                        />
                                    )}
                                />
                            </S.Select>
                        </S.FormControl>
                        {residence?.value === "INT" && (
                            <S.FormControl
                                error={errors.location_of_birth}
                                required={true}
                            >
                                <label>{t("place_of_birth")}</label>
                                <input
                                    type="text"
                                    {...register("location_of_birth", {
                                        required: true,
                                        disabled: true,
                                    })}
                                />
                            </S.FormControl>
                        )}

                        {data.player.residence === "INT" &&
                            residence?.value === "NL" && (
                                <S.FormControl
                                    error={errors.bsn_number}
                                    required={true}
                                >
                                    <label>{t("bsn_number")}</label>
                                    <input
                                        type="number"
                                        {...register("bsn_number", {
                                            required: true,
                                            pattern: {
                                                value: /^[0-9]{9}$/,
                                                message: t(
                                                    "bsn_must_contain_9_numbers"
                                                ),
                                            },
                                        })}
                                        placeholder="111112222"
                                    />
                                </S.FormControl>
                            )}

                        <S.FormControl error={errors.number}>
                            <label>{t("house_number")}</label>
                            <input
                                type="text"
                                placeholder={t("example_house_number")}
                                {...register("house_number")}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.number}>
                            <label>{t("additions")}</label>
                            <input
                                type="text"
                                placeholder={t("example_additions")}
                                {...register("house_number_addition")}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.postcode}>
                            <label>{t("postal_code")}</label>
                            <input
                                type="text"
                                maxLength="6"
                                placeholder={t("example_1234AB")}
                                {...register("postcode", {
                                    pattern: {
                                        value: /^[0-9]{4}[a-zA-Z]{2}$/,
                                        message: t("invalid_zip_code"),
                                    },
                                })}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.street_name}>
                            <label>{t("streetname")}</label>
                            <input
                                type="text"
                                readOnly={false}
                                {...register("street_name")}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.city}>
                            <label>{t("city")}</label>
                            <input
                                type="text"
                                readOnly={false}
                                {...register("city")}
                            />
                        </S.FormControl>
                        <S.FormControl error={errors.phone}>
                            <label>{t("phone_number")}</label>
                            <input type="text" {...register("phone")} />
                        </S.FormControl>
                        <S.FormControl error={errors.email}>
                            <label>{t("email")}</label>
                            <input
                                type="email"
                                {...register("email", {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t("invalid_email_address"),
                                    },
                                })}
                            />
                        </S.FormControl>
                        <S.FormControl>
                            <label>{t("loyalty_card")}</label>
                            {!loyaltyCard && (
                                <S.AddItem
                                    onClick={() => {
                                        popup({
                                            type: "SCAN_LOYALTY_CARD_POPUP",
                                            data: {
                                                updateLoyaltyCard: code =>
                                                    updateLoyaltyCard(code),
                                            },
                                        });
                                    }}
                                >
                                    <>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span>{t("add_loyalty_card")}</span>
                                    </>
                                </S.AddItem>
                            )}
                            {loyaltyCard && (
                                <S.RemoveItem
                                    onClick={() => {
                                        popup({
                                            type: "DELETE_ENTITY",
                                            data: {
                                                deleteHandler: () =>
                                                    deleteLoyaltyCard(),
                                                entityName: t("loyalty_card"),
                                            },
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                    <span>{t("remove_loyalty_card")}</span>
                                </S.RemoveItem>
                            )}
                        </S.FormControl>
                        {(hasConnectedDevice || generatedSignature) && (
                            <S.FormControl>
                                <label>{t("signature")}</label>
                                {!generatedSignature && (
                                    <>
                                        <S.AddItem onClick={addSignature}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span>{t("add_signature")}</span>
                                        </S.AddItem>
                                    </>
                                )}

                                {generatedSignature && (
                                    <S.SignatureContainer>
                                        <img
                                            alt="signature"
                                            src={generatedSignature}
                                        />
                                        <button
                                            onClick={e => {
                                                e.preventDefault();
                                                removeSignature();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </S.SignatureContainer>
                                )}
                            </S.FormControl>
                        )}
                        <S.CheckBoxes>
                            <S.CheckBox error={errors.optin_phone}>
                                <input
                                    id="optin_phone"
                                    type="checkbox"
                                    {...register("optin_phone")}
                                />
                                <label htmlFor="optin_phone">
                                    {t(
                                        "i_consent_to_be_contacted_through_phone_number"
                                    )}
                                </label>
                            </S.CheckBox>
                            <S.CheckBox error={errors.optin_emails}>
                                <input
                                    id="optin_emails"
                                    type="checkbox"
                                    {...register("optin_emails")}
                                />
                                <label htmlFor="optin_emails">
                                    {t(
                                        "i_consent_to_be_contacted_through_emails"
                                    )}
                                </label>
                            </S.CheckBox>
                            <S.CheckBox
                                error={errors.privacy_agree}
                                required={true}
                            >
                                <input
                                    id="privacy_agree"
                                    type="checkbox"
                                    {...register("privacy_agree", {
                                        required: true,
                                    })}
                                />
                                <label htmlFor="privacy_agree">
                                    {t("agree_to_privacy_plan")}
                                </label>
                            </S.CheckBox>
                        </S.CheckBoxes>
                        <FormErrors errors={errors} />
                        <S.FormBottom>
                            <Button className="btn-primary">{t("save")}</Button>
                            <Button
                                className="btn-secondary"
                                onClick={e => {
                                    e.preventDefault();
                                    setShowPopup(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    style={{
                                        "--fa-primary-color": "#fff",
                                        "--fa-secondary-color": "#fff",
                                        "--fa-primary-opacity": "1.0",
                                        "--fa-secondary-opacity": "0.9",
                                    }}
                                />
                                <span>Delete</span>
                            </Button>
                        </S.FormBottom>
                    </S.Form>
                </S.FormSection>
            </S.RegisterPlayer>
        </>
    );
}
