import { ReactNode, useEffect } from "react";
import { Switch } from "react-router-dom";
import mainRoutes from "../../routes/mainRoutes";
import userEntryRoutes from "../../routes/userEntryRoutes";
import MainContainer from "./MainContainer";
import UserEntryContainer from "./UserEntryContainer";
import { useUserStore } from "../../store/userStore";
import { useQueryClient } from "react-query";
import useAuth from "../../hooks/useAuth";
import useSocket from "../../hooks/useSocket";
import useScrollToTop from "../../hooks/useScrollToTop";
import { Route } from "react-router";
import { RouteType } from "../../types/routes";
import Loading from "../Loading";

export default function Container() {
    const queryClient = useQueryClient();
    const { isLoading } = useAuth();
    const { user } = useUserStore();

    useScrollToTop();
    useSocket();

    useEffect(() => {
        queryClient.invalidateQueries();
    }, [user, queryClient]);

    return (
        <>
            {isLoading ? (
                <div className="flex min-h-screen items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <div className="relative min-h-screen w-full ">
                    <Switch>
                        <Route path={mainRoutes.map(route => route.path)}>
                            <RenderRoutes
                                routes={mainRoutes}
                                Container={MainContainer}
                            />
                        </Route>

                        <Route path={userEntryRoutes.map(route => route.path)}>
                            <RenderRoutes
                                routes={userEntryRoutes}
                                Container={UserEntryContainer}
                            />
                        </Route>
                    </Switch>
                </div>
            )}
        </>
    );
}

function RenderRoutes({
    routes,
    Container,
}: {
    routes: RouteType[];
    Container: ({ children }: { children: ReactNode }) => JSX.Element;
}) {
    return (
        <Container>
            <Switch>
                {routes.map(route => {
                    return (
                        <Route
                            path={route.path}
                            render={route.render}
                            component={route.component}
                            key={route.path}
                            exact={route.exact}
                        />
                    );
                })}
            </Switch>
        </Container>
    );
}
