import React, { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "../../components/DataTable";
import PageHeader from "../../components/PageHeader";
import dayjs from "dayjs";
import Filter from "../../components/Filter";
import { dateOptions } from "../../utils/filterOptions";
import client from "../../client";
import usePermissions from "../../hooks/usePermissions";
import usePopup from "../../hooks/usePopup";
import { dayTranslation } from "../../utils/date";
import {
    getTableRows,
    getTableColumns,
    tableMap,
} from "../../utils/floorNumberTables";
import useCustomToast from "../../hooks/useCustomToast";
import { Button } from "../../types/button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";

function FloorNumbersOverview() {
    const { t } = useTranslation();
    const { popup } = usePopup();
    const dateFrom = useMemo(
        () => dayjs().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss"),
        []
    );
    const [filteredData, setFilteredData] = useState(null);
    const { hasPermissions } = usePermissions();
    const hasExportPermissions = hasPermissions(["export_floor_numbers"]);
    const { customToast } = useCustomToast();

    const tableColumns = () => {
        const tableColumns = getTableColumns();

        const dayColumnIndex = tableColumns.findIndex(
            column => column.accessor === "days"
        );

        tableColumns[dayColumnIndex].Cell = props => {
            const dayOfTheWeek =
                dayTranslation[
                    dayjs(props.value).format(
                        "dddd"
                    ) as keyof typeof dayTranslation
                ];
            const formattedDay = dayjs(props.value).format("D MMM YYYY");
            return (
                <div className="flex w-[200px] justify-between">
                    <span>{dayOfTheWeek}</span>
                    <span>{formattedDay}</span>
                </div>
            );
        };

        return tableColumns;
    };

    const filters = [
        {
            type: "date",
            label: t("filter_by_date"),
            options: dateOptions,
        },
    ];

    const updateData = useCallback(
        async (params: { date_from: string; date_until: string }) => {
            const defaultParams = {
                date_from: dateFrom,
            };

            try {
                const res = await client.get("/api/guest-count-logs/history", {
                    params: { ...defaultParams, ...params },
                });
                setFilteredData(res.data.data);
            } catch (err: any) {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            }
        },
        [dateFrom, customToast, t]
    );

    const buttons: Button[] = hasExportPermissions
        ? [
              {
                  text: t("export"),
                  clickHandler: () =>
                      popup({
                          type: "EXPORT_FLOOR_NUMBERS_OVERVIEW",
                      }),
                  type: "pageHeader",
                  faIcon: faFileExport as IconProp,
              },
          ]
        : [];

    const renderTables = () => {
        return (
            <>
                {Object.entries(tableMap).map(([key, tableInfo]) => (
                    <div className="mb-8" key={key}>
                        <div className="text-xl">{tableInfo.title}</div>
                        {/* 
                        // @ts-ignore */}
                        <DataTable
                            columns={tableColumns()}
                            data={getTableRows(filteredData || {}, key)}
                            name={t("guests")}
                            pagination={false}
                            tableType="floor_numbers_overview"
                        />
                    </div>
                ))}
            </>
        );
    };

    return (
        <div>
            <PageHeader title={t("floor_numbers_overview")} buttons={buttons} />
            {/* 
            // @ts-ignore */}
            <Filter filters={filters} updateFilterParams={updateData} />

            {filteredData && renderTables()}
        </div>
    );
}

export default FloorNumbersOverview;
