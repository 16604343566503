export default function UserEntryContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <>
            <div className="flex min-h-screen items-center justify-center">
                {children}
            </div>
        </>
    );
}
