import * as S from "./FormBottom.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Button from "../../Button";

type Props = {
    buttonText: string;
    onClick?: (e: React.MouseEvent) => void;
    type?: string;
    secondaryButton?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
};

function FormBottom({
    buttonText,
    onClick,
    type,
    secondaryButton = false,
    disabled,
    isLoading,
}: Props) {
    const buttonContent = isLoading ? (
        <FontAwesomeIcon
            icon={faSpinner as IconDefinition}
            className="fa-spin"
        />
    ) : (
        buttonText
    );

    const isDisabled = disabled ?? isLoading;

    return (
        <>
            <S.FormBottom onClick={onClick}>
                {secondaryButton ? (
                    <Button className="btn-secondary" disabled={isDisabled}>
                        {buttonContent}
                    </Button>
                ) : (
                    <Button
                        className="btn-primary"
                        disabled={isDisabled}
                        onClick={onClick}
                    >
                        {buttonContent}
                    </Button>
                )}
            </S.FormBottom>
        </>
    );
}

export default FormBottom;
