import * as S from "./info.styled";
import PageHeader from "../../components/PageHeader";

import CurrentGuests from "../../components/CurrentGuests";
import TabsBox from "../../components/TabsBox";
import { useTranslation } from "react-i18next";
import HandOverColumn from "../../components/HandOverColumn";
import { faAddressBook, faCog } from "@fortawesome/pro-solid-svg-icons";
const Dashboard = () => {
    const { t } = useTranslation();

    const optionsPreventionPlan = [
        {
            text: `${t("add_addiction_prevention_talk")}`,
            link: "/prevention-plan",
        },
        {
            text: `${t("casino_prevention_plan_policy")}`,
            link: "/prevention-plan-policy",
        },
        {
            text: `${t("prevention_plan_overview")}`,
            link: "/prevention-plan-overview",
        },
    ];

    const optionsCasinoActions = [
        {
            text: `${t("add_handover")}`,
            link: "/add-report",
            state: "handover",
        },
        {
            text: `${t("floor_numbers_overview")}`,
            link: "/floor-numbers-overview",
        },
        { text: `${t("facilities_check")}`, link: "/facilities-check" },
    ];

    return (
        <>
            <PageHeader showSearchButton={false} title={t("actions")} />
            <S.InfoContainer>
                <S.Statistics>
                    <HandOverColumn />
                    <CurrentGuests />
                </S.Statistics>
                <S.ActionBoxes>
                    <TabsBox
                        title={t("prevention_plan")}
                        headerColor={"#BDBDBD"}
                        headerTextColor={"#050505"}
                        icon={faAddressBook}
                        iconColor={"#fff"}
                        options={optionsPreventionPlan}
                    />
                    <TabsBox
                        title={t("casino_actions")}
                        headerColor={"#1D1E45"}
                        headerTextColor={"#fff"}
                        icon={faCog}
                        iconColor={"#fff"}
                        options={optionsCasinoActions}
                    />
                </S.ActionBoxes>
            </S.InfoContainer>
        </>
    );
};

export default Dashboard;
