import { useState, useEffect } from "react";
import * as S from "../Popups.styled";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExcelExportFields from "../../ExcelExportFields";
import Popup from "../Popup";
import { reasonOptionsBlackList } from "../../../utils/formOptions";
import { queryMaker } from "../../../utils/queryMaker";

function ExportBlackListPopup() {
    const {
        register,
        watch,
        formState: { errors },
        control,
    } = useForm();

    const { t } = useTranslation();
    const [queryString, setQueryString] = useState(undefined);
    const [fields, setFields] = useState();

    let {
        locations,
        date_from,
        date_until,
        reason: reasons,
        columns: column,
    } = watch();

    useEffect(() => {
        setFields({
            filters: {
                date_from,
                date_until,
                locations: locations?.map(item => item.value),
                reasons: reasons?.map(item => item.value),
            },
            columns: column?.map(item => item.value),
        });
    }, [column, date_from, date_until, locations, reasons]);

    useEffect(() => {
        setQueryString(queryMaker(fields));
    }, [fields]);

    const additionalSelectBoxes = [
        {
            label: "reason",
            name: "reason",
            placeholder: "reason",
            options: reasonOptionsBlackList,
            selectKey: "reason",
        },
    ];

    return (
        <Popup>
            <S.Header>
                {t("export")} {t("blacklist").toLowerCase()}
            </S.Header>

            <S.EntryLogPopupForm>
                <ExcelExportFields
                    register={register}
                    errors={errors}
                    control={control}
                    additionalSelectBoxes={additionalSelectBoxes}
                />
                <S.StyledLink
                    href={`${
                        process.env.REACT_APP_API_BASE_URL
                    }/exports/blacklist${queryString ? `?${queryString}` : ""}`}
                    download
                >
                    {t("export")}
                </S.StyledLink>
            </S.EntryLogPopupForm>
        </Popup>
    );
}

export default ExportBlackListPopup;
