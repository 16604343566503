import styled from "styled-components";

export const FilterContainer = styled.div`
    display: flex;
    margin: 0px 0 10px 0;
    line-height: 50px;
    height: 50px;
    align-items: center;
`;

export const AppliedFiltersContainer = styled.div`
    display: flex;
    margin: 0 0 0 10px;
    line-height: 50px;
    min-height: 50px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

export const SelectedValue = styled.div`
    height: 35px;
    line-height: 35px;
    padding: 0 20px 0 20px;
    min-width: 20px;
    border-radius: 30px;
    background: #237da1;
    color: white;
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
        background: white;
        color: #1c4670;
        border: 1px solid #278ab0;
    }
`;

export const Select = styled.button`
    min-width: 100px;
    min-height: 32px;
    margin: 0 10px 0 10px;
    border-radius: 5px;
    padding: 9px 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 1;

    border: 1px solid ${props => (props.open ? "#278AB0" : " #bdbdbd")};

    &:hover {
        background: #278ab0;
        color: white;
    }

    svg {
        height: 10px;
        margin-left: 6px;
    }
`;

export const DropDown = styled.div`
    position: absolute;
    height: auto;
    min-width: 100px;
    max-width: 400px;
    margin: 5px 0 0 10px;
    background: #fffbfb;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transition: 0.2s;
    z-index: 10;
    padding: 0;

    button {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        text-align: left;
        padding: 0 16px;

        &:hover {
            background: rgba(241, 234, 234, 0.6);
        }
        &:last-child {
            border-bottom: 0px;
        }
    }
`;

export const ClearFilters = styled.button`
    color: ${props => (props.active ? "#A94430" : " #bdbdbd")};
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-bottom: 1px solid ${props => (props.active ? "#A94430" : " #bdbdbd")};
    > svg {
        margin-right: 5px;
    }
`;

export const MultiSelect = styled.div`
    button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 2;
        padding: 10px 15px;
    }

    svg {
        padding-right: 10px;
    }

    border-bottom: 1px solid #e0e0e0;
    &:last-child {
        border-bottom: none;
    }
`;

export const CheckBox = styled.div`
    width: 18px;
    height: 18px;
    border: ${props =>
        props.active ? "2px solid #C54D34" : "2px solid black"};
    background: ${props => (props.active ? "#C54D34" : "transparant")};
    border-radius: 3px;
    margin-left: 20px;

    > svg {
        position: absolute;
        color: white;
    }
`;

export const DateSelect = styled.div`
    min-width: 160px;
    max-width: 195px;
`;

export const DateSelectContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    justify-content: space-between;

    > span {
        color: black;
    }

    > input {
        height: 20px;
        background: none;
        border: none;
        border-bottom: 1px solid #e0e0e0;
        align-self: left;
        color: #333;
    }
`;
