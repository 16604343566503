import i18n from "i18next";

export const columnsOptions = [
    { value: "Guest", label: i18n.t("guest"), key: "columns" },
    { value: "Type", label: i18n.t("type"), key: "columns" },
    { value: "Signals", label: i18n.t("signals"), key: "columns" },
    { value: "Notes", label: i18n.t("notes"), key: "columns" },
    { value: "Reaction", label: i18n.t("reaction"), key: "columns" },
    { value: "Conclusion", label: i18n.t("conclusion"), key: "columns" },
    { value: "Followed up", label: i18n.t("followup"), key: "columns" },
    { value: "Date", label: i18n.t("date"), key: "columns" },
];

export const typeOptions = [
    { value: "signalling", label: i18n.t("signalling"), key: "types" },
    { value: "pamphlet", label: i18n.t("pamphlet"), key: "types" },
    { value: "trace_card", label: i18n.t("trace_card"), key: "types" },
    {
        value: "play_pause_offer",
        label: i18n.t("play_pause_offer"),
        key: "types",
    },
    {
        value: "involuntary_cruks",
        label: i18n.t("involuntary_cruks"),
        key: "types",
    },
    { value: "re-entry_talk", label: i18n.t("re-entry_talk"), key: "types" },
    { value: "general_talk", label: i18n.t("general_talk"), key: "types" },
    { value: "followup", label: i18n.t("followup"), key: "types" },
];

export const signalOptions = [
    {
        value: "guest_comes_more_often",
        label: i18n.t("guest_comes_more_often"),
        key: "signals",
    },
    {
        value: "guest_stays_longer",
        label: i18n.t("guest_stays_longer"),
        key: "signals",
    },
    {
        value: "guest_spends_more_money",
        label: i18n.t("guest_spends_more_money"),
        key: "signals",
    },
    {
        value: "external_signals",
        label: i18n.t("external_signals"),
        key: "signals",
    },
    {
        value: "expression_of_frustration",
        label: i18n.t("expression_of_frustration"),
        key: "signals",
    },
    {
        value: "other",
        label: i18n.t("other"),
        key: "signals",
    },
];

export const reactionsOptions = [
    {
        value: "guest_acknowledges_playing_risky",
        label: i18n.t("guest_acknowledges_playing_risky"),
        key: "reactions",
    },
    {
        value: "guest_got_angry",
        label: i18n.t("guest_got_angry"),
        key: "reactions",
    },
    {
        value: "guest_says_dont_worry",
        label: i18n.t("guest_says_dont_worry"),
        key: "reactions",
    },
    {
        value: "guest_appreciates_attention",
        label: i18n.t("guest_appreciates_attention"),
        key: "reactions",
    },
    {
        value: "guest_refused_to_talk",
        label: i18n.t("guest_refused_to_have_a_talk"),
        key: "reactions",
    },
    {
        value: "other_reaction",
        label: i18n.t("other"),
        key: "reactions",
    },
];
