import { useEffect, useState, useCallback } from "react";
import {
    startSignature,
    closeDevice,
    checkDeviceConnection,
} from "../utils/signature";

function useSignature() {
    const [sigCaptXStatus, setSigCaptXStatus] = useState({
        isReady: false,
        hasError: false,
    });

    const [hasConnectedDevice, setHasConnectedDevice] = useState(false);

    const checkForSigCaptX = useCallback((retryCount: number) => {
        // Establishing a connection to SigCaptX Web Service can take a few seconds,
        // particularly if the browser itself is still loading/initialising
        // or on a slower machine.
        retryCount = retryCount + 1;
        if (window.WacomGSS.STU.isServiceReady()) {
            retryCount = 0;
            setSigCaptXStatus(status => ({ ...status, isReady: true }));
        } else {
            if (retryCount < 20) {
                setTimeout(checkForSigCaptX, 1000);
            } else {
                // Unable to establish connection to SigCaptX
                setSigCaptXStatus(status => ({ ...status, hasError: true }));
            }
        }
    }, []);

    useEffect(() => {
        checkForSigCaptX(0);
        checkDeviceConnection().then((isConnected: boolean) => {
            setHasConnectedDevice(isConnected);
        });

        return () => {
            closeDevice();
        };
    }, [checkForSigCaptX]);

    return {
        startSignature,
        closeDevice,
        sigCaptXStatus,
        hasConnectedDevice,
    };
}

export default useSignature;
