import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);
    }, [location.pathname]);
}

export default useScrollToTop;
