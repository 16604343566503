import React, { RefObject } from "react";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../../types/form";
import { classNames } from "../../utils/style";

type Props = {
    options: SelectOption[];
    selectedOptions: SelectOption[] | undefined;
    comboBoxButtonRef: RefObject<HTMLButtonElement>;
    selectAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
    clearAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

function MultiSelectOptions({
    options,
    selectedOptions,
    comboBoxButtonRef,
    selectAll,
    clearAll,
}: Props) {
    const { t } = useTranslation();

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        comboBoxButtonRef.current?.click();
    };

    return (
        <Combobox.Options
            className="absolute z-10 w-full py-1 mt-1 text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            as="div"
            static
        >
            <div className="flex justify-between p-3">
                <button
                    onClick={selectAll}
                    className="font-semibold text-secondary"
                >
                    {t("select_all")}
                </button>
                <button
                    onClick={clearAll}
                    className="font-semibold text-secondary"
                >
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                    <span className="ml-1">{t("clear")}</span>
                </button>
            </div>
            <ul className="w-full py-3 overflow-auto border-t max-h-60">
                {!options.length && (
                    <div className="px-3">{t("no_options")}</div>
                )}
                {options.map(option => (
                    <Combobox.Option
                        key={option.value}
                        value={option}
                        disabled={!!option.disabled}
                        className={({ active }) =>
                            classNames(
                                "relative flex cursor-default select-none items-center py-2 pl-3 pr-3",
                                active
                                    ? "bg-sky-100 text-gray-900"
                                    : "text-gray-900",
                                !!option.disabled && "opacity-40"
                            )
                        }
                    >
                        {({ selected }) => (
                            <>
                                <span
                                    className={classNames(
                                        "mr-2 flex h-6 w-6 items-center justify-center rounded-md border text-white",
                                        selected
                                            ? "border-transparent bg-secondary"
                                            : "text-blue-400"
                                    )}
                                >
                                    {selected && (
                                        <FontAwesomeIcon
                                            icon={faCheck as IconProp}
                                        />
                                    )}
                                </span>
                                <span
                                    className={classNames(
                                        "block truncate",
                                        selected && "font-semibold"
                                    )}
                                >
                                    {option.label}
                                </span>
                            </>
                        )}
                    </Combobox.Option>
                ))}
            </ul>
            <div className="flex items-center justify-between p-3 border-t">
                <span className="text-gray-500">
                    {selectedOptions?.length} {t("of")} {options.length}
                </span>
                <button
                    className="px-6 py-2 text-white transition rounded-2xl bg-primary hover:bg-primary-light"
                    onClick={e => handleClick(e)}
                >
                    {t("done")}
                </button>
            </div>
        </Combobox.Options>
    );
}

export default MultiSelectOptions;
