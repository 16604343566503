import i18n from "i18next";
import { RenderCells } from "../components/DataTable";

const {
    NameCell,
    EntryCell,
    DateCell,
    MessageCell,
    RolesCell,
    ActionCell,
    FilesCell,
    BirthDateCell,
    WhiteBlackCell,
    ActivityCell,
    DeleteReports,
    NotesActions,
    ReasonCell,
    EntryCodeCell,
    DateCellOnList,
    OnlyNameCell,
    TypeCell,
    FollowUpCell,
    SignalsCell,
    NameCellWithDate,
    ResidenceCell,
    ExpandCell,
    GuestMessageCell,
} = RenderCells();

const entryLog = [
    {
        Header: i18n.t("name"),
        accessor: "player.full_name",
        Cell: NameCell,
        sortKey: "name",
        width: 250,
        minWidth: 100,
        maxWidth: 350,
    },
    {
        Header: i18n.t("id"),
        accessor: "type",
        Cell: ResidenceCell,
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("entry_status"),
        accessor: "entry",
        Cell: EntryCell,
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("notes"),
        accessor: "notes",
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("checked_on"),
        accessor: "created_at",
        sortKey: "created_at",
    },
];

const recentEntries = [
    {
        Header: i18n.t("name"),
        accessor: "player.full_name",
        Cell: NameCell,
        width: 250,
        minWidth: 100,
        maxWidth: 350,
    },
    {
        Header: i18n.t("id"),
        accessor: "type",
        Cell: ResidenceCell,
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("entry_status"),
        accessor: "entry",
        Cell: EntryCell,
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("notes"),
        accessor: "notes",
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("checked_on"),
        accessor: "created_at",
    },
];

const playerNotes = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
    },
    {
        Header: i18n.t("details"),
        accessor: "title",
        Cell: ActivityCell,
    },
    {
        Header: i18n.t("files"),
        accessor: "files",
        Cell: FilesCell,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "added_by.full_name",
    },
    {
        Header: "",
        accessor: "id",
        Cell: NotesActions,
    },
];

const whitelist = [
    {
        Header: i18n.t("name"),
        accessor: "player",
        Cell: NameCell,
        sortKey: "name",
        width: 250,
        minWidth: 100,
        maxWidth: 350,
    },
    {
        Header: i18n.t("from"),
        accessor: "start_date",
        Cell: DateCellOnList,
        sortKey: "start_date",
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("until"),
        accessor: "end_date",
        Cell: DateCellOnList,
        sortKey: "end_date",
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("initiated_by"),
        accessor: "entry_code",
        Cell: EntryCodeCell,
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("notes"),
        accessor: "entry_note",
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "added_by",
        width: 150,
        minWidth: 100,
        maxWidth: 200,
    },
    {
        Header: i18n.t("added_at"),
        accessor: "added_at",
        width: 150,
        minWidth: 100,
        maxWidth: 200,
    },
];

const blacklist = [
    {
        Header: i18n.t("name"),
        accessor: "player",
        Cell: NameCell,
        sortKey: "name",
        width: 250,
        minWidth: 100,
        maxWidth: 350,
    },
    {
        Header: i18n.t("from"),
        accessor: "start_date",
        Cell: DateCellOnList,
        sortKey: "start_date",
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("until"),
        accessor: "end_date",
        Cell: DateCellOnList,
        sortKey: "end_date",
        width: 100,
        minWidth: 50,
        maxWidth: 150,
    },
    {
        Header: i18n.t("reason"),
        accessor: "reasons",
        Cell: ReasonCell,
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("notes"),
        accessor: "entry_note",
        width: 250,
        minWidth: 200,
        maxWidth: 350,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "added_by",
        width: 150,
        minWidth: 100,
        maxWidth: 200,
    },
    {
        Header: i18n.t("added_at"),
        accessor: "added_at",
        width: 150,
        minWidth: 100,
        maxWidth: 200,
    },
];

const guestHistory = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
    },
    {
        Header: i18n.t("details"),
        accessor: "title",
        Cell: ActivityCell,
    },
    {
        Header: i18n.t("files"),
        accessor: "files",
        Cell: FilesCell,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "added_by.full_name",
    },
    {
        Header: i18n.t("location"),
        accessor: "location",
    },
];

const activity = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
        width: 200,
        minWidth: 100,
        maxWidth: 300,
    },
    {
        Header: i18n.t("details"),
        accessor: "type",
        Cell: ExpandCell,
        width: 450,
        minWidth: 200,
        maxWidth: 600,
        detailsStructure: {
            sub_type: "sub_type",
            notes: "notes",
        },
    },

    {
        Header: i18n.t("files"),
        accessor: "files",
        Cell: FilesCell,
    },

    {
        Header: i18n.t("location"),
        accessor: "location",
    },
    {
        Header: i18n.t("added_by"),
        accessor: "added_by.full_name",
    },
];

const guestMessage = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
        width: 200,
        minWidth: 100,
        maxWidth: 300,
    },
    {
        Header: i18n.t("details"),
        accessor: "title",
        Cell: GuestMessageCell,
        width: 450,
        minWidth: 200,
        maxWidth: 600,
        detailsStructure: {
            sub_type: "body",
            notes: "body",
        },
    },
    {
        Header: i18n.t("location"),
        accessor: "location.name",
    },
    {
        Header: i18n.t("added_by"),
        accessor: "user.full_name",
    },
];

const guestNotes = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
        width: 200,
        minWidth: 100,
        maxWidth: 300,
    },
    {
        Header: i18n.t("details"),
        accessor: "title",
        Cell: ActivityCell,
    },
    {
        Header: i18n.t("files"),
        accessor: "files",
        Cell: FilesCell,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "employee.full_name",
    },
];

const prevention = [
    {
        Header: i18n.t("date_and_time"),
        accessor: "created_at",
        Cell: DateCell,
        width: 200,
        minWidth: 100,
        maxWidth: 300,
    },
    {
        Header: i18n.t("details"),
        accessor: "type",
        Cell: ExpandCell,
        width: 450,
        minWidth: 200,
        maxWidth: 600,
        detailsStructure: {
            signals: "signals",
            reaction: "reaction",
            notes: "notes",
            conclusion: "conclusion",
            followed_up: "followed_up",
        },
    },
    {
        Header: i18n.t("files"),
        accessor: "files",
        Cell: FilesCell,
        width: 250,
        minWidth: 50,
        maxWidth: 350,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "employee.full_name",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
    },
];

const guests = [
    {
        Header: i18n.t("name"),
        accessor: "full_name",
        Cell: NameCell,
    },
    {
        Header: i18n.t("member"),
        accessor: guest => (guest.profile ? "Yes" : "no"),
    },
    {
        Header: i18n.t("last_visit"),
        accessor: "latest_entry_at",
        Cell: DateCell,
    },
    {
        Header: i18n.t("notes"),
        accessor: "notes",
    },
];

const reports = [
    {
        Header: i18n.t("type"),
        accessor: "type",
    },
    {
        Header: i18n.t("date_and_time"),
        accessor: "date",
        Cell: DateCell,
        sortType: "datetime",
    },
    {
        Header: i18n.t("by"),
        accessor: "employee.first_name",
    },
    {
        Header: i18n.t("title"),
        accessor: "title",
    },
    {
        Header: i18n.t("message"),
        accessor: "description",
        Cell: MessageCell,
    },
    {
        Header: i18n.t("location"),
        accessor: "location.name",
        Cell: DeleteReports,
    },
];

const users = [
    {
        Header: i18n.t("name"),
        accessor: "full_name",
        Cell: NameCell,
        sortKey: "name",
    },

    {
        Header: i18n.t("roles"),
        accessor: "roles",
        Cell: RolesCell,
    },

    {
        Header: i18n.t("last_login_location"),
        accessor: "last_location",
    },

    {
        Header: i18n.t("action"),
        accessor: "id",
        Cell: ActionCell,
    },
];

const suggestionsTable = [
    {
        Header: i18n.t("name"),
        accessor: "full_name",
        Cell: NameCellWithDate,
    },
    {
        Header: i18n.t("id"),
        accessor: "residence",
        Cell: ResidenceCell,
    },
    {
        Header: i18n.t("date_of_birth"),
        accessor: "date_of_birth",
        Cell: BirthDateCell,
        sortType: "datetime",
    },
    {
        Header: i18n.t("wl_slash_bl"),
        accessor: "is_white_listed",
        Cell: WhiteBlackCell,
    },
];

const checklists = [
    {
        Header: i18n.t("type"),
        accessor: "facility_check_template.name",
    },

    {
        Header: i18n.t("status"),
        accessor: "status",
    },

    {
        Header: i18n.t("by"),
        accessor: "employee.full_name",
    },

    {
        Header: i18n.t("date"),
        accessor: "created_at",
        sortKey: "created_at",
    },
];

const preventionPlanOverview = [
    {
        Header: i18n.t("guest_name"),
        accessor: "guest.full_name",
        Cell: OnlyNameCell,
        sortKey: "name",
    },
    {
        Header: i18n.t("type_of_intervention"),
        accessor: "type",
        Cell: TypeCell,
    },
    {
        Header: i18n.t("follow_up_status"),
        accessor: "followed_up",
        Cell: FollowUpCell,
    },
    {
        Header: i18n.t("added_by"),
        accessor: "employee.full_name",
    },
    {
        Header: i18n.t("date"),
        accessor: "created_at",
        Cell: DateCell,
        sortKey: "created_at",
    },
];

const preventionPlanTypeOverview = [
    {
        Header: i18n.t("guest_name"),
        accessor: "guest.full_name",
        Cell: NameCell,
    },

    {
        Header: i18n.t("follow_up_status"),
        accessor: "followed_up",
        Cell: FollowUpCell,
    },

    {
        Header: i18n.t("singals_checked"),
        accessor: "signals",
        Cell: SignalsCell,
    },
    {
        Header: i18n.t("notes"),
        accessor: "notes",
    },
    {
        Header: i18n.t("date"),
        accessor: "created_at",
        Cell: DateCell,
    },
];
const preventionPlanTypeSignalingOverview = [
    {
        Header: i18n.t("guest_name"),
        accessor: "guest.full_name",
        Cell: NameCell,
    },
    {
        Header: i18n.t("singals_checked"),
        accessor: "signals",
        Cell: SignalsCell,
    },

    {
        Header: i18n.t("notes"),
        accessor: "notes",
    },
    {
        Header: i18n.t("date"),
        accessor: "created_at",
        Cell: DateCell,
    },
];

const preventionPlanOverviewForReport = [
    {
        Header: i18n.t("guest_name"),
        accessor: "guest.full_name",
    },
    {
        Header: i18n.t("type_of_intervention"),
        accessor: "type",
    },
    {
        Header: i18n.t("signals"),
        accessor: "signals",
    },
    {
        Header: i18n.t("notes"),
        accessor: "notes",
    },
    {
        Header: i18n.t("follow_up_status"),
        accessor: "followed_up",
    },
    {
        Header: i18n.t("date"),
        accessor: "created_at",
    },
];

export const columns = {
    activity,
    entryLog,
    whitelist,
    blacklist,
    guestHistory,
    guests,
    reports,
    users,
    suggestionsTable,
    playerNotes,
    checklists,
    prevention,
    preventionPlanOverview,
    preventionPlanTypeOverview,
    preventionPlanTypeSignalingOverview,
    preventionPlanOverviewForReport,
    recentEntries,
    guestMessage,
    guestNotes,
};
