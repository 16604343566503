import { Control, Controller } from "react-hook-form";
import MultiSelect from "../MultiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../store/userStore";
import { columnsOptions } from "../../utils/exportWhitelistOptions";
import { BlacklistFormValues } from "../../types/exportForm";
import { reasonOptionsBlackList } from "../../utils/formOptions";

function ExportBlacklistFields({
    control,
}: {
    control: Control<BlacklistFormValues>;
}) {
    const { t } = useTranslation();
    const { user } = useUserStore();

    const locationOptions =
        user?.locations.map(location => {
            return {
                value: location.id.toString(),
                label: location.name,
                key: "locations",
            };
        }) ?? [];

    return (
        <>
            {locationOptions.length > 1 && (
                <div className="form-control">
                    <label className="form-label">{t("location")}:</label>
                    <div className="col-span-2">
                        <Controller
                            name="locations"
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        options={locationOptions}
                                        onChange={field.onChange}
                                        placeholder={t("add_locations") + ".."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="form-control">
                <label className="form-label">{t("columns")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="columns"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={columnsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("add_columns") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <div className="form-control">
                <label className="form-label">{t("reason")}:</label>
                <div className="col-span-2">
                    <Controller
                        name="reasons"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={reasonOptionsBlackList}
                                    onChange={field.onChange}
                                    placeholder={t("reason") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default ExportBlacklistFields;
