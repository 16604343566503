import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import useFetch from "../../hooks/useFetch";
import { useHistory } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import client from "../../client";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import WebcamPopup from "../Popups/WebcamPopup";
import useFormResponseError from "../../hooks/useFormResponseError";
import useCustomToast from "../../hooks/useCustomToast";
import usePermissions from "../../hooks/usePermissions";
import { StaffFormValues, StaffMember } from "../../types/staff";
import StaffProfilePhoto from "./StaffProfilePhoto";
import { TranslationsKeys } from "../../types/translations";
import StaffDetails from "./StaffDetails";
import StaffForm from "./StaffForm";
import { Redirect } from "react-router-dom";
import { startCase } from "lodash-es";
import Button from "../Button";

const StaffControl = ({ edit }: { edit: boolean }) => {
    const { user } = useUserStore();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
        watch,
        setValue,
    } = useForm<StaffFormValues>({
        defaultValues: {
            roles: [{ value: 3, label: "cashier" }],
            locations:
                user?.locations.length === 1
                    ? [
                          {
                              value: user?.locations[0].id,
                              label: user?.locations[0].name,
                          },
                      ]
                    : [],
        },
    });

    const { t } = useTranslation();
    const [showWebcamPopup, setShowWebcamPopup] = useState<boolean>(false);
    const [userPhoto, setUserPhoto] = useState<{
        url: string;
        file: File;
    } | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const history = useHistory();
    const params = useParams();
    const { formResponseError } = useFormResponseError();
    const { isLoading, data } = useFetch<StaffMember>(
        `employee/${edit ? params.id : user?.id}`,
        "",
        false
    );

    const { customToast } = useCustomToast();

    const { hasPermissions } = usePermissions();
    const hasViewPermissions = hasPermissions(["view_staff"]);
    const hasEditStaffPermissions = hasPermissions(["edit_staff"]);

    useEffect(() => {
        if (data) {
            const resetFields: { [key: string]: string | null } = {
                first_name: data.first_name,
                middle_name: data.middle_name,
                last_name: data.last_name,
                username: data.username,
                started_at: data.started_at,
                date_of_birth: data.date_of_birth,
                email: data.email,
            };

            // Remove unnecessary keys
            Object.entries(resetFields).forEach(([key, val]) => {
                if (!val) {
                    delete resetFields[key];
                }
            });

            if (!edit) return;

            const dateOfBirth = data.date_of_birth
                ? dayjs(data.date_of_birth).format("YYYY-MM-DD")
                : "";

            const locations = data.locations.map(location => {
                return {
                    value: location.id,
                    label: location.name,
                };
            });

            const roles = data.roles.map(role => {
                return {
                    value: role.id,
                    label: t(role.name as TranslationsKeys) as string,
                };
            });

            if (hasEditStaffPermissions) {
                reset({
                    ...resetFields,
                    date_of_birth: dateOfBirth,
                    locations,
                    roles,
                });
            } else {
                reset({
                    ...resetFields,
                    date_of_birth: dateOfBirth,
                });
            }
        }
    }, [data, reset, edit, setValue, hasEditStaffPermissions, t]);

    if (!hasViewPermissions && parseInt(params.id) !== user?.id) {
        return <Redirect to="/actions" />;
    }

    if (isLoading) {
        return <Loading />;
    }

    const onSubmit: SubmitHandler<StaffFormValues> = async data => {
        setIsSubmitting(true);

        const {
            locations,
            roles,
            first_name,
            middle_name,
            last_name,
            ...fields
        } = data;

        const formData = new FormData();
        formData.append("first_name", startCase(data.first_name));
        formData.append("middle_name", data.middle_name.toLowerCase());
        formData.append("last_name", startCase(data.last_name));

        if (userPhoto) {
            formData.append("profile_photo", userPhoto.file);
        }

        if (params.id) {
            formData.append("_method", "PUT");
        }

        Object.entries(fields).forEach(([key, value]) => {
            value && formData.append(key, value);
        });

        if (hasEditStaffPermissions) {
            locations.forEach(location => {
                formData.append("locations[]", location.value.toString());
            });

            roles.forEach(role => {
                formData.append("roles[]", role.value.toString());
            });
        }

        const url = edit ? `/api/employee/${params.id}` : "/api/employee";

        try {
            setIsSubmitting(true);
            const res = await client.post(url, formData);

            if (edit) {
                customToast("success", {
                    title: t("staff_updated"),
                });
            } else {
                customToast("success", {
                    title: t("staff_registered"),
                    link: {
                        title: t("see_staff_member"),
                        url: `/staff/${res.data.id}`,
                    },
                });

                history.push("/staff");
            }
        } catch (err: any) {
            formResponseError(err, setError);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex gap-12">
            {showWebcamPopup && (
                <WebcamPopup
                    setUserPhoto={setUserPhoto}
                    setShowWebcamPopup={setShowWebcamPopup}
                />
            )}
            <div className="w-72 shrink-0">
                <StaffProfilePhoto
                    edit={edit}
                    userPhoto={userPhoto}
                    setUserPhoto={setUserPhoto}
                    profilePhoto={data?.profile_photo_url}
                />
                {hasEditStaffPermissions && <StaffDetails control={control} />}
            </div>
            <div>
                <StaffForm
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    watch={watch}
                    onSubmit={onSubmit}
                    edit={edit}
                    setValue={setValue}
                />
                <div className="mt-8">
                    <Button
                        className="btn-primary"
                        isLoading={isSubmitting}
                        onClick={handleSubmit(data => onSubmit(data))}
                    >
                        {edit ? t("update_staff") : t("add_staff")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StaffControl;
