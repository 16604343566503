import styled from "styled-components";
import {
    CustomSelect,
    CustomSelectCurrent,
} from "../../components/CustomSelect/CustomSelect.styled";
import { FormControl } from "../../style/shared/form.styled";
import { motion } from "framer-motion";
export { FormControl } from "../../style/shared/form.styled";

export const Buttons = styled.div`
    display: flex;
    gap: 30px;
    margin: 40px 0px;
    padding-left: 200px;
`;

export const Wrapper = styled.div`
    display: flex;
    position: relative;

    ${FormControl} {
        width: 100%;
    }

    ${CustomSelect} {
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 0px;
        text-decoration-line: underline;
        color: #050505;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        padding: 10px;
        border-left: none;
        border-right: none;
    }
    ${CustomSelectCurrent} {
        border-bottom: none;
        display: flex;
        align-items: center;
    }
`;

export const Views = styled.div`
    font-size: 18px;
    line-height: 23px;
    width: 9%;
    height: 100%;
    color: ${props => props.textColor && props.textColor};
    padding: 16px 14px;
    background: ${props => props.color && props.color};
    border-radius: 0px 10px 10px 0;
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`;

export const ReportIcon = styled.div`
    border-radius: 10px 0px 0px 10px;
    width: 54px;
    min-width: 54px;
    height: 100%;
    background: ${props => props.color && props.color};
    padding: 16px 14px;
    display: flex;
    justify-content: center;
`;

export const ReportDropdown = styled(motion.div)`
    position: absolute;
    top: 58px;
    right: 1px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 340px;
    padding: 32px;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    transition-property: opacity, left, top, height;
    transition-duration: 3s, 5s;
    > svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`;

export const ViewersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 2px;

    :nth-child(even) {
        background: #f5f3f3;
    }
`;

export const Viewers = styled.div`
    font-weight: normal;
    line-height: 18px;
    color: #000000;
    margin: 32px 0px 15px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 170px;
    min-height: 170px;
    width: 100%;
`;

export const Time = styled.div`
    font-size: 14px;
`;

export const User = styled.div`
    font-size: 16px;
    margin: 5px 10px;
`;

export const Button = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 4;
`;

export const Title = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d1e45;
    align-items: center;
    display: flex;
    gap: 10px;
`;
