import { useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useCustomToast from "../hooks/useCustomToast";
import { useHistory } from "react-router";

function useGuestCountReminder() {
    const { t } = useTranslation();
    const { customToast } = useCustomToast();
    const history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            if (dayjs().minute() === 0) {
                customToast(
                    "warning",
                    {
                        title: t("please_update_guest_count"),
                        buttons: {
                            primary: {
                                onClick: () => {
                                    history.push("/update-floor-numbers");
                                },
                                className: "btn-primary",
                                children: t("update_now"),
                            },
                        },
                    },
                    { id: "guestCountReminder" }
                );
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [customToast, t, history]);
}

export default useGuestCountReminder;
