import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import socket from "../socket";
import useCustomToast from "./useCustomToast";
import { SocketStateChangeEvent } from "../types/socket";
import { useSocketState } from "../store/socketStore";

export default function useSocket() {
    const { t } = useTranslation();
    const { setSocketState } = useSocketState();
    const { customToast } = useCustomToast();

    useEffect(() => {
        socket.connector.pusher.connection.bind(
            "state_change",
            (event: SocketStateChangeEvent) => {
                setSocketState(event.current);
            }
        );

        return () => {
            socket.connector.pusher.connection.unbind("state_change");
        };
    }, [setSocketState]);

    useEffect(() => {
        socket.channel("deployments").listen("NewVersionPublishedEvent", () => {
            customToast("warning", {
                title: t("new_version_available"),
                body: t("new_version_available_notification"),
            });
        });

        return () => {
            socket.leave("deployments");
        };
    }, [customToast, t]);
}
