import { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { CSSProperties } from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { classNames } from "../../utils/style";

export default function SelectBox({
    label,
    isOpen,
    setIsOpen,
}: {
    label: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
    return (
        <button
            className={classNames(
                `mx-2 flex min-h-[32px] min-w-[100px] items-center justify-around rounded border py-[9px] px-7 leading-none hover:bg-blue-light hover:text-white`,
                isOpen ? "border-blue-light" : "border-[#bdbdbd]"
            )}
            onClick={() => setIsOpen(prev => !prev)}
        >
            {label}
            <FontAwesomeIcon
                icon={faChevronDown as IconProp}
                className="ml-1 h-2.5"
                style={
                    {
                        "--fa-primary-color": "#000",
                        "--fa-secondary-color": "#000",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "1.0",
                    } as CSSProperties
                }
                size="lg"
            />
        </button>
    );
}
