import React, { useMemo, useEffect, useState } from "react";
import * as S from "./TemplateOptions.styled";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import usePermissions from "../../hooks/usePermissions";
import usePopup from "../../hooks/usePopup";
import useTemplateMutations from "../../hooks/useTemplateMutations";
import TemplateSelectionButton from "./TemplateSelectionButton";
import TemplateEditField from "./TemplateEditField";
import TemplateAdd from "./TemplateAdd/TemplateAdd";

const preparedTemplateItems = (templates, selectedTemplateId) => {
    const currentTemplate = templates.find(
        template => template.id === selectedTemplateId
    );

    if (!currentTemplate) return [];

    return currentTemplate.facility_check_items.map(item => ({
        ...item,
        checked: false,
        notes: null,
        checked_at: null,
    }));
};

function TemplateOptions({
    dispatch,
    selectedTemplateId,
    setSelectedTemplateId,
}) {
    const { hasPermissions } = usePermissions();
    const hasEditPermissions = hasPermissions(["edit_settings"]);
    const { t } = useTranslation();
    const { data: templateData } = useFetch("facility-check-templates");
    const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
    const [templatedEditId, setTemplatedEditId] = useState(null);
    const [addTemplateText, setAddTemplateText] = useState("");
    const [editTemplateText, setEditTemplateText] = useState("");
    const { popup } = usePopup();

    const { saveTemplate, deleteTemplate, editTemplate } =
        useTemplateMutations();

    const templates = useMemo(() => {
        return templateData?.data || [];
    }, [templateData?.data]);

    useEffect(() => {
        // Update checklist when templates are switched or templates query gets invalidated
        if (!selectedTemplateId) return;

        if (!templates.find(template => template.id === selectedTemplateId)) {
            setSelectedTemplateId(null);
        }

        dispatch({
            type: "listReady",
            payload: preparedTemplateItems(templates, selectedTemplateId),
        });
    }, [dispatch, selectedTemplateId, setSelectedTemplateId, templates]);

    useEffect(() => {
        if (templates?.length && !selectedTemplateId) {
            // Set initial template
            setSelectedTemplateId(templates[0].id);
        }
    }, [selectedTemplateId, setSelectedTemplateId, templates]);

    const addTemplate = () => {
        saveTemplate(addTemplateText);
        setIsCreatingTemplate(false);
        setAddTemplateText("");
    };

    const updateTemplate = id => {
        editTemplate(id, editTemplateText);
        setTemplatedEditId(null);
        setEditTemplateText("");
    };

    const exitCreate = () => {
        setIsCreatingTemplate(false);
        setAddTemplateText("");
    };

    const exitEdit = () => {
        setTemplatedEditId(null);
        setEditTemplateText("");
    };

    const onDelete = id => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => deleteTemplate(id),
                entityName: t("template"),
            },
        });
    };

    return (
        <S.SelectionOptions>
            <span>{t("select")}:</span>
            {templates.map(template => {
                const isEditingTemplate = template.id === templatedEditId;

                return (
                    <S.SelectionButtonContainer key={template.id}>
                        {isEditingTemplate ? (
                            <TemplateEditField
                                template={template}
                                setEditTemplateText={setEditTemplateText}
                                updateTemplate={updateTemplate}
                                exitEdit={exitEdit}
                            />
                        ) : (
                            <TemplateSelectionButton
                                template={template}
                                selectedTemplateId={selectedTemplateId}
                                setSelectedTemplateId={setSelectedTemplateId}
                                setTemplatedEditId={setTemplatedEditId}
                                onDelete={onDelete}
                            />
                        )}
                    </S.SelectionButtonContainer>
                );
            })}
            {hasEditPermissions && (
                <TemplateAdd
                    isCreatingTemplate={isCreatingTemplate}
                    setAddTemplateText={setAddTemplateText}
                    addTemplate={addTemplate}
                    setIsCreatingTemplate={setIsCreatingTemplate}
                    exitCreate={exitCreate}
                />
            )}
        </S.SelectionOptions>
    );
}

export default TemplateOptions;
