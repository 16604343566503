import dayjs from "dayjs";
import React, { useState } from "react";
import Popup from "./Popup";
import * as S from "./Popups.styled";
import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";
import { useForm } from "react-hook-form";
import ExportDateFields from "../ExportDateFields";
import useFileExport from "../../hooks/useFileExport";
import {
    getTableColumns,
    getTableRows,
    tableMap,
} from "../../utils/floorNumberTables";
import { dayTranslation } from "../../utils/date";
import Button from "../Button";

function ExportFloorNumbersOverviewPopup() {
    const { close } = usePopup();
    const { t } = useTranslation();

    const entityName = t("floor_numbers_overview");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { requestExportDataByDate, exportPdfTablesByDate } =
        useFileExport(entityName);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const prepareExport = (responseData, formValues) => {
        const filterDates = {
            from_date: formValues.from_date,
            from_time: formValues.from_time,
            until_date: formValues.until_date,
            until_time: formValues.until_time,
        };

        const tableColumns = getTableColumns().map(column => {
            return column.Header;
        });

        // Add empty column for formatted day layout
        tableColumns.splice(1, 0, "");

        const tables = Object.entries(tableMap).map(([key, tableInfo]) => {
            return {
                title: tableInfo.title,
                headers: tableColumns,
                rows: getTableRows(responseData, key).map(row => {
                    // Transform for pdf
                    const { days, ...hours } = row;
                    const rowValues = Object.entries(hours).map(
                        ([key, val]) => val
                    );

                    // Split days in two cells
                    const weekDay = dayTranslation[dayjs(days).format("dddd")];
                    const formattedDay = dayjs(days).format("D MMM YYYY");
                    rowValues.unshift(...[weekDay, formattedDay]);

                    return rowValues;
                }),
            };
        });

        exportPdfTablesByDate(tables, filterDates);
    };

    const onSubmit = async data => {
        setIsSubmitting(true);
        await requestExportDataByDate("/api/guest-count-logs/history", data)
            .then(responseData => {
                close();
                prepareExport(responseData, data);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Popup>
            <S.Header>
                {t("export")}: {entityName}
            </S.Header>
            <S.ExportTablePopupForm onSubmit={handleSubmit(onSubmit)}>
                <ExportDateFields register={register} errors={errors} />
                <Button
                    type="submit"
                    className="btn-secondary"
                    isLoading={isSubmitting}
                >
                    {t("export")}
                </Button>
            </S.ExportTablePopupForm>
        </Popup>
    );
}

export default ExportFloorNumbersOverviewPopup;
