import * as S from "./DevicesBlock.styled";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-solid-svg-icons";
import usePermissions from "../../hooks/usePermissions";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TranslationsKeys } from "../../types/translations";
import usePopup from "../../hooks/usePopup";
import { DeviceType } from "../../types/devices";
import { useCallback, useEffect, useState } from "react";

type Device = {
    type: DeviceType;
    name: string;
    id: string | null;
};

function DevicesBlock() {
    const [scannerId, setScannerId] = useState<string | null>("");
    const [readerId, setReaderId] = useState<string | null>("");

    const { t } = useTranslation();
    // const [editId, setEditId] = useState<number | null>(null);
    // const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const { hasPermissions } = usePermissions();
    const { popup } = usePopup();

    const deviceHeaders = [
        {
            name: t("device_type"),
        },
        {
            name: t("device_id"),
        },
    ];

    const devices: Device[] = [
        {
            type: "id-scan",
            name: "id_scanner",
            id: scannerId,
        },
        {
            type: "rfid",
            name: "rfid_reader",
            id: readerId,
        },
    ];

    const hasEditPermissions = hasPermissions(["view_entry_log"]);

    const set = useCallback((type: DeviceType, identifier: string) => {
        if (type === "id-scan") {
            setScannerId(identifier);
        } else {
            setReaderId(identifier);
        }
    }, []);

    useEffect(() => {
        setScannerId(window.localStorage.getItem("acarat_id_scanner"));
        setReaderId(window.localStorage.getItem("acarat_rfid_reader"));
    }, []);

    // const editHandler = (index: number | null, visible: boolean) => {
    //     setEditId(index);
    //     setSaveButtonDisabled(visible);
    // };

    // const handleChange = (
    //     e: React.ChangeEvent<HTMLInputElement>,
    //     device: string
    // ) => {
    //     setDevice({ name: device, id: e.target.value });
    // };

    // const handlerDeviceId = () => {
    //     if (device.id === "") {
    //         localStorage.removeItem(device.name);
    //     } else {
    //         localStorage.setItem(device.name, device.id);
    //     }
    //     setEditId(null);
    //     setSaveButtonDisabled(true);
    // };

    return (
        <S.DevicesBlock>
            <S.BlockTitle>{t("devices")}</S.BlockTitle>
            <S.BlockBody>
                <S.DevicesHeader>
                    {deviceHeaders.map((header, index) => (
                        <S.DevicesHeaderColumn key={index}>
                            {header.name}
                        </S.DevicesHeaderColumn>
                    ))}
                </S.DevicesHeader>

                <S.DevicesBody>
                    {devices.map((device, index) => (
                        <S.DeviceRow key={index}>
                            <S.DeviceNames>
                                {t(device.name as TranslationsKeys) as string}
                            </S.DeviceNames>

                            <S.DeviceInputs>
                                {device.id || t("device_id_not_set")}
                            </S.DeviceInputs>

                            <S.DeviceIdEdit
                                // disabled={editId !== null && editId !== index}
                                disabled={false}
                            >
                                {hasEditPermissions && (
                                    <button
                                        onClick={() => {
                                            popup({
                                                type: "ADD_DEVICE_POPUP",
                                                data: {
                                                    type: device.type,
                                                    set,
                                                },
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                device.id
                                                    ? (faPencil as IconProp)
                                                    : (faPlus as IconProp)
                                            }
                                            size="lg"
                                        />
                                    </button>
                                )}
                            </S.DeviceIdEdit>
                        </S.DeviceRow>
                    ))}
                </S.DevicesBody>
            </S.BlockBody>
        </S.DevicesBlock>
    );
}

export default DevicesBlock;
