import create from "zustand";
import { SocketState } from "../types/socket";

type SocketStore = {
    socketState: SocketState;
    setSocketState: (socketState: SocketState) => void;
};

export const useSocketState = create<SocketStore>(set => ({
    socketState: "connecting",
    setSocketState: (socketState: SocketState) =>
        set(() => {
            return { socketState };
        }),
}));
