import { useState, useEffect } from "react";
import * as S from "../Popups.styled";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExcelExportFields from "../../ExcelExportFields";
import Popup from "../Popup";
import { reasonOptionsPlayPause } from "../../../utils/formOptions";
import { queryMaker } from "../../../utils/queryMaker";

function ExportPlayPausePopup() {
    const {
        register,
        watch,
        formState: { errors },
        control,
    } = useForm();

    const { t } = useTranslation();
    const [fields, setFields] = useState();
    const [queryString, setQueryString] = useState(undefined);

    const {
        location_id,
        date_from,
        date_until,
        initiated_by: initiated,
        columns: column,
    } = watch();

    useEffect(() => {
        setFields({
            filters: {
                date_from,
                date_until,
                locations: location_id?.map(item => item.value),
                reasons: initiated?.map(item => item.value),
            },
            columns: column?.map(item => item.value),
        });
    }, [column, date_from, date_until, location_id, initiated]);

    useEffect(() => {
        setQueryString(queryMaker(fields));
    }, [fields]);

    const additionalSelectBoxes = [
        {
            label: "initiated_by",
            name: "initiated_by",
            placeholder: "initiated_by",
            options: reasonOptionsPlayPause,
            selectKey: "initiated_by",
        },
    ];

    return (
        <Popup>
            <S.Header>
                {t("export")} {t("playpause").toLowerCase()}
            </S.Header>

            <S.EntryLogPopupForm>
                <ExcelExportFields
                    register={register}
                    errors={errors}
                    control={control}
                    additionalSelectBoxes={additionalSelectBoxes}
                />

                <S.StyledLink
                    href={`${
                        process.env.REACT_APP_API_BASE_URL
                    }/exports/play-pause${
                        queryString ? `?${queryString}` : ""
                    }`}
                    download
                >
                    {t("export")}
                </S.StyledLink>
            </S.EntryLogPopupForm>
        </Popup>
    );
}

export default ExportPlayPausePopup;
