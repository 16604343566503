import i18n from "i18next";

export const nationalityOptions = [
    { value: "INT", label: i18n.t("no"), key: "type" },
    { value: "NL", label: i18n.t("yes"), key: "type" },
];

export const checkOptions = [
    { value: "Denied", label: i18n.t("denied"), key: "sub_type" },
    { value: "Approved", label: i18n.t("approved"), key: "sub_type" },
];

export const columnsOptions = [
    { value: "Name", label: i18n.t("name"), key: "columns" },
    { value: "Nationality", label: i18n.t("has_bsn"), key: "columns" },
    { value: "Check status", label: i18n.t("entry_status"), key: "columns" },
    { value: "Notes", label: i18n.t("notes"), key: "columns" },
    { value: "Checked in at", label: i18n.t("checked_on"), key: "columns" },
];
