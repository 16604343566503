import styled from "styled-components";
import { Form, FormControl } from "../../style/shared/form.styled";
export {
    ExportTablePopupForm,
    EntryLogPopupForm,
} from "../../style/shared/exportTablePopupForm.styled";

export const AddGuest = styled.div`
    width: 470px;
    height: 490px;
    background: #fffbfb;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    padding-bottom: 100px;
`;

export const FormExtension = styled(Form)`
    input {
        background: transparent;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    button {
        align-self: center;
    }
`;

export const FormControlExtension = styled(FormControl)`
    textarea:not([resize="true"]) {
        resize: none !important;
    }

    select {
        border: none;
        margin-left: 10px;
        border-bottom: 1px solid #bdbdbd !important;
        width: 100%;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0px;
        background: red;
        background-image: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: center;
        * {
            background: red;
        }
    }
    /* .select-option {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: red !important;
        color: red !important;
        font-size: 20px;
    } */
`;

export const AddGuestFormContainer = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
`;

export const SelectContainer = styled.div`
    width: 200px;
    margin-top: 20px;
`;

export const StyledLink = styled.a`
    background: ${props =>
        props.colors ? props.colors.main : props.theme.main.secondaryColor};
    color: white;
    width: 180px;
    border-radius: 250px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    margin-top: 20px;
    padding: 0 16px;

    &:hover {
        color: white;
        background: #e66147;
    }
`;

export const AddGuestContainer = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
`;

export const AddGuestFormTitle = styled.h2`
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
`;

export const AddGuestForm = styled.form`
    width: 100%;
    margin-top: 40px;
    text-align: center;

    button {
        margin-top: 50px;
    }

    div {
        font-size: 20px;
        display: flex;

        justify-content: space-between;
        margin-top: 15px;

        select {
            width: 225px;
            height: 35px;
            background: #f2f2f2;
            border: 0;
            font-size: 15px;
            padding-left: 15px;
            color: #bdbdbd;
        }

        input {
            width: 225px;
            outline: none;
            border: none;
            background: #f2f2f2;
            height: 35px;
            padding-left: 15px;
            font-size: 0.7em;

            &::placeholder {
                color: #bdbdbd;
            }
        }
    }
`;

export const Entry = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const EntryContainer = styled.div`
    width: 100%;
    max-width: 600px;
    background: #fffbfb;
    border-radius: 5px;
`;

export const EntryHeader = styled.div`
    width: 100%;
    background: ${props => (props.approved ? "#219653" : "#EB5757")};
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 28px;
    color: white;
    border-radius: 5px;
`;

export const StatusImage = styled.div`
    background: url(${props => props.bg});
    background-size: cover;
    height: 100px;
    width: 100px;
    flex: 0 0 100px;
    margin-right: 20px;
    position: absolute;
    left: 20px;
    top: 20px;
`;

export const EntryContent = styled.div`
    position: relative;
    padding: 20px 20px 40px 20px;
`;

export const EntryInfo = styled.div`
    width: 300px;
    margin: 0 auto;
`;

export const PlayerInfo = styled.div`
    margin: 20px 0 60px 0;
    font-size: 20px;
    color: #333333;

    > div {
        display: flex;
        margin-bottom: 30px;
        span {
            &:first-child {
                flex: 0 0 160px;
            }
        }
    }

    button {
        margin-top: 50px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

    > button {
        width: auto;
    }
`;

export const AdditionalButtons = styled.div`
    width: 300px;
    text-align: center;
    button {
        padding: 0;
        text-decoration: underline;
        margin: 0 10px;
    }
`;

//DeletePopups
export const DeleteContainer = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
`;

export const ButtonsDelete = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 40px;
`;

export const DeleteFormContainer = styled.div`
    width: 440px;
    height: 268px;
    padding: 32px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;

    > svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`;
export const Title = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d1e45;
    align-items: center;
    display: flex;
    gap: 10px;
`;

export const DeleteRequest = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-top: 32px;
    padding-right: 40px;
`;
export const Buttons = styled.div`
    display: flex;
    gap: 15px;
    /* margin-top: 40px; */
`;

export const ButtonsCapture = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
`;

export const WelcomeBackPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const WelcomeBackPopupContainer = styled.div`
    width: 770px;
    background: #fffbfb;
    border-radius: 4px;
    padding: 40px 35px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: black;
    p {
        padding-top: 25px;
    }

    > form {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            border: none;
            background: none;
            margin-left: 10px;
            border-bottom: 1px solid #bdbdbd;
        }

        textarea {
            width: 100%;
            margin-top: 25px;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 210px;
            padding: 10px;
            resize: none;
            overflow: auto;

            &::placeholder {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        button {
            margin-top: 20px;
        }
    }
    svg {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
        cursor: pointer;
    }
`;

export const Header = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d1e45;
    margin: 0 auto;
    text-align: left;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 25px;
`;

export const LoaderContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    min-width: 280px;
    min-height: 280px;
    max-width: 400px;
    max-height: 400px;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

export const Divider = styled.div`
    height: 1px;
    background: #bdbdbd;
    width: auto;
    margin-top: 25px;
`;

export const Inputs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > input {
        display: flex;
        border: none;
        border-bottom: 1px solid #bdbdbd;

        ${props => props.hasErrors && "color: red; border-color: red;"}
    }
`;

export const ErrorMessage = styled.div`
    margin-top: 5px;
    height: 20px;
    > p {
        font-size: 14.5px;
        color: red;
        margin-top: 5px;
        /* margin-bottom: 5px; */
    }
    > p::before {
        display: inline;
        content: " ";
    }
`;

export const ViewerContainer = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
`;

export const ViewerFormContainer = styled.div`
    width: 440px;
    height: 340px;
    padding: 32px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;

    > svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`;

export const EditContainer = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
`;

export const EditFormContainer = styled.div`
    width: 700px;
    height: 480px;
    padding: 32px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;

    > svg {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`;

export const ButtonsEdit = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 40px;
    justify-content: center;
`;

export const CorrectiveMeasurePopop = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CorrectiveMeasurePopopContainer = styled.div`
    width: 770px;
    height: 700px;
    background: #fffbfb;
    border-radius: 4px;
    padding: 40px 35px 0px 35px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: black;
    p {
        padding-top: 25px;
    }

    > form {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            border: none;
            background: none;
            margin-left: 10px;
            border-bottom: 1px solid #bdbdbd;
        }

        textarea {
            width: 100%;
            margin-top: 25px;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 210px;
            padding: 10px;
            resize: none;
            overflow: auto;

            &::placeholder {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        button {
            margin-top: 20px;
        }
    }
    svg {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
`;

export const CorrectiveMeasurePopopTitle = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d1e45;
    margin: 0 auto;
    text-align: left;
`;

export const Text = styled.div`
    padding: 20px;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
`;

export const FormContainer = styled.div`
    margin: 20px;
    ${FormControl} {
        label {
            flex: 0 0 150px;
            @media screen and (max-width: ${props => props.theme.layout.bpM}) {
                flex: 0 0 0px;
            }
        }
    }
`;

export const ButtonContianer = styled.div`
    text-align: center;
`;

export const ExportPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ExportPopupContainer = styled.div`
    width: 550px;
    background: #fffbfb;
    border-radius: 4px;
    padding: 40px 35px 40px 35px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: black;
    p {
        padding-top: 25px;
    }

    > form {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            border: none;
            background: none;
            padding-right: 11px;
            width: 100%;
            border-bottom: 1px solid #bdbdbd;
        }

        textarea {
            width: 100%;
            margin-top: 25px;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 210px;
            padding: 10px;
            resize: none;
            overflow: auto;

            &::placeholder {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        button {
            margin-top: 20px;
        }
    }
    svg {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;

        cursor: pointer;
    }
`;

export const ExportPlanPopup = styled.div`
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ExportPlanPopupContainer = styled.div`
    width: 770px;
    height: auto;
    background: #fffbfb;
    border-radius: 4px;
    padding: 40px 35px 35px 35px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: black;
    p {
        padding-top: 25px;
    }

    > form {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            border: none;
            background: none;
            margin-left: 10px;
            border-bottom: 1px solid #bdbdbd;
        }

        textarea {
            width: 100%;
            margin-top: 25px;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 210px;
            padding: 10px;
            resize: none;
            overflow: auto;

            &::placeholder {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        button {
            margin-top: 20px;
        }
    }
    svg {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
        cursor: pointer;
    }
`;
