import React, { useState, useEffect } from "react";
import CustomSelect from "../../CustomSelect";
import * as S from "./FormSelect.styled";

function FormSelect({
    formKey,
    label,
    required = false,
    register,
    errors,
    options,
    placeholder = "",
    setValue,
    defaultValue,
    readOnly = false,
    before,
    after,
    callBack,
    afterBottom,
    borderStyle = false,
}) {
    const updateSelectedOption = option => {
        option && setValue(formKey, option);
    };

    const [isSelected, setIsSelected] = useState(null);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setIsSelected(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        register(formKey, { required: required });
    }, [formKey, required, register]);

    const error = errors[formKey];

    useEffect(() => {
        if (error) {
            setShowError(true);
        }
    }, [error, formKey]);

    return (
        <S.FormControl error={errors[formKey]} required={required}>
            <label>{label}</label>
            {before}
            <CustomSelect
                options={options}
                onChangeCallback={callBack ?? updateSelectedOption}
                placeholder={placeholder}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                readOnly={readOnly}
                errors={errors}
                formKey={formKey}
                required={required}
                showError={showError}
                setShowError={setShowError}
                afterBottom={afterBottom}
                borderStyle={borderStyle}
            />
            {after}
        </S.FormControl>
    );
}

export default FormSelect;
