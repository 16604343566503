import client from "../client";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useCallback } from "react";
import useLogo from "./useLogo";
import useCustomToast from "./useCustomToast";

function useFileExport(entityName) {
    const { t } = useTranslation();
    const { customToast } = useCustomToast();
    const { getClientLogo } = useLogo();

    const requestExportDataByDate = useCallback(
        (endpoint, params) => {
            const currentTime = dayjs().format("HH:mm");

            return new Promise((resolve, reject) => {
                client
                    .get(`${endpoint}?`, {
                        params: {
                            per_page: 10000,
                            type: params?.type,
                            date_from: `${params?.from_date}T${params?.from_time}`,
                            date_until: `${params?.until_date}T${
                                params.until_time || currentTime
                            }`,
                        },
                    })
                    .then(res => {
                        const responseData = res.data.data;
                        if (responseData.length < 1) {
                            customToast("error", {
                                title: t("no_data_found"),
                            });
                            reject();
                        } else {
                            customToast("success", {
                                title: t(`entity_exported`, {
                                    entity: entityName,
                                }),
                            });
                            resolve(responseData);
                        }
                    })
                    .catch(err => {
                        if (err.isAxiosError) {
                            customToast("error", {
                                title: t("error_data_could_not_be_sent"),
                                body: err.response.data.message,
                            });
                        }

                        reject();
                    });
            });
        },
        [customToast, entityName, t]
    );

    const pdfHeader = useCallback(
        (doc, filterDates, title) => {
            doc.addImage(getClientLogo(), "PNG", 20, 3, 25, 15);
            doc.text(20, 22, title || entityName);
            doc.setFontSize(11);
            doc.text(20, 28, dayjs(filterDates.from_date).format("DD-MM-YYYY"));
            doc.text(41, 28, "-");
            doc.text(43, 28, filterDates.from_time || "00:00");
            doc.text(54, 28, "=>");
            doc.text(
                59,
                28,
                dayjs(filterDates.until_date).format("DD-MM-YYYY")
            );
            doc.text(80, 28, "-");
            doc.text(82, 28, filterDates.until_time || "00:00");
            doc.text(240, 22, t("date"));
            doc.text(240, 28, dayjs(new Date()).format("DD-MM-YYYY HH:mm"));
        },
        [entityName, getClientLogo, t]
    );

    const exportPdfTableByDate = useCallback(
        (
            tableHeaders,
            tableRows,
            filterDates,
            title,
            fileName,
            columnStyles
        ) => {
            const doc = new jsPDF({
                orientation: "landscape",
            });

            pdfHeader(doc, filterDates, title);

            doc.autoTable({
                head: [tableHeaders],
                margin: { top: 30, left: 6 },
                body: tableRows,
                columnStyles: columnStyles ?? {
                    0: { halign: "left", fontStyle: "bold" },
                },
            });

            doc.save(fileName || dayjs(new Date()).format("DD-MM-YYYY HH:mm"));
        },
        [pdfHeader]
    );

    const exportPdfTablesByDate = useCallback(
        (tables, filterDates, title, fileName, columnStyles) => {
            const doc = new jsPDF({
                orientation: "landscape",
            });
            pdfHeader(doc, filterDates, title);

            tables.forEach((table, index) => {
                const tableStartY = index ? doc.lastAutoTable.finalY + 14 : 44;
                const textStartY = index ? doc.lastAutoTable.finalY + 10 : 40;

                doc.setFontSize(12);
                doc.setFont(undefined, "bold");
                doc.text(table.title, 6, textStartY);

                doc.autoTable({
                    startY: tableStartY,
                    head: [table.headers],
                    margin: { horizontal: 6 },
                    body: table.rows,
                    columnStyles: columnStyles ?? {
                        0: { halign: "left", fontStyle: "bold" },
                    },
                });
            });

            doc.save(fileName || dayjs(new Date()).format("DD-MM-YYYY HH:mm"));
        },
        [pdfHeader]
    );

    return {
        requestExportDataByDate,
        exportPdfTableByDate,
        exportPdfTablesByDate,
    };
}

export default useFileExport;
