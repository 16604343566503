import React, { useState } from "react";
import * as S from "./Customize.styled";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLanguage,
    faCamera,
    faPalette,
    faAdjust,
    faIcons,
    faFont,
} from "@fortawesome/pro-solid-svg-icons";
import LanguageSettings from "../../components/LanguageSettings";
import Button from "../../components/Button";

const appearanceMenuItems = {
    language: {
        title: "Language",
        icon: faLanguage,
        component: <LanguageSettings />,
    },
    casino_logo: {
        title: "Add Casino Logo",
        icon: faIcons,
    },
    cover_picture: {
        title: "Add Cover picture",
        icon: faCamera,
    },
    colors: {
        title: "Edit interface colors",
        icon: faPalette,
    },
    font: {
        title: "Change font",
        icon: faFont,
    },
    dark_mode: {
        title: "Turn on dark mode",
        icon: faAdjust,
    },
};

function Customize() {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState("language");

    return (
        <S.Customize>
            <S.Block>
                <S.BlockHeader>{t("appearence")}</S.BlockHeader>
                <S.BlockContent>
                    <S.AppearanceContent>
                        <S.AppearanceMenu>
                            {Object.entries(appearanceMenuItems).map(
                                ([key, item]) => (
                                    <S.MenuItem
                                        active={key === activeSection}
                                        onClick={() => setActiveSection(key)}
                                        key={key}
                                    >
                                        <S.MenuIcon>
                                            <FontAwesomeIcon icon={item.icon} />
                                        </S.MenuIcon>
                                        <span>{item.title}</span>
                                    </S.MenuItem>
                                )
                            )}
                        </S.AppearanceMenu>
                        <S.AppearanceSection>
                            {appearanceMenuItems[activeSection].component}
                            <S.ButtonContainer>
                                <Button className="btn-primary">
                                    {t("save")}
                                </Button>
                            </S.ButtonContainer>
                        </S.AppearanceSection>
                    </S.AppearanceContent>
                </S.BlockContent>
            </S.Block>
            <S.Block>
                <S.BlockHeader>{t("settings")}</S.BlockHeader>
                <S.BlockContent></S.BlockContent>
            </S.Block>
        </S.Customize>
    );
}

export default Customize;
