import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as S from "./ExcelExportFields.styled";
import { useUserStore } from "../../store/userStore";
import MultiSelect from "../MultiSelect/MultiSelect";
import { columnsOptions } from "../../utils/exportOptionsBlackListLog";
import { Controller } from "react-hook-form";

function ExcelExportFields({
    register,
    errors,
    control,
    additionalSelectBoxes,
}) {
    const { t } = useTranslation();

    const { user } = useUserStore();

    const locationOptions =
        user?.locations.map(location => {
            return {
                value: location.id,
                label: location.name,
                key: "locations",
            };
        }) ?? [];

    return (
        <>
            <S.FormControl error={errors.date_from} required={false}>
                <label>{t("from_date")}:</label>
                <input
                    type="date"
                    min={dayjs(new Date("2020/06/01")).format("YYYY-MM-DD")}
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("date_from", { required: false })}
                />
            </S.FormControl>

            <S.FormControl error={errors.date_until} required={false}>
                <label>{t("until_date")}:</label>
                <input
                    type="date"
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("date_until", { required: false })}
                />
            </S.FormControl>

            <S.FormControl>
                <label>{t("location") + "/" + t("columns")}:</label>
                <S.MultiSelectContainer>
                    {locationOptions.length > 1 && (
                        <Controller
                            name="location_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => {
                                return (
                                    <MultiSelect
                                        options={locationOptions}
                                        onChange={field.onChange}
                                        placeholder={t("add_locations") + ".."}
                                        hasError={
                                            fieldState.error !== undefined
                                        }
                                        value={field.value}
                                    />
                                );
                            }}
                        />
                    )}
                    <Controller
                        name="columns"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                            return (
                                <MultiSelect
                                    options={columnsOptions}
                                    onChange={field.onChange}
                                    placeholder={t("add_columns") + ".."}
                                    hasError={fieldState.error !== undefined}
                                    value={field.value}
                                />
                            );
                        }}
                    />
                </S.MultiSelectContainer>
            </S.FormControl>

            {additionalSelectBoxes.length &&
                additionalSelectBoxes.map(selectBox => (
                    <S.FormControl key={selectBox.name}>
                        <label>{t(selectBox.label)}:</label>
                        <S.MultiSelectContainer>
                            <Controller
                                name={selectBox.name}
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <MultiSelect
                                            options={selectBox.options}
                                            onChange={field.onChange}
                                            placeholder={t(
                                                selectBox.placeholder
                                            )}
                                            selectKey={selectBox.selectKey}
                                            hasError={
                                                fieldState.error !== undefined
                                            }
                                            value={field.value}
                                        />
                                    );
                                }}
                            />
                        </S.MultiSelectContainer>
                    </S.FormControl>
                ))}
        </>
    );
}

export default ExcelExportFields;
