import i18n from "i18next";
import dayjs from "dayjs";

export const dayTranslation = {
    Monday: i18n.t("monday"),
    Tuesday: i18n.t("tuesday"),
    Wednesday: i18n.t("wednesday"),
    Thursday: i18n.t("thursday"),
    Friday: i18n.t("friday"),
    Saturday: i18n.t("saturday"),
    Sunday: i18n.t("sunday"),
};

export const validateDateOfBirth = (val: string, requiredAge: string) => {
    const difference = requiredAge
        ? dayjs().diff(val, "years") >= parseInt(requiredAge)
        : false;

    return (
        difference ||
        (i18n.t("age_must", {
            age: requiredAge,
        }) as string)
    );
};
