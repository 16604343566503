import CustomToast from "../components/CustomToast";
import toast from "react-hot-toast";
import { useCallback } from "react";
import { ToastType, ToastContent } from "../types/toast";

type ToastOptions = {
    duration?: number;
    id?: string;
};

const toastDuration: { [key in ToastType]: number } = {
    success: 7000,
    warning: 900000,
    checkInMessage: 900000,
    error: Infinity,
    denied: Infinity,
    birthday: Infinity,
};

function useCustomToast() {
    const customToast = useCallback(
        (type: ToastType, content: ToastContent, options?: ToastOptions) =>
            toast.custom(
                toastState => (
                    <CustomToast
                        toastState={toastState}
                        type={type}
                        content={content}
                    />
                ),
                options?.id
                    ? {
                          id: options.id,
                          duration: options.duration || toastDuration[type],
                      }
                    : { duration: options?.duration || toastDuration[type] }
            ),
        []
    );

    const clearToasts = useCallback(() => {
        toast.dismiss();
    }, []);

    return { customToast, clearToasts };
}

export default useCustomToast;
