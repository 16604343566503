import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import VersionInformationContent from "../../components/VersionInformationContent/VersionInformationContent";

export default function VersionInformation() {
    const { t } = useTranslation();

    return (
        <div>
            <PageHeader
                title={t("version_information")}
                showLists={false}
                showRegisterButton={false}
                showSearch={false}
            />

            <VersionInformationContent versionsToShow={999999999} />
        </div>
    );
}
