import React, { useState, useRef } from "react";
import * as S from "./Filter.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

export default function Select({ label, children }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();
    useOnClickOutside(dropdownRef, () => setIsOpen(false));

    return (
        <>
            <S.Select onClick={() => setIsOpen(prev => !prev)} open={isOpen}>
                {label}
                <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                        "--fa-primary-color": "#000",
                        "--fa-secondary-color": "#000",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "1.0",
                    }}
                    size="lg"
                />
            </S.Select>

            {isOpen && <S.DropDown ref={dropdownRef}>{children}</S.DropDown>}
        </>
    );
}
