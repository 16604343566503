import * as S from "./GuestToday.styled";
import { useTranslation } from "react-i18next";
import { EntriesStats } from "../../../types/statistics";
import BlockHeader from "../BlockHeader/BlockHeader";

function GuestsToday({
    entriesStats,
}: {
    entriesStats: EntriesStats | undefined;
}) {
    const { t } = useTranslation();

    return (
        <S.Block>
            <BlockHeader
                title={t("guests")}
                subTitle={t("all_locations")}
                link="/log"
            />
            <S.StatSquares>
                <S.StatSquare>
                    <S.StatSquareNumber>
                        {entriesStats?.approved}
                    </S.StatSquareNumber>
                    <S.StatSquareName>{t("accepted")}</S.StatSquareName>
                </S.StatSquare>
                <S.StatSquare>
                    <S.StatSquareNumber>
                        {entriesStats?.denied_total}
                    </S.StatSquareNumber>
                    <S.StatSquareName>{t("rejected")}</S.StatSquareName>
                </S.StatSquare>
            </S.StatSquares>
            <S.GuestCountList>
                <S.GuestCountListItem>
                    <span>{t("rejected_by_cruks")}</span>
                    <span>{entriesStats?.denied_cruks}</span>
                </S.GuestCountListItem>
                <S.GuestCountListItem>
                    <span>{t("rejected_by_blacklist")}</span>
                    <span>{entriesStats?.denied_blacklist}</span>
                </S.GuestCountListItem>
                <S.GuestCountListItem>
                    <span>{t("rejected_by_playpause")}</span>
                    <span>{entriesStats?.denied_whitelist}</span>
                </S.GuestCountListItem>
            </S.GuestCountList>
        </S.Block>
    );
}

export default GuestsToday;
