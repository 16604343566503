import styled from "styled-components";

export const CurrentGuestsContainer = styled.div`
    width: 100%;
    height: auto;
    max-height: 400px;
    margin-top: 33px;
    background: #fffbfb;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
    }
`;

export const Header = styled.div`
    font-size: 20px;
    font-weight: 400;
    height: 55px;
    color: #fff;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    > span {
        color: #fff;
        font-size: 15px;
        margin: 5px 0 0 5px;
    }
    background: #fa448c;
    > svg {
        margin: 0 10px 0 13px;
    }
`;

export const Floors = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

export const Floor = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px;
`;

export const FloorLabel = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const FloorCount = styled.div`
    display: flex;
    justify-content: flex-end;
`;
