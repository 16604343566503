import React, { useRef, useState } from "react";
import * as S from "./Popups.styled";
import { useForm } from "react-hook-form";
import client from "../../client";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../../hooks/useFetch";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { usePopupStore } from "../../store/popupStore";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import useCustomToast from "../../hooks/useCustomToast";
import Button from "../Button";

export default function WelcomeBackPopup({ recordId, list, playerId }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { t } = useTranslation();
    const welcomeBackPopupRef = useRef(null);
    const history = useHistory();
    const { refetch } = useFetch(`player/${playerId}`);
    const { setPopupType } = usePopupStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { customToast } = useCustomToast();

    const onSubmit = data => {
        setIsSubmitting(true);

        const resource = list === "wl" ? "whitelist" : "blacklist";

        client
            .delete(`/api/${resource}-records/${recordId}`, {
                data,
            })
            .then(() => {
                setPopupType(null);

                refetch();

                history.push(`/guest/${playerId}?action=activity_overview`);
                customToast("success", {
                    title: t("player_is_removed_from_the_entity", {
                        entity: t(list === "wl" ? "playpause" : "blacklist"),
                    }),
                });
            })
            .catch(error => {
                customToast("error", {
                    title: error.message,
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useOnClickOutside(welcomeBackPopupRef, () => setPopupType(null));

    return (
        <S.WelcomeBackPopup>
            <S.WelcomeBackPopupContainer ref={welcomeBackPopupRef}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                        "--fa-primary-color": "#1D1E45",
                        "--fa-secondary-color": "#1D1E45",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "0.9",
                    }}
                    onClick={() => {
                        setPopupType(null);
                    }}
                />

                <S.Header>{t("welcome_back_talk")}</S.Header>
                <p>
                    {t("this_player_is_being_removed_from_the_entity", {
                        entity: t(list === "wl" ? "playpause" : "blacklist"),
                    })}
                </p>
                <p>{t("to_confirm_have_a_welcome_back_talk")}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <S.FormControlExtension
                        error={errors.removal_reason}
                        required={true}
                    >
                        <label>{t("reason_for_removal")}</label>
                        <input
                            type="text"
                            {...register("removal_reason", { required: true })}
                        />
                    </S.FormControlExtension>

                    <S.FormControlExtension>
                        <textarea
                            placeholder="Notes"
                            {...register("removal_note")}
                            prop={errors.removal_reason}
                        />
                    </S.FormControlExtension>
                    <Button
                        className="btn-secondary"
                        isLoading={isSubmitting}
                        type="submit"
                    >
                        {t("save")}
                    </Button>
                </form>
            </S.WelcomeBackPopupContainer>
        </S.WelcomeBackPopup>
    );
}
