import { useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Loading from "../../components/Loading";
import useFetch from "../../hooks/useFetch";
import { useHistory } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import dayjs from "dayjs";
import ChangePasswordPopup from "../../components/Popups/ChangePasswordPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import client from "../../client";
import { faPencil, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import useCustomToast from "../../hooks/useCustomToast";
import usePopup from "../../hooks/usePopup";
import { StaffMember } from "../../types/staff";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties } from "styled-components";

const Account = () => {
    const { t } = useTranslation();
    const { user } = useUserStore();
    const history = useHistory();
    const params = useParams();

    const [showPopupPasswords, setShowPopupPasswords] = useState(false);
    const queryClient = useQueryClient();
    const { popup } = usePopup();

    const { isLoading, data } = useFetch<StaffMember>(
        `employee/${params.id ? params.id : user?.id}`
    );
    const { customToast } = useCustomToast();

    if (isLoading) {
        return <Loading />;
    }

    type Params = {
        [key: string]: string;
    };

    const deleteHandler = (params?: Params) => {
        client
            .delete(`/api/employee/${params?.id}`)
            .then(() => {
                customToast("success", {
                    title: t(`entity_successfully_deleted`, {
                        entity: t("staff_member"),
                    }),
                });

                queryClient.invalidateQueries("employee");
                history.push("/staff");
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
                history.push("/staff");
            });
    };

    const removeProfile = () => {
        popup({
            type: "DELETE_ENTITY",
            data: {
                deleteHandler: () => deleteHandler(params),
                entityName: t("profile"),
            },
        });
    };

    const editButton =
        "mt-[25px] flex cursor-pointer items-center justify-center gap-[10px] rounded-[22px] bg-[#1d1e45] pt-[15px] pr-[35px] pb-[15px] pl-[35px] text-center text-xl text-white shadow-lg hover:bg-[#23256d] [&>svg]:text-2xl";

    return (
        <>
            {data && (
                <>
                    <PageHeader
                        title={params.id ? t("staff_profile") : t("my_account")}
                        showLists={false}
                    />
                    <div className="flex flex-row items-start gap-[55px]">
                        {showPopupPasswords && (
                            <ChangePasswordPopup
                                setShowPopupPasswords={setShowPopupPasswords}
                            />
                        )}

                        <div className="relative w-[280px]">
                            <img
                                src={data.profile_photo_url}
                                alt="profile"
                                className="h-full max-h-[280px] min-h-[280px] w-full min-w-[280px] max-w-[280px] rounded-full object-cover"
                            />
                            <div className="mt-[50px] flex flex-col gap-[10px]">
                                <span className="text-xl font-normal leading-[23px] text-[#050505]">
                                    {t("locations")}:
                                </span>
                                {data.locations.map(location => {
                                    return (
                                        <li
                                            key={location.id}
                                            className="list-none text-[#f2994a] underline"
                                        >
                                            {location.name}
                                        </li>
                                    );
                                })}
                            </div>
                            <div className="mt-[50px] flex flex-col gap-[10px]">
                                <span className="text-xl font-normal leading-[23px] text-[#050505]">
                                    {t("roles")}:
                                </span>
                                {data.roles.map(role => {
                                    return (
                                        <li
                                            key={role.id}
                                            className="list-none capitalize text-[#c54d34]"
                                        >
                                            {role.name}
                                        </li>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-[440px]">
                            <div className="mt-[10px] flex flex-col gap-[10px] text-xl leading-[23px] text-[#050505]">
                                <li className="flex list-none items-center gap-[30px] last:text-lg">
                                    {data.full_name}{" "}
                                </li>
                            </div>
                            <div className="mt-[40px] flex flex-col gap-[25px] text-left text-base leading-[18px] text-[#050505] [&>li]:flex [&>li]:first-letter:uppercase [&>li>span]:w-[180px] [&>li>span]:text-[#828282]">
                                <li>
                                    <span>{t("first_name")}</span>{" "}
                                    {data.first_name}
                                </li>
                                <li>
                                    <span>{t("middle_name")}</span>{" "}
                                    {data.middle_name ?? null}
                                </li>
                                <li>
                                    <span>{t("last_name")}</span>{" "}
                                    {data.last_name}
                                </li>
                                <li>
                                    <span>{t("username")}</span> {data.username}
                                </li>
                                {user?.id === data.id && (
                                    <li>
                                        <span>{t("password_control")}</span>{" "}
                                        <div
                                            onClick={() =>
                                                setShowPopupPasswords(true)
                                            }
                                            className="italic underline cursor-pointer"
                                        >
                                            {t("change_password")}
                                        </div>
                                    </li>
                                )}

                                <li>
                                    <span>{t("date_of_birth")}</span>
                                    {data.date_of_birth
                                        ? dayjs(data.date_of_birth).format(
                                              "DD-MM-YYYY"
                                          )
                                        : ""}
                                </li>
                                <li>
                                    <span>{t("email_address")}</span>
                                    {data.email ?? null}
                                </li>
                                <li>
                                    <span>{t("working_since")}</span>
                                    {data.started_at
                                        ? dayjs(data.started_at).format(
                                              "DD-MM-YYYY"
                                          )
                                        : ""}
                                </li>
                            </div>
                            <div className="mt-[60px] flex flex-col text-base leading-[18px] text-[#828282] first-letter:uppercase">
                                <li className="flex list-none">
                                    <span className="w-[180px]">
                                        {t("last_login")}
                                    </span>
                                    {data.last_login_at
                                        ? dayjs(data.last_login_at).format(
                                              "DD-MM-YYYY, HH:mm"
                                          )
                                        : t("never")}
                                </li>
                            </div>
                            <div className="flex gap-[25px]">
                                <div
                                    onClick={() => {
                                        history.push(`/edit-user/${data.id}`);
                                    }}
                                    className={editButton}
                                >
                                    <FontAwesomeIcon
                                        icon={faPencil as IconProp}
                                        className="text-3xl cursor-pointer"
                                        style={
                                            {
                                                "--fa-primary-color": "#fff",
                                                "--fa-secondary-color": "#fff",
                                                "--fa-primary-opacity": "1.0",
                                                "--fa-secondary-opacity": "0.9",
                                            } as CSSProperties
                                        }
                                    />
                                    {t("edit")}
                                </div>
                                {user?.id !== data.id && (
                                    <div
                                        onClick={removeProfile}
                                        className={editButton}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashAlt as IconProp}
                                            style={
                                                {
                                                    "--fa-primary-color":
                                                        "#fff",
                                                    "--fa-secondary-color":
                                                        "#fff",
                                                    "--fa-primary-opacity":
                                                        "1.0",
                                                    "--fa-secondary-opacity":
                                                        "0.9",
                                                } as CSSProperties
                                            }
                                        />
                                        {t("delete")}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Account;
