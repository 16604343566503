import styled from "styled-components";
export { MenuItem, MenuIcon } from "../../style/shared/settingsMenu.styled";

export const LanguageSettings = styled.div``;

export const SectionHeader = styled.div`
    font-size: 20px;
    margin-bottom: 20px;
`;

export const SectionNote = styled.div`
    display: flex;
    margin-bottom: 20px;
    > svg {
        font-size: 16px;
        margin: 2px 6px 0 0;
    }
`;

export const Menu = styled.div``;
