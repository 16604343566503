import i18n from "i18next";

export const reportTypeOptions = [
    {
        value: "signalling",
        label: i18n.t("signalling_notice"),
    },
    {
        value: "pamphlet",
        label: i18n.t("pamphlet"),
    },
    {
        value: "trace_card",
        label: i18n.t("trace_card"),
    },
    {
        value: "play_pause_offer",
        label: i18n.t("play_pause_offer"),
    },
    {
        value: "re-entry_talk",
        label: i18n.t("re_entry_talk"),
    },
    {
        value: "general_talk",
        label: i18n.t("general_talk"),
    },
];

export const oldReportTypeOptions = [
    {
        value: "signalling",
        text: i18n.t("signalling_notice"),
    },
    {
        value: "pamphlet",
        text: i18n.t("pamphlet"),
    },
    {
        value: "trace_card",
        text: i18n.t("trace_card"),
    },
    {
        value: "play_pause_offer",
        text: i18n.t("play_pause_offer"),
    },
    {
        value: "re-entry_talk",
        text: i18n.t("re_entry_talk"),
    },
    {
        value: "general_talk",
        text: i18n.t("general_talk"),
    },
];

export const guestReactionOptions = [
    {
        value: "guest_acknowledges_playing_risky",
        label: i18n.t("guest_acknowledges_playing_risky"),
    },
    {
        value: "guest_got_angry",
        label: i18n.t("guest_got_angry"),
    },
    {
        value: "guest_says_dont_worry",
        label: i18n.t("guest_says_not_to_worry"),
    },
    {
        value: "guest_appreciates_attention",
        label: i18n.t("guest_appreciates_attention"),
    },
    {
        value: "guest_refused_to_have_a_talk",
        label: i18n.t("guest_refused_to_have_a_talk"),
    },
    {
        value: "other_reaction",
        label: i18n.t("other_reaction"),
    },
];

export const signalOptions = [
    {
        value: "guest_comes_more_often",
        label: i18n.t("guest_comes_more_often"),
    },
    {
        value: "guest_stays_longer",
        label: i18n.t("guest_stays_longer"),
    },
    {
        value: "guest_spends_more_money",
        label: i18n.t("guest_spends_more_money"),
    },
    {
        value: "external_signals",
        label: i18n.t("external_signals"),
    },
    {
        value: "expression_of_frustration",
        label: i18n.t("expression_of_frustration"),
    },
    {
        value: "other",
        label: i18n.t("other"),
    },
];
