import { useUserStore } from "./../store/userStore";
import { useEffect, useCallback, useRef } from "react";
import socket from "../socket";
import {
    ExistingGuestScanEvent,
    IDFlipEvent,
    IDScanEvent,
    ScanErrorEvent,
} from "../types/event";
import { useTranslation } from "react-i18next";
import useCustomToast from "./useCustomToast";

function useIdScanner(
    scanEventCallback: (event: IDScanEvent) => void,
    guestEntryEventCallback: (event: ExistingGuestScanEvent) => void,
    flipEventCallback: (event: IDFlipEvent) => void
) {
    const { user } = useUserStore();
    const { t } = useTranslation();
    const { customToast, clearToasts } = useCustomToast();

    // prevent unnecessary rerenders
    const scanEventCallbackRef = useRef(scanEventCallback);
    scanEventCallbackRef.current = scanEventCallback;

    const guestEntryEventCallbackRef = useRef(guestEntryEventCallback);
    guestEntryEventCallbackRef.current = guestEntryEventCallback;

    const flipEventCallbackRef = useRef(flipEventCallback);
    flipEventCallbackRef.current = flipEventCallback;

    const scanEventHandler = useCallback(
        (event: IDScanEvent) => {
            clearToasts();
            scanEventCallbackRef.current(event);
        },
        [clearToasts]
    );

    const guestEntryEventHandler = useCallback(
        (event: ExistingGuestScanEvent) => {
            clearToasts();
            guestEntryEventCallbackRef.current(event);
        },
        [clearToasts]
    );

    const flipEventHandler = useCallback(
        (event: IDFlipEvent) => {
            clearToasts();
            flipEventCallbackRef.current(event);
        },
        [clearToasts]
    );

    const scanError = useCallback(
        (event: ScanErrorEvent) => {
            customToast("error", {
                title: t("scan_error"),
                body: event.data.message,
            });
        },
        [customToast, t]
    );

    useEffect(() => {
        if (!user) return;

        const entryChannel = `Location.${user.current_location_id}.Entries`;
        const IDScanChannel = `Location.${user.current_location_id}.Scanners`;

        socket
            .private(entryChannel)
            .listen("ExistingGuestScanEvent", guestEntryEventHandler);

        socket
            .private(IDScanChannel)
            .listen("IDScanEvent", scanEventHandler)
            .listen("IDFlipEvent", flipEventHandler)
            .listen("ScanErrorEvent", scanError);

        return () => {
            socket
                .private(entryChannel)
                .stopListening("ExistingGuestScanEvent");
            socket.leave(entryChannel);

            socket.private(IDScanChannel).stopListening("IDScanEvent");
            socket.private(IDScanChannel).stopListening("IDFlipEvent");
            socket.private(IDScanChannel).stopListening("ScanErrorEvent");
            socket.leave(IDScanChannel);
        };
    }, [
        flipEventHandler,
        guestEntryEventHandler,
        scanEventHandler,
        scanError,
        user,
    ]);
}

export default useIdScanner;
