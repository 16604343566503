import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import client from "../../client";
import { useQueryClient } from "react-query";
import { useUserStore } from "../../store/userStore";
import usePermissions from "../../hooks/usePermissions";
import DeletePopup from "../Popups/OldDeletePopup";
import { useTranslation } from "react-i18next";
import { options } from "../../utils/optionsMessages";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

import {
    faCommentAltDots,
    faEye,
    faFileAlt,
    faCalendar,
} from "@fortawesome/pro-light-svg-icons";
import { messageIconTypes } from "../../utils/filterOptions";
import useCustomToast from "../../hooks/useCustomToast";
import LoadingOverlay from "../LoadingOverlay";
import { Message } from "../../types/messages";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { TranslationsKeys } from "../../types/translations";
import ViewsPopup from "./ViewsPopup";

const MessageColumns = ({
    data,
    click,
    scrollRef,
    onScroll,
    isLoading,
}: {
    data: Message[];
    click: (arg: number) => void;
    scrollRef: React.MutableRefObject<HTMLDivElement>;
    onScroll: () => void;
    isLoading: boolean;
}) => {
    const [showPopup, setShowPopup] = useState(false);

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
    const [showViewId, setShowViewId] = useState<number | null>(null);
    const currentUser = useUserStore().user;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const popupRef = useRef(null);
    const { hasPermissions } = usePermissions();
    const hasDeletePermissions = hasPermissions(["delete_messages"]);
    const { customToast } = useCustomToast();

    useOnClickOutside(popupRef, () => setShowPopup(false));

    const handleDelete = (value: number) => {
        client.delete(`/api/reports/${value}`).then(res => {
            customToast("success", {
                title: t("entity_successfully_deleted", {
                    entity: t("message"),
                }),
            });
            queryClient.invalidateQueries("reports");
            setDeleteItemId(null);
            setShowDeletePopup(false);
        });
    };

    return (
        <>
            {isLoading ? (
                <div className="relative">
                    <LoadingOverlay isLoading />
                </div>
            ) : (
                <div className="flex grow flex-col" ref={popupRef}>
                    <div
                        className="message-grid h-[450px] w-full grow overflow-y-scroll"
                        ref={scrollRef}
                        onScroll={() => onScroll && onScroll()}
                    >
                        {data.map(item => (
                            <div
                                className={`relative max-h-[210px] cursor-pointer rounded-md border border-gray-100 bg-white px-6 py-6 shadow-lg transition hover:bg-gray-50`}
                                onClick={() => {
                                    click(item.id);
                                }}
                            >
                                {showViewId === item.id && (
                                    <ViewsPopup
                                        setShowViewId={setShowViewId}
                                        setShowPopup={setShowPopup}
                                        item={item}
                                    />
                                )}

                                <div className="flex space-x-3">
                                    <div className="flex-shrink-0">
                                        <div
                                            className={`h-12 w-12 rounded-full ${
                                                options[item?.type]?.twColor
                                            } flex items-center justify-center`}
                                        >
                                            <FontAwesomeIcon
                                                icon={[
                                                    "fal",
                                                    `${messageIconTypes(
                                                        item?.type
                                                    )}` as IconName,
                                                ]}
                                                size="lg"
                                                style={{
                                                    color: `${
                                                        options[item?.type]
                                                            ?.textColor
                                                    }`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex min-w-0 flex-1 justify-between ">
                                        <div>
                                            <p className="pb-0.5 text-lg leading-[1.3] text-gray-900">
                                                {
                                                    t(
                                                        item.type as TranslationsKeys
                                                    ) as string
                                                }
                                            </p>

                                            <p className="text-sm text-gray-500 ">
                                                {item.location.name}
                                            </p>
                                        </div>
                                        <div className="text-right">
                                            <p className="text-sm text-gray-500">
                                                {item.employee.full_name}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                <FontAwesomeIcon
                                                    icon={
                                                        faCalendar as IconProp
                                                    }
                                                    size="sm"
                                                />{" "}
                                                {dayjs(item.created_at).format(
                                                    "DD-MM-YYYY HH:mm"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-20 overflow-hidden pb-5 pt-4">
                                    <p className="pb-2">{item.title}</p>
                                    <p className="h-10 w-1/2 truncate opacity-40">
                                        {item.description}
                                    </p>
                                </div>

                                <div className="flex justify-end gap-3">
                                    {item.files.length > 0 && (
                                        <button className="rounded-md bg-white px-2 py-1 shadow-md">
                                            <FontAwesomeIcon
                                                icon={faFileAlt as IconProp}
                                            />{" "}
                                            <span>{item.files.length}</span>
                                        </button>
                                    )}

                                    <button className="rounded-md bg-white px-2 py-1 shadow-md">
                                        <FontAwesomeIcon
                                            icon={faCommentAltDots as IconProp}
                                        />{" "}
                                        <span>{item.comments.length}</span>
                                    </button>
                                    <button
                                        className={`px-1 py-1 ${
                                            options[item?.type]?.twColorLight
                                        } rounded-md shadow-md ${
                                            options[item?.type]?.twHover
                                        } transition`}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (showViewId) {
                                                setShowViewId(null);
                                                setShowPopup(false);
                                            } else {
                                                setShowViewId(item.id);
                                                setShowPopup(true);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEye as IconProp}
                                        />
                                        <span> {item.views.length}</span>
                                    </button>

                                    {(item?.employee?.id === currentUser?.id ||
                                        hasDeletePermissions) && (
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                setShowDeletePopup(!showPopup);
                                                setDeleteItemId(item.id);
                                            }}
                                            className="rounded-md bg-red-300 px-2 py-1 shadow-md transition hover:bg-red-500"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashAlt as IconProp}
                                                style={{
                                                    color: "#fff",
                                                }}
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}

                        {showDeletePopup && (
                            <DeletePopup
                                setShowPopup={setShowDeletePopup}
                                value={deleteItemId}
                                deleteHandler={handleDelete}
                                text={t("message")}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default MessageColumns;
