import React, { useRef } from "react";
import * as S from "./Popup.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import usePopup from "../../../hooks/usePopup";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

function Popup({ children }) {
    const { close } = usePopup();
    const containerRef = useRef();

    useOnClickOutside(containerRef, () => close());

    return (
        <S.Popup>
            <S.PopupContainer ref={containerRef}>
                <S.CloseButton
                    onClick={() => {
                        close();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                            "--fa-primary-color": "#1D1E45",
                            "--fa-secondary-color": "#1D1E45",
                            "--fa-primary-opacity": "1.0",
                            "--fa-secondary-opacity": "0.9",
                        }}
                    />
                </S.CloseButton>

                {children}
            </S.PopupContainer>
        </S.Popup>
    );
}

export default Popup;
