import * as S from "./GuestCountMap.styled";
import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { FloorNumbersStats } from "../../../types/statistics";
import dayjs from "dayjs";
import { getTimeRangeHours } from "../../../utils/timeRange";
import BlockHeader from "../BlockHeader/BlockHeader";

const Chart = lazy(() => import("react-apexcharts"));

function GuestCountMap({
    floorNumbers,
}: {
    floorNumbers: FloorNumbersStats | undefined;
}) {
    const { t } = useTranslation();

    const getFloorSeries = () => {
        if (!floorNumbers) return [];

        const timeRange = getTimeRangeHours().map(hour => 0);

        const series = [
            {
                key: "Monday",
                name: t("monday"),
                data: [...timeRange],
            },
            {
                key: "Tuesday",
                name: t("tuesday"),
                data: [...timeRange],
            },
            {
                key: "Wednesday",
                name: t("wednesday"),
                data: [...timeRange],
            },
            {
                key: "Thursday",
                name: t("thursday"),
                data: [...timeRange],
            },
            {
                key: "Friday",
                name: t("friday"),
                data: [...timeRange],
            },
            {
                key: "Saturday",
                name: t("saturday"),
                data: [...timeRange],
            },
            {
                key: "Sunday",
                name: t("sunday"),
                data: [...timeRange],
            },
        ];

        Object.entries(floorNumbers).forEach(([date, counts]) => {
            const dayOfTheWeek = dayjs(date).format("dddd");

            Object.entries(counts).forEach(([currentHour, count]) => {
                const matchedHourIndex = getTimeRangeHours().findIndex(
                    hour => hour === currentHour
                );

                const matchedItemIndex = series.findIndex(
                    item => item.key === dayOfTheWeek
                );

                if (matchedHourIndex !== -1 && matchedItemIndex !== -1) {
                    const matchedData = series[matchedItemIndex].data;
                    matchedData[matchedHourIndex] =
                        matchedData[matchedHourIndex] + count.total_count;
                }
            });
        });

        return series.reverse();
    };

    const options = {
        chart: {
            type: "heatmap",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#008FFB"],
        xaxis: {
            categories: getTimeRangeHours(),
        },
    };

    return (
        <S.GuestCountMap>
            <BlockHeader
                title={t("guest_count_peak_hours")}
                subTitle={t("all_locations")}
                link="/floor-numbers-overview"
            />
            <Suspense fallback="">
                <Chart
                    options={options}
                    series={getFloorSeries()}
                    type="heatmap"
                    height={350}
                />
            </Suspense>
        </S.GuestCountMap>
    );
}

export default GuestCountMap;
