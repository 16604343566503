import React, { useState, useEffect } from "react";
import * as S from "./ViewMessage.styled";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import PageHeader from "../../components/PageHeader";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import client from "../../client";
import { useUserStore } from "../../store/userStore";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { options } from "../../utils/optionsMessages";
import dayjs from "dayjs";
import {
    Form,
    FormSelect,
    FormTextArea,
    FormFiles,
    FormComments,
} from "../../components/formPartials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faEye as falEye } from "@fortawesome/pro-light-svg-icons";
import usePermissions from "../../hooks/usePermissions";
import useCustomToast from "../../hooks/useCustomToast";
import Button from "../../components/Button";

const ViewMessage = () => {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const history = useHistory();
    const params = useParams();
    const { user } = useUserStore();
    const { isLoading, data } = useFetch(`reports/${params.id}`, "", false);
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [comment, setComment] = useState(null);
    const [changeOption, setChangeOption] = useState(null);
    const [defaultState, setDefaultState] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const queryClient = useQueryClient();
    const { hasPermissions } = usePermissions();
    const { customToast } = useCustomToast();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        formState: { errors, isSubmitted },
        reset,
    } = useForm();

    const hasEditPermissions = hasPermissions([
        "add_messages",
        "edit_messages",
    ]);

    const closeDropDown = e => {
        e.stopPropagation();
        setShowPopup(false);
    };

    const formFunctions = {
        register,
        errors,
        setValue,
        setFocus,
        readOnly: isReadOnly,
        defaultValue: defaultState,
        isSubmitted,
    };

    const updateSelectedOption = option => {
        setChangeOption(option);
    };

    useEffect(() => {
        if (user?.id === data?.employee.id) {
            setIsReadOnly(false);
        } else if (hasEditPermissions) {
            setIsReadOnly(false);
        } else {
            setIsReadOnly(true);
        }
        setExistingFiles(data?.files);
        setDefaultState(data?.type);

        reset({
            ...data,
            title: data?.title,
            description: data?.description,
            // type: [{ value: data?.type, text: data?.type }],
        });
    }, [user?.id, data?.employee.id, hasEditPermissions, data, reset]);

    if (isLoading) {
        return <Loading />;
    }

    const onSubmit = data => {
        setIsSubmitting(true);
        const postData = {
            ...data,
            title: data.title,
            type: changeOption || data.type,
            description: data.description,
        };
        const formData = new FormData();

        formData.append("_method", "PUT");

        Object.entries(postData).forEach(([key, value]) => {
            if (value !== null) {
                formData.append(key, value);
            }
        });

        files.forEach(file => {
            formData.append("files[]", file);
        });

        client
            .post(`/api/reports/${params.id}`, formData)
            .then(res => {
                customToast("success", {
                    title: t("report_updated"),
                    link: {
                        title: t("see_updated_report"),
                        url: `/messages/${params.id}`,
                    },
                });

                setChangeOption(null);
                queryClient.invalidateQueries(`reports/${params.id}`);
                history.push("/messages");
            })
            .catch(err => {
                if (err.isAxiosError) {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };
    const reportTypeOptions = [
        {
            value: "handover",
            text: t("handover"),
        },
        {
            value: "malfunction",
            text: t("malfunction"),
        },
        {
            value: "about_employee",
            text: t("about_employee"),
        },
        {
            value: "cash",
            text: t("cash_slash_hallichter"),
        },
        {
            value: "general",
            text: t("general"),
        },
        {
            value: "whitelist",
            text: t("playpause"),
        },
        {
            value: "blacklist",
            text: t("blacklist"),
        },
    ];

    const before = (
        <S.ReportIcon
            color={options[changeOption || data?.type]?.color}
            textColor={options[changeOption || data?.type]?.textColor}
        >
            <FontAwesomeIcon
                icon={["fal", `${options[changeOption || data?.type]?.icon}`]}
                size="lg"
                style={{
                    color: `${options[changeOption || data?.type]?.textColor}`,
                }}
            />
        </S.ReportIcon>
    );

    const after = (
        <S.Views
            color={options[changeOption || data?.type]?.color}
            textColor={options[changeOption || data?.type]?.textColor}
            onClick={() => {
                setShowPopup(!showPopup);
            }}
        >
            <FontAwesomeIcon icon={faEye} />
            {data.views.length}
        </S.Views>
    );

    return (
        <>
            <PageHeader
                title={t("view_message")}
                back={true}
                showSearch={false}
            />

            <Form onSubmit={handleSubmit(onSubmit)}>
                <S.Wrapper>
                    <FormSelect
                        formKey="type"
                        label={t("type")}
                        options={reportTypeOptions}
                        {...formFunctions}
                        before={before}
                        after={after}
                        borderStyle={true}
                        callBack={updateSelectedOption}
                    />
                    {showPopup && (
                        <S.ReportDropdown
                            show={showPopup}
                            initial={{
                                scale: 0.5,
                            }}
                            animate={{
                                scale: 1,
                            }}
                            transition={{ duration: 0.3 }}
                            onClick={e => {
                                closeDropDown(e);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                size="lg"
                                onClick={e => {
                                    closeDropDown(e);
                                }}
                            />
                            <S.Title>
                                {t("report_views")}{" "}
                                <FontAwesomeIcon icon={falEye} />
                            </S.Title>

                            <S.Viewers>
                                {data.views.map(viewer => {
                                    return (
                                        <S.ViewersContainer
                                            key={viewer.employee.id}
                                        >
                                            <S.User>
                                                {viewer.employee.full_name}
                                            </S.User>
                                            <S.Time>
                                                {" "}
                                                {dayjs(
                                                    viewer.created_at
                                                ).format("DD-MM-YYYY HH:mm")}
                                            </S.Time>
                                        </S.ViewersContainer>
                                    );
                                })}
                            </S.Viewers>
                            <S.Button>
                                <Button
                                    className="btn-primary"
                                    onClick={e => {
                                        closeDropDown(e);
                                    }}
                                >
                                    {t("close")}
                                </Button>
                            </S.Button>
                        </S.ReportDropdown>
                    )}
                </S.Wrapper>
                <S.FormControl error={errors["title"]}>
                    <label>{t("title")}</label>
                    <input
                        type="text"
                        placeholder={t("title")}
                        {...register("title", {
                            required: true,
                        })}
                    />
                </S.FormControl>
                <FormTextArea
                    formKey="description"
                    label={t("description")}
                    placeholder={t("description")}
                    row={"10"}
                    columns={"50"}
                    {...formFunctions}
                />

                <FormFiles
                    formKey="files"
                    label={t("files")}
                    setFiles={setFiles}
                    existingFiles={existingFiles}
                    {...formFunctions}
                />
                <S.Buttons>
                    {!isReadOnly && (
                        <Button
                            className="btn-primary"
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            {t("save")}
                        </Button>
                    )}
                </S.Buttons>

                <FormComments
                    formKey="comments"
                    label={t("comments")}
                    value={data}
                    isReadOnly={isReadOnly}
                    {...formFunctions}
                    typeOption={changeOption}
                    setValue={setValue}
                    setFocus={setFocus}
                    comment={comment}
                    setComment={setComment}
                />
            </Form>
        </>
    );
};

export default ViewMessage;
