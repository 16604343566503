import styled from "styled-components";

export const Header = styled.div`
    border-bottom: 1px solid #333333;
    padding: 10px 0px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: flex-end;
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    line-height: 23px;
    color: #050505;
    min-width: 300px;
`;

export const SubTypes = styled.div`
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${props => (props.weightText ? "#050505" : "#828282")};
    padding: 0px 20px;
    min-width: 170px;
    font-weight: ${props => (props.weightText ? "bold" : "normal")};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`;
export const Types = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Type = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 15px 0px;
    display: flex;
    align-items: center;
`;

export const TypeText = styled.div`
    width: 300px;
    font-size: 18px;
    line-height: 21px;
    color: #c54d34;
`;

export const InputsContainer = styled.div`
    min-width: 150px;
    display: flex;
    justify-content: center;
`;

export const Inputs = styled.input`
    /* margin: 25px 58px 25px 0px; */
    line-height: 18px;
    height: 32px;
    max-width: 64px;
    background: ${props => (props.readOnly ? "#f0f0f0" : "#fff")};
    border-radius: 4px;
    border: ${props => (props.readOnly ? "none" : "1px solid #1D1E45")};
    text-align: center;
`;

export const MinButton = styled.button`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #1d1e45;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    margin-right: 10px;
`;
export const MaxButton = styled.button`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #1d1e45;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    margin-left: 10px;
`;

export const TypeTotal = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 60px 0px;
    display: flex;
    align-items: center;
`;

export const InputsTotal = styled.input`
    line-height: 18px;
    height: 32px;
    max-width: 64px;
    background: ${props => (props.weightText ? "#fff" : "#f0f0f0")};
    border-radius: 4px;
    border: ${props => (props.weightText ? "1px solid #1D1E45" : "none")};
    text-align: center;
`;

export const InputContainer = styled.div`
    min-width: 130px;
    display: flex;
    justify-content: center;
    margin: 0 11px;
`;

export const TotalInput = styled.input`
    line-height: 18px;
    margin-left: 20px;
    height: 32px;
    max-width: 64px;
    background: ${props => (props.weightText ? "#fff" : "#f0f0f0")};
    border-radius: 4px;
    border: ${props => (props.weightText ? "1px solid #1D1E45" : "none")};
    text-align: center;
`;

export const TypeTextLoaded = styled.div`
    margin-top: 15px;
    font-size: 16px;
    line-height: 18px;
    color: #050505;
    width: 300px;
`;

export const LoadedData = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: #050505;
    min-width: 150px;
    display: flex;
    justify-content: center;
    margin: 0 11px;
`;

export const TotalLoadedData = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: #050505;
    min-width: 150px;
    display: flex;
    justify-content: center;
    margin: 0 11px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Button = styled.button`
    color: #fff;
    min-width: 150px;
    padding: 0 16px;
    height: 40px;
    border-radius: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        margin-right: 10px;
    }

    span + svg {
        margin-left: 10px;
    }

    &:hover {
        transition: 0.2s;
        color: #fff;
        background: #23256d;
    }
    background: ${props => props.theme.main.primaryColor};
    &:disabled {
        background: #dddddd;
        cursor: no-drop;
    }
`;
