import styled from "styled-components";

export const Blocks = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    width: 90%;
`;
export const BlockTitle = styled.div`
    color: #fffbfb;
    background: #1d1e45;
    border-radius: 4px 4px 0 0;
    font-size: 20px;
    padding: 15px 20px;
`;

export const BlockBody = styled.div`
    padding: 15px 20px;
`;

export const DevicesBlock = styled.div`
    background: red;
    background: #fffbfb;
    box-shadow: 0px 2px 11px rgba(29, 30, 69, 0.15);
    border-radius: 4px;
`;

export const Row = styled.div`
    display: flex;
    border-bottom: 1px solid rgb(224, 224, 224);
    > div {
        &:nth-child(1) {
            width: 180px;
        }
        &:nth-child(2) {
            flex-grow: 1;
        }
    }
`;

export const DevicesBody = styled.div``;

export const DeviceRow = styled(Row)`
    height: 90px;
    align-items: center;
`;

export const DevicesHeader = styled(Row)`
    border-bottom: 1px solid rgb(5, 5, 5);
`;

export const DevicesHeaderColumn = styled.div`
    padding: 10px 10px 10px 0px;
    font-size: 20px;
`;

export const DeviceNames = styled.div`
    font-size: 16px;
    line-height: 25px;
`;

export const DeviceInputs = styled.div`
    font-size: 16px;
    line-height: 25px;
    overflow-x: hidden;

    > input {
        border: none;
        border-bottom: 1px solid #1d1e45;
        background: #fffbfb;
    }
`;

export const DeviceIdEdit = styled.div<{ disabled: boolean }>`
    font-size: 16px;
    line-height: 25px;
    padding-right: 30px;
    justify-content: end;
    user-select: none;
    > svg {
        pointer-events: ${props => (props.disabled ? "none" : "auto")};
        color: ${props => (props.disabled ? "#c0c0c0" : "#1d1e45")};
        cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    }
`;

export const SystemsBody = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

export const SystemStatus = styled.div<{ statusText: string }>`
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 30px 0;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
    color: ${props => (props.statusText === "online" ? "#27AE60" : "red")};
    height: 90px;
`;
