import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { classNames } from "../utils/style";
import { useForm } from "react-hook-form";
import Button from "./Button";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import useFormResponseError from "../hooks/useFormResponseError";
import client from "../client";
import { useUserStore } from "../store/userStore";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useFetch from "../hooks/useFetch";

type FormFields = {
    username: string;
    password: string;
};

type RecentUsers = {
    data: {
        full_name: string;
        username: string;
        profile_photo_url: string;
    }[];
};

const maxUsers = 4;

const UserSwitch = () => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
    } = useForm<FormFields>();
    const history = useHistory();
    const { setUser } = useUserStore();
    const { formResponseError } = useFormResponseError();
    const [displayLogin, setDisplayLogin] = useState(false);
    const [displayUsers, setDisplayUsers] = useState(false);
    const [currentUserEdit, setCurrentUserEdit] = useState<string | null>(null);
    const [currentUserPassword, setCurrentUserPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const loginContainerRef = useRef(null);
    const guestContainerRef = useRef(null);

    useOnClickOutside(loginContainerRef, () => setDisplayLogin(false));
    useOnClickOutside(guestContainerRef, () => {
        setDisplayUsers(false);
        setCurrentUserEdit(null);
        setCurrentUserPassword("");
    });

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [currentUserEdit]);

    const { data: recentUsers } = useFetch<RecentUsers>(
        "recently-authenticated-users"
    );

    const login = (data: FormFields, isActiveUser: boolean) => {
        setIsSubmitting(true);
        client
            .post("/switch-user", {
                username: data.username,
                password: data.password,
            })
            .then(res => {
                isActiveUser && setErrorMessage("");
                setUser(res.data.user);
                history.push("/check-in");
                reset();
                setDisplayLogin(false);
            })
            .catch(err => {
                isActiveUser
                    ? formResponseError(err, setError)
                    : setErrorMessage(err.response.data.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            <div className="relative" ref={loginContainerRef}>
                <button
                    className="flex-shrink-0 text-[#278AB0]"
                    onClick={() => {
                        setDisplayLogin(prev => !prev);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faPlusCircle as IconProp}
                        className="text-[40px]"
                    />
                </button>
                <form
                    onSubmit={handleSubmit(data => {
                        login(data, false);
                    })}
                    className={classNames(
                        "absolute right-0 top-14 z-10 w-96 space-y-2 rounded-md bg-[#fffbfb] p-7 shadow-lg",
                        displayLogin ? "block" : "hidden"
                    )}
                >
                    <p className="mb-3 text-2xl">{t("sign_in")}</p>
                    {errorMessage && (
                        <p className="mb-3 text-base text-error">
                            {errorMessage}
                        </p>
                    )}
                    <div className="form-control sm:grid-cols-5">
                        <label className="form-label col-span-2">
                            {t("username")}
                        </label>
                        <div className="col-span-3">
                            <input
                                className={classNames(
                                    "form-input",
                                    errors.username !== undefined && "error"
                                )}
                                type="input"
                                {...register("username", { required: true })}
                            />
                        </div>
                    </div>
                    <div className="form-control sm:grid-cols-5">
                        <label className="form-label col-span-2">
                            {t("password")}
                        </label>
                        <div className="col-span-3">
                            <input
                                className={classNames(
                                    "form-input",
                                    errors.username !== undefined && "error"
                                )}
                                autoComplete="password"
                                type="password"
                                {...register("password", { required: true })}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="pt-4">
                            <Button
                                type="submit"
                                className="btn-secondary"
                                isLoading={isSubmitting}
                            >
                                {t("sign_in")}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            {recentUsers?.data && recentUsers.data.length > 0 && (
                <div className="relative" ref={guestContainerRef}>
                    <button
                        className="isolate flex -space-x-6 opacity-60"
                        onClick={() => {
                            setDisplayUsers(prev => !prev);
                        }}
                    >
                        {recentUsers?.data
                            .slice(0, maxUsers)
                            .map((user, index) => {
                                return (
                                    <img
                                        className={`relative inline-block h-10 w-10 rounded-full object-cover drop-shadow-xl`}
                                        src={user.profile_photo_url}
                                        alt={user.full_name}
                                        key={user.username}
                                        style={{
                                            zIndex: (
                                                maxUsers - index
                                            ).toString(),
                                        }}
                                    />
                                );
                            })}
                    </button>
                    <div
                        className={classNames(
                            "absolute right-0 top-14 z-10 w-60 overflow-hidden rounded-md bg-white shadow-lg",
                            displayUsers ? "block" : "hidden"
                        )}
                    >
                        {recentUsers?.data.map(user => (
                            <button
                                className="flex h-14 w-full items-center bg-[#fffbfb] px-4 text-left transition hover:bg-[#F8F6F1]"
                                key={user.username}
                                onClick={() => {
                                    setCurrentUserPassword("");
                                    setCurrentUserEdit(user.username);
                                }}
                            >
                                <img
                                    className={classNames(
                                        "relative mr-4 inline-block h-10 w-10 shrink-0 rounded-full object-cover drop-shadow-xl"
                                    )}
                                    src={user.profile_photo_url}
                                    alt={user.full_name}
                                />
                                <div className="grow">
                                    {currentUserEdit === user.username ? (
                                        <input
                                            type="password"
                                            className="form-input w-full"
                                            ref={inputRef}
                                            placeholder={t("enter_password")}
                                            value={currentUserPassword}
                                            autoComplete="password"
                                            onChange={e =>
                                                setCurrentUserPassword(
                                                    e.target.value
                                                )
                                            }
                                            onKeyDown={e => {
                                                if (e.key === "Enter") {
                                                    login(
                                                        {
                                                            username:
                                                                user.username,
                                                            password:
                                                                currentUserPassword,
                                                        },
                                                        true
                                                    );
                                                    setCurrentUserPassword("");
                                                    setDisplayUsers(false);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <span>{user.full_name}</span>
                                    )}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default UserSwitch;
