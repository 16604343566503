import styled from "styled-components";

export const BlockHeader = styled.div`
    margin-bottom: 26px;
`;

export const BlockTitle = styled.div`
    display: block;
    font-size: 18px;
    color: black;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const BlockSubTitle = styled.div`
    display: block;
    font-size: 14px;
    color: #828282;
`;
