import React, { useState } from "react";
import * as S from "../Popups.styled";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "jspdf-autotable";
import { columns } from "../../../utils/tableColumns";
import useFileExport from "../../../hooks/useFileExport";
import ExportDateFields from "../../ExportDateFields";
import usePopup from "../../../hooks/usePopup";
import Popup from "../Popup";
import Button from "../../Button";

function ExportMessagesPopup() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { close } = usePopup();
    const { t } = useTranslation();

    const entityName = t("messages");
    const { requestExportDataByDate, exportPdfTableByDate } =
        useFileExport(entityName);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const prepareExport = (responseData, formValues) => {
        const filterDates = {
            from_date: formValues.from_date,
            from_time: formValues.from_time,
            until_date: formValues.until_date,
            until_time: formValues.until_time,
        };

        const tableHeaders = columns.reports.map(column => {
            return column.Header;
        });

        const tableRows = responseData.map(data => {
            return [
                t(data.type),
                dayjs(data.date).format("DD-MM-YY HH:mm"),
                data.employee.full_name,
                data.title,
                data.description,
                data.location.name,
            ];
        });

        exportPdfTableByDate(tableHeaders, tableRows, filterDates);
    };

    const onSubmit = async data => {
        setIsSubmitting(true);
        await requestExportDataByDate("/api/reports", data).then(
            responseData => {
                close();
                prepareExport(responseData, data);
            }
        );
        setIsSubmitting(false);
    };

    return (
        <Popup>
            <S.Header>
                {t("export")}: {entityName}
            </S.Header>
            <S.ExportTablePopupForm onSubmit={handleSubmit(onSubmit)}>
                <ExportDateFields register={register} errors={errors} />
                <Button
                    type="submit"
                    className="btn-secondary"
                    isLoading={isSubmitting}
                >
                    {t("export")}
                </Button>
            </S.ExportTablePopupForm>
        </Popup>
    );
}

export default ExportMessagesPopup;
