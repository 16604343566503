import styled from "styled-components";

type Action = {
    visitLimit?: boolean;
};

export const ErrorsContainer = styled.div`
    margin: 0 0 0 200px;
`;

export const PlayerAction = styled.div<Action>`
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 20px auto 0px;
    max-width: 400px;
    border-radius: 4px;
    background: ${props => (props.visitLimit ? "#eb5757" : "#ffebd9")};
    color: ${props => (props.visitLimit ? "#fff" : "#000")};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    font-weight: 500;
    cursor: pointer;

    svg {
        color: ${props => (props.visitLimit ? "#fff" : "#f2994a")};

        margin-right: 10px;
    }
`;
