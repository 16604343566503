import styled from "styled-components";
export { FormControl } from "../../style/shared/form.styled";

export const SelectContainer = styled.div`
    width: 100%;
    > div {
        width: 100%;
    }
`;

export const MultiSelectContainer = styled.div`
    width: 100%;
    display: flex;

    > div {
        width: 100%;
        > div {
            border: none;
        }
    }
`;
