import styled from "styled-components";

export const CustomSelect = styled.div`
    position: relative;
    border-bottom: 1px solid #828282;
    display: flex;
    flex-grow: 1;
    ${props => props.showError && `border-bottom: 2px solid #e65f5f;`}
`;

export const CustomSelectCurrent = styled.div`
    color: ${props => props.readOnly && "#616161"};
    font-size: ${props => props.fontSize};
    padding: 6px 4px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:hover {
        cursor: ${props => (props.readOnly ? "" : "pointer")};
    }
    > svg {
        margin: 0 5px 0 10px;
        color: ${props => (props.readOnly ? "#616161" : "#000")};
    }
`;

export const CustomSelectOptions = styled.div`
    width: 100%;
    min-width: 180px;
    background: #fff;
    border-radius: 4px;
    color: #000000;
    user-select: none;
    position: absolute;
    top: 110%;
    left: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    ${props => props.position === "bottom" && `top: auto; bottom: 50px;`};
    z-index: 11;
`;

export const CustomSelectOption = styled.div`
    padding: 20px 20px;
    background: none;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #bdbdbd;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: rgba(200, 200, 200, 0.3);
    }
`;

export const Placeholder = styled.span`
    color: #bdbdbd;
    font-size: 16px;
`;
