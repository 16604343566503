import styled from "styled-components";
export { FormControl } from "../../../style/shared/form.styled";

export const CustomSelectWrapper = styled.div`
    margin-right: 20px;
    width: 400px;
    flex: 0 0 200px;
`;

export const CustomSelectWrapperSwitch = styled.div`
    margin-left: 20px;
    width: 400px;
    flex: 0 0 200px;
`;
