import React, { useState } from "react";
import * as S from "./DataTable.styled";
import userChip from "../../assets/chip-user.png";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import client from "../../client";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import i18n from "i18next";
import usePermissions from "../../hooks/usePermissions";
import DeletePopup from "../Popups/OldDeletePopup";
import {
    faCheck,
    faPencil,
    faTimes,
    faTrashAlt,
    faGlobeAmericas,
} from "@fortawesome/pro-solid-svg-icons";
import { faAddressBook, faCrown } from "@fortawesome/pro-duotone-svg-icons";
import nlFlag from "../../assets/lang/nl-flag.svg";
import { useTranslation } from "react-i18next";
import useCustomToast from "../../hooks/useCustomToast";

function RenderCells() {
    function EntryCell(props) {
        if (props.value) {
            return (
                <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="2x"
                />
            );
        }

        return (
            <FontAwesomeIcon
                icon={faTimes}
                style={{ color: "EB5757" }}
                size="2x"
            />
        );
    }

    function NameCell(props) {
        return (
            <S.NameWrapper>
                <S.UserAvatar
                    src={
                        props.row.original.player?.profile_photo_url ||
                        props.row.original.profile_photo_url ||
                        props.row.original.player_photo ||
                        userChip
                    }
                />
                <S.UserName>{props.value}</S.UserName>
            </S.NameWrapper>
        );
    }

    function NameCellWithDate(props) {
        const isMember = props.row.original.is_member;
        const { t } = useTranslation();
        return (
            <S.NameWrapper>
                <S.AvatarContainer>
                    <S.UserAvatarWithVip
                        src={
                            props.row.original.player?.profile_photo_url ||
                            props.row.original.profile_photo_url ||
                            props.row.original.player_photo ||
                            userChip
                        }
                    />
                    {isMember && (
                        <S.SvgContainerWithAvatar>
                            <FontAwesomeIcon
                                icon={faCrown}
                                style={{
                                    "--fa-primary-color": "#eb5757",
                                    "--fa-secondary-color": "#eb5757",
                                    "--fa-primary-opacity": "1.0",
                                    "--fa-secondary-opacity": "1.0",
                                }}
                                size="lg"
                            />
                        </S.SvgContainerWithAvatar>
                    )}
                </S.AvatarContainer>
                <S.UserNameContainer>
                    <S.UserNameWithResidence>
                        {props.value}
                    </S.UserNameWithResidence>
                    <S.DateUnderName>
                        {t("last_visit")}:
                        {props.row.original.latest_entry_at
                            ? dayjs(props.row.original.latest_entry_at).format(
                                  "DD-MM-YYYY HH:mm"
                              )
                            : "-"}
                    </S.DateUnderName>
                </S.UserNameContainer>
            </S.NameWrapper>
        );
    }

    function DateCell(props) {
        return (
            <S.CellContainer>
                <S.Date>
                    {props.value
                        ? dayjs(props.value).format("DD-MM-YYYY HH:mm")
                        : "-"}
                </S.Date>
            </S.CellContainer>
        );
    }

    function DateCellOnList(props) {
        return (
            <S.CellContainer>
                <S.Date>{props.value ? props.value : "-"}</S.Date>
            </S.CellContainer>
        );
    }

    function MessageCell(props) {
        const truncatedMessage = props.value
            ? props.value.substring(0, 180) + "..."
            : "";

        return (
            <S.CellContainer>
                <S.Title>{props.row.original.title}</S.Title>
                <S.Message>{truncatedMessage}</S.Message>
            </S.CellContainer>
        );
    }

    function ActivityCell(props) {
        return (
            <S.CellContainer>
                <S.Type>{i18n.t(props.value)}</S.Type>
                <S.SubType>{i18n.t(props.row.original.sub_type)}</S.SubType>
                <S.Notes>{props.row.original.notes}</S.Notes>
            </S.CellContainer>
        );
    }
    function GuestMessageCell(props) {
        return (
            <S.CellContainer>
                <S.Notes>{i18n.t(props.row.original.body)}</S.Notes>
                <S.Dates>
                    {i18n.t("active_from_date")}

                    <span className="font-normal">
                        {dayjs(props.row.original.active_from).format(
                            "DD-MM-YYYY"
                        )}
                    </span>
                </S.Dates>
            </S.CellContainer>
        );
    }

    function ExpandCell(props) {
        function addDataToStructure(detailsStructure, data) {
            const result = {};

            for (const key in detailsStructure) {
                if (detailsStructure.hasOwnProperty(key)) {
                    let value = data[key] || null;
                    if (Array.isArray(value)) {
                        value = value.map(val => i18n.t(val));
                    } else if (typeof value === "string") {
                        value = i18n.t(value);
                    }
                    result[detailsStructure[key]] = value;
                }
            }

            return result;
        }
        const detailsStructure = props.column.detailsStructure;
        const result = addDataToStructure(detailsStructure, props.row.original);

        if (!props.row.isExpanded) {
            return (
                <S.CellContainer>
                    <S.Type>{i18n.t(props.value)}</S.Type>
                    <S.SubType>{i18n.t(props.row.original.sub_type)}</S.SubType>
                </S.CellContainer>
            );
        }

        return (
            <S.CellContainer>
                <S.Type>{i18n.t(props.value)}</S.Type>
                {Object.keys(result).map(key => {
                    return (
                        <React.Fragment key={key}>
                            {key === "sub_type" ? (
                                <div className="text-base font-medium">
                                    {i18n.t(result[key])}
                                </div>
                            ) : (
                                <S.TypeTitle>{i18n.t(key)}</S.TypeTitle>
                            )}

                            {Array.isArray(result[key]) ? (
                                <ul>
                                    {result[key].map(
                                        item =>
                                            <li key={item}>{item}</li> ||
                                            i18n.t(
                                                "not_tracked_or_no_data_entered"
                                            )
                                    )}
                                </ul>
                            ) : key !== "sub_type" ? (
                                <S.TypeNotes>
                                    {result[key] ? result[key] : "-"}
                                </S.TypeNotes>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    );
                })}
            </S.CellContainer>
        );
    }

    function RolesCell({ value }) {
        const truncatedMessage = value.map(role => {
            return role.name;
        });

        return (
            <S.CellContainer>
                <S.RolesList>
                    {truncatedMessage.map((role, i) => {
                        return <div key={i}>{role}</div>;
                    })}
                </S.RolesList>
            </S.CellContainer>
        );
    }

    function FilesCell(props) {
        const img = props.row.original;
        return (
            <>
                <S.FileContainer>
                    <>
                        {img.files.length ? (
                            img.files.map((file, i) => {
                                return (
                                    <a
                                        href={file.url}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                        key={i}
                                    >
                                        <S.Images src={file.url} />
                                    </a>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </>
                </S.FileContainer>
            </>
        );
    }

    function LocationCell({ value }) {
        return (
            <S.LocationContainer>
                <span>Amsterdam fun casino</span>
            </S.LocationContainer>
        );
    }

    function ActionCell({ value }) {
        const [showPopup, setShowPopup] = useState(false);
        const { t } = useTranslation();
        const history = useHistory();
        const editUser = () => {
            history.push(`/edit-user/${value}`);
        };
        const deleteUser = () => {
            setShowPopup(true);
        };
        const { customToast } = useCustomToast();

        const queryClient = useQueryClient();

        const deleteHandler = (value, params) => {
            client
                .delete(`/api/employee/${value ? value : params.id}`)
                .then(res => {
                    customToast("success", {
                        title: t("entity_successfully_deleted", {
                            entity: t("staff_member"),
                        }),
                    });
                    setShowPopup(false);
                    queryClient.invalidateQueries("employee");
                    history.push("/staff");
                })
                .catch(err => {
                    customToast("error", {
                        title: t("error_sending_data"),
                        body: err.response.data.message,
                    });
                    history.push("/staff");
                    setShowPopup(false);
                });
        };

        return (
            <div style={{ cursor: "default" }}>
                {showPopup && (
                    <DeletePopup
                        setShowPopup={setShowPopup}
                        value={value}
                        deleteHandler={deleteHandler}
                        text="profile"
                    />
                )}
                <S.ActionContainer>
                    <button
                        onClick={e => {
                            e.stopPropagation();
                            editUser();
                        }}
                    >
                        <FontAwesomeIcon icon={faPencil} size="lg" />
                    </button>
                    <button
                        onClick={e => {
                            e.stopPropagation();
                            deleteUser();
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </S.ActionContainer>
            </div>
        );
    }

    function BirthDateCell(props) {
        return (
            <S.CellContainer>
                <S.Date>
                    {props.value
                        ? dayjs(props.value).format("DD-MM-YYYY")
                        : "-"}
                </S.Date>
            </S.CellContainer>
        );
    }

    function WhiteBlackCell(props) {
        const whiteListed = props.row.original.is_white_listed;
        const blackListed = props.row.original.is_black_listed;

        return (
            <>
                {whiteListed && (
                    <FontAwesomeIcon
                        icon={faAddressBook}
                        style={{
                            "--fa-primary-color": "#000",
                            "--fa-secondary-color": "#e9e9e9",
                            "--fa-primary-opacity": "1.0",
                            "--fa-secondary-opacity": "1.0",
                        }}
                        size="2x"
                    />
                )}

                {blackListed && (
                    <FontAwesomeIcon
                        icon={faAddressBook}
                        style={{
                            "--fa-primary-color": "#fff",
                            "--fa-secondary-color": "#000",
                            "--fa-primary-opacity": "1.0",
                            "--fa-secondary-opacity": "1.0",
                        }}
                        size="2x"
                    />
                )}
                {!whiteListed && !blackListed && "-"}
            </>
        );
    }

    function MemberCell(props) {
        const isMember = props.row.original.is_member;

        return (
            <>
                {isMember && (
                    <S.SvgContainer>
                        <FontAwesomeIcon
                            icon={faCrown}
                            style={{
                                "--fa-primary-color": "#eb5757",
                                "--fa-secondary-color": "#eb5757",
                                "--fa-primary-opacity": "0.8",
                                "--fa-secondary-opacity": "1.0",
                            }}
                            size="lg"
                        />
                    </S.SvgContainer>
                )}

                {!isMember && "NO"}
            </>
        );
    }

    function DeleteReports(props) {
        const currentUser = useUserStore().user;
        const queryClient = useQueryClient();
        const [showPopup, setShowPopup] = useState(false);
        const { hasPermissions } = usePermissions();
        const { customToast } = useCustomToast();
        const { t } = useTranslation();

        const hasDeletePermissions = hasPermissions(["delete_messages"]);

        const handleDelete = (value, params) => {
            client.delete(`/api/reports/${value}`).then(res => {
                customToast("success", {
                    title: t("entity_successfully_deleted", {
                        entity: t("report"),
                    }),
                });
                queryClient.invalidateQueries("reports");
                setShowPopup(false);
            });
        };

        return (
            <S.DeleteReports>
                {showPopup && (
                    <DeletePopup
                        setShowPopup={setShowPopup}
                        value={props.row.original.id}
                        deleteHandler={handleDelete}
                        text="report"
                    />
                )}
                <div>{props.value}</div>

                {(props.row.original.employee.id === currentUser.id ||
                    hasDeletePermissions) && (
                    <button
                        onClick={e => {
                            e.stopPropagation();
                            setShowPopup(prev => !prev);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                )}
            </S.DeleteReports>
        );
    }

    function NotesActions(props) {
        const history = useHistory();
        const params = useParams();
        const currentUser = useUserStore().user;
        const [showPopup, setShowPopup] = useState(false);
        const queryClient = useQueryClient();
        const { hasPermissions } = usePermissions();
        const { customToast } = useCustomToast();
        const { t } = useTranslation();

        const hasDeletePermissions = hasPermissions(["delete_notes"]);

        const handleDelete = (value, params) => {
            client.delete(`/api/player/notes/${value}`).then(res => {
                setShowPopup(false);
                queryClient.invalidateQueries(`player/${params.id}`);
                history.push(`/guest/${params.id}`);
                customToast("success", {
                    title: t("entity_successfully_deleted", {
                        entity: t("note"),
                    }),
                });
            });
        };
        const handleEdit = e => {
            e.stopPropagation();
            history.push({
                pathname: `/general-guest-notes/edit/${params.id}`,
                state: {
                    noteId: props.row.original.id,
                    id: params.id,
                },
            });
        };

        return (
            <>
                <S.NotesActions>
                    {showPopup && (
                        <DeletePopup
                            setShowPopup={setShowPopup}
                            value={props.row.original.id}
                            deleteHandler={handleDelete}
                            text="note"
                        />
                    )}
                    {(currentUser?.id === props.row.original.added_by.id ||
                        hasDeletePermissions) && (
                        <>
                            <button onClick={handleEdit}>
                                <FontAwesomeIcon icon={faPencil} />
                            </button>

                            <button
                                onClick={e => {
                                    e.stopPropagation();
                                    setShowPopup(prev => !prev);
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </>
                    )}
                </S.NotesActions>
            </>
        );
    }

    function ReasonCell(props) {
        return <>{props.cell.value.map(key => i18n.t(key)).join(", ")}</>;
    }

    function EntryCodeCell(props) {
        return <>{i18n.t(props.cell.value)}</>;
    }

    function OnlyNameCell(props) {
        return <S.UserNameFull>{props.value}</S.UserNameFull>;
    }

    function TypeCell(props) {
        return <>{i18n.t(props.value)}</>;
    }

    function FollowUpCell(props) {
        return (
            <S.ColoredText success={props.value}>
                {props.value ? i18n.t("done") : i18n.t("followup")}
            </S.ColoredText>
        );
    }

    function SignalsCell(props) {
        return (
            <>
                {props?.row?.values?.signals
                    ? props?.row?.values?.signals?.length
                    : 0}
                /6
            </>
        );
    }

    function ResidenceCell(props) {
        return props.row.original.type === "NL" ||
            props.row.original.residence === "NL" ? (
            <S.ResidenceImageContainer>
                <S.ResidenceImage src={nlFlag} />
            </S.ResidenceImageContainer>
        ) : (
            <S.ResidenceInternational>
                <FontAwesomeIcon
                    icon={faGlobeAmericas}
                    size="2xl"
                    color="#1d1e45"
                />
            </S.ResidenceInternational>
        );
    }

    return {
        EntryCell,
        NameCell,
        NameCellWithDate,
        DateCell,
        MessageCell,
        RolesCell,
        LocationCell,
        ActionCell,
        FilesCell,
        BirthDateCell,
        WhiteBlackCell,
        MemberCell,
        ActivityCell,
        DeleteReports,
        NotesActions,
        ReasonCell,
        EntryCodeCell,
        DateCellOnList,
        OnlyNameCell,
        TypeCell,
        FollowUpCell,
        SignalsCell,
        ResidenceCell,
        ExpandCell,
        GuestMessageCell,
    };
}
export default RenderCells;
