import { useCheckinFields } from "../../store/checkInStore";
import { useLocation } from "react-router-dom";
import { CheckInFields, SelectedGuestFormFields } from "./../../types/checkIn";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import useDebounce from "../../hooks/useDebounce";
import { SearchFields } from "../../types/advancedSearch";
import { SubmitHandler, UseFormReset } from "react-hook-form";
import { Guest } from "../../types/guest";
import { residenceOptions } from "../../utils/formOptions";
import { SelectOption } from "../../types/form";
import { startCase } from "lodash-es";
import toast from "react-hot-toast";
import { convertBase64ToBlob } from "../../utils/convert";
import useCheckinHelpers from "../../hooks/useCheckinHelpers";

type RequestData = {
    type: string | undefined;
    first_name: string;
    inserts: string;
    last_name: string;
    location_of_birth: string;
    date_of_birth: string;
    bsn: string;
    guest_id?: string;
    signature_src?: Blob | null;
};

function useCheckInForm(
    reset: UseFormReset<CheckInFields>,
    setFormFields: Dispatch<SetStateAction<SelectedGuestFormFields | null>>,
    setSelectedGuest: Dispatch<SetStateAction<Guest | null>>,
    selectedGuest: Guest | null,
    updateSuggestions: (arg: SearchFields) => void,
    defaultValues: CheckInFields,
    signature?: {
        generatedSignature: string | null;
        setGeneratedSignature: Dispatch<SetStateAction<string | null>>;
        text: string;
    }
) {
    const location = useLocation();
    const { setFields, fields } = useCheckinFields();
    const [searchFields, setSearchFields] = useState<SearchFields | null>(null);
    const [isChecking, setIsChecking] = useState<boolean>(false);

    const { entryRequest } = useCheckinHelpers();
    const debouncedSearchFields = useDebounce<SearchFields | null>(
        searchFields,
        400
    );

    useEffect(() => {
        if (!debouncedSearchFields) return;
        updateSuggestions(debouncedSearchFields);
    }, [debouncedSearchFields, updateSuggestions]);

    useEffect(() => {
        if (location.state) {
            const resetFields = {
                first_name: location.state.first_name,
                inserts: location.state.inserts,
                last_name: location.state.last_name,
                residence: residenceOptions.find(
                    option => option.value === location.state.type
                ) as SelectOption,
                bsn: location.state.bsn,
                date_of_birth: location.state.date_of_birth,
            };

            reset(resetFields);

            updateSuggestions({
                first_name: location.state.first_name,
                last_name: location.state.last_name,
                date_of_birth: location.state.date_of_birth,
            });
        }
    }, [location, reset, updateSuggestions]);

    const handleFieldUpdate = (value: string, fieldKey: string) => {
        setSelectedGuest(null);
        setSearchFields(fields => {
            return {
                ...fields,
                [fieldKey]: value,
            };
        });
    };

    const resetForm = () => {
        setFormFields(defaultValues);
        reset(defaultValues);
        setSelectedGuest(null);
        setSearchFields({});
        setFields({});
        signature?.setGeneratedSignature(null);
    };

    const onSubmit: SubmitHandler<CheckInFields> = async data => {
        setIsChecking(true);

        if (fields.temp_profile_photo_path) {
            data.temp_profile_photo_path = fields.temp_profile_photo_path;
        }

        if (selectedGuest?.id) {
            data.guest_id = selectedGuest.id;
        }

        const requestData: RequestData = {
            type: data?.residence?.value.toString(),
            first_name: startCase(data.first_name),
            inserts: data.inserts?.toLowerCase() || "",
            last_name: startCase(data.last_name),
            location_of_birth: startCase(data.location_of_birth),
            date_of_birth: data.date_of_birth,
            bsn: data.bsn,
        };

        if (data.guest_id) {
            requestData.guest_id = data.guest_id.toString();
        }

        const formData = new FormData();

        if (signature?.generatedSignature) {
            const signatureBlob = await convertBase64ToBlob(
                signature.generatedSignature
            );

            requestData.signature_src = signatureBlob;
            formData.append("displayed_text", signature.text);
        }

        Object.entries(requestData).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        toast.dismiss("form_filled");

        entryRequest(formData)
            .then(() => {
                resetForm();
            })
            .finally(() => {
                setIsChecking(false);
            });
    };

    return {
        isChecking,
        handleFieldUpdate,
        onSubmit,
        resetForm,
    };
}

export default useCheckInForm;
