import { ReactNode, useState } from "react";
import PageHeader from "../components/PageHeader";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Control } from "react-hook-form";
import dayjs from "dayjs";
import { classNames } from "../utils/style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import queryMaker from "../utils/queryMaker";
import ExportEntryLogFields from "../components/export/ExportEntryLogFields";
import ExportWhitelistFields from "../components/export/ExportWhitelistFields";
import ExportMessagesFields from "../components/export/ExportMessagesFields";
import ExportPreventionFields from "../components/export/ExportPreventionFields";

import {
    BlacklistFormValues,
    EntryLogFormValues,
    GenericFormValues,
    MessagesFormValue,
    PlayPauseFormValues,
    PreventionFormValue,
} from "../types/exportForm";
import ExportBlacklistFields from "../components/export/ExportBlacklistFields";

type Category = {
    name: string;
    component: ReactNode;
    url: string;
};

export default function Export() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm<GenericFormValues>();

    const fromDate = watch("from_date");
    const untilDate = watch("until_date");
    const locations = watch("locations");

    const categories: { [key: string]: Category } = {
        entryLog: {
            name: t("entry_log"),
            component: (
                <ExportEntryLogFields
                    control={control as Control<EntryLogFormValues, any>}
                />
            ),
            url: "entries",
        },
        playPause: {
            name: t("playpause"),
            component: (
                <ExportWhitelistFields
                    control={control as Control<PlayPauseFormValues, any>}
                />
            ),
            url: "play-pause",
        },
        blackList: {
            name: t("blacklist"),
            component: (
                <ExportBlacklistFields
                    control={control as Control<BlacklistFormValues, any>}
                />
            ),
            url: "blacklist",
        },
        preventionPlan: {
            name: t("prevention_plan"),
            url: "prevention",
            component: (
                <ExportPreventionFields
                    control={control as Control<PreventionFormValue, any>}
                />
            ),
        },
        messages: {
            name: "Berichten",
            component: (
                <ExportMessagesFields
                    control={control as Control<MessagesFormValue, any>}
                />
            ),
            url: "messages",
        },
        // floorCount: { name: "Vloer tellingen", component: <></> },
    };

    type ActiveCategories =
        | "messages"
        | "entryLog"
        | "playPause"
        | "blackList"
        | "preventionPlan";

    const [activeCategory, setActiveCategory] =
        useState<ActiveCategories>("entryLog");

    const onSubmit: SubmitHandler<GenericFormValues> = async data => {
        try {
            setIsSubmitting(true);

            const locations = data.locations?.map(item => item.value) || [];
            const columns = data.columns?.map(item => item.value) || [];

            data.key = activeCategory;

            const filters: {
                date_from: string;
                date_until: string;
                locations: (string | number)[];
                type?: string | number;
                sub_type?: string | number;
                types?: (string | number)[];
                reasons?: (string | number)[];
                signals?: (string | number)[];
                reactions?: (string | number)[];
            } = {
                date_from: data.from_date,
                date_until: data.until_date,
                locations,
            };

            if (data.key === "messages") {
                const types = data.types?.map(item => item.value) || [];
                filters.types = types;
            }

            if (data.key === "preventionPlan") {
                const signals = data.signals?.map(item => item.value) || [];
                const reactions = data.reactions?.map(item => item.value) || [];
                const types = data.types?.map(item => item.value) || [];
                filters.signals = signals;
                filters.reactions = reactions;
                filters.types = types;
            }

            if (data.key !== "messages" && data.key !== "preventionPlan") {
                filters.sub_type = data.check_status?.value;
                filters.type = data.type?.value;
            }

            if (data.key === "blackList" || data.key === "playPause") {
                const reasons = data.reasons?.map(item => item.value) || [];
                filters.reasons = reasons;
            }

            const queryString = queryMaker({
                filters,
                columns,
            });

            window.open(
                `${process.env.REACT_APP_API_BASE_URL}/exports/${
                    categories[activeCategory].url
                }?${queryString ?? ""}`
            );
        } catch (e: any) {
            throw new Error(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <PageHeader title={t("export")} />
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 rounded-md border border-gray-100 px-4 py-8 shadow-lg lg:grid-cols-4">
                <ul className="px-2 ">
                    {Object.entries(categories).map(([key, category]) => (
                        <li key={key}>
                            <button
                                className={classNames(
                                    "block w-full border-b  border-gray-200 px-2 py-3 text-left text-lg font-medium text-primary hover:text-secondary",
                                    key === activeCategory
                                        ? "text-secondary"
                                        : "text-primary"
                                )}
                                onClick={() => {
                                    reset({
                                        from_date: fromDate,
                                        until_date: untilDate,
                                        locations,
                                    });
                                    setActiveCategory(key as ActiveCategories);
                                }}
                            >
                                {category.name}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="px-8 py-2 lg:col-span-3 lg:border-l">
                    <h2 className="mb-4 text-2xl font-semibold">
                        {t("export")}: {categories[activeCategory].name}
                    </h2>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="max-w-3xl space-y-6 sm:space-y-5"
                    >
                        <div className="required form-control">
                            <label className="form-label">
                                {t("from_date")}:
                            </label>
                            <input
                                className={classNames(
                                    "form-input col-span-2",
                                    errors.from_date !== undefined && "error"
                                )}
                                type="date"
                                min={dayjs(new Date("2020/06/01")).format(
                                    "YYYY-MM-DD"
                                )}
                                max={dayjs(new Date()).format("YYYY-MM-DD")}
                                {...register("from_date", {
                                    required: true,
                                })}
                            />
                        </div>
                        <div className="required form-control">
                            <label className="form-label">
                                {t("until_date")}:
                            </label>
                            <input
                                className={classNames(
                                    "form-input col-span-2",
                                    errors.from_date !== undefined && "error"
                                )}
                                type="date"
                                min={dayjs(new Date("2020/06/01")).format(
                                    "YYYY-MM-DD"
                                )}
                                max={dayjs(new Date()).format("YYYY-MM-DD")}
                                {...register("until_date", {
                                    required: true,
                                })}
                            />
                        </div>
                        {categories[activeCategory].component}
                        <div className="flex pt-8">
                            <button
                                type="submit"
                                className="btn-primary ml-auto"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <FontAwesomeIcon
                                        icon={faSpinner as IconProp}
                                        className="fa-spin"
                                    />
                                ) : (
                                    t("export")
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
