import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation,
    faCircleXmark,
    faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import useFetch from "../../hooks/useFetch";
import { IconProp, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TranslationsKeys } from "../../types/translations";
import { classNames } from "../../utils/style";
import useOnClickOutside from "../../hooks/useOnClickOutside";

type Services = { cloud: string; cruks: string };
type ServiceStatus = {
    key: TranslationsKeys;
    value: IconDefinition;
};
type IconStatus = {
    name: string;
    icon: IconProp;
};
function SystemStatus() {
    const { t } = useTranslation();
    const systemStatusRef = useRef(null);
    const [statusArray, setStatusArray] = useState<ServiceStatus[]>();
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [systemIcon, setSystemIcon] = useState<IconStatus>({
        name: "loading",
        icon: faSpinnerThird as IconProp,
    });
    const { data: status } = useFetch<Services>("system/status");

    const isOffline = useMemo(() => {
        if (!status) {
            return false;
        }
        return Object.values(status).some(status => status === "offline");
    }, [status]);

    function getStatusArray(services: Services) {
        const statusArray = Object.entries(services).map(([key, value]) => {
            const status =
                value === "online" ? faCircleCheck : faCircleExclamation;
            return { key, value: status } as ServiceStatus;
        });
        return statusArray;
    }
    useOnClickOutside(systemStatusRef, () => setShowStatus(false));

    const updateStatus = useCallback(
        (services: Services) => {
            if (!services)
                setSystemIcon({
                    name: "error",
                    icon: faCircleXmark as IconProp,
                });
            isOffline
                ? setSystemIcon({
                      name: "offline",
                      icon: faCircleExclamation as IconProp,
                  })
                : setSystemIcon({
                      name: "online",
                      icon: faCircleCheck as IconProp,
                  });
            setStatusArray(getStatusArray(services));
        },
        [isOffline]
    );

    useEffect(() => {
        if (!status) return;
        updateStatus(status);
    }, [status, updateStatus]);

    return (
        <div className="relative">
            <button
                className="hidden lg:block ml-20 -mr-8"
                onClick={() => setShowStatus(prevState => !prevState)}
            >
                <div
                    className="mx-auto flex h-16 lg:drop-shadow-[0_1px_1px_rgba(0,0,0,0.15)]"
                    ref={systemStatusRef}
                >
                    <svg
                        preserveAspectRatio="none"
                        viewBox="0 0 50 100"
                        className="w-6 fill-alternative"
                    >
                        <polygon points="0,100,50,0,100,100"></polygon>
                    </svg>

                    <div className="flex w-32 items-center justify-center bg-alternative px-2 leading-10">
                        <div
                            className={
                                "flex w-36 items-center justify-center text-lg"
                            }
                            data-tip="status"
                        >
                            <div className="text-black">{t("system")}</div>
                            <span className="ml-2 h-6 w-6 ">
                                <FontAwesomeIcon
                                    className={classNames(
                                        `h-6 w-6 ${
                                            systemIcon.name === "online"
                                                ? "text-success"
                                                : "text-warning"
                                        }`,
                                        systemIcon.name === "loading" &&
                                            "fa-spin"
                                    )}
                                    icon={systemIcon.icon as IconProp}
                                />
                            </span>
                        </div>{" "}
                    </div>
                    <svg
                        preserveAspectRatio="none"
                        viewBox="0 0 50 100"
                        className="w-6 fill-alternative"
                    >
                        <polygon points="0,100,-40,0,100,-100"></polygon>
                    </svg>
                </div>
            </button>
            <div
                className={`absolute top-16 right-0 mt-1 block w-48 space-y-4 rounded-md bg-[#fffbfb] py-2 pt-2 shadow-2xl ${
                    showStatus ? "absolute" : "hidden"
                }`}
            >
                {statusArray?.map(status => {
                    return (
                        <div
                            key={status.key}
                            className="flex w-full items-center"
                        >
                            <div className="flex w-1/3 items-center justify-center">
                                <FontAwesomeIcon
                                    className={`h-6 w-6 ${
                                        status.value === faCircleCheck
                                            ? "text-success"
                                            : "text-warning"
                                    }`}
                                    icon={status.value as IconProp}
                                />
                            </div>
                            <div className="flex w-2/3 justify-start uppercase text-black">
                                <span>{t(status.key) as string}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SystemStatus;
