import * as S from "./VersionInformationBlocks.styled";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import VersionInformationContent from "../VersionInformationContent/VersionInformationContent";

function VersionInformationBlock() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = () => {
        history.push(`/version-information`);
    };

    return (
        <S.VersionInformationBlocks>
            <S.BlockTitle>
                {t("version_information")}
                <button onClick={handleClick}>
                    {t("see_all")}
                    <FontAwesomeIcon
                        icon={faChevronRight as IconProp}
                        style={{
                            marginLeft: "5px",
                        }}
                    />
                </button>
            </S.BlockTitle>
            <S.BlockBody>
                <VersionInformationContent versionsToShow={3} />
            </S.BlockBody>
        </S.VersionInformationBlocks>
    );
}

export default VersionInformationBlock;
