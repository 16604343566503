import React, { useEffect, useReducer, useState } from "react";
import { useParams, Link } from "react-router-dom";
import * as S from "./FacilitiesCheck.styled";
import PageHeader from "../../components/PageHeader";
import FacilitiesCheckRow from "./FacilitiesCheckRow";
import TemplateOptions from "../../components/TemplateOptions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { useUserStore } from "../../store/userStore";
import checklistReducer from "../../reducers/checklistReducer";
import client from "../../client";
import useExitPrompt from "../../hooks/useExitPrompt";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import { useLocation } from "react-router";
import { useSetSelectedTemplate } from "../../hooks/useSetSelectedTemplate";
import useCustomToast from "../../hooks/useCustomToast";
import Button from "../../components/Button";

function FacilitiesCheck() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const history = useHistory();
    const [checklist, dispatch] = useReducer(checklistReducer, []);
    const { id } = useParams();
    const [isNew, setIsNew] = useState(false);
    const [templateUserId, setTemplateUserId] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [checklistStatus, setChecklistStatus] = useState({
        isModified: false,
        requestIsSuccess: false,
    });
    const { hasPermissions } = usePermissions();
    const { user } = useUserStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasEditPermissions = hasPermissions(["view_settings"]);
    const { customToast } = useCustomToast();
    const canEdit = isNew || hasEditPermissions || user.id === templateUserId;

    useExitPrompt(checklistStatus.isModified);

    let urlData = useLocation();
    useSetSelectedTemplate(urlData, setSelectedTemplateId);

    useEffect(() => {
        setChecklistStatus(prevState => {
            return {
                ...prevState,
                isModified:
                    checklist.find(item => item.isModified) !== undefined,
            };
        });
    }, [checklist]);

    useEffect(() => {
        checklistStatus.requestIsSuccess && history.push("/facilities-check");
    }, [checklistStatus.requestIsSuccess, history]);

    useEffect(() => {
        if (!id) return;

        if (id === "new") {
            setIsNew(true);
        } else {
            // We are editing an existing checklist
            client
                .get(`/api/facility-checks/${id}`)
                .then(res => {
                    const data = res.data.data;
                    setTemplateUserId(data.user_id);
                    setSelectedTemplateId(data.facility_check_template_id);
                    dispatch({
                        type: "listReady",
                        payload: data.facility_check_items || [],
                    });
                })
                .catch(err => {
                    if (err.isAxiosError) {
                        customToast("error", {
                            title: t("error_data_could_not_be_sent"),
                            body: err.response.data.message,
                        });
                    }
                });
        }
    }, [id, t, customToast]);

    const sendRequest = async postData => {
        try {
            if (isNew) {
                await client.post(`/api/facility-checks`, postData);
            } else {
                await client.put(`/api/facility-checks/${id}`, postData);
            }
            customToast("success", {
                title: t("facilities_check_updated"),
            });
            queryClient.invalidateQueries("facility-checks");
            setChecklistStatus({
                isModified: false,
                requestIsSuccess: true,
            });
        } catch (err) {
            if (err.isAxiosError) {
                customToast("error", {
                    title: t("error_data_could_not_be_sent"),
                    body: err.response.data.message,
                });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const saveChanges = () => {
        setIsSubmitting(true);
        const checklistData = checklist.map(
            ({ id, user_id, checked, checked_at, notes }) => {
                return {
                    id,
                    user_id: user_id || null,
                    checked,
                    checked_at,
                    notes,
                };
            }
        );

        const postData = {
            facility_check_template_id: selectedTemplateId,
            facility_check_items: checklistData,
        };

        sendRequest(postData);
    };

    return (
        <>
            <PageHeader
                title={t("facilities_check")}
                showLists={false}
                showSearch={false}
                back={true}
            />
            {isNew && (
                <TemplateOptions
                    dispatch={dispatch}
                    selectedTemplateId={selectedTemplateId}
                    setSelectedTemplateId={setSelectedTemplateId}
                />
            )}
            <S.Table>
                <S.RowHeader>
                    <S.Cel>{t("item")}</S.Cel>
                    <S.Cel>{t("checked")}</S.Cel>
                    <S.Cel>{t("by")}</S.Cel>
                    <S.Cel>{t("notes")}</S.Cel>
                </S.RowHeader>
                {checklist?.map(item => (
                    <FacilitiesCheckRow
                        key={item.id}
                        item={item}
                        dispatch={dispatch}
                        user={user}
                        canEdit={canEdit}
                    />
                ))}
            </S.Table>
            <S.Bottom>
                {canEdit && (
                    <Button
                        className="btn-primary"
                        disabled={!checklistStatus.isModified}
                        onClick={() => saveChanges()}
                        isLoading={isSubmitting}
                    >
                        {t("save")}
                    </Button>
                )}
                {hasEditPermissions && (
                    <S.Edit
                        as={Link}
                        to={{
                            pathname: "/settings/facilities-check",
                            state: selectedTemplateId,
                        }}
                    >
                        <>
                            <FontAwesomeIcon icon={faPencil} />
                            <span>{t("edit_form_fields")}</span>
                        </>
                    </S.Edit>
                )}
            </S.Bottom>
        </>
    );
}

export default FacilitiesCheck;
