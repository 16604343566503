import styled from "styled-components";

export const Popup = styled.div`
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CloseButton = styled.button`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
`;

export const PopupContainer = styled.div`
    background: #fff;
    border-radius: 4px;
    padding: 40px;
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: black;
`;
