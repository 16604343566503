import * as S from "./ScanLoyaltyCardPopup.styled";
import Popup from "../Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import {
    faCreditCardBlank,
    faHdd,
    faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";
import { useUserStore } from "../../../store/userStore";
import socket from "../../../socket";
import { RFIDScanEvent } from "../../../types/event";
import usePopup from "../../../hooks/usePopup";
import { matchLocalDevice } from "../../../utils/matchLocalDevice";
import { Status } from "./types";
import Button from "../../Button";

type LayoutMap = {
    [key in Status]: {
        icon: IconProp;
        text: string;
    };
};

function ScanLoyaltyCardPopup({
    updateLoyaltyCard,
}: {
    updateLoyaltyCard: (code: string) => void;
}) {
    const { t } = useTranslation();
    const [status, setStatus] = useState<Status>("default");

    const { close } = usePopup();

    const { user } = useUserStore();

    useEffect(() => {
        if (!user) return;

        const channel = `Location.${user.current_location_id}.rfid`;

        const loyaltyCardEventHandler = (event: RFIDScanEvent) => {
            if (!matchLocalDevice(event.device_id, "rfid")) {
                setStatus("no_connection");
                return;
            }

            if (event.data.rfid) {
                setStatus("scanned");
                updateLoyaltyCard(event.data.rfid);
            }
        };

        const takenLoyaltyCardHandler = (event: RFIDScanEvent) => {
            setStatus("taken");
        };

        socket
            .private(channel)
            .listen("NewRFIDScannedEvent", loyaltyCardEventHandler)
            .listen("TakenRFIDScannedEvent", takenLoyaltyCardHandler);

        return () => {
            socket.private(channel).stopListening("NewRFIDScannedEvent");
            socket.private(channel).stopListening("TakenRFIDScannedEvent");
            socket.leave(channel);
        };
    }, [user, updateLoyaltyCard]);

    const layoutMap: LayoutMap = {
        default: {
            icon: faCreditCardBlank as IconProp,
            text: t("scan_a_loyalty_card"),
        },
        scanned: {
            icon: faCheck as IconProp,
            text: t("loyalty_card_successfully_added"),
        },
        taken: {
            icon: faExclamationTriangle as IconProp,
            text: t("card_is_already_used"),
        },
        no_connection: {
            icon: faHdd as IconProp,
            text: t("device_is_not_connected"),
        },
    };

    return (
        <Popup>
            <S.Box>
                <S.Header>{t("add_loyalty_card")}</S.Header>
                <S.ScanContent>
                    <>
                        <S.ScanImage status={status}>
                            <FontAwesomeIcon icon={layoutMap[status].icon} />
                        </S.ScanImage>
                        <span>{layoutMap[status].text}</span>
                    </>
                </S.ScanContent>
                <S.Bottom isActive={status === "scanned"}>
                    <Button className="btn-secondary" onClick={close}>
                        {t("ok")}
                    </Button>
                </S.Bottom>
            </S.Box>
        </Popup>
    );
}

export default ScanLoyaltyCardPopup;
