import styled from "styled-components";
export { FormControl } from "../../../style/shared/form.styled";

export const SearchContainer = styled.div`
    /* background: rgba(241, 234, 234, 0.6); */
    width: 100%;
    border-bottom: ${props =>
        props.error ? "2px solid #e65f5f" : "1px solid #828282"};

    margin-top: -12px;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    svg {
        height: 30px;
        color: rgba(0, 0, 0, 0.87);
        margin-right: 5px;
        z-index: 10;
        cursor: pointer;
    }
    ${props =>
        props.required &&
        `
            &:after {
                content: "*";
                margin-left: 2px;
                color: ${props.theme.main.secondaryColor};
            }        
        `}
`;

export const Search = styled.input`
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    color: black;
    font-size: 16px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: ${props => (props.error ? "#e65f5f" : "#bdbdbd")};
    }
`;

export const ResultsContainer = styled.div`
    width: 340px;
    max-height: 400px;
    position: absolute;
    top: 110%;
    background: white;
    border: 1px solid rgb(224, 224, 224);
    z-index: 999;
    overflow-y: scroll;

    > button {
        font-size: 16px;
        height: 50px;
        width: 100%;
        border-bottom: 1px solid rgb(224, 224, 224);
        transition: 0.2s;
        overflow: auto;
        &:hover {
            background: rgba(241, 234, 234, 0.6);
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    > div {
        font-size: 16px;
        height: 50px;
        width: 100%;
        border-bottom: 1px solid rgb(224, 224, 224);
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
