import React, { useRef, useEffect } from "react";
import * as S from "./Popups.styled";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import Button from "../Button";
export default function EditCommentPopup({
    id,
    value,
    setShowPopup,
    handleUpdate,
    text,
    checkActive,
}) {
    const { t } = useTranslation();
    const popupRef = useRef(null);

    useOnClickOutside(popupRef, () => setShowPopup(null));

    const {
        register,
        formState: { errors },
        setValue,
        watch,
    } = useForm();

    useEffect(() => {
        setValue("comment", value);
    }, [setValue, value]);

    const newValue = watch("comment");

    return (
        <S.EditContainer>
            <S.EditFormContainer
                ref={popupRef}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                        "--fa-primary-color": "#1D1E45",
                        "--fa-secondary-color": "#1D1E45",
                        "--fa-primary-opacity": "1.0",
                        "--fa-secondary-opacity": "0.9",
                    }}
                    onClick={() => setShowPopup(false)}
                />
                <S.Title>
                    {t(
                        "update_this_entity",
                        { entity: t(text) },
                        "update_this_entity"
                    )}
                </S.Title>
                <S.FormControlExtension>
                    <textarea
                        placeholder="Comment"
                        draggable="false"
                        rows="10"
                        columns="50"
                        {...register("comment")}
                        prop={errors.removal_reason}
                        style={{ marginTop: "30px" }}
                    />
                </S.FormControlExtension>
                <S.ButtonsEdit>
                    <Button
                        className="btn-alternative"
                        onClick={e => {
                            e.stopPropagation();
                            setShowPopup(false);
                        }}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => handleUpdate(id, newValue)}
                        disabled={checkActive}
                        className="btn-primary"
                    >
                        {checkActive ? (
                            <FontAwesomeIcon
                                icon={faSpinnerThird}
                                className="fa-spin"
                            />
                        ) : (
                            t("update_this_entity", { entity: t(text) })
                        )}
                    </Button>
                </S.ButtonsEdit>
            </S.EditFormContainer>
        </S.EditContainer>
    );
}
