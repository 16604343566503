import styled from "styled-components";
export {
    Form,
    FormControl,
    Select,
    AddItem,
    RemoveItem,
} from "../../style/shared/form.styled";
export { SignatureContainer } from "../../style/shared/signature.styled";

export const RegisterPlayer = styled.div`
    display: flex;
`;

export const CheckMark = styled.div`
    top: -3px;
    position: relative;
    svg {
        color: ${({ theme }) => theme.main.successColor};
        font-size: 30px;
    }
`;

export const PhotoArea = styled.div`
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background: rgba(242, 242, 242, 1);
    display: flex;
    flex: 0 0 256px;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    position: relative;
    label {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        z-index: 2;
        &:hover {
            cursor: pointer;
        }
    }
    svg {
        font-size: 50px;
    }
`;

export const AddPhoto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > svg {
        margin-bottom: 10px;
    }
    > span {
        text-decoration: underline;
        font-weight: 700;
    }
`;

export const EditPhoto = styled.div`
    width: 100%;
    height: 100%;
    min-width: 280px;
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > label {
        width: 61px;
        height: 61px;
        background: #e0e0e0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 220px;
        left: 200px;
        font-size: 10px;
        > svg {
            font-size: 30px;
            cursor: pointer;
        }
    }
`;

export const UserPhoto = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 280px;
    min-height: 280px;
    max-width: 280px;
    max-height: 280px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
`;

export const FormSection = styled.div`
    width: 600px;
`;

export const FormBottom = styled.div`
    margin-top: 40px;
    display: flex;
    gap: 40px;
`;

export const SignatureWrapper = styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
    ${props => props.show && `height: auto; width: auto;`}
`;

export const CheckBoxes = styled.div`
    display: flex;
    margin: 40px 0 20px 0;
    flex-direction: column;
    border: 1px solid ${props => props.theme.main.borderColor};
    border-radius: 5px;
    padding: 20px;
`;

export const CheckBox = styled.div`
    margin-bottom: 10px;
    ${({ theme, error }) => error && `color: ${theme.main.errorColor};`}
    ${({ theme, required }) =>
        required &&
        `
        &:after {
            content: "*";
            margin-left: 2px;
            color: ${theme.main.errorColor};
        }        
    `}  
    &:last-child {
        margin: 0;
    }
    input {
        margin-right: 10px;
    }
`;

export const IconProfileWebcamPhoto = styled.div`
    width: 61px;
    height: 61px;
    background: #e0e0e0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 210px;
    left: 15px;
    z-index: 5;

    button {
        width: 100%;
        height: 100%;
    }

    svg {
        font-size: 30px;
        cursor: pointer;
    }
`;
