import styled from "styled-components";

export {
    SelectionOptions,
    SelectionButton,
    DeleteButton,
    EditButton,
    SelectionButtonContainer,
    SelectionButtonEdit,
} from "../../style/shared/selectionOptions.styled";

export const AddNewField = styled.button`
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0;
    height: 30px;
    svg {
        margin-right: 6px;
    }

    span {
        display: inline-block;
    }

    &:after {
        content: "";
        height: 1px;
        bottom: -3px;
        left: 0;
        right: 0;
        background: black;
        position: absolute;
    }
`;

export const AddContainer = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
`;

export const AddFieldConfirm = styled.button`
    height: 30px;
    font-size: 16px;
    min-width: auto;
    border-radius: 4px;
    margin-left: 10px;
    background: blue;
    color: #fff;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AddField = styled.div`
    display: flex;
    input {
        border: 0;
        border-bottom: 1px solid black;
        padding: 6px;
        height: 30px;
        width: 200px;
    }
`;
