import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import useCheckInNotifications from "./useCheckInNotifications";
import {
    CheckGuestResponse,
    CheckGuestError,
    Message,
} from "../types/api/checkGuest";
import { AxiosError, AxiosResponse } from "axios";
import client from "../client";
import { EntryRequestData } from "../types/checkIn";
import useCustomToast from "./useCustomToast";
import dayjs from "dayjs";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useUserStore } from "../store/userStore";

function useCheckinHelpers() {
    const {
        notificationApproved,
        notificationOfflineApproved,
        notificationDenied,
    } = useCheckInNotifications();

    const { t } = useTranslation();
    const { customToast } = useCustomToast();
    const queryClient = useQueryClient();
    const { user } = useUserStore();

    const handleButtonClick = useCallback(
        (message: Message, action: "read" | "snooze") => {
            const endpoint = `/api/players/messages/${message.id}/${action}`;
            const query = `guests/${message.player_id}/activity`;

            client
                .put(endpoint)
                .then(() => {
                    queryClient.invalidateQueries(query);
                })
                .catch(err => {
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: err.response.data.message,
                    });
                });
        },
        [customToast, queryClient, t]
    );

    const responseAction = useCallback(
        (res: AxiosResponse<CheckGuestResponse>) => {
            if (
                res.data.BlacklistCheck &&
                res.data.WhitelistCheck &&
                res.data.CRUKSCheck
            ) {
                const today = dayjs().format("DD-MM");
                const birthday = dayjs(res.data.player.date_of_birth).format(
                    "DD-MM"
                );

                const age = dayjs().diff(
                    res.data.player.date_of_birth,
                    "years"
                );

                const youthAge = 24;

                if (age < youthAge) {
                    customToast("warning", {
                        title: t(`guest_younger_than_24`, {
                            name: res.data.player.full_name,
                        }),
                        image: {
                            src: res.data.player.profile_photo_url,
                            url: `/guest/${res.data.player.id}`,
                        },
                    });
                }

                if (today === birthday) {
                    customToast("birthday", {
                        title: t(`its_name_birthday`, {
                            name: res.data.player.full_name,
                        }),
                        image: {
                            src: res.data.player.profile_photo_url,
                            url: `/guest/${res.data.player.id}`,
                        },
                    });
                }

                if (
                    res.data.player.needs_to_sign_for_no_bsn &&
                    user?.current_location.has_signature_pad
                ) {
                    customToast("warning", {
                        title: t("guest_needs_to_declare_bsn"),
                    });
                }

                if (res.data.messages) {
                    res.data.messages.forEach(message => {
                        const { player } = res.data;
                        const {
                            full_name,
                            id: playerId,
                            profile_photo_url,
                        } = player;

                        customToast("checkInMessage", {
                            title: t("check_in_message"),
                            image: {
                                src: profile_photo_url,
                                url: `/guest/${playerId}`,
                            },
                            subTitle: {
                                text: full_name,
                                url: `/guest/${playerId}`,
                            },
                            body: message.body,
                            buttons: {
                                primary: {
                                    onClick: () =>
                                        handleButtonClick(message, "read"),
                                    className: "btn-primary",
                                    children: (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle as IconProp}
                                                className="text-2xl"
                                            />
                                            <span>{t("read")}</span>
                                        </>
                                    ),
                                },
                                secondary: {
                                    onClick: () =>
                                        handleButtonClick(message, "snooze"),
                                    className: "btn-secondary",
                                    children: (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faTimesCircle as IconProp}
                                                className="text-2xl"
                                            />
                                            <span>{t("snooze")}</span>
                                        </>
                                    ),
                                },
                            },
                        });
                    });
                }

                res.data.CRUKSOnline
                    ? notificationApproved(res)
                    : notificationOfflineApproved(res);
            } else {
                notificationDenied(res);
            }
        },
        [
            user?.current_location.has_signature_pad,
            notificationApproved,
            notificationOfflineApproved,
            customToast,
            t,
            handleButtonClick,
            notificationDenied,
        ]
    );

    const requestErrorHandling = useCallback(
        (e: AxiosError<CheckGuestError>) => {
            if (!e.response) return;
            const { status, data } = e.response;

            switch (status) {
                case 422:
                    customToast("error", {
                        title: t("invalid_data"),
                        body: data.errors.bsn
                            ? t("invalid_bsn")
                            : t("check_input_data"),
                    });
                    break;
                default:
                    customToast("error", {
                        title: t("error_data_could_not_be_sent"),
                        body: data.message,
                    });

                    break;
            }
        },
        [customToast, t]
    );

    const entryRequest = useCallback(
        async (data: EntryRequestData | FormData) => {
            try {
                const result = await client.post<CheckGuestResponse>(
                    "/api/entries",
                    data
                );

                result && responseAction(result);
                queryClient.invalidateQueries("entries?unique=1");
                return Promise.resolve();
            } catch (e: any) {
                if (e.isAxiosError) {
                    requestErrorHandling(e);
                }
                return Promise.reject();
            }
        },
        [requestErrorHandling, responseAction, queryClient]
    );

    return {
        responseAction,
        entryRequest,
    };
}

export default useCheckinHelpers;
