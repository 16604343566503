import { classNames } from "../../utils/style";

function DropDownContainer({
    isOpen,
    children,
}: {
    isOpen: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames(
                "absolute z-10 m-1 h-auto min-w-[100px] max-w-[400px] rounded bg-[#fffbfb] p-0 shadow-md transition",
                isOpen ? "block" : "hidden"
            )}
        >
            {children}
        </div>
    );
}

export default DropDownContainer;
