import * as S from "./Systems.styled";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import SystemStatusBlock from "../../components/SystemStatusBlock/SystemStatusBlock";
import DevicesBlock from "../../components/DevicesBlock/DevicesBlock";
import VersionInformationBlocks from "../../components/VersionInformationBlocks/VersionInformationBlocks";

export default function Systems() {
    const { t } = useTranslation();

    return (
        <div>
            <PageHeader
                title={t("system_status")}
                showLists={false}
                showRegisterButton={false}
                showSearch={false}
            />
            <SystemStatusBlock />
            <S.Blocks>
                <DevicesBlock />
                <VersionInformationBlocks />
            </S.Blocks>
        </div>
    );
}
