import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFlag,
    faExclamationSquare,
    faSquareList,
    faMessageLines,
    faNote,
} from "@fortawesome/pro-light-svg-icons";
import { faSquareList as faSquareListBlack } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RootDataGuest } from "../../types/guest";

const PlayerCardActions = ({ playerData }: { playerData: RootDataGuest }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const actionParams = new URLSearchParams(location.search);
    const actionParamsValue = actionParams.get("action");

    const playerActions = [
        {
            icon: faFlag,
            label: t("prevention_action"),
            key: "prevention_action",
        },
        {
            icon: faExclamationSquare,
            label: playerData.player.active_visit_limit
                ? t("update_visit_limit")
                : t("add_visit_limit"),
            key: "visit_limit",
        },
        {
            icon: faSquareList,
            label: playerData?.whitelist?.id
                ? t("update_playpause")
                : t("add_playpause"),
            key: "playpause",
        },
        {
            icon: faSquareListBlack,
            label: playerData?.blacklist?.id
                ? t("update_blacklist")
                : t("add_blacklist"),
            key: "blacklist",
        },
        {
            icon: faMessageLines,
            label: t("add_check_in_message"),
            key: "check_in_message",
        },
        {
            icon: faNote,
            label: t("add_note_about_guest"),
            key: "note",
        },
    ];

    const handleAction = (value: string) => {
        const params = new URLSearchParams();
        params.set("action", value);

        history.push({
            pathname: history.location.pathname,
            search: `?${params.toString()}`,
        });
    };

    return (
        <div className="relative h-[355px] rounded-lg bg-[#FFFBFB] shadow-md">
            <div className="flex h-12 w-full items-center rounded-t-lg bg-dark-secondary pl-5 text-xl text-[#FFFBFB]">
                {t("actions")}:
            </div>
            {playerActions.map(({ icon, label, key }) => (
                <div
                    key={key}
                    onClick={() => handleAction(key)}
                    className={`my-5 flex cursor-pointer items-center justify-center ${
                        actionParamsValue === key
                            ? "text-[#278AB0]"
                            : "text-black"
                    } hover:text-[#278AB0]`}
                >
                    <FontAwesomeIcon
                        icon={icon as IconProp}
                        size="lg"
                        className="w-1/6"
                    />
                    <div className="w-5/6 text-lg">{label}</div>
                </div>
            ))}
        </div>
    );
};

export default PlayerCardActions;
