import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    &:before {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
        display: block;
        background: url(${props => props.backgroundLogin});
        background-size: cover;
        width: 100%;
        height: 100%;
        filter: blur(13px);
        background-position-y: -300px;
    }
`;

export const Mask = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
`;

export const Container = styled.div`
    margin: auto;
`;

export const UserAvatar = styled.div`
    height: 96px;
    width: 96px;
    margin: 0 auto 40px;
    background-color: #f5f5f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        color: #c3cbcd;
    }
`;

export const Logo = styled.img`
    margin-bottom: 100px;
`;

export const ErrorContainer = styled.div`
    width: 348px;
    background: rgba(252, 249, 249, 0.7);
    border-radius: 10px;
    padding: 14px;
    display: flex;
    align-items: center;
    position: absolute;
    top: -90px;
    > svg {
        color: #eb5757;
        font-size: 26px;
        margin-right: 12px;
    }
`;

export const ErrorMessage = styled.div``;

export const ErrorTitle = styled.span`
    font-size: 22px;
    color: #eb5757;
    display: block;
    margin-bottom: 6px;
`;

export const ErrorText = styled.span`
    display: block;
    font-size: 14px;
`;

export const Inputs = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const Title = styled.div`
    width: 100%;
    max-width: 500px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
`;

export const FormControl = styled.div`
    color: #fff;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    > svg {
        position: absolute;
        right: 0;
        cursor: pointer;
    }
    > input {
        width: 350px;
        height: 45px;
        background: none;
        color: #fff;
        border: none;
        border-bottom: 1px solid #fffbfb;

        &::placeholder {
            color: #fff;
            transition: 0.2s ease-in-out;
        }
        &:focus::placeholder {
            color: transparent;
        }
    }
`;

export const SelectInputParent = styled.div`
    width: 350px;
`;

export const SelectInput = styled.div`
    width: 350px;
    height: 45px;
    background: none;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    user-select: none;
    cursor: pointer;
`;

export const SelectInputOptions = styled.div`
    width: 300px;
    background: #fff;
    border-radius: 4px;
    color: #000000;
    display: flex;
    flex-direction: column;
    user-select: none;
    position: absolute;
    top: 40px;
    cursor: pointer;
`;

export const InputOption = styled.div`
    width: 100%;
    padding: 20px;
    background: none;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: rgba(200, 200, 200, 0.3);
    }
`;

export const Divider = styled.div`
    width: 90%;
    height: 1px;
    background: #bdbdbd;
    margin: 0 auto;

    &:last-child {
        display: none;
    }
`;
