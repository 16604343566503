import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    max-width: 1200px;
    width: 100%;
    gap: 20px;
`;
export const Box = styled.div`
    background: #fffbfb;
    border-radius: 10px;
    width: calc(100% * (4 / 8) - 10px - 1px);
    height: auto;
    filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.1));
    width: ${props => (props.fullwidth ? "100%" : "")};
    @media screen and (max-width: ${props => props.theme.layout.bpXL}) {
        width: 100%;
    }
`;
export const BoxHeader = styled.div`
    color: ${props => props.color};
    background: ${props => props.background};
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
    > svg {
        margin-right: 10px;
        filter: drop-shadow(0px 2px 11px rgba(0, 0, 0, 0.15));
    }
    font-size: 20px;
    line-height: 23px;
`;

export const BoxContent = styled.div`
    padding: 20px 40px;
    list-style: none;
    > ul {
        text-indent: -25px;
        margin-left: 25px;
        > li {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 18px;
            line-height: 21px;
            list-style-type: disc;
            list-style-position: inside;
        }
    }
    > ol {
        margin-left: 25px;
        > li {
            font-size: 18px;
            line-height: 30px;
        }
    }
    font-size: 16px;
    line-height: 18px;
    color: #050505;
`;

export const BoxSubHeader = styled.div`
    color: #c54d34;
    font-size: 20px;
    line-height: 23px;
    margin-top: 10px;
    margin-bottom: 15px;
`;
